import { useSessionStore } from 'store/session-store';

export const hasPower = (power: string): boolean => {
  const userPowers = useSessionStore.getState().session?.powers;
  return Boolean(userPowers?.some(({ resource, effect }) => resource === power && effect === 'Allow'));
};

export const recommendationBypassSharingRestrictions = 'mc/recommendation_bypass_sharing_restrictions';
export const amWriteProtectedAttributes = 'am/write_protected_attributes';
export const amAssetProtectionRead = 'am/asset_protection_read';
export const amAssetProtectionEdit = 'am/asset_protection_upsert';
export const amAffiliationProtectionRead = 'am/affiliation_asset_protection_read';
export const amAffiliationProtectionEdit = 'am/affiliation_asset_protection_upsert';
export const amAffiliationEdit = 'um/affiliation_upsert';
