import React from 'react';
import { observer } from 'mobx-react-lite';

import { IFiltersHandlers } from 'helpers/filters';

import { DataSectionStore } from 'store/data-section-store';
import { IContact } from 'types';
import { useStore } from 'store';
import { IContactFilters } from 'components/contact-filters';

import { SmartGroupFilterWrapper } from 'components/smart-group-filter-wrapper/smart-group-filter-wrapper';
import { ContactEssentialFilters } from 'components/contact-filters/contact-essential-filters';
import { ContactSettingsFilters } from 'components/contact-filters/contact-settings-filters';
import { ContactTimestampFilters } from 'components/contact-filters/contact-timestamps-filters';
import { FILTER_SECTIONS } from 'utils/constants';

import './styles.scss';

export const SmartGroupContactFilters: React.FC<{
  dataSectionStore: DataSectionStore<IContact>;
  filterValues: IContactFilters;
  filterHandlers: IFiltersHandlers<IContactFilters>;
}> = observer(({ dataSectionStore, filterValues, filterHandlers }) => {
  const {
    basicStore: { divisions },
  } = useStore();

  return (
    <>
      <SmartGroupFilterWrapper title={FILTER_SECTIONS.ESSENTIALS}>
        <ContactEssentialFilters
          filterValues={filterValues}
          filterHandlers={filterHandlers}
          dataSectionStore={dataSectionStore}
          divisions={divisions}
          isSmartGroupFilters
        />
      </SmartGroupFilterWrapper>
      <div className="smart-group-filters-row">
        <SmartGroupFilterWrapper title={FILTER_SECTIONS.SETTINGS}>
          <ContactSettingsFilters
            filterValues={filterValues}
            filterHandlers={filterHandlers}
            dataSectionStore={dataSectionStore}
            isSmartGroupFilters
          />
        </SmartGroupFilterWrapper>
        <SmartGroupFilterWrapper title={FILTER_SECTIONS.TIMESTAMPS} childrenClassName="timestamps-filter__content">
          <ContactTimestampFilters filterValues={filterValues} filterHandlers={filterHandlers} />
        </SmartGroupFilterWrapper>
      </div>
    </>
  );
});
