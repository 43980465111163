import React, { useCallback } from 'react';

import { useStore } from 'store';

import { FormSubmitSection } from 'components/form-submit-section';
import { IUploadType } from 'components/upload-preview-image';
import {
  SubtitleFileUpload,
  SubtitleFormCommonParts,
  allowedSubtitlesExtensions,
} from 'components/asset/subtitle-form';

import { useUppyUpload } from 'utils/hooks';
import { useForm } from 'helpers/form';
import { Model } from 'helpers/filters/types';
import { IFile, ISubtitle } from 'types';

export type IUploadSubtitleForm = Omit<ISubtitle, 'asset_id'>;

export interface IUploadSubtitleProps {
  message?: string;
  type?: IUploadType;
  onSubmit: (subtitle: IUploadSubtitleForm, file?: IFile | null) => void;
}

const initialValues: IUploadSubtitleForm = {
  label: 'uploaded',
  language_id: '',
  purpose: 'subtitle',
  file_attachment: '',
  creation_type: undefined,
  show_in_player: true,
};

const UploadSubtitleDialogBody: React.FC<IUploadSubtitleProps> = ({
  type,
  message = 'Select a file to upload',
  onSubmit,
}) => {
  const { dialogStore, toastStore } = useStore();

  const { formData, handlers, valid, values } = useForm<IUploadSubtitleForm>(initialValues, Model.SUBTITLES);

  const { progress, uploadFinished, dropAreaId, file } = useUppyUpload(type, allowedSubtitlesExtensions);

  const handleSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        await onSubmit(values, file.current);
        toastStore.success('Subtitle upload in progress.');
        return dialogStore.close();
      } catch (error) {
        toastStore.error(error.text);
        return dialogStore.close();
      }
    },
    [onSubmit, values, file, toastStore, dialogStore],
  );

  return (
    <>
      <form onSubmit={handleSubmit}>
        <SubtitleFormCommonParts formData={formData} handlers={handlers} />
        <SubtitleFileUpload dropAreaId={dropAreaId} progress={progress} message={message} />
      </form>
      <FormSubmitSection
        labels={{ confirm: 'Upload' }}
        submitDisabled={!uploadFinished || !valid}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default UploadSubtitleDialogBody;
