import React from 'react';
import { Button } from '@mantine/core';

import { DetailsPageTabs, GroupTypes, IGroup } from 'types';
import { IUseActionsOptionParam, Pages } from 'utils/actions';

import { IActionName } from 'utils/actions/types';
import { useAddEntitiesToGroupAction } from 'utils/actions/common';
import { MantineIcon } from 'utils/ui/icon';

interface IAddItemsToEventButtonProps {
  currentTab: DetailsPageTabs;
  screeningRoom: IGroup;
  refreshScreeningRoom?: (action?: IActionName) => void;
  disabled: boolean;
}

export const AddItemsToScreeningRoomButton: React.FC<IAddItemsToEventButtonProps> = ({
  currentTab,
  screeningRoom,
  refreshScreeningRoom,
  disabled,
}) => {
  const options: IUseActionsOptionParam = {
    onSuccess: async (action?: IActionName) => {
      refreshScreeningRoom?.(action);
    },
    context: 'single',
    page: Pages.SCREENING_ROOM_DETAILS,
  };

  const addContacts = useAddEntitiesToGroupAction([screeningRoom], {
    ...options,
    groupType: GroupTypes.SCREENING_ROOM,
    dialogTitle: 'Add contacts to this Screening Room',
    entityType: 'contacts_and_selections',
    shouldDisplay: () => true,
    groupLabel: 'Screening room',
    title: 'Add contacts',
    omni: true,
  });
  const addAssets = useAddEntitiesToGroupAction([screeningRoom], {
    ...options,
    groupType: GroupTypes.SCREENING_ROOM,
    dialogTitle: 'Add assets to this Screening Room',
    entityType: 'assets', // TODO assets_and_selections
    shouldDisplay: () => true,
    groupLabel: 'Screening room',
    title: 'Add assets',
    omni: true,
  });
  let action;

  switch (currentTab) {
    case DetailsPageTabs.CONTACTS:
      action = addContacts;
      break;

    case DetailsPageTabs.ASSETS:
      action = addAssets;
      break;

    default:
      return <></>;
  }
  const { handler, icon, title } = action;

  return (
    <Button size="xs" leftSection={<MantineIcon icon={icon} />} disabled={disabled} onClick={handler} variant="primary">
      {title}
    </Button>
  );
};
