import React, { useState, useCallback } from 'react';
import { startOfDay, endOfDay } from 'date-fns';
import { useStore } from 'store';
import { FormRemoteSelect, FormContacts, FormDateRange } from 'helpers/form';
import { FormSubmitSection } from 'components/form-submit-section';
import { queryLocation } from 'utils/apis/location';
import { downloadExport } from 'utils/apis/meeting';
import { today } from 'utils/date';
import { useDisableWhileSubmitting } from 'utils/hooks/submit';

import { IMeetingPropsFilters } from './calendar';
import './style.scss';

interface IExportFormProps {
  filters: IMeetingPropsFilters;
}

export const ExportForm: React.FC<IExportFormProps> = ({ filters }) => {
  const { dialogStore, toastStore } = useStore();
  const [exportfilters, setExportFilters] = useState({ ...filters, starts_at: [startOfDay(today), endOfDay(today)] });

  const onFilterChange = (filter): void => {
    const key = Object.keys(filter)[0];
    setExportFilters({ ...exportfilters, [key]: filter[key] });
  };

  const onDownload = useCallback(
    async (evt) => {
      evt.preventDefault();
      try {
        await downloadExport(exportfilters);
        dialogStore.close();
      } catch (error) {
        toastStore.error('Something went wrong!');
      }
    },
    [exportfilters, dialogStore, toastStore],
  );
  const { onSubmit, disabled } = useDisableWhileSubmitting(onDownload);

  return (
    <>
      <form onSubmit={onSubmit} className="export-calendar">
        <FormDateRange
          label="Date Range"
          name="starts_at"
          value={exportfilters.starts_at as [Date, Date]}
          onChange={onFilterChange}
        />

        <FormRemoteSelect
          label="Location"
          name="location_id"
          value={exportfilters?.location_id}
          fetchOptions={queryLocation}
          onChange={onFilterChange}
        />

        <FormContacts label="Host" name="host_id" value={exportfilters?.host_id} onChange={onFilterChange} />
      </form>
      <FormSubmitSection labels={{ confirm: 'Export' }} onSubmit={onSubmit} submitDisabled={disabled} />
    </>
  );
};
