import React from 'react';
import { observer } from 'mobx-react-lite';

import { FormMultiSelect, IFormMultiSelectProps } from 'helpers/form';

import { useBasicStore } from 'store/hooks';

export const FormCountries: React.FC<IFormMultiSelectProps> = observer((props) => {
  const { required, placeholder = 'Select Countries', label = 'Countries', name, ...other } = props;
  const { countriesDataOptions } = useBasicStore();

  return (
    <FormMultiSelect
      name={name}
      label={label}
      placeholder={placeholder}
      required={required}
      {...other}
      options={countriesDataOptions}
    />
  );
});
