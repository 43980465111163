import { AmGenericBaseAffiliationProtectionOptions } from '@mediafellows/mm3-types';
import omit from 'lodash/omit';

import { Model } from 'helpers/filters/types';
import { ItemId } from 'types';
import { chipmunk } from 'utils/chipmunk';
import { logger } from 'utils/logger';

export const fetchAffiliationProtections = async (): Promise<AmGenericBaseAffiliationProtectionOptions> => {
  const { object } = await chipmunk.run(({ action }) => {
    return action<AmGenericBaseAffiliationProtectionOptions>(Model.AFFILIATION_PROTECTION_OPTIONS, 'get', {
      params: {},
    });
  });

  return object;
};

export const updateAffiliationWatermarks = async (
  body: Partial<AmGenericBaseAffiliationProtectionOptions>,
): Promise<AmGenericBaseAffiliationProtectionOptions> => {
  const { object } = await chipmunk.run(({ action }) => {
    return action<AmGenericBaseAffiliationProtectionOptions>(Model.AFFILIATION_PROTECTION_OPTIONS, 'update', {
      body,
    });
  });

  return object;
};

export const getAssetProtections = async (assetId: ItemId): Promise<AmGenericBaseAffiliationProtectionOptions> => {
  const { object } = await chipmunk.run(async ({ action }) => {
    return action<AmGenericBaseAffiliationProtectionOptions>(Model.ASSET_PROTECTION_OPTIONS, 'get', {
      params: { asset_id: assetId },
    });
  }, logger.info);

  return object;
};

export const editAssetProtections = async (
  values: Partial<AmGenericBaseAffiliationProtectionOptions>,
): Promise<AmGenericBaseAffiliationProtectionOptions> => {
  const { object } = await chipmunk.run(async ({ action }) => {
    return action<AmGenericBaseAffiliationProtectionOptions>(Model.ASSET_PROTECTION_OPTIONS, 'update', {
      params: { asset_id: values.host_id },
      body: values,
    });
  });

  return object;
};

export const customEditAssetProtections = async (
  values: Partial<AmGenericBaseAffiliationProtectionOptions>,
): Promise<AmGenericBaseAffiliationProtectionOptions> => {
  let payload = { ...values };
  const fields = ['drm', 'forensic_watermark', 'visible_watermark', 'geo_ip', 'two_factor_authentication'];
  fields.map((field) => {
    if (values?.meta?.[field]?.enabled === null) {
      payload = omit(payload, `meta.${field}`);
    }
  });

  return editAssetProtections(payload);
};
