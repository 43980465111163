import React, { useCallback, useEffect } from 'react';
import { ActionIcon, em, Menu } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { Routes } from 'utils/routes';
import { NavLink, useMatches } from 'react-router-dom';
import { Globals } from 'types';

import { IconAlias } from 'icons';

import { ISidebarSection } from 'store/profile-store';
import { useWindowSize } from 'utils/hooks';
import { SIDE_BAR_WEBSITE_SECTION } from 'utils/side-bar';
import { RoleAcl } from 'components/role-acl';
import { UserAvatar } from 'components/user-avatar';
import { CollapsibleMenuItem } from 'components/sidebar/collapsable-menu-item';
import { CollapsibleMenuSubitem } from 'components/sidebar/collapsible-menu-subitem';
import { SidebarMenuItem } from './sidebar-menu-item';
import { DropdownMenu } from './dropdown-menu';
import { useProfileStore } from 'store';
import { useSessionStore } from 'store/session-store';
import { allowedRolesByRoute, flags } from 'utils/flags';
import { MantineIcon } from 'utils/ui';

import { CustomSidebarSection } from 'components/sidebar/custom-section';
import { isAssetRecommendationEnabled } from 'utils/recommendation';
import { SidebarCreateItem } from './sidebar-create-item';
import { PopoverMenuItem } from './popover-menu-item';
import { sideBarWebsiteRoutes } from './utils';

import './style.scss';

interface ISidebar {
  opened: boolean;
  toggle: () => void;
}

const body = document.body;

const Sidebar: React.FC<ISidebar> = ({ opened, toggle }) => {
  const user = useSessionStore((state) => state.user);
  const isMobile = useMediaQuery(`(max-width: ${em(576)})`);

  const size = useWindowSize();
  const matches = useMatches();

  const activeSidebarSection = useProfileStore((store) => store.activeSidebarSection);
  const updateActiveSidebarSection = useProfileStore((store) => store.updateActiveSidebarSection);

  useEffect(() => {
    if (size.width < 768 && !body.classList.contains(Globals.SIDEBAR_MOBILE_CLASS)) {
      body.classList.add(Globals.SIDEBAR_MOBILE_CLASS);
    }

    if (size.width >= 768 && body.classList.contains(Globals.SIDEBAR_MOBILE_CLASS)) {
      body.classList.remove(Globals.SIDEBAR_MOBILE_CLASS);
    }
  }, [size]);

  /**
   * Controls opened submenu in case of location change
   */
  useEffect(() => {
    const matched = matches.find((match) => {
      const handle = match?.handle as Record<string, unknown>;
      return handle?.activeSidebar;
    });

    const handle = matched?.handle as Record<string, unknown>;

    const activeSection = handle?.activeSidebar || null;
    updateActiveSidebarSection(activeSection);
  }, [matches, updateActiveSidebarSection]);

  const handleToggleSection = useCallback(
    (e): void => {
      const { section } = e.currentTarget.dataset;
      const newActiveSidebarSection = activeSidebarSection !== section ? section : null;
      updateActiveSidebarSection(newActiveSidebarSection);
    },
    [activeSidebarSection, updateActiveSidebarSection],
  );
  const showAssetRecommendations = isAssetRecommendationEnabled() && flags.showAssetRecommendationsFeature;
  const showBothRecommendations = flags.showRecommendationFeature && showAssetRecommendations;
  return (
    <>
      <div className="sidebar__head">
        <NavLink to={`${Routes.CONTACTS}/${user?.id}`} className="sidebar__user">
          <div className="sidebar__user">
            <div className="sidebar__avatar">
              <UserAvatar disabled user={user} />
            </div>
            <span className="sidebar__username">{user?.first_name}</span>
          </div>
        </NavLink>

        <ActionIcon color="gray.5" variant="subtle" hiddenFrom="sm" onClick={toggle}>
          <MantineIcon icon="cross" />
        </ActionIcon>
      </div>

      <Menu width={210} shadow="md" offset={1} position={opened ? 'bottom' : 'right-start'}>
        <Menu.Target>
          <div className="sidebar__create-btn-wrap">
            <PopoverMenuItem iconName="plus" text="Create New" />
          </div>
        </Menu.Target>

        <Menu.Dropdown className="sidebar__create-links">
          <SidebarCreateItem link={Routes.CREATE_CONTACT} icon={IconAlias.CONTACT} text="Contact" />
          <SidebarCreateItem link={Routes.CREATE_NEW_PRODUCT} icon={IconAlias.PRODUCT} text="Product" />
          <SidebarCreateItem link={Routes.CREATE_ASSET} icon={IconAlias.ASSET} text="Asset" />
          <SidebarCreateItem link={Routes.CREATE_SHELL_ASSET} icon={IconAlias.ASSET} text="Shell Asset" />
          <SidebarCreateItem link={Routes.CREATE_ORGANIZATION} icon={IconAlias.ORGANIZATION} text="Organization" />
          <SidebarCreateItem link={Routes.CREATE_EVENT} icon={IconAlias.EVENT} text="Event" />
          <SidebarCreateItem link={Routes.CREATE_NEW_CONFERENCE} icon={IconAlias.CONFERENCE} text="Conference" />
          {showBothRecommendations && !isMobile && (
            <DropdownMenu
              icon={IconAlias.RECOMMENDATION}
              title="Recommendation"
              items={[
                { text: 'Recommend Products', icon: IconAlias.PRODUCT, link: Routes.CREATE_PRODUCT_RECOMMENDATION },
                { text: 'Recommend Assets', icon: IconAlias.ASSET, link: Routes.CREATE_ASSET_RECOMMENDATION },
              ]}
            />
          )}
          {showBothRecommendations && isMobile && (
            <>
              <SidebarCreateItem
                link={Routes.CREATE_PRODUCT_RECOMMENDATION}
                icon={IconAlias.PRODUCT}
                text="Recommend Products"
              />
              <SidebarCreateItem
                link={Routes.CREATE_ASSET_RECOMMENDATION}
                icon={IconAlias.ASSET}
                text="Recommend Assets"
              />
            </>
          )}
          {(flags.showRecommendationFeature || showAssetRecommendations) && !showBothRecommendations && (
            <SidebarCreateItem
              link={
                flags.showRecommendationFeature
                  ? Routes.CREATE_PRODUCT_RECOMMENDATION
                  : Routes.CREATE_ASSET_RECOMMENDATION
              }
              icon={IconAlias.RECOMMENDATION}
              text="Recommendation"
            />
          )}
          <SidebarCreateItem
            link={Routes.CREATE_ACCESS_PRIVILEGE}
            icon={IconAlias.ACCESS_PRIVILEGE}
            text="Access privilege"
          />
          <SidebarCreateItem
            link={Routes.CREATE_MOBILE_APP_SYNC}
            icon={IconAlias.MOBILE_APP_SYNC}
            text="Mobile Selection"
          />
          <SidebarCreateItem
            link={Routes.CREATE_SCREENING_ROOM}
            icon={IconAlias.SCREENING_ROOM}
            text="Screening Room"
          />

          <SidebarCreateItem link={Routes.CREATE_ACCESS_GROUP} icon={IconAlias.ACCESS_GROUPS} text="Access Group" />
          <SidebarCreateItem link={Routes.CREATE_SMART_GROUP} icon={IconAlias.SMART} text="Smart Group" />

          <SidebarCreateItem link={Routes.CREATE_GUILD} icon={IconAlias.GUILDS} text="Guild" />

          <SidebarCreateItem link={Routes.CREATE_DELIVERY_PACKAGE} icon={IconAlias.DELIVERY} text="Delivery Package" />

          <SidebarCreateItem link={Routes.CREATE_LOCALIZATION_ORDER} icon={IconAlias.ORDER} text="Localization Order" />
        </Menu.Dropdown>
      </Menu>

      <Menu>
        <div className="sidebar__menu">
          <SidebarMenuItem route={Routes.DASHBOARD} text="Dashboard" iconName="dashboard" />

          <CollapsibleMenuItem
            text="Contacts"
            iconName="person"
            onMenuItemClick={handleToggleSection}
            activeSidebarSection={activeSidebarSection}
            sidebarSection={ISidebarSection.contacts}
            routes={[Routes.CONTACTS, Routes.CONTACT_SELECTIONS, Routes.ORGANIZATIONS]}
          >
            <CollapsibleMenuSubitem route={Routes.CONTACTS} text={'All contacts'} />
            <CollapsibleMenuSubitem route={Routes.CONTACT_SELECTIONS} text={'Selections'} />
            <CollapsibleMenuSubitem route={Routes.ORGANIZATIONS} text="Organizations" />
          </CollapsibleMenuItem>
          <CollapsibleMenuItem
            text="Products"
            iconName="film"
            onMenuItemClick={handleToggleSection}
            activeSidebarSection={activeSidebarSection}
            sidebarSection={ISidebarSection.products}
            routes={[Routes.PRODUCTS, Routes.PRODUCT_SELECTIONS, Routes.BASKETS, Routes.CAST_CREW]}
          >
            <CollapsibleMenuSubitem route={Routes.PRODUCTS} text={'All products'} />
            <CollapsibleMenuSubitem route={Routes.PRODUCT_SELECTIONS} text={'Selections'} />

            <CollapsibleMenuSubitem route={Routes.BASKETS} text="Baskets" />
            <CollapsibleMenuSubitem route={Routes.CAST_CREW} text="Cast & Crew" />
          </CollapsibleMenuItem>
          <CollapsibleMenuItem
            text="Assets"
            iconName="media"
            onMenuItemClick={handleToggleSection}
            activeSidebarSection={activeSidebarSection}
            sidebarSection={ISidebarSection.assets}
            routes={[Routes.ASSETS, Routes.ASSET_SELECTIONS]}
          >
            <CollapsibleMenuSubitem route={Routes.ASSETS} text="All assets" />
            <CollapsibleMenuSubitem route={Routes.ASSET_SELECTIONS} text="Selections" />
          </CollapsibleMenuItem>
          <CollapsibleMenuItem
            text="Website"
            iconName="panel-table"
            onMenuItemClick={handleToggleSection}
            activeSidebarSection={activeSidebarSection}
            sidebarSection={ISidebarSection.website}
            routes={sideBarWebsiteRoutes}
          >
            {SIDE_BAR_WEBSITE_SECTION.map(({ route, text }) => (
              <CollapsibleMenuSubitem key={route} route={route} text={text} />
            ))}
          </CollapsibleMenuItem>
          <CollapsibleMenuItem
            text="Marketing"
            iconName="shop"
            sidebarSection={ISidebarSection.marketing}
            onMenuItemClick={handleToggleSection}
            activeSidebarSection={activeSidebarSection}
            routes={[
              Routes.SCREENING_ROOMS,
              Routes.EVENTS,
              Routes.CONFERENCES,
              Routes.RECOMMENDATIONS,
              Routes.ACCESS_PRIVILEGES,
              Routes.MOBILE_APP_SYNC,
              Routes.ACCESS_GROUPS,
            ]}
          >
            <CollapsibleMenuSubitem route={Routes.SCREENING_ROOMS} text="Screening Rooms" />
            <CollapsibleMenuSubitem route={Routes.EVENTS} text="Events" />
            <CollapsibleMenuSubitem route={Routes.CONFERENCES} text="Conferences" />
            <CollapsibleMenuSubitem route={Routes.RECOMMENDATIONS} text="Recommendations" />
            <CollapsibleMenuSubitem route={Routes.ACCESS_PRIVILEGES} text="Access Privileges" />
            <CollapsibleMenuSubitem route={Routes.MOBILE_APP_SYNC} text="Mobile Selections" />
            <CollapsibleMenuSubitem route={Routes.ACCESS_GROUPS} text="Access Groups" />
            <CollapsibleMenuSubitem route={Routes.GUILDS} text="Guilds" />
          </CollapsibleMenuItem>
          <CollapsibleMenuItem
            text="Meetings"
            iconName="calendar"
            onMenuItemClick={handleToggleSection}
            activeSidebarSection={activeSidebarSection}
            sidebarSection={ISidebarSection.meetings}
            routes={[Routes.LOCATIONS, Routes.CALENDAR]}
          >
            <RoleAcl allowedRoles={allowedRolesByRoute[Routes.LOCATIONS]}>
              <CollapsibleMenuSubitem route={Routes.LOCATIONS} text="Locations" />
            </RoleAcl>
            <CollapsibleMenuSubitem route={Routes.CALENDAR} text="Calendar" />
          </CollapsibleMenuItem>

          <CustomSidebarSection handleToggleSection={handleToggleSection} activeSidebarSection={activeSidebarSection} />
          <CollapsibleMenuItem
            text="Analytics"
            iconName="chart"
            onMenuItemClick={handleToggleSection}
            activeSidebarSection={activeSidebarSection}
            sidebarSection={ISidebarSection.analytics}
            routes={[
              Routes.ANALYTICS_CONTACTS,
              Routes.ANALYTICS_PRODUCTS,
              Routes.ANALYTICS_ASSETS,
              Routes.ANALYTICS_RECOMMENDATIONS,
            ]}
          >
            <CollapsibleMenuSubitem route={Routes.ANALYTICS_CONTACTS} text="Contacts" />
            <CollapsibleMenuSubitem route={Routes.ANALYTICS_PRODUCTS} text="Products" />
            <CollapsibleMenuSubitem route={Routes.ANALYTICS_ASSETS} text="Assets" />
            <CollapsibleMenuSubitem route={Routes.ANALYTICS_RECOMMENDATIONS} text="Recommendations" />
          </CollapsibleMenuItem>
          <CollapsibleMenuItem
            text="Fulfillment"
            iconName="compressed"
            onMenuItemClick={handleToggleSection}
            activeSidebarSection={activeSidebarSection}
            sidebarSection={ISidebarSection.fulfillment}
            routes={[Routes.DELIVERIES, Routes.ORDER_MANAGEMENT]}
          >
            <CollapsibleMenuSubitem route={Routes.DELIVERIES} text="Deliveries" />
            <CollapsibleMenuSubitem route={Routes.ORDER_MANAGEMENT} text="Order Management" />
          </CollapsibleMenuItem>
          <CollapsibleMenuItem
            text="Settings"
            iconName="settings"
            onMenuItemClick={handleToggleSection}
            activeSidebarSection={activeSidebarSection}
            sidebarSection={ISidebarSection.settings}
            routes={[Routes.AFFILIATION, Routes.GENRES_MANAGEMENT, Routes.DIVISIONS]}
          >
            <CollapsibleMenuSubitem route={Routes.AFFILIATION} text="Affiliation" />
            <CollapsibleMenuSubitem route={Routes.GENRES_MANAGEMENT} text="Genres" />
            <CollapsibleMenuSubitem route={Routes.DIVISIONS} text="Divisions" />
            <CollapsibleMenuSubitem route={Routes.SMART_GROUP} text="Smart Groups" />
          </CollapsibleMenuItem>
        </div>
      </Menu>
    </>
  );
};

export default Sidebar;
