import { useAssociateAction } from './associate';
import { IListItem, IProduct } from 'types';

import {
  IUseActionsOptionParam,
  IUseActionsResponse,
  dividerAction,
  useActions,
  useGrantAccessPrivilegeAction,
  useCreateMobileSyncAction,
  useTitleAction,
} from 'utils/actions';
import { useRemoveFromListAction } from 'utils/actions/list-item';
import { useAddProductsAction, productsToItems } from 'utils/actions/access-privilege';
import { useCloneAction } from './clone-action';
import { usePreviewAsClientAction } from './preview-as-client-action';
import { useRestoreAction } from './restore-action';
import { useDeleteAction } from './delete-action';
import { useCreateSelectionAction } from './create-selection-action';
import { useAddToSelectionAction } from './add-to-selection-action';
import { usePassOnDataAction } from './pass-on-data-action';
import { useRecommendAction } from './recommend-action';
import { useDeliveryAction } from './deliver-action';
import { useAddProductsAction as useAddProductsToGuidAction, useCreateGuildAction } from 'utils/actions/guilds';
import { IProductActionName, IProductActionConfig } from 'utils/actions/product/types';
import { useAssignAssetsToProductAction } from './assign-asset-to-product-action';
import { useChangeAccessAction } from './change-access';
import { useUnassignAssetsAction } from './unassign-asset';
import { useRemoveFromGroupAction } from './remove-from-group';
import { useRemoveFromRelatedAction } from './remove-from-related';
import { useRemoveFromMobileSyncAction } from './remove-from-mobile-sync';
import { useAccessPrivilegeProductAccessSettingsAction } from './access-privilege-product-access-settings-action';
import {
  shouldDisplayAccessPrivilegeTitle,
  shouldDisplayCreateMobileAppSync,
  shouldDisplaAccessPrivilege,
  shouldDisplayAddToGuild,
} from './actions-acl';

import { useReassignAction } from './reassign-parent';
import { UmGenericListItem } from '@mediafellows/mm3-types';
import { useAddCastCrewToProductsAction } from './add-cast-crew';

export {
  changeProductAccessImmediate,
  createScheduledJobs,
  removeScheduledJob,
  loadScheduledJobs,
  loadAllScheduledJobs,
} from './api';
export type {
  IProductScheduledJobAccess,
  IAccessChangeScheduledFormAccess,
  IAccessChangeScheduledForm,
  IProductScheduledJob,
  IProductScheduledJobName,
} from './types';

export const useProductActions = <T extends { listItem?: UmGenericListItem | IListItem }>(
  entities: (IProduct & T)[],
  options: IUseActionsOptionParam,
): IUseActionsResponse<IProductActionName> => {
  const removeFromGroupAction = useRemoveFromGroupAction(entities, options);
  const createSelectionAction = useCreateSelectionAction(entities);
  const cloneAction = useCloneAction(entities, options);
  const restoreAction = useRestoreAction(entities, options);
  const associateAction = useAssociateAction(entities, options);
  const deleteAction = useDeleteAction(entities, options);
  const addToSelectionAction = useAddToSelectionAction(entities);
  const passOnDataAction = usePassOnDataAction(entities, options);
  const recommendAction = useRecommendAction(entities);
  const accessPrivilegeProductAccessSettings = useAccessPrivilegeProductAccessSettingsAction(entities, options);
  const reassignAction = useReassignAction(entities, options);
  const addToAccessPrivilegeAction = useAddProductsAction({
    options: {
      ...options,
      items: productsToItems(entities),
      shouldDisplay: shouldDisplaAccessPrivilege,
    },
  });
  const grantAccessPrivilegeAction = useGrantAccessPrivilegeAction(
    { products: entities },
    {
      ...options,
      shouldDisplay: shouldDisplaAccessPrivilege,
    },
  );
  const createMobileSync = useCreateMobileSyncAction(
    { products: entities },
    {
      ...options,
      shouldDisplay: shouldDisplayCreateMobileAppSync,
    },
  );

  const assignAssetsAction = useAssignAssetsToProductAction(entities, options);
  const unassignAction = useUnassignAssetsAction(entities, options);
  const accessChangeAction = useChangeAccessAction(entities, options);
  const previewAsClientAction = usePreviewAsClientAction(entities);
  const removeFromRelatedAction = useRemoveFromRelatedAction(entities, options);
  const removeFromMobileSyncAction = useRemoveFromMobileSyncAction(entities, options);

  const accessPrivilegeTitle = useTitleAction(entities, {
    ...options,
    title: 'Access privilege',
    shouldDisplay: shouldDisplayAccessPrivilegeTitle,
  });
  const productTitle = useTitleAction([], {
    ...options,
    title: 'Product',
    shouldDisplay: shouldDisplayAccessPrivilegeTitle,
  });
  const removeFromList = useRemoveFromListAction(
    entities.map((e) => e.listItem as UmGenericListItem),
    options,
  );

  const addCastCrewToProduct = useAddCastCrewToProductsAction(entities, options);

  const deliverAction = useDeliveryAction(entities, options);
  const addProductsToGuild = useAddProductsToGuidAction({
    options: {
      ...options,
      items: productsToItems(entities),
      shouldDisplay: shouldDisplayAddToGuild,
    },
  });
  const createGuildAction = useCreateGuildAction(entities, {
    ...options,
    shouldDisplay: shouldDisplayAddToGuild,
  });
  const actions = [
    removeFromList,
    dividerAction,
    accessPrivilegeTitle,
    accessPrivilegeProductAccessSettings,
    removeFromGroupAction,
    productTitle,
    removeFromMobileSyncAction,
    removeFromRelatedAction,
    unassignAction,
    dividerAction,
    previewAsClientAction,
    dividerAction,
    createSelectionAction,
    addToSelectionAction,
    accessChangeAction,
    addToAccessPrivilegeAction,
    grantAccessPrivilegeAction,
    createMobileSync,
    recommendAction,
    deliverAction,
    createGuildAction,
    addProductsToGuild,
    dividerAction,
    reassignAction,
    associateAction,
    assignAssetsAction,
    addCastCrewToProduct,
    cloneAction,
    restoreAction,
    passOnDataAction,
    dividerAction,
    deleteAction,
  ];

  return useActions<IProduct, IProductActionName>(actions as IProductActionConfig[], options, entities);
};
