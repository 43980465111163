import React from 'react';

import { InfoBoxV2 } from 'components/info-box-v2';
import { LabelValuePair as Row, yn } from 'components/label-value-pair';
import { IAsset, IMm3Asset } from 'types';
import { formatField } from 'utils/general';
import { CustomSettingsRow } from 'pages/asset-details/asset-metadata-tab/custom-row';
import { getIsMm3Assets } from 'utils/asset';

export const AssetMetadataSettingsSection: React.FC<{ asset?: IAsset | IMm3Asset }> = ({ asset }) => {
  const { access_level, permissions, protection_levels, status, sharable } = asset || {};
  const isMm3Assets = getIsMm3Assets();

  return (
    <InfoBoxV2 gridTemplateColumns="180px 1fr" title="Settings">
      <Row label="status" value={status} size="m" />
      <CustomSettingsRow asset={asset as IMm3Asset} />
      <Row label="Access Level" value={access_level} size="m" />
      <Row label="Sharable" value={yn(sharable)} size="m" />
      <Row label="Permissions" value={formatField(permissions)} size="m" />
      <Row hidden={Boolean(isMm3Assets)} label="protection levels" value={formatField(protection_levels)} />
    </InfoBoxV2>
  );
};
