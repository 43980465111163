import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';
import { Button } from '@mantine/core';
import { ArrowLeft } from 'blueprint5-icons';

import { useNavigate } from 'react-router';

import { Routes } from 'utils/routes';
import { useWizardSteps, Wizard, WizardStep } from 'components/wizard';
import {
  productLayerInitialValues as layerInitialValues,
  productInitialValues,
} from 'components/product-edit-sections';
import { ToastError } from 'components/toast';
import { IProduct, IProductLayer, Product3Types } from 'types';
import { useStore } from 'store';
import { createProduct } from 'utils/apis/product';
import { MantineIcon } from 'utils/ui/icon';
import { useMm3Form } from 'helpers/form';
import { Model } from 'helpers/filters/types';
import { customValidator, prepareProductPayload } from './utils';
import { FormActionSection } from 'components/form-submit-section';
import ProductMetadataTabEditFormView from 'pages/product-details/product-metadata-tab/product-metadata-tab-edit-form-view';

import ProductForm from './product-form';
import { useParentFilters } from './hooks';

import './style.scss';
import { getProductModel } from 'utils/product';
enum Steps {
  Product,
  Layer,
}

interface IProductCreateProps {
  productType?: Product3Types;
  parent?: IProduct;
  onSuccess?: VoidFunction;
}

const ProductCreate: React.FC<IProductCreateProps> = observer(({ productType, parent, onSuccess }) => {
  const { toastStore } = useStore();
  const navigate = useNavigate();
  const [model, setModel] = useState<string | Model>(Model.PRODUCTS);

  const formInitialValues = productType ? { ...productInitialValues, type: productType } : productInitialValues;
  const productForm = useMm3Form<IProduct>(formInitialValues, model, undefined, customValidator);

  useEffect(() => {
    // update the schema when a different product type is selected
    setModel(getProductModel(productForm.values?.type));
  }, [productForm.values.type]);

  const handleSubmit = useCallback(
    async (layer: IProductLayer): Promise<void> => {
      try {
        const payload = prepareProductPayload({ product: productForm.values, layer });
        const product = await createProduct(payload);
        navigate(`${Routes.PRODUCTS}/${product.id}`);
        toastStore.success('Product was created Successfully');
        onSuccess?.();
      } catch (error) {
        toastStore.error(<ToastError error={error} placeholder={'Sorry, something went wrong'} />);
      }
    },
    [productForm.values, navigate, toastStore, onSuccess],
  );

  const layerForm = useMm3Form<IProductLayer>(layerInitialValues, Model.PRODUCT_STANDARD_LAYER, handleSubmit);

  const [additionalParentFilters] = useParentFilters(parent, productType, productForm);

  const steps = [
    {
      id: Steps.Product,
      disabled: !productForm.valid || !layerForm.valid,
    },
    {
      id: Steps.Layer,
      disabled: !productForm.valid || !layerForm.valid || layerForm.isSending || productForm.isSending,
      action: layerForm.onSubmit,
      nextButtonTitle: 'Create',
    },
  ];

  const { nextButton, backButton, currentStepId, setCurrentStepId, isStepDisabled } = useWizardSteps(steps);

  return (
    <Wizard
      selectedStepId={currentStepId}
      footerPadded={false}
      onChange={setCurrentStepId}
      className="product-create__wizard"
      footer={
        <FormActionSection className="d-flex align-items-center justify-content-between w-100">
          <Button
            className={cx('m-0', { invisible: backButton.hide })}
            onClick={backButton.action}
            variant="subtle"
            leftSection={<MantineIcon icon={<ArrowLeft />} />}
          >
            {backButton.title}
          </Button>
          <Button className="m-0" variant="primary" onClick={nextButton.action} disabled={nextButton.disabled}>
            {nextButton.title}
          </Button>
        </FormActionSection>
      }
      fitInParent
    >
      <WizardStep
        id={Steps.Product}
        key={Steps.Product}
        disabled={isStepDisabled(Steps.Product)}
        title="Product"
        icon="film"
        panel={
          <ProductForm
            productForm={productForm}
            layerForm={layerForm}
            additionalParentFilters={additionalParentFilters}
          />
        }
      />
      <WizardStep
        id={Steps.Layer}
        key={Steps.Layer}
        title="Metadata"
        icon="new-layers"
        disabled={isStepDisabled(Steps.Layer)}
        panel={<ProductMetadataTabEditFormView productForm={productForm} layerForm={layerForm} padded={false} />}
      />
    </Wizard>
  );
});

export default ProductCreate;
