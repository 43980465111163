import React from 'react';
import { Button } from '@mantine/core';

import { FormDate } from 'helpers/form';
import { FormActionSection } from 'components/form-submit-section';

import { AccessPrivilegeGrantFormSettings } from 'components/access-privilege-grant';
import { IGroupAccessChangeForm } from './types';
import { Classes } from 'utils/ui';

interface IGroupAccessChangeFormComponent {
  form: IGroupAccessChangeForm;
  showScheduledFor?: boolean;
  groupName?: string;
}

export const GroupAccessChangeForm: React.FC<IGroupAccessChangeFormComponent> = ({
  form,
  showScheduledFor = false,
  groupName,
}) => {
  return (
    <>
      {showScheduledFor && (
        <>
          <FormDate
            name="scheduled_for"
            label="Scheduled for"
            large
            {...form.formData.scheduled_for}
            {...form.handlers}
          />
          <hr />
        </>
      )}
      <AccessPrivilegeGrantFormSettings form={form} groupName={groupName} />
      <FormActionSection className={Classes.DIALOG_FOOTER_ACTIONS}>
        <Button size="xs" variant="primary" onClick={form.onSubmit}>
          Save
        </Button>
      </FormActionSection>
    </>
  );
};
