import { ILocalizationOrder } from 'types/localization-order';

import type { ILocalizationOrderActionConfig } from './types';
import { ILocalizationOrderActionName } from './types';
import { useEditAction } from './edit';
import { useSendAction } from './send-action';
import { useDeleteAction } from './delete-action';
import { useCopyAction } from './copy-action';

import { IUseActionsOptionParam, IUseActionsResponse, useActions } from 'utils/actions';

export { useEditAction, ILocalizationOrderActionName };

export const useLocalizationOrderActions = (
  entities: ILocalizationOrder[] = [],
  options: IUseActionsOptionParam,
): IUseActionsResponse<ILocalizationOrderActionName> => {
  const editAction = useEditAction(entities, options);
  const sendAction = useSendAction(entities, options);
  const copyAction = useCopyAction(entities, options);
  const deleteAction = useDeleteAction(entities, options);

  const actions = [editAction, sendAction, copyAction, deleteAction];

  return useActions(actions as ILocalizationOrderActionConfig[], options, entities);
};
