import React, { useEffect } from 'react';
import { Button } from '@mantine/core';
import cx from 'classnames';

import { FormActionSection } from 'components/form-submit-section';
import { Wizard, WizardStep, useWizardSteps } from 'components/wizard';
import { isValid } from 'helpers/form';
import { IconAlias } from 'icons';
import {
  GuildFormGeneral,
  keysGeneral,
  GuildFormProducts,
  keysProducts,
  GuildFormAssets,
  GuildFormContacts,
  GuildFormSettings,
  keysSettings,
  GuildFormSummary,
} from '.';

import { keysContacts } from './guild-form-contacts';

import { IGuildForm } from './types';
import { MantineIcon } from 'utils/ui/icon';
import { ArrowLeft } from 'blueprint5-icons';
import { querySubAssetsMultipleAssets } from 'utils/apis/asset';

import { StepIds } from './utils';
import { getValidIds } from 'utils/general';

interface IGuildGrantWizard {
  form: IGuildForm;
  isCreating: boolean;
  fitInParent?: boolean;
  onSuccess?: () => void;
}

export const GuildWizard: React.FC<IGuildGrantWizard> = ({ form, isCreating, fitInParent }) => {
  const steps = [
    { id: StepIds.General, disabled: !isValid(form.formData, keysGeneral) },
    { id: StepIds.Products, disabled: !isValid(form.formData, keysProducts) },
    { id: StepIds.Assets },
    { id: StepIds.Contacts, disabled: !isValid(form.formData, keysContacts) },
    { id: StepIds.Settings, disabled: !isValid(form.formData, keysSettings) },
    {
      id: StepIds.Summary,
      nextButtonTitle: 'Create',
      action: form.onSubmit,
    },
  ];

  const { nextButton, backButton, currentStepId, setCurrentStepId, isStepDisabled } = useWizardSteps(steps);

  useEffect(() => {
    if (currentStepId === StepIds.Settings) {
      const fetchSubAssets = async (): Promise<void> => {
        const result = await querySubAssetsMultipleAssets({ asset_id: form.values.asset_ids });
        const subAssetIds = getValidIds(Object.values(result).flat());
        form.handlers.onChange({ subAssetIds: subAssetIds || [] });
      };

      fetchSubAssets();
    }
  }, [currentStepId, form.values.asset_ids, form.handlers]);

  return (
    <Wizard
      fitInParent
      isLoading={isCreating}
      loadingMessage="Creating the group now."
      footerPadded={false}
      className={fitInParent ? 'p-3' : undefined}
      selectedStepId={currentStepId}
      onChange={setCurrentStepId}
      footer={
        <FormActionSection className="d-flex justify-content-between w-100">
          <Button
            className={cx('m-0', { invisible: backButton.hide })}
            onClick={backButton.action}
            disabled={backButton.hide || isCreating}
            variant="subtle"
            leftSection={<MantineIcon icon={<ArrowLeft />} />}
          >
            {backButton.title}
          </Button>
          <div className="d-flex gap-3">
            <Button
              className="m-0"
              variant="primary"
              onClick={nextButton.action}
              disabled={nextButton.disabled || isCreating}
            >
              {nextButton.title}
            </Button>
          </div>
        </FormActionSection>
      }
    >
      <WizardStep
        id={StepIds.General}
        title="General info"
        icon="properties"
        disabled={isStepDisabled(StepIds.General)}
        panel={<GuildFormGeneral form={form} />}
      />
      <WizardStep
        id={StepIds.Products}
        disabled={isStepDisabled(StepIds.Products)}
        title="Products"
        icon={IconAlias.PRODUCT}
        panel={<GuildFormProducts form={form} />}
      />
      <WizardStep
        id={StepIds.Assets}
        disabled={isStepDisabled(StepIds.Assets)}
        title="Assets"
        icon={IconAlias.ASSET}
        panel={<GuildFormAssets form={form} />}
      />
      <WizardStep
        id={StepIds.Contacts}
        disabled={isStepDisabled(StepIds.Contacts)}
        title="Contacts"
        icon={IconAlias.CONTACT}
        panel={<GuildFormContacts form={form} />}
      />
      <WizardStep
        id={StepIds.Settings}
        disabled={isStepDisabled(StepIds.Settings)}
        title="Settings"
        icon={IconAlias.SETTINGS}
        panel={<GuildFormSettings form={form} />}
      />
      <WizardStep
        id={StepIds.Summary}
        disabled={isStepDisabled(StepIds.Summary)}
        title="Summary"
        icon="menu"
        panel={<GuildFormSummary form={form} />}
      />
    </Wizard>
  );
};
