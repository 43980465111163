import React, { useMemo, useState, useCallback } from 'react';
import { map } from 'lodash';
import { Button, Stepper } from '@mantine/core';
import { ArrowLeft } from 'blueprint5-icons';

import { FormSubmitSection, FormActionSection } from 'components/form-submit-section';
import { FormAddToGroupItemsForm } from 'components/add-to-group-dialog/form-add-to-group-items-form';
import { AccessPrivilegeGrantFormEmail } from 'components/access-privilege-grant';
import { useForm, FormCheckbox } from 'helpers/form';

import { IAddToGroupForm, IFormAddToGroupItems } from 'components/add-to-group-dialog/types';
import { Model } from 'helpers/filters/types';
import { IAccessPrivilegeEmail } from 'components/access-privilege-grant/access-privilege-grant-form-email';
import { getDialogParams, createInitialAddToAccessPrivilegeValues, Steps } from './utils';
import { Classes, MantineIcon } from 'utils/ui';

const emailInitialValues: IAccessPrivilegeEmail = { email_subject: '', email_message: '' };
const customContext = {
  properties: {
    group_id: { required: true },
    item_ids: { required: true },
  },
};
const emailFormContext = {
  properties: {
    email_subject: { required: true, validations: [{ length: { maximum: 300 } }] },
    email_message: { required: true, validations: [{ length: { maximum: 2000 } }] },
  },
};

const FormAddToAccessPrivilegeItems: React.FC<IFormAddToGroupItems> = ({
  items,
  onConfirm,
  onCancel,
  entity,
  group,
  groupType,
  groupLabel,
  hideProductsParams,
  addSelectedProductAncestors,
  withEmailNotification = false,
  omni,
  initialValues,
}) => {
  const initialIds = useMemo(() => map(items, 'value'), [items]);
  const [currentStep, setCurrentStep] = useState(Steps.Step1);
  const initialFormValues = {
    ...createInitialAddToAccessPrivilegeValues(initialIds, group?.id),
    ...(initialValues || {}),
  };
  const form = useForm<IAddToGroupForm>(initialFormValues, Model.GROUPS, onConfirm, customContext);

  const { valid, onSubmit } = form;

  const emailForm = useForm<IAccessPrivilegeEmail>(emailInitialValues, Model.GROUPS, onConfirm, emailFormContext);

  const generalTab = (
    <>
      <FormAddToGroupItemsForm
        form={form}
        entity={entity}
        group={group}
        groupType={groupType}
        groupLabel={groupLabel}
        disabledGroupSelector={Boolean(group?.id)}
        disabledItemsSelector={!!initialIds.length}
        hideProductsParams={hideProductsParams}
        addSelectedProductAncestors={addSelectedProductAncestors}
        omni={omni}
      />
      {withEmailNotification && (
        <FormCheckbox
          label="Notify per email"
          name="notify_per_email"
          {...form.formData.notify_per_email}
          {...form.handlers}
        />
      )}
    </>
  );
  const goBack = useCallback(() => {
    setCurrentStep(Steps.Step1);
  }, []);

  if (!withEmailNotification) {
    return (
      <>
        <form onSubmit={onSubmit}>{generalTab}</form>
        <FormSubmitSection submitDisabled={!valid} onSubmit={onSubmit} />
      </>
    );
  }

  const params = getDialogParams({
    form,
    emailForm,
    onConfirm,
    currentStep,
    setCurrentStep,
  });

  const isEmailNotificationEnabled = Boolean(form.values.notify_per_email && form.valid);

  return (
    <>
      <form onSubmit={onSubmit}>
        <Stepper active={currentStep} onStepClick={setCurrentStep}>
          <Stepper.Step key={Steps.Step1} label="General">
            {generalTab}
          </Stepper.Step>
          <Stepper.Step
            key={Steps.Step2}
            label="Email notification"
            allowStepSelect={isEmailNotificationEnabled}
            disabled={!isEmailNotificationEnabled}
          >
            {<AccessPrivilegeGrantFormEmail form={emailForm} />}
          </Stepper.Step>
        </Stepper>
      </form>
      <FormActionSection className={Classes.DIALOG_FOOTER_ACTIONS}>
        <Button className="me-2" variant="default" size="xs" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          size="xs"
          variant="subtle"
          onClick={goBack}
          leftSection={<MantineIcon icon={<ArrowLeft />} />}
          hidden={currentStep !== Steps.Step2}
          className="me-auto ms-0"
        >
          Back
        </Button>
        <Button size="xs" variant="primary" disabled={params.disabled} onClick={params.onClick}>
          {params.title}
        </Button>
      </FormActionSection>
    </>
  );
};

export { FormAddToAccessPrivilegeItems };
