import { McGenericRecommendation } from '@mediafellows/mm3-types';
import { useSessionStore } from 'store/session-store';
import { hasPower, recommendationBypassSharingRestrictions } from './powers';
import { ISearchFilter } from 'types';
import { getIsMm3Assets } from 'utils/asset';

export const isAssetRecommendationEnabled = (): boolean => {
  return Boolean(
    useSessionStore
      .getState()
      .session?.powers?.some(
        ({ resource, effect }) => resource === 'mc/asset_recommendations_enabled' && effect === 'Allow',
      ),
  );
};

export const isAssetRecommendation = ({ type }: Pick<McGenericRecommendation, 'type'>): boolean =>
  type === 'Recommendation::Asset';

let recommendationAssetsFilters;
export const getRecommendationAssetsFilters = (): ISearchFilter[] => {
  if (!recommendationAssetsFilters) {
    const isMm3Assets = getIsMm3Assets();
    recommendationAssetsFilters = hasPower(recommendationBypassSharingRestrictions)
      ? [['status', 'eq', 'available'], ...(isMm3Assets ? [['root', 'eq', true]] : [])]
      : [...(isMm3Assets ? [['root', 'eq', true]] : []), ['status', 'eq', 'available'], ['sharable', 'eq', true]];
  }

  return recommendationAssetsFilters;
};
