import { IUseActionsOptionParam, IUseActionsResponse, useActions } from 'utils/actions';
import { IIdentifiable } from 'types';
import { useDeleteAction } from './delete-action';
import { useRestoreAction } from './restore-action';
import { useChangeAccessAction } from './change-access-action';
import { useCopyAction } from 'utils/actions/common/copy-action';

import { IGuildActionName } from './types';

import type { IShouldDisplayGuildActionFunction, IGuildActionConfig } from './types';
import {
  useAddProductsAction,
  useAddAssetsAction,
  useAddContactsAction,
  useAddOrganizationsAction,
} from './add-entities-actions';
import { useGenerateCodesAction } from './generate-codes-action';
import { IGuild } from 'components/guilds';
export { useCreateGuildAction } from './create-guild-action';

export { IGuildActionName, IShouldDisplayGuildActionFunction };

export {
  useAddContactsAction,
  useAddOrganizationsAction,
  useAddProductsAction,
  useAddAssetsAction,
  useAddContactSelectionsAction,
  contactsToItems,
  productsToItems,
  organizationsToItems,
  contactSelectionsToItems,
} from './add-entities-actions';

export const useGildsActions = (
  entities: (IGuild & IIdentifiable)[] = [],
  options: IUseActionsOptionParam,
): IUseActionsResponse<IGuildActionName> => {
  const generateCodes = useGenerateCodesAction(entities, options);
  const addProductsAction = useAddProductsAction({
    entities,
    options: { ...options, addSelectedProductAncestors: true },
  });

  const addAssetsAction = useAddAssetsAction({ entities, options });
  const addContactsAction = useAddContactsAction({ entities, options });
  const addOrganizationsAction = useAddOrganizationsAction({
    entities,
    options,
  });
  const changeAccessAction = useChangeAccessAction(entities, options);

  const copyGuildAction = useCopyAction(entities, {
    ...options,
    messageSuccess: 'Guild has been copied',
  });

  const deleteGuildAction = useDeleteAction(entities, options);
  const restoreAction = useRestoreAction(entities, options);

  const actions = [
    generateCodes,
    addProductsAction,
    addAssetsAction,
    addContactsAction,
    addOrganizationsAction,
    changeAccessAction,
    copyGuildAction,
    restoreAction,
    deleteGuildAction,
  ];

  return useActions(actions as IGuildActionConfig[], options, entities);
};
