import React, { useCallback, useMemo } from 'react';

import { useStore } from 'store';

import { IAsset, IMm3Asset } from 'types';
import { FormDate, FormChecklist, useForm, useMm3Form } from 'helpers/form';
import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';
import { ToastError } from 'components/toast';
import { SharedScheduledForm } from 'components/scheduled-job-list/shared-scheduled-form-elements';

import { IAccessChangeScheduledProtection } from 'utils/actions/asset';
import { useScheduledJobs } from './hooks';
import { protectionContextExtension } from './utils';
import { minAccessDate, maxAccessDate } from 'utils/constants';
import { Model } from 'helpers/filters/types';
import { getValidIds } from 'utils/general';
import { getIsMm3Assets } from 'utils/asset';

const renderDetails = (job): string => job.protection_levels.join(', ');

export const AccessChangeScheduledProtectionForm: React.FC<{ items: (IAsset | IMm3Asset)[]; readOnly?: boolean }> = ({
  items,
  readOnly = false,
}) => {
  const isMm3Assets = getIsMm3Assets();
  const formId = `asset-access-change-scheduled-protection`;
  const { toastStore } = useStore();

  const itemIds = useMemo(() => getValidIds(items), [items]);

  const [scheduledJobs, removeJob, scheduleJob] = useScheduledJobs<IAccessChangeScheduledProtection>(
    'change_protection_levels_on_asset',
    itemIds,
  );

  const submitForm = useCallback(
    async (data: IAccessChangeScheduledProtection) => {
      try {
        await scheduleJob(data);
        toastStore.success('Protection changed');
      } catch (error) {
        toastStore.error(<ToastError error={error} placeholder={'Sorry, something went wrong'} />);
      }
    },
    [toastStore, scheduleJob],
  );
  const initialValues = {
    scheduled_for: '',
    ...(isMm3Assets ? {} : { protection_levels: [] }),
    item_ids: itemIds,
  };
  const mm3Form = useMm3Form<IAccessChangeScheduledProtection>(initialValues, Model.MM3_ASSETS, submitForm);

  const coreForm = useForm<IAccessChangeScheduledProtection>(
    initialValues,
    Model.ASSETS,
    submitForm,
    protectionContextExtension,
  );
  const currentForm = isMm3Assets ? mm3Form : coreForm;
  const { formData, handlers, valid, onSubmit } = currentForm;
  const disableSubmit = scheduledJobs.find(({ scheduled_for }) => scheduled_for === formData.scheduled_for.value);

  return (
    <SharedScheduledForm
      readOnly={readOnly}
      renderDetails={renderDetails}
      onSubmit={onSubmit}
      removeJob={removeJob}
      scheduledJobs={scheduledJobs}
      submitDisabled={!valid || Boolean(disableSubmit)}
      title="Scheduled Protections"
    >
      <FormSectionWrapperV2 title="Schedule new protection" gridTemplateColumns="1fr">
        <FormDate
          name="scheduled_for"
          label="Date"
          large
          withTime={true}
          minDate={minAccessDate}
          maxDate={maxAccessDate}
          {...formData.scheduled_for}
          {...handlers}
        />
        <br />
        {isMm3Assets ? (
          <></>
        ) : (
          <FormChecklist
            name="protection_levels"
            label="Protection Levels"
            formId={formId}
            large
            {...formData.protection_levels}
            {...handlers}
          />
        )}
      </FormSectionWrapperV2>
    </SharedScheduledForm>
  );
};
