import React, { useEffect, useState, useMemo } from 'react';
import { isEqual, merge } from 'lodash';
import { observer } from 'mobx-react-lite';

import { SharedAssetForm } from './shared-single-asset-form';

import { Model } from 'helpers/filters/types';
import { useMm3Form } from 'helpers/form';
import { getAssetModelByType } from 'utils/asset';
import { getRequiredError, IValidationResult } from 'helpers/form/mm3';

import { IAssetFormProps } from './types';
import { IAssetFields, IAssetFile, IMm3AssetType } from 'types/asset';

import './index.scss';

export const customMm3Context = (
  asset: IAssetFile,
  validations: IValidationResult<IAssetFile>,
): IValidationResult<IAssetFile> => {
  if (!asset) return validations;

  return merge({}, validations, {
    id: {
      isValueNumber: false,
      validation: { valid: true },
    },
    classification: {
      required: true,
      ...(asset.classification ? {} : getRequiredError()),
    },
    division_ids: {
      required: asset.access_level === 'division',
      ...(asset.access_level === 'division' && !asset?.division_ids?.length ? getRequiredError() : {}),
    },
    meta: {
      language_id: {
        required: true,
        ...(asset?.['meta.language_id'] ? {} : getRequiredError()),
      },
    },
  });
};

export const Mm3AssetForm: React.FC<IAssetFormProps> = ({
  index,
  initialValues,
  removeFile,
  onDataChange,
  setIsValidForm,
  shouldShowErrors,
  applyPreset,
  savePreset,
  withParentField,
  assetsUploaderStore,
}) => {
  const [model, setModel] = useState<string>(Model.MM3_ASSETS);
  const form = useMm3Form<IAssetFields>(initialValues, model, undefined, customMm3Context);

  const { valid, values, handlers: handleChanges } = form;

  useEffect(() => {
    if (!isEqual(initialValues, values)) {
      form.onSetFields(initialValues);
      onDataChange(initialValues, index);
    }
  }, [initialValues, values, onDataChange, index, form]);

  useEffect(() => {
    setIsValidForm(initialValues.id, valid);
  }, [valid, initialValues, setIsValidForm]);

  const handlers = useMemo(() => {
    return {
      ...handleChanges,
      onChange(values: Partial<IAssetFile>): void {
        handleChanges.onChange(values);
        onDataChange(values, index);
      },
    };
  }, [handleChanges, onDataChange, index]);

  useEffect(() => {
    // TODO Adjust preset form for different asset types
    if (values.type === IMm3AssetType.SUBTITLE && values.classification !== 'document/subtitle') {
      handlers.onChange({ classification: 'document/subtitle' });
    }
    if (values.type === IMm3AssetType.AUDIO && values.classification !== 'audio') {
      handlers.onChange({ classification: 'audio' });
    }
  }, [values.classification, values.type, handlers]);

  useEffect(() => {
    setModel(getAssetModelByType(values?.type));
  }, [values?.type]);

  return (
    <SharedAssetForm
      initialValues={initialValues}
      removeFile={removeFile}
      form={form}
      shouldShowErrors={shouldShowErrors}
      withParentField={withParentField}
      applyPreset={applyPreset}
      savePreset={savePreset}
      onDataChange={onDataChange}
      index={index}
      setIsValidForm={setIsValidForm}
      assetsUploaderStore={assetsUploaderStore}
    />
  );
};

export default React.memo(observer(Mm3AssetForm));
