import React, { useCallback, useMemo, useRef, useEffect } from 'react';
import { ActionIcon, Button, Flex, RingProgress, Text, Tooltip } from '@mantine/core';

import { Thumbnail } from 'components/thumbnail';
import { ThumbnailFileStatus } from 'components/file-status';

import { MantineIcon, Classes } from 'utils/ui';
import { AssetFilters, getIngestStatus, getPreviewImageUrl } from 'utils/asset';
import { getEntityType } from 'utils/general';
import { IAssetFileUploadDialog, isAssetFileIngesting, useRefreshIngestingAssetFile } from './utils';

import { IAssetFile, IMm3Asset, IMm3AssetType } from 'types';

import './index.scss';

export const AssetFileUploadShortcut: React.FC<IAssetFileUploadDialog> = ({
  files,
  progress,
  isUploadPending,
  uploadFinished,
  asset,
  isSending,
  isSubmitted,
  onClick,
  removeFile,
  setAsset,
  cleanUp,
  isCancelled,
}) => {
  const file = files?.[0];
  const fileRef = useRef<IAssetFile | null>(file);
  const isAssetIngestInProgress = asset && isAssetFileIngesting(asset);
  const assetItemType = getEntityType(asset?.classification);
  const coreImage = assetItemType === AssetFilters.IMAGE;
  const mm3Image = (asset as IMm3Asset)?.type === IMm3AssetType.IMAGE;
  const isImage = coreImage || mm3Image;

  const isIngestDone = useRefreshIngestingAssetFile(asset, setAsset);

  const onRemove = useCallback(() => {
    removeFile(file);
  }, [file, removeFile]);

  useEffect(() => {
    if (file) fileRef.current = file;
  }, [file]);

  useEffect(() => {
    return () => {
      fileRef.current = null;
    };
  }, []);

  const backgroundOverlay = useMemo(() => {
    if (!isSubmitted && file) {
      return (
        <div className="asset-upload-modal__progress-indicator">
          <RingProgress
            variant="primary"
            sections={[{ value: progress, color: 'var(--mfx-primary)' }]}
            size={65}
            thickness={4}
            roundCaps
            label={<div className="asset-upload-modal__progress-text">{progress}%</div>}
          />
        </div>
      );
    }
    if (isCancelled) {
      return <ThumbnailFileStatus status={'cancelled'} size={35} withBackground />;
    }
    if (isAssetIngestInProgress) {
      return <ThumbnailFileStatus status={getIngestStatus(asset)} size={35} withBackground />;
    }
    if (isIngestDone) {
      return (
        <div className="file-status--with-bg">
          <span className="file-status__text ">Complete</span>
        </div>
      );
    }
  }, [asset, isCancelled, file, isAssetIngestInProgress, isSubmitted, progress, isIngestDone]);

  const thumbnailPlaceholder = useMemo(() => {
    if (!isImage && !isSubmitted && file) {
      return (
        <div className="asset-upload-modal__progress-indicator">
          <RingProgress
            variant="primary"
            sections={[{ value: progress, color: 'var(--mfx-primary)' }]}
            size={65}
            thickness={4}
            roundCaps
            label={<div className="asset-upload-modal__progress-text">{progress}%</div>}
          />
        </div>
      );
    }
    if ((!isImage && isSubmitted) || isCancelled) {
      return (
        <ThumbnailFileStatus status={isCancelled ? 'cancelled' : getIngestStatus(asset)} size={35} withBackground />
      );
    }
  }, [asset, file, isCancelled, isImage, isSubmitted, progress]);

  const shortcutButtons = useMemo(() => {
    return (
      <Flex direction={!isSubmitted && !isCancelled ? 'column' : 'column-reverse'} justify="space-between">
        <Tooltip label="Go to asset page">
          <ActionIcon
            variant="subtle"
            color="gray.5"
            className="open-assets-upload-page-btn align-self-end"
            onClick={onClick}
          >
            <MantineIcon icon="share" />
          </ActionIcon>
        </Tooltip>
        {file && !isSubmitted && !isCancelled && (
          <div className={`${Classes.TEXT_MUTED} align-self-center mx-2 my-4`}>
            <div>Uploading…</div>
          </div>
        )}
        <Tooltip label="Closing will not cancel anything" hidden={isCancelled}>
          <ActionIcon
            variant="subtle"
            color="gray.5"
            className="open-assets-upload-page-btn"
            onClick={cleanUp}
            hidden={!isSubmitted && !isCancelled}
          >
            <MantineIcon icon="cross" />
          </ActionIcon>
        </Tooltip>

        <Button
          onClick={onRemove}
          variant="primary"
          disabled={isSending || uploadFinished}
          hidden={progress === 100 || !files.length || uploadFinished}
          size="xs"
          w="fit-content"
          className="align-self-end"
        >
          Cancel
        </Button>
      </Flex>
    );
  }, [isCancelled, cleanUp, file, files, isSending, isSubmitted, onClick, onRemove, progress, uploadFinished]);

  const previewImage = useMemo((): string | undefined => {
    if (isImage) {
      return file?.preview || fileRef.current?.preview || '';
    }
    if (isSubmitted && isIngestDone) return getPreviewImageUrl(asset?.preview_image);
  }, [isImage, isSubmitted, isIngestDone, asset?.preview_image, file?.preview]);

  if (!isUploadPending) {
    return null;
  }

  return (
    <div className="assets-file-upload-modal d-flex flex-column">
      <Flex gap={1} direction="row" justify="space-between">
        <Flex gap={1} direction="column" align="center" className="p-1 asset-upload-shortcut__thumbnail-container">
          {fileRef.current && (
            <Text lineClamp={3} component="div" className="text-center text-break" maw="100%">
              {fileRef.current.file_name}
            </Text>
          )}
          <div className="asset-upload-shortcut__thumbnail">
            <Thumbnail
              size="hd"
              image={previewImage}
              backgroundOverlay={backgroundOverlay}
              thumbnailContentClassName={'d-flex justify-content-center align-items-center'}
              noResize
              placeholder={thumbnailPlaceholder}
            />
          </div>
        </Flex>
        {shortcutButtons}
      </Flex>
    </div>
  );
};

export default AssetFileUploadShortcut;
