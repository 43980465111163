import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { pick } from 'lodash';
import { Progress } from '@mantine/core';
import { v4 as uuidv4 } from 'uuid';
import { Uppy as IUppy } from '@uppy/core';

import { useStore } from 'store';

import { FormSubmitSection } from 'components/form-submit-section';

import { getIsMm3Assets, getOrientation } from 'utils/asset';
import { initializeUppy } from './api';

import { IModalSize } from 'components/dialogs/types';
import { IFile } from 'types';
import type { IUploadType } from './types';

import './index.scss';

interface IUploadImageProps {
  onProgressUpdate?: (progress: number) => void;
  onUploadFinished: (file?: IFile) => void;
  message?: string;
  type?: IUploadType;
  extensions?: string[] | null;
  disableSubmit?: boolean;
  handleSubmit: React.FormEventHandler<HTMLFormElement>;
  setFileUrl?: (url: string) => void;
}

const UploadImageView: React.FC<IUploadImageProps> = ({
  onProgressUpdate,
  onUploadFinished,
  type,
  extensions,
  message = 'Select an image to upload',
  handleSubmit,
  setFileUrl,
}) => {
  const [progress, setProgress] = useState(0);

  const uppyRef = useRef<IUppy | null>(null);
  const [uploadFinished, setUploadFinished] = useState(false);

  const dropAreaId = useMemo(() => `upload-image-drop-area-${uuidv4()}`, []);

  useEffect(() => {
    initializeUppy({ target: dropAreaId, type, extensions }).then((uppy) => {
      uppy.on('file-added', (file) => {
        const data = file.data; // is a Blob instance
        const url = URL.createObjectURL(data);
        const image = new Image();
        image.src = url;
        setFileUrl?.(url);

        image.onload = () => {
          uppy.setFileMeta(file.id, { width: image.width, height: image.height });
          URL.revokeObjectURL(url);
        };
      });

      uppy.on('upload-success', (file, response) => {
        if (!file?.id) {
          return;
        }
        setUploadFinished(true);

        onUploadFinished({
          id: file.id,
          url: response['uploadURL'],
          name: file.name,
          size: file.size,
          meta: {
            ...pick(file.meta, 'width', 'height'),
            orientation: getOrientation(file),
          } as unknown as IFile['meta'],
        });
      });

      uppy.on('progress', (progress) => {
        setProgress(Math.max(progress, 1));
      });
      uppy.on('upload', () => {
        setProgress(1);
      });

      uppyRef.current = uppy;
    });

    return () => {
      uppyRef.current?.close();
    };
  }, [dropAreaId, extensions, onUploadFinished, setFileUrl, setUploadFinished, type]);

  useEffect(() => {
    onProgressUpdate?.(progress);
  }, [progress, onProgressUpdate]);

  const [isSending, setIsSending] = useState(false);
  const onSubmit = useCallback(
    async (evt) => {
      setIsSending(true);
      await handleSubmit(evt);
      setIsSending(false);
    },
    [handleSubmit],
  );
  const uploadButtonEnabled = progress >= 100 && uploadFinished;

  return (
    <form onSubmit={onSubmit}>
      <div className="preview-image-uploader-container">
        <div id={dropAreaId} className="preview-image-uploader" />
        <div className="image-uploader-progress-container">
          <div className="progress-text-container">
            {progress >= 100 ? 'Upload completed!' : progress > 0 ? `Uploading ${progress}%...` : message}
          </div>
          <Progress value={progress} />
        </div>
      </div>
      <FormSubmitSection labels={{ confirm: 'Upload' }} submitDisabled={!uploadButtonEnabled || isSending} />
    </form>
  );
};

interface IUseUploadImageDialog {
  onSubmit: (file?: IFile | null) => void;
  customMessage?: string;
  extensions?: string[] | null;
  type?: IUploadType;
  successMessage?: string;
  setFileUrl?: (url: string) => void;
}

export const useUploadImageDialog = (props: IUseUploadImageDialog): [() => void] => {
  const {
    onSubmit,
    customMessage,
    type,
    extensions,
    successMessage = 'Preview processing in progress.',
    setFileUrl,
  } = props;
  const { dialogStore, toastStore } = useStore();
  const uploadedFile = useRef<IFile | undefined | null>(null);
  const isMm3Assets = getIsMm3Assets();
  const imageExtensions = isMm3Assets ? ['image/png', 'image/jpeg'] : ['image/png', 'image/jpeg', 'image/gif'];
  const fileExtensions = extensions || imageExtensions;
  const handleSubmit = useCallback<React.FormEventHandler<HTMLFormElement>>(
    async (e) => {
      try {
        e.preventDefault();
        await onSubmit?.(uploadedFile.current);
        toastStore.clearAll();
        toastStore.success(successMessage);
        return dialogStore.close();
      } catch (error) {
        toastStore.error(error.text);
      }
    },
    [onSubmit, toastStore, dialogStore, successMessage],
  );

  const body = useCallback(() => {
    return (
      <UploadImageView
        type={type}
        extensions={fileExtensions}
        onUploadFinished={(file: IFile) => {
          uploadedFile.current = file;
        }}
        setFileUrl={setFileUrl}
        message={customMessage}
        handleSubmit={handleSubmit}
      />
    );
  }, [type, fileExtensions, setFileUrl, customMessage, handleSubmit]);

  const openDialog = (): void => {
    dialogStore.openModal({
      title: 'Upload',
      body,
      size: IModalSize.XS,
    });
  };

  return [openDialog];
};
