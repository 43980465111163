import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Accordion, Switch } from '@mantine/core';
import cx from 'classnames';
import { useLocation } from 'react-router-dom';

import { IFiltersDefinition, QFilter, RangeFilter, EqFilter, InFilter } from 'helpers/filters/types';
import { useFilters } from 'helpers/filters';
import { submitFilters, toggleParam } from 'helpers/filters/helpers';

import { customContactParam } from 'components/contact-data-section';
import { DataSectionPreset } from 'components/data-section-preset';
import { AccreditaionFiltersCmp } from 'components/contact-filters/additional-filters';
import { getActiveFiltersCount } from 'components/product-filters/utils';
import { FilterAccordionItem } from 'components/filter-accordion-item/filter-accordion-item';
import {
  contactEssentialFilters,
  contactSettingsFilters,
  contactTimestampFilters,
} from 'components/contact-filters/utils';
import { ContactSettingsFilters } from 'components/contact-filters/contact-settings-filters';
import { ContactTimestampFilters } from 'components/contact-filters/contact-timestamps-filters';
import { ContactEssentialFilters } from 'components/contact-filters/contact-essential-filters';

import { useStore } from 'store';
import { parseQueryParams } from 'utils/general';
import { FILTER_SECTIONS } from 'utils/constants';
import { clearActiveFiltersSection } from 'utils/filters';

export interface IContactFilters extends IFiltersDefinition {
  _: QFilter;
  status: InFilter;
  role_id: EqFilter;
  activated_at: RangeFilter;
  created_at: RangeFilter;
  last_login_at: RangeFilter;
  organization_id: EqFilter;
  country_id: EqFilter;
  responsible_user_id: EqFilter;
  bouncing: EqFilter;
  mobile_sync: EqFilter;
  division_ids: InFilter;
}

const defaultParamComponentValues = (queryParams): typeof customContactParam => ({
  include_deleted: !!queryParams['include_deleted'] && queryParams['include_deleted'].toString() === 'true',
  include_internal_accounts:
    !!queryParams['include_internal_accounts'] && queryParams['include_internal_accounts'].toString() === 'true',
});

const ContactFilters: React.FC = observer(() => {
  const location = useLocation();
  const { dataSectionStore, basicStore } = useStore();
  const { divisions } = basicStore;
  const queryParams = parseQueryParams(location.search);

  const [paramComponentValues, setParamComponentValues] = useState(defaultParamComponentValues(queryParams));
  const [activeAccordion, setActiveAccordion] = useState<string | null>(FILTER_SECTIONS.ESSENTIALS);

  const handleSubmit = (action): void => {
    submitFilters({
      action,
      setParamComponentValues,
      customParams: customContactParam,
    });
  };

  const [filterValues, filterHandlers] = useFilters<IContactFilters>(dataSectionStore, handleSubmit);

  const handleToggleParam = (event): void => {
    toggleParam({
      event,
      paramComponentValues,
      setParamComponentValues,
    });
  };

  const activeEssentialFiltersCount = getActiveFiltersCount(filterValues, contactEssentialFilters);
  const activeSettingsFiltersCount = getActiveFiltersCount(filterValues, contactSettingsFilters);
  const activeTimestampFiltersCount = getActiveFiltersCount(filterValues, contactTimestampFilters);

  return (
    <div className="entity-filters">
      <DataSectionPreset filterHandlers={filterHandlers} />
      <Accordion
        chevronPosition="left"
        classNames={{
          chevron: cx({
            'active-accordion__element': activeAccordion === FILTER_SECTIONS.ESSENTIALS,
          }),
        }}
        value={activeAccordion}
        onChange={setActiveAccordion}
      >
        <div className="entity-filters__new-filter">
          <FilterAccordionItem
            value={FILTER_SECTIONS.ESSENTIALS}
            title={FILTER_SECTIONS.ESSENTIALS}
            activeAccordion={activeAccordion}
            activeFiltersCount={activeEssentialFiltersCount}
            onClearFilters={(e) => {
              e.stopPropagation();
              clearActiveFiltersSection(contactEssentialFilters, filterHandlers.onChange);
            }}
          >
            <ContactEssentialFilters
              filterValues={filterValues}
              filterHandlers={filterHandlers}
              dataSectionStore={dataSectionStore}
              divisions={divisions}
            />
          </FilterAccordionItem>

          <AccreditaionFiltersCmp
            filterValues={filterValues}
            filterHandlers={filterHandlers}
            activeAccordion={activeAccordion}
          />

          <FilterAccordionItem
            value={FILTER_SECTIONS.SETTINGS}
            title={FILTER_SECTIONS.SETTINGS}
            activeAccordion={activeAccordion}
            activeFiltersCount={activeSettingsFiltersCount}
            onClearFilters={(e) => {
              e.stopPropagation();
              clearActiveFiltersSection(contactSettingsFilters, filterHandlers.onChange);
            }}
          >
            <ContactSettingsFilters
              filterValues={filterValues}
              filterHandlers={filterHandlers}
              dataSectionStore={dataSectionStore}
            />
          </FilterAccordionItem>
          <FilterAccordionItem
            value={FILTER_SECTIONS.TIMESTAMPS}
            title={FILTER_SECTIONS.TIMESTAMPS}
            activeAccordion={activeAccordion}
            activeFiltersCount={activeTimestampFiltersCount}
            onClearFilters={(e) => {
              e.stopPropagation();
              clearActiveFiltersSection(contactTimestampFilters, filterHandlers.onChange);
            }}
            mb={20}
          >
            <ContactTimestampFilters filterValues={filterValues} filterHandlers={filterHandlers} />
          </FilterAccordionItem>
          <Switch
            mb="sm"
            label="Include internal accounts"
            data-param={'include_internal_accounts'}
            onChange={handleToggleParam}
            checked={paramComponentValues.include_internal_accounts}
          />

          <Switch
            mb="sm"
            label="Include deleted"
            data-param={'include_deleted'}
            onChange={handleToggleParam}
            checked={paramComponentValues.include_deleted}
          />
        </div>
      </Accordion>
    </div>
  );
});

export default ContactFilters;
