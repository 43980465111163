import React, { useCallback } from 'react';
import { ILocalizationOrder } from 'types/localization-order';
import noop from 'lodash/noop';

import { EmptySectionMessage } from 'components/section-message';
import { useRemote } from 'utils/hooks';

import { queryProducts } from 'utils/apis/product';
import { IProduct, ItemId } from 'types';
import { ProductDataSectionListItem } from 'components/product-data-section-item/product-data-section-list-item';

import './style.scss';

interface IOrderRecipientsTabProps {
  order: ILocalizationOrder;
}

const OrderProductsTab: React.FC<IOrderRecipientsTabProps> = ({ order }) => {
  const { product_id } = order || {};
  const fetchProduct = useCallback(async () => {
    const products = await queryProducts({ ids: [product_id as ItemId] });
    return products;
  }, [product_id]);

  const [products, loading] = useRemote(fetchProduct);

  if (!loading && !products?.length) {
    return <EmptySectionMessage />;
  }

  const product = products?.[0] || ({} as IProduct);
  return (
    <div className="asset-metadata-tab h-100">
      <ProductDataSectionListItem
        product={product}
        id={product.id || 0}
        isAllItemsSelected={false}
        active={NaN}
        handleClick={noop}
        isChecked={false}
        contextMenu={<></>}
      />
    </div>
  );
};

export default OrderProductsTab;
