import { AmGenericAsset3 } from '@mediafellows/mm3-types';
import { UppyFile, UploadedUppyFile } from '@uppy/core';

import { ItemId } from 'types';

export interface IAssetFields extends IAssetPresetFields {
  id: string;
  selected: boolean;
  file_name?: string | null;
  name?: string | null;
  preview?: string | null;
  uploadURL?: string | null;
  type?: string | null;
}

export interface IAssetPresetFields {
  protection_levels?: string[];
  watermark?: string;
  access_level: string;
  permissions: string[];
  languages: string[];
  burned_in_subtitles_language_id?: string;
  status: string;
  classification?: string | null;
  type?: string | null;
  products: string[];
  parent_id?: ItemId | null;
  sharable: boolean;
  'meta.language_id'?: string;
  'meta.purpose'?: string;
  'meta.ai_generated'?: boolean;
  'meta.ai_notes'?: string;
  'product.marketing_use': boolean;
  'layer.notes': string;
  dubbed_language_id?: string;
  division_ids?: number[];
}

export interface IAssetFile extends IAssetFields {
  fileData: Partial<UppyFile>;
  uploadURL?: string | null;
}

export interface IAssetPayload {
  id: string;
  selected: boolean;
  file_name: string;
  name: string;
  preview?: string;
  type: string;
  protection_levels?: string[];
  watermark: string;
  access_level: string;
  permissions: string[];
  languages: string[];
  sharable: boolean;
  status: string;
  classification: string;
  products: string[];
  product: { marketing_use: boolean };
  layer: { notes: string };
  burned_in_subtitles_language_id?: string;
  dubbed_language_id?: string;
}

export interface IMm3AssetPayload {
  id: string;
  selected: boolean;
  file_name: string;
  name: string;
  preview?: string;
  type: AmGenericAsset3['type'];
  protection_levels: Array<'2fa' | 'drm' | 'watermark'>;
  watermark: string;
  access_level: AmGenericAsset3['access_level'];
  permissions: AmGenericAsset3['permissions'];
  languages: string[];
  language_id: string;
  sharable: boolean;
  status: AmGenericAsset3['status'];
  classification: AmGenericAsset3['classification'];
  products: string[];
  product: { marketing_use: boolean };
  parent_id: number;
  layer: { notes: string };
  burned_in_subtitles_language_id?: string;
  dubbed_language_id?: string;
}

export type IUploadedFile = UploadedUppyFile<{}, {}> & { orientation?: string };

export interface IAssetToProduct {
  id: number;
  asset_ids: ItemId[];
  product_ids: ItemId[];
  marketing_use: boolean;
  include_descendants: boolean;
}

export interface IResolution {
  width?: number | null;
  height?: number | null;
}

export type IAssetMainClassification = 'video' | 'audio' | 'image' | 'document' | 'imf';
export enum IAssetMainClassificationValues {
  VIDEO = 'video',
  AUDIO = 'audio',
  IMAGE = 'image',
  DOCUMENT = 'document',
  IMF = 'imf',
}

export enum IMm3AssetType {
  IMF = 'Asset3::Digital::Imf',
  VIDEO = 'Asset3::Digital::Video',
  AUDIO = 'Asset3::Digital::Audio',
  IMAGE = 'Asset3::Digital::Image',
  DOCUMENT = 'Asset3::Digital::Document',
  OTHER = 'Asset3::Digital::Other',
  SUBTITLE = 'Asset3::Digital::Subtitle',
}

export type IAssetAggregation<T = IAssetMainClassification> = {
  [P: `count_by_${string}`]: {
    count: number;
    value: string | T;
  }[];
};

export enum AssetStatus {
  AVAILABLE = 'available',
  CREATED = 'created',
  REVIEW = 'review',
}

export enum IngestStatus {
  INVALID = 'invalid',
  AVAILABLE = 'available',
  READY = 'ready',
}
