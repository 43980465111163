import React from 'react';
import { observer } from 'mobx-react-lite';

import { InfoBoxV2 } from 'components/info-box-v2';
import { LabelValuePair as Row } from 'components/label-value-pair';

import { FormAccessLevel, FormAssetStatus, FormCheckbox, FormChecklist } from 'helpers/form';
import { AccessLevelLabel } from 'utils/ui';
import { useSessionStore } from 'store/session-store';
import { IAssetMm3MetadataEditTabProps } from 'components/asset/edit-sections/types';
import { hasUserRoleAmWriteProtectedAttributesPower } from 'components/asset/asset-edit-form/utils';
import { UserRoles } from 'types';

const formId = 'edit-asset-overview-settings';

export const AssetOverviewSettingsEdit: React.FC<IAssetMm3MetadataEditTabProps> = observer(({ form }) => {
  const { user } = useSessionStore();
  const { formData, handlers } = form;
  const isUserRoleAllowed = hasUserRoleAmWriteProtectedAttributesPower(user?.role_name as UserRoles);

  return (
    <InfoBoxV2 gridTemplateColumns={'minmax(135px, 1fr) 2fr'} title="Settings" wrapperClassName="mt-3">
      <Row
        label="Status"
        showAsterisk
        value={<FormAssetStatus name="status" large {...{ ...formData.status }} {...handlers} />}
        size="m"
      />
      <Row
        label={<AccessLevelLabel label={'Access Level'} required options={formData.access_level?.options} />}
        value={
          <FormAccessLevel
            name="access_level"
            placeholder="Select Access Level"
            large
            {...formData.access_level}
            {...handlers}
          />
        }
        size="m"
      />
      <Row
        label="Sharable"
        value={
          <FormCheckbox
            name="sharable"
            label="Allow non-Administrator contacts to share this asset with other contacts"
            formId={formId}
            {...formData.sharable}
            {...handlers}
            disabled={!isUserRoleAllowed}
          />
        }
        size="m"
      />
      <Row
        label="Permissions"
        value={<FormChecklist name="permissions" formId={formId} {...formData.permissions} {...handlers} />}
        size="m"
      />
    </InfoBoxV2>
  );
});
