import React from 'react';

import { IFormData, IFormHandlers } from 'helpers/form/types';
import { FormAssets, FormCheckbox, FormMultiSelect, FormProducts } from 'helpers/form';

import { IFormSelectionItemsForm } from './types';
import { onQueryContacts } from './api';

interface IFormProps {
  formData: IFormData<IFormSelectionItemsForm>;
  handlers: IFormHandlers<IFormSelectionItemsForm>;
  omni?: boolean;
}

const AssetsForm: React.FC<IFormProps> = ({ formData, handlers, omni = true }) => {
  return <FormAssets name="item_ids" label="Assets" {...formData['item_ids']} {...handlers} omni={omni} />;
};

const ProductsForm: React.FC<IFormProps> = ({ formData, handlers, omni = true }) => {
  return (
    <>
      <FormProducts name="item_ids" label="Products" {...formData['item_ids']} {...handlers} omni={omni} />
      <FormCheckbox
        className="mb-0"
        name="include_descendants"
        label="Including descendants"
        {...formData['include_descendants']}
        {...handlers}
      />
    </>
  );
};

const UsersForm: React.FC<IFormProps> = ({ formData, handlers, omni = true }) => {
  return (
    <FormMultiSelect
      fetchValues={onQueryContacts}
      name="item_ids"
      label="Contacts"
      {...formData['item_ids']}
      {...handlers}
      omni={omni}
    />
  );
};

export default {
  assets: AssetsForm,
  products: ProductsForm,
  users: UsersForm,
};
