import React from 'react';

import { getTabId, orderItemsKeys, projectDetailsKeys, StepIds } from './utils';
import { useWizardSteps, Wizard, WizardStep } from 'components/wizard';
import { IconAlias } from 'icons';
import { ProjectDetailStep } from './wizard-steps/order-project-detail-step';
import { OrdersProductsStep } from './wizard-steps/order-products-step';
import { OrderItemsStep } from './wizard-steps/order-items-step';
import { OrderSummaryStep } from './wizard-steps/order-summary-step';
import { WizardButtons } from './ui';
import { isValidMM3 } from 'helpers/form';
import { ILocalizationOrderStep } from './types';
import { OrderItemsGridStep } from './wizard-steps/order-items-grid-step';

const LocalizationOrderWizard = ({ orderForm, onFinish }: ILocalizationOrderStep): JSX.Element => {
  const steps = [
    {
      id: StepIds.ProjectDetails,
      disabled: !isValidMM3(orderForm.formData, projectDetailsKeys),
    },
    { id: StepIds.Products },
    { id: StepIds.Order, disabled: !isValidMM3(orderForm.formData, orderItemsKeys) },
    { id: StepIds.OrderItems },
    {
      id: StepIds.Summary,
      nextButtonTitle: 'Save Draft',
      action: (evt: React.MouseEvent<HTMLElement, MouseEvent>) => {
        orderForm.onSubmit(evt);
      },
    },
  ];

  const { nextButton, backButton, currentStepId, setCurrentStepId, isStepDisabled } = useWizardSteps(steps, {
    defaultStepId: getTabId(StepIds.ProjectDetails),
  });

  return (
    <Wizard
      selectedStepId={currentStepId}
      onChange={setCurrentStepId}
      showStepTitleIndex
      fitInParent
      footerPadded={false}
      footer={
        <WizardButtons
          {...{
            form: orderForm,
            onFinish,
            nextButton,
            backButton,
            currentStepId,
          }}
        />
      }
    >
      <WizardStep
        id={StepIds.ProjectDetails}
        title="Project Details"
        disabled={isStepDisabled(StepIds.ProjectDetails)}
        icon={IconAlias.ORGANIZATION}
        panel={<ProjectDetailStep orderForm={orderForm} />}
      />

      <WizardStep
        id={StepIds.Products}
        title="Products"
        disabled={isStepDisabled(StepIds.Products)}
        icon={IconAlias.PRODUCT}
        panel={<OrdersProductsStep orderForm={orderForm} />}
      />

      <WizardStep
        id={StepIds.Order}
        title="Order"
        disabled={isStepDisabled(StepIds.Order)}
        icon={IconAlias.DELIVERY}
        panel={<OrderItemsStep orderForm={orderForm} />}
      />

      <WizardStep
        id={StepIds.OrderItems}
        title="Order Items"
        icon={IconAlias.ITEMS}
        panel={<OrderItemsGridStep orderForm={orderForm} />}
      />

      <WizardStep
        id={StepIds.Summary}
        title="Summary"
        disabled={isStepDisabled(StepIds.Summary)}
        icon={IconAlias.SETTINGS}
        panel={<OrderSummaryStep orderForm={orderForm} />}
      />
    </Wizard>
  );
};

export default LocalizationOrderWizard;
