import React from 'react';
import { observer } from 'mobx-react-lite';

import { IFilters } from 'components/product-filters/types';
import { ProductEssentialFilters } from 'components/product-filters/product-essential-filters';
import { ProductAccessAndOwnershipFilters } from 'components/product-filters/product-access-ownership-filters';
import { ProductTimestampsFilters } from 'components/product-filters/product-timestamps-filters';
import { SmartGroupFilterWrapper } from 'components/smart-group-filter-wrapper/smart-group-filter-wrapper';

import { IFiltersHandlers } from 'helpers/filters';
import { IProduct } from 'types';
import { FILTER_SECTIONS } from 'utils/constants';

import { useBasicStore } from 'store/hooks';
import { DataSectionStore } from 'store/data-section-store';

import './styles.scss';

export const SmartGroupProductFilters: React.FC<{
  dataSectionStore: DataSectionStore<IProduct>;
  filterValues: IFilters;
  filterHandlers: IFiltersHandlers<IFilters>;
}> = observer(({ dataSectionStore, filterValues, filterHandlers }) => {
  const { divisions } = useBasicStore();

  return (
    <>
      <SmartGroupFilterWrapper title={FILTER_SECTIONS.ESSENTIALS}>
        <ProductEssentialFilters
          filterValues={filterValues}
          filterHandlers={filterHandlers}
          dataSectionStore={dataSectionStore}
          isSmartGroupFilters
        />
      </SmartGroupFilterWrapper>
      <SmartGroupFilterWrapper title={FILTER_SECTIONS.ACCESS_OWNERSHIP}>
        <ProductAccessAndOwnershipFilters
          filterValues={filterValues}
          filterHandlers={filterHandlers}
          dataSectionStore={dataSectionStore}
          divisions={divisions}
        />
      </SmartGroupFilterWrapper>
      <SmartGroupFilterWrapper title={FILTER_SECTIONS.TIMESTAMPS}>
        <ProductTimestampsFilters filterValues={filterValues} filterHandlers={filterHandlers} />
      </SmartGroupFilterWrapper>
    </>
  );
});
