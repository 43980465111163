import React from 'react';

import { AssetVideoPlayer } from 'components/common/asset-video-player';
import AudioPlayer from './custom-audio-player';

import { getIsMm3Assets } from 'utils/asset';

import { IAsset, IMm3Asset } from 'types';

interface IAudioPlayerProps {
  asset?: IAsset | IMm3Asset;
  customTag?: string;
}
export const AssetAudioPlayer: React.FC<IAudioPlayerProps> = ({ asset, customTag }) => {
  const isMm3Assets = getIsMm3Assets();

  if (!isMm3Assets) return <AudioPlayer asset={asset} customTag={customTag} />;
  return <AssetVideoPlayer video={asset} customTag={customTag} />;
};
