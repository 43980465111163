import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';

import ScrollWrapper from 'components/scroll-wrapper';
import { Loading } from 'components/loading';

import { Routes } from 'utils/routes';
import { useRemote } from 'utils/hooks';
import { formatDate } from 'utils/date';
import { getContactName } from 'utils/ui';
import { getOperations } from 'utils/apis/asset';
import { getContacts } from 'utils/apis/contacts';
import { byId } from 'utils/general';

import { IMm3Asset } from 'types';

const AssetOperationsTab: React.FC<{ asset: IMm3Asset }> = ({ asset }) => {
  const assetId = asset?.id;
  const fetcher = useCallback(() => getOperations(assetId), [assetId]);
  const [operations = [], loading] = useRemote(fetcher, []);
  const fallback = '—';

  const contactIds = useMemo(
    () => operations.reduce((acc, op) => (op?.owner_id ? [...acc, op?.owner_id] : acc), []),
    [operations],
  );

  const ownerFetcher = useCallback(
    () => (contactIds.length ? getContacts({ contactIds }) : Promise.resolve([])),
    [contactIds],
  );
  const [owners = [], loadingOwners] = useRemote(ownerFetcher, []);
  const ownersById = byId(owners);

  if (loading || loadingOwners) {
    return <Loading />;
  }

  return (
    <ScrollWrapper className="w-100">
      <table className="w-100">
        <thead key="header" className="asset-details__workflow--table-header">
          <tr>
            <th className="ps-2">Id</th>
            <th>Status</th>
            <th>Operation</th>
            <th>Owner</th>
            <th>Created At</th>
            <th>Starts At</th>
            <th>Ends At</th>
            <th></th>
          </tr>
        </thead>

        <tbody className="w-100">
          {operations.map((operation) => (
            <tr key={operation.id} className="asset-details__workflow--table-line">
              <td className="ps-2">{operation.id || fallback}</td>
              <td>{operation.op_status || fallback}</td>
              <td>{operation.label || fallback}</td>
              <td>
                {operation?.owner_id ? (
                  <Link to={`${Routes.CONTACTS}/${operation.owner_id}`}>
                    {getContactName(ownersById[operation.owner_id])}
                  </Link>
                ) : (
                  <i>unknown</i>
                )}
              </td>
              <td>{formatDate(operation.created_at) || fallback}</td>
              <td>{formatDate(operation.start_time) || fallback}</td>
              <td>{formatDate(operation.end_time) || fallback}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </ScrollWrapper>
  );
};

export { AssetOperationsTab };
