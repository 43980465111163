import React, { useCallback } from 'react';
import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';
import { FormInput, FormHtmlInput, FormMultiSelect } from 'helpers/form';
import { queryContacts } from 'utils/apis/contacts';

import { IScreeningRoomEmailForm } from './types';

interface IScreeningRoomFormEmailProps {
  form: IScreeningRoomEmailForm;
}

export const ScreeningRoomFormEmail: React.FC<IScreeningRoomFormEmailProps> = ({ form }) => {
  const onContactChange = useCallback(
    (recipients) => {
      form.handlers.onChange({ email_recipient_ids: recipients?.map((e) => e?.value) });
    },
    [form.handlers],
  );

  return (
    <FormSectionWrapperV2 padded={false} gridTemplateColumns={'1fr'}>
      <FormMultiSelect
        name="email_recipient_ids"
        label="Recipients"
        fetchValues={queryContacts}
        {...form.formData.email_recipient_ids}
        onSelectedItemsChange={onContactChange}
        onBlur={form.handlers.onBlur}
        omni
      />
      <FormInput name="email_subject" label="Subject" {...form.formData.email_subject} {...form.handlers} />
      <FormHtmlInput name="email_message" label="Message" large {...form.formData.email_message} {...form.handlers} />
    </FormSectionWrapperV2>
  );
};
