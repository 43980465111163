import React, { useCallback, useEffect, useMemo } from 'react';
import { FormCheckbox, useForm } from 'helpers/form';
import { observer } from 'mobx-react-lite';
import { ActionIcon, Button, Divider, Flex, Switch } from '@mantine/core';
import { useNavigate } from 'react-router';

import { Model } from 'helpers/filters/types';
import { SectionHeader } from 'components/section-header';
import { AssetsUploaderStore } from 'store/assets-upload-store';
import { MantineIcon } from 'utils/ui';
import { Routes } from 'utils/routes';

interface IApplyPresetFormValues {
  selectedAssets: boolean;
}

const initialValues: IApplyPresetFormValues = {
  selectedAssets: false,
};

interface IAssetsFormHeader {
  openPresetModal: () => void;
  assetsUploaderStore: AssetsUploaderStore;
}
export const AssetsFormHeader: React.FC<IAssetsFormHeader> = observer(({ openPresetModal, assetsUploaderStore }) => {
  const { files, setFiles, setIsUploadPending } = assetsUploaderStore;
  const navigate = useNavigate();
  const { formData, handlers } = useForm<IApplyPresetFormValues>(initialValues, Model.ASSETS);
  const { onChange } = handlers;
  const numberOfSelectedFiles = useMemo(() => files.filter(({ selected }) => selected).length, [files]);

  useEffect(() => {
    onChange({ selectedAssets: numberOfSelectedFiles === files.length });
  }, [onChange, numberOfSelectedFiles, files]);

  const onSelectedAssetsChange = useCallback(
    ({ selectedAssets }) => {
      onChange({ selectedAssets });
      setFiles(files.map((file) => (file.selected === selectedAssets ? file : { ...file, selected: selectedAssets })));
    },
    [onChange, setFiles, files],
  );

  const onSelectAllChange = useCallback(
    (evt) => {
      const selectAll = evt.target.checked;
      setFiles(files.map((file) => (file.selected === selectAll ? file : { ...file, selected: selectAll })));
    },
    [setFiles, files],
  );

  const onMinimizeClick = useCallback(() => {
    setIsUploadPending?.(true);
    navigate(`${Routes.ASSETS}`);
  }, [navigate, setIsUploadPending]);

  const CustomRightRenderer = useCallback(
    (): JSX.Element => (
      <Flex align="center" gap="xs">
        <Button variant="outline" color="white" className="asset-upload__form-header" onClick={openPresetModal}>
          Select Preset
        </Button>
        <ActionIcon color="gray.5" variant="subtle" onClick={onMinimizeClick}>
          <MantineIcon icon="minimize" />
        </ActionIcon>
      </Flex>
    ),
    [onMinimizeClick, openPresetModal],
  );

  const allSelected = useMemo(() => files.every(({ selected }) => selected), [files]);

  if (files.length === 0) {
    return null;
  }

  return (
    <>
      <SectionHeader title="Asset Info" customRightRenderer={CustomRightRenderer} useBackground />

      <div className="asset-upload__form-select">
        <FormCheckbox
          label={`${numberOfSelectedFiles}/${files.length}`}
          name="selectedAssets"
          {...formData.selectedAssets}
          {...handlers}
          onChange={onSelectedAssetsChange}
          hasBottomMargin={false}
          indeterminate={Boolean(numberOfSelectedFiles && numberOfSelectedFiles !== files.length)}
        />
        <Divider orientation="vertical" mx={10} />
        <Switch checked={allSelected} label="Select All" name="selectAll" onChange={onSelectAllChange} />
      </div>
    </>
  );
});

export default React.memo(AssetsFormHeader);
