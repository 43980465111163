import React, { useEffect, useMemo, useState } from 'react';
import { isEqual } from 'lodash';
import { observer } from 'mobx-react-lite';

import { assetFormCustomContext } from 'components/asset/asset-edit-form/constants';
import { Mm3AssetForm } from './mm3-single-asset-form';
import { SharedAssetForm } from './shared-single-asset-form';

import { Model } from 'helpers/filters/types';
import { useForm } from 'helpers/form';
import { withAssetVersion } from 'utils/asset';

import { IAssetFormProps } from './types';
import { IAssetFields } from 'types/asset';

import './index.scss';

export const AssetOldForm: React.FC<IAssetFormProps> = ({
  index,
  initialValues,
  removeFile,
  onDataChange,
  setIsValidForm,
  shouldShowErrors,
  applyPreset,
  savePreset,
  withParentField,
  assetsUploaderStore,
}) => {
  const customContext = useMemo(
    () => ({
      properties: {
        name: { required: true },
        classification: { required: true },
        status: { required: true },
        access_level: { required: true },
        ...(assetFormCustomContext ? assetFormCustomContext['properties'] : {}),
      },
    }),
    [],
  );

  const [context, setContext] = useState(customContext);

  const form = useForm<IAssetFields>(initialValues, Model.ASSET_DIGITAL_IMAGE, undefined, context);

  const { values } = form;

  useEffect(() => {
    if (!isEqual(initialValues, values)) {
      form.handlers.onSetFields(initialValues);
      onDataChange(initialValues, index);
    }
  }, [initialValues, values, onDataChange, index, form]);

  useEffect(() => {
    setContext((prevContext) => {
      const updatedProperties = { ...prevContext.properties };
      updatedProperties['division_ids'] = { required: form.values.access_level === 'division' };

      return {
        ...prevContext,
        properties: updatedProperties,
      };
    });
  }, [form.values.access_level]);

  return (
    <SharedAssetForm
      initialValues={initialValues}
      removeFile={removeFile}
      form={form}
      shouldShowErrors={shouldShowErrors}
      withParentField={withParentField}
      applyPreset={applyPreset}
      savePreset={savePreset}
      onDataChange={onDataChange}
      index={index}
      setIsValidForm={setIsValidForm}
      assetsUploaderStore={assetsUploaderStore}
    />
  );
};

export const AssetForm = withAssetVersion(
  React.memo(observer(AssetOldForm)),
  Mm3AssetForm,
) as React.FC<IAssetFormProps>;
