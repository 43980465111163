import React from 'react';
import cx from 'classnames';

import { IStyled } from 'types';

import './style.scss';

export interface IEventLayoutThumbnailProps extends IStyled {
  url?: string;
  alt?: string;
}

const EventLayoutThumbnail: React.FC<IEventLayoutThumbnailProps> = ({ url, className, alt }) => {
  return (
    <>
      {url && (
        <img
          src={url}
          alt={alt}
          className={className}
          onLoad={(e) => {
            const img = e.target as HTMLImageElement;
            img.classList.toggle('portrait', img.naturalHeight > img.naturalWidth);
          }}
        />
      )}

      {!url && (
        <div className={cx(['thumbnail__content thumbnail__content--fallback', className])}>No Preview Image</div>
      )}
    </>
  );
};

export default EventLayoutThumbnail;
