import React, { useCallback, useEffect, useState } from 'react';
import { isEmpty, startCase } from 'lodash';
import { zonedTimeToUtc } from 'date-fns-tz';
import { CalGenericLocation } from '@mediafellows/mm3-types';
import omit from 'lodash/omit';

import {
  FormDate,
  FormInput,
  FormNumericInput,
  FormCheckbox,
  FormSelect,
  FormMultiSelect,
  useMm3Form,
} from 'helpers/form';
import { FormSubmitSection } from 'components/form-submit-section';

import { useStore } from 'store';
import { ToastError } from 'components/toast';
import { Model } from 'helpers/filters/types';
import { getTimezones } from 'utils/apis/meeting';
import { useLocationsStore } from 'store/hooks';
import { customValidator } from 'components/scheduler/location/utils';
import { fetchSchedulerHosts } from 'components/scheduler/api';

export const LocationForm: React.FC<{ location: CalGenericLocation; onSuccess: VoidFunction }> = ({
  location,
  onSuccess,
}) => {
  const { updateLocation, createLocation } = useLocationsStore();
  const { toastStore, dialogStore } = useStore();
  const [timezones, setTimezone] = useState<string[]>([]);

  useEffect(() => {
    async function fetchData(): Promise<void> {
      if (isEmpty(timezones)) {
        const timezones = await getTimezones();
        setTimezone(timezones);
      }
    }
    fetchData();
  }, [location?.id, timezones]);

  const handleSubmit = useCallback(
    async (values: CalGenericLocation): Promise<void> => {
      const starts_at = zonedTimeToUtc(values?.starts_at, values?.time_zone);
      const ends_at = zonedTimeToUtc(values?.ends_at, values?.time_zone);

      try {
        const submit = values?.id ? updateLocation : createLocation;
        await submit({ ...values, starts_at: starts_at.toISOString(), ends_at: ends_at.toISOString() });

        toastStore.success(`Location has been ${values?.id ? 'updated' : 'created'} successfully!`);
        dialogStore.close();
        onSuccess?.();
      } catch (error) {
        toastStore.error(<ToastError error={error} />);
      }
    },
    [updateLocation, createLocation, toastStore, dialogStore, onSuccess],
  );

  const { handlers, formData, values, onSubmit, valid } = useMm3Form<CalGenericLocation>(
    omit(location, ['hosts']),
    Model.LOCATIONS,
    handleSubmit,
    customValidator,
  );

  return (
    <>
      <form onSubmit={onSubmit}>
        <FormInput label="Name" name="name" {...handlers} {...formData.name} />

        <FormInput label="Address" name="address" {...handlers} {...formData.address} />

        <FormSelect
          large
          label="Location type"
          name="meta.location_type"
          {...handlers}
          {...formData.meta?.location_type}
          formatLabel={startCase}
          emptyValueIsUndefined
          required
        />

        <FormMultiSelect
          label="Hosts"
          name="host_ids"
          fetchValues={fetchSchedulerHosts}
          {...handlers}
          {...formData.host_ids}
        />

        <FormNumericInput label="Capacity" name="capacity" {...handlers} {...formData.capacity} />

        <FormSelect
          large
          label="Timezone"
          name="time_zone"
          {...handlers}
          {...formData.time_zone}
          options={timezones}
          emptyValueIsUndefined
        />

        <FormDate
          label="Availability Start Time"
          timeZone={location.time_zone}
          withTime
          name="starts_at"
          {...handlers}
          {...formData.starts_at}
        />

        <FormDate
          label="Availability End Time"
          withTime
          name="ends_at"
          minDate={values.starts_at ? new Date(values.starts_at) : undefined}
          timeZone={location.time_zone}
          {...handlers}
          {...formData.ends_at}
        />

        <FormCheckbox
          label="Allow Overlapping Meetings"
          name="allow_overlapping_meetings"
          {...handlers}
          {...formData.allow_overlapping_meetings}
        />
      </form>
      <FormSubmitSection
        submitDisabled={!valid}
        labels={{ confirm: location?.id ? 'Update' : 'Create' }}
        onSubmit={onSubmit}
      />
    </>
  );
};
