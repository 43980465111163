import React, { useCallback } from 'react';
import { AmGenericAsset3ProtectionOptions, AmGenericProtectionOptions } from '@mediafellows/mm3-types';
import WatermarkVideoEditor, { Watermark as WatermarkProps } from '@mediafellows/watermark-editor';
import { useRemote } from 'utils/hooks';

import { IAsset, IMm3Asset } from 'types';
import { useSessionStore } from 'store/session-store';
import { IFormMm3Handlers } from 'helpers/form';
import { AssetVideoPlayer } from 'components/common';

import './style.scss';

type IWatermark = {
  align?: 'left' | 'right' | 'center' | null;
  type?: string | null;
} & Omit<WatermarkProps, 'align' | 'type'>;

type IWatermarkEditorProps = {
  value?: IWatermark[] | null;
  video?: IAsset | IMm3Asset;
  name: string & `${keyof AmGenericAsset3ProtectionOptions}.${string}`;
  hidden?: boolean;
} & IFormMm3Handlers<AmGenericProtectionOptions>;

export const WatermarkEditor: React.FC<IWatermarkEditorProps> = ({ onChange, video, value, name, hidden }) => {
  const { user } = useSessionStore();
  const fullName = `${user?.first_name || ''} ${user?.last_name || ''}`.trim();

  const fetchIpAddress = useCallback(async (): Promise<string> => {
    return fetch('https://api.ipify.org?format=json')
      .then((response) => response.json())
      .then((data) => data.ip);
  }, []);

  const data = useRemote(fetchIpAddress);
  const handleWatermarkChange = useCallback(
    (watermarks) => {
      return onChange({
        [name]: watermarks,
      });
    },
    [onChange, name],
  );

  if (!video?.id || hidden) {
    return <></>;
  }

  return (
    <div className="watermark-editor__wrapper">
      <WatermarkVideoEditor
        minFontSize={2}
        maxFontSize={10}
        userEmail={user?.email}
        fullName={fullName}
        userID={user?.id}
        ipAddress={data?.[0]}
        placeholder="{ INSERT TEXT }"
        marginSize={3}
        maxPadding={5}
        className="watermark-editor"
        watermarks={value as WatermarkProps[]}
        onChange={handleWatermarkChange}
      >
        <AssetVideoPlayer video={video} />
      </WatermarkVideoEditor>
    </div>
  );
};
