import { action, observable, IObservableArray, computed } from 'mobx';
import Uppy, { Uppy as IUppy, UppyFile } from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import AwsS3Multipart from '@uppy/aws-s3-multipart';
import { pick } from 'lodash';
import { MantineColorScheme } from '@mantine/core';

import { formatFileFields } from 'utils/assets-upload-format-file-fields';
import { uploadAsset, uploadMm3Asset } from 'utils/actions/asset';
import { loadAsset } from 'utils/apis/asset';
import { getIsMm3Assets, getOrientation } from 'utils/asset';
import { chipmunk } from 'utils/chipmunk';
import { getConfig } from 'utils/companion';
import { parseFileName } from 'utils/assets-upload';

import { IUploadType } from 'components/upload-preview-image';
import { IAssetFile, IFile, IUploadedFile, IMm3Asset, IAsset } from 'types';

export class AssetsFileUploadStore {
  @observable progress = 0;
  @observable uploadFinished = false;
  @observable asset: IAsset | IMm3Asset | undefined;
  @observable uppy: IUppy | null;
  @observable files: IAssetFile[] = [];
  @observable isUploadPending = false;
  @observable uploadedFiles: IObservableArray<IFile> = observable.array<IFile>();
  @observable isSending = false;
  @observable isSubmitted = false;
  @observable isCancelled = false;
  @observable filesRemote: IUploadedFile[] = [];

  @action.bound
  setProgress(progress: number): void {
    this.progress = progress;
  }

  @action.bound
  setUploadFinished(isFinished: boolean): void {
    this.uploadFinished = isFinished;
  }

  @action.bound
  setIsUploadPending(isUploadPending: boolean): void {
    this.isUploadPending = isUploadPending;
  }

  @action.bound
  setFiles(files: IAssetFile[]): void {
    this.files = files;
  }

  @action.bound
  setAsset(asset?: IAsset | IMm3Asset | null): void {
    if (asset) this.asset = asset;
  }

  @action.bound
  appendUploadedFiles(file: IFile): void {
    this.uploadedFiles = observable([...this.uploadedFiles, file]);
  }

  @action.bound
  setIsSending = (isSending: boolean): void => {
    this.isSending = isSending;
  };

  @action.bound
  setIsSubmitted = (submitted: boolean): void => {
    this.isSubmitted = submitted;
  };

  @action.bound
  removeFile = (file: { id: string }): void => {
    this.files = this.files.filter(({ id }) => id !== file.id);
    this.uploadedFiles = observable(this.uploadedFiles.filter(({ id }) => id !== file.id));
    this.filesRemote = this.filesRemote.filter(({ id }) => id !== file.id);
    this.isCancelled = true;
    this.uppy?.removeFile(file.id);
    if (this.files.length > 0) this.progress = 100;
    else this.progress = 0;
  };

  @action.bound
  submitFile = async (
    file: IFile,
    onSuccess?: (asset: IAsset | IMm3Asset) => void | Promise<void> | undefined,
  ): Promise<void> => {
    try {
      if (this.isSending) return;
      this.setIsSending(true);
      const isMm3Assets = getIsMm3Assets();
      if (file && this.asset?.id) {
        isMm3Assets ? await uploadMm3Asset(this.asset.id, file) : await uploadAsset(this.asset.id, file);
        const result = await loadAsset(this.asset.id);
        this.setAsset(result);
        this.setIsSubmitted(true);
        onSuccess?.(result);
      }
    } finally {
      this.setIsSending(false);
    }
  };

  @computed get isAssetsUploaderStoreInitialized(): boolean {
    return Boolean(this.uppy);
  }

  @action.bound
  initializeUppy({
    target = 'upload-preview-image',
    type,
    extensions,
    setFileUrl,
    onUploadFinished,
    asset,
    maxNumberOfFiles = 1,
    onSuccess,
    theme = 'dark',
  }: {
    target?: string;
    type?: IUploadType;
    extensions?: string[] | null;
    autoProceed?: boolean;
    maxNumberOfFiles?: number;
    asset?: IAsset | IMm3Asset;
    setFileUrl?: (url: string) => void;
    onUploadFinished: (file?: IFile) => void;
    onSuccess?: (asset: IAsset | IMm3Asset) => void | Promise<void> | undefined;
    theme: MantineColorScheme;
  }): () => void {
    this.isUploadPending = false;
    this.asset = asset;

    const { companionUrl, power } = getConfig(type);
    chipmunk.run(async ({ action }) => {
      const result = await action('um.session', 'token', { params: { power } });
      const jwtToken = result?.object?.token;

      this.uppy = new Uppy({
        id: `asset-file-upload-${target}`,
        autoProceed: true,
        restrictions: { allowedFileTypes: extensions, maxNumberOfFiles },
        onBeforeFileAdded: (currentFile: UppyFile) => {
          this.uppy?.cancelAll();
          const filename = parseFileName(currentFile.name);
          return { ...currentFile, meta: { ...currentFile.meta, filename, name: filename } };
        },
      }).use(AwsS3Multipart, {
        limit: 1,
        companionUrl,
        companionHeaders: {
          'uppy-auth-token': jwtToken,
        },
      });

      if (target) {
        this.uppy.use(Dashboard, {
          inline: true,
          target: `#${target}`,
          id: `Dashboard-${target}`,
          width: '100%',
          locale: { strings: { dropHint: 'Drop a file here or browse' } },
          showProgressDetails: true,
          showSelectedFiles: true,
          hideCancelButton: true,
          hideUploadButton: true,
          hidePauseResumeButton: true,
          hideRetryButton: true,
          showRemoveButtonAfterComplete: true,
          closeModalOnClickOutside: false,
          closeAfterFinish: false,
          proudlyDisplayPoweredByUppy: false,
          theme,
          doneButtonHandler: null as unknown as () => void, // remove after upgrade to version 4
        });
      }

      this.uppy.on('file-added', (file: UppyFile) => {
        const parsedFile = formatFileFields(file);
        this.files = [parsedFile];
        const data = file.data;
        const url = URL.createObjectURL(data);
        const image = new Image();
        image.src = url;
        setFileUrl?.(url);

        image.onload = () => {
          this.uppy?.setFileMeta(file.id, { width: image.width, height: image.height });
          URL.revokeObjectURL(url);
        };
      });

      this.uppy.on('upload-success', (file, response) => {
        if (!file?.id) {
          return;
        }
        const newFile: IFile = {
          id: file.id,
          url: response['uploadURL'],
          name: file.name,
          size: file.size,
          meta: {
            ...pick(file.meta, 'width', 'height'),
            orientation: getOrientation(file),
          } as unknown as IFile['meta'],
        };

        this.appendUploadedFiles(newFile);
        this.setUploadFinished(true);
        onUploadFinished(newFile);
        this.isUploadPending && !this.isSubmitted && this.submitFile(newFile, onSuccess);
      });

      this.uppy.on('progress', (progress) => {
        this.progress = Math.max(progress, 1);
      });
      this.uppy.on('upload', () => {
        this.progress = 1;
      });
      this.uppy.on('file-removed', this.removeFile);

      this.uppy.on('thumbnail:generated', (file: UppyFile) => {
        this.files = this.files.map((f) => (f.id === file.id ? { ...f, preview: file.preview } : f));
      });
      this.uppy.on('info-visible', () => {
        const info = this.uppy?.getState().info;
        const parsedMsgInfo = info?.map((notif) => ({ ...notif, message: notif.message.replace('/*', '') })); // parse error msgs
        this.uppy?.setState({ info: parsedMsgInfo });
      });
    });

    return (): void => {
      this.isUploadPending = Boolean(this.files.length);
    };
  }
  @action.bound
  cleanUp(): void {
    this.uppy?.close();
    this.uppy = null;
    this.uploadedFiles.clear();
    this.filesRemote = [];
    this.files = [];
    this.isUploadPending = false;
    this.asset = undefined;
    this.isCancelled = false;
    this.isSubmitted = false;
    this.uploadFinished = false;
    this.isSending = false;
  }

  @computed
  get disableSubmit(): boolean {
    return this.isSending || !this.files.length;
  }
}
