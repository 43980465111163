import React, { useCallback } from 'react';
import { AmGenericBaseAffiliationProtectionOptions } from '@mediafellows/mm3-types';
import startCase from 'lodash/startCase';
import cx from 'classnames';

import { InfoBoxV2 } from 'components/info-box-v2';
import ScrollWrapper from 'components/scroll-wrapper';
import { FormCountries, FormSelect } from 'helpers/form';
import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';

import { WatermarkEditor } from 'components/watermark-editor';
import { FormEnabled } from 'helpers/form/custom-fields/form-enabled/form-enabled';
import { IAsset } from 'types';

import './style.scss';

export const AffiliationEditProtectionTab: React.FC<{
  protectionsForm: IUseMm3FormReturn<AmGenericBaseAffiliationProtectionOptions>;
  sampleAsset?: IAsset;
}> = ({ protectionsForm, sampleAsset }) => {
  const { handlers, values, formData } = protectionsForm;
  const { drm, geo_ip } = values?.meta || {};
  const handleDrmChange = useCallback(
    (values) => {
      return handlers.onChange({
        ...values,
        ...(!values['meta.drm.enabled'] ? { ['meta.drm.HDCP_enabled']: false } : {}),
      });
    },
    [handlers],
  );

  const handleGeoIpChange = useCallback(
    (values) => {
      handlers.onChange({
        ...values,
        ...(values['meta.geo_ip.enabled']
          ? {}
          : {
              ['meta.geo_ip.list_type']: null,
              ['meta.geo_ip.country_ids']: [],
            }),
      });
    },
    [handlers],
  );

  return (
    <ScrollWrapper>
      <InfoBoxV2
        title="Default Protections"
        wrapperClassName="affiliation-protections__wrapper"
        className="affiliation-protections__section"
      >
        <div className="text-no-transform me-2">
          <FormEnabled
            name="meta.drm.enabled"
            key="meta.drm.enabled"
            label="DRM"
            {...formData.meta?.drm?.enabled}
            {...handlers}
            onChange={handleDrmChange}
          />
          {drm?.enabled ? (
            <FormEnabled
              name="meta.drm.HDCP_enabled"
              key="HDCP_enabled"
              label="HDCP"
              className="ms-3"
              {...formData.meta?.drm?.HDCP_enabled}
              {...handlers}
            />
          ) : (
            <></>
          )}

          <FormEnabled
            name="meta.casting_prevention.enabled"
            key="casting_prevention"
            label="Casting prevention"
            {...formData.meta?.casting_prevention?.enabled}
            {...handlers}
          />
          <FormEnabled
            name="meta.forensic_watermark.enabled"
            key="forensic_watermark"
            label="Forensic watermarks"
            {...formData.meta?.forensic_watermark?.enabled}
            {...handlers}
          />
          <FormEnabled
            name="meta.visible_watermark.enabled"
            key="visible_watermark"
            label="Visible Watermark"
            {...formData.meta?.visible_watermark?.enabled}
            {...handlers}
          />
          <FormEnabled
            name="meta.geo_ip.enabled"
            key="geo_ip.enabled"
            label="GeoIP"
            {...formData.meta?.geo_ip?.enabled}
            {...handlers}
            onChange={handleGeoIpChange}
          />
          <FormSelect
            name="meta.geo_ip.list_type"
            key="geo_ip.list_type"
            label="GeoIP List Type"
            className={cx('ms-3', { ['d-none']: !geo_ip?.enabled })}
            {...formData.meta?.geo_ip?.list_type}
            {...handlers}
            formatLabel={startCase}
          />
          <FormCountries
            name="meta.geo_ip.country_ids"
            className={cx('affiliation-edit-protections__country-ids ms-3', { ['d-none']: !geo_ip?.enabled })}
            key="geo_ip.country_ids"
            label="GeoIP Countries"
            {...formData.meta?.geo_ip?.country_ids}
            {...handlers}
          />
        </div>
        <WatermarkEditor
          name="meta.visible_watermark.watermarks"
          {...handlers}
          value={values.meta?.visible_watermark?.watermarks}
          video={sampleAsset}
          hidden={!values?.meta?.visible_watermark?.enabled}
        />
      </InfoBoxV2>
    </ScrollWrapper>
  );
};
