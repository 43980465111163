export const initialValues = {
  project_name: '',
  type: 'LocalizationOrder::Series::LibraryTv',
  vendor: null,
  vendor_order_ref: null,
  vendor_project_manager_id: null,
  organization_project_manager_id: null,
  organization_order_ref: null,
  owner_organization_id: null,
  organization_name: 'Amazon',
  order_template: 'LibraryTv',

  recipient_list: [],
  product_id: null,
  asset_ids: [],
  product_type: 'Series',

  due_date: null,
  launch_territory: null,
  delivery_date_from_vendor: null,
  date_to_posthouse: null,
  catalog_count: null,
  release_cycle: null,
  license_type: null,
  licensee: null,
  content_partner_name: null,
  content_partner_alias: null,
  billing_entity: null,

  resolution: 'HD',
  trailer: 'NO',
  subtitles: [],
  dubbing: [],
  metadata: [],
  artwork_season: [],
  artwork_episode: [],
  localized_artwork: '',
  partner_deliverables: null,
  content_partner_delivery_deadline: null,
  notes: null,

  product_ids: [],
  recipients: [],

  products: [],
};
