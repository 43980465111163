import React, { useCallback } from 'react';
import { IMm3Asset, ItemId } from 'types';

import { useStore } from 'store';

import { IAssetActionName } from 'utils/actions/asset/types';
import { IActionConfig, IUseActionsOptionParam } from 'utils/actions';
import { getValidIds, pluralEntityInflect } from 'utils/general';
import { IModalSize } from 'components/dialogs/types';

import { shouldDisplayAssignToAsset as shouldDisplay } from './actions-acl';
import { ToastError } from 'components/toast';
import { AssignSubAssetForm } from 'components/asset/assign-sub-asset';
import { assignSubAssets } from 'utils/apis/asset';

export const useAssignToAssetAction = (
  items: IMm3Asset[],
  options?: IUseActionsOptionParam<IMm3Asset>,
): IActionConfig<IMm3Asset, IAssetActionName> => {
  const { toastStore, dialogStore } = useStore();

  const assetIds = getValidIds(items);
  const { entity } = pluralEntityInflect('Sub-Asset', items.length);

  const handleSubmit = useCallback(
    async (values: { asset_ids: ItemId[]; parent_id: ItemId }) => {
      try {
        await assignSubAssets(values.asset_ids.map((id) => ({ asset_id: values.parent_id, related_asset_id: id })));
        toastStore.success(`${entity} will be assigned`);
        options?.onSuccess?.(IAssetActionName.ASSIGN_TO_ASSET);
        return dialogStore.close();
      } catch (error) {
        toastStore.error(<ToastError error={error} />);
      }
    },
    [dialogStore, toastStore, options, entity],
  );

  const body = useCallback(
    () => <AssignSubAssetForm assetIds={assetIds} handleSubmit={handleSubmit} parentId={options?.parentId} />,
    [assetIds, handleSubmit, options?.parentId],
  );

  const openDialog = (): void => {
    dialogStore.openModal({
      title: 'Assign to Asset',
      size: IModalSize.S,
      body,
    });
  };

  return {
    name: IAssetActionName.ASSIGN_TO_ASSET,
    shouldDisplay,
    icon: 'flow-end',
    title: 'Assign to Asset',
    handler: openDialog,
  };
};
