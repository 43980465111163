import { get, omit } from 'lodash';

import { Model } from 'helpers/filters/types';
import { chipmunk } from 'utils/chipmunk';
import { assetDetailsSchema, assetListSchema } from 'utils/schemas';
import { IResult } from 'utils/chipmunk';
import { logger } from 'utils/logger';
import { preparePayload } from 'utils/payload';
import { removeObjectsFromPayload } from 'utils/payload';
import { parseToSearchParams } from 'utils/general';
import {
  IAsset,
  IStorage,
  ItemId,
  IQueryParams,
  ISearchFilter,
  IAssetAggregation,
  IMm3Asset,
  IAssetMainClassification,
  IAssetOperation,
} from 'types';
import { IAccessChangeImmediateForm } from 'utils/actions/asset';
import { parseTags } from 'utils/tags';
import { AmGenericAsset3, AmGenericAsset3DigitalSubtitle, AmGenericAsset3Relation } from '@mediafellows/mm3-types';
import { IAssetArtifact } from 'utils/actions/types';
import { IMm3AssetType } from 'types';
import { getAssetModel, getIsMm3Assets, selectAssetHelper } from 'utils/asset';

export interface IAssetStatistics {
  assets_archived: number;
  assets_audio: number;
  assets_available: number;
  assets_count: number;
  assets_created: number;
  assets_document: number;
  assets_files_ingesting: number;
  assets_files_invalid: number;
  assets_files_pending: number;
  assets_files_ready: number;
  assets_files_reingesting: number;
  assets_image: number;
  assets_increase_percents: number;
  assets_other: number;
  assets_review: number;
  assets_video: number;
  assets_with_no_products_count: number | null;
}

export async function loadAsset<T extends IAsset | IMm3Asset = IAsset>(
  assetIds: ItemId | ItemId[],
  schema = assetDetailsSchema,
): Promise<T> {
  const [asset] = await getAssets<T>(assetIds, schema);
  return asset;
}

export async function getAssets<T extends IAsset | AmGenericAsset3 = IAsset>(
  assetIds: ItemId | ItemId[],
  schema = assetListSchema,
): Promise<T[]> {
  return chipmunk.run(async ({ action }) => {
    const { objects } = await action<T>(getAssetModel(), 'get', {
      params: { asset_ids: assetIds },
      schema,
    });

    return objects;
  });
}

export async function searchAssets<T extends IAsset | AmGenericAsset3 = IAsset>(
  query: IQueryParams,
  additionalFilters: ISearchFilter[],
  schema,
): Promise<IResult<T>> {
  return chipmunk.run(async ({ action }) => {
    const result = await action<T>(getAssetModel(), 'search', {
      body: parseToSearchParams(query, additionalFilters),
      schema,
    });

    return result;
  });
}

export async function getAssetsByProductId<T extends IAsset | AmGenericAsset3 = IAsset>(
  product_id?: ItemId | null,
  schema = assetListSchema,
  actionName: 'action' | 'unfurl' = 'action',
): Promise<T[]> {
  if (!product_id) {
    return [];
  }
  const { objects } = await chipmunk[actionName]<T>(getAssetModel(), 'search', {
    body: { product_id },
    schema,
  });

  return objects;
}

export async function queryAssetsByProductId<T extends true | void = void, P extends IAsset | AmGenericAsset3 = IAsset>(
  product_id?: ItemId | null,
  params: IQueryParams = {},
  { schema = assetListSchema, stats, rawResult }: { rawResult?: T; stats?: string; schema?: string } = {},
): Promise<T extends true ? IResult<P> : P[]> {
  if (!product_id) {
    const objects: P[] = [];
    return (rawResult ? { objects } : []) as T extends true ? IResult<P> : P[];
  }

  return chipmunk.run(async ({ action }) => {
    const result = await action<P>(getAssetModel(), 'search', {
      body: { product_id, stats },
      params,
      schema,
    });

    return rawResult ? result : result.objects;
  });
}

export const getStorage = (): Promise<IStorage[]> => {
  return chipmunk.run(async ({ action }) => {
    try {
      const { objects } = await action<IStorage>(Model.STORAGE, 'query');
      return objects;
    } catch (error) {
      logger.error(error);
      return [];
    }
  });
};

export const getMetadata = async (asset_id?: ItemId | null): Promise<Record<string, unknown> | undefined> => {
  if (!asset_id) {
    return;
  }
  return chipmunk.run(async ({ action }) => {
    const { object } = await action(Model.ASSET_METADATA, 'member.get', {
      params: { asset_id },
    });

    return object;
  });
};

export const getMediaInfo = async (
  asset_ids?: ItemId | ItemId[] | null,
): Promise<Record<string, unknown> | undefined> => {
  if (!asset_ids || (Array.isArray(asset_ids) && !asset_ids.length)) {
    return;
  }
  return chipmunk.run(async ({ action }) => {
    const { object } = await action(Model.ASSET_MEDIA_INFO, 'get', {
      params: { asset_ids },
    });

    return object;
  });
};

export const saveOldAsset = async (
  assetId: string,
  { preview_image, ...data }: IAsset,
  schema?: string,
): Promise<IResult[]> => {
  return await chipmunk.run(async ({ action }) => {
    const tags = parseTags(data?.tags || []);

    return Promise.all([
      action(Model.ASSETS, 'member.update', {
        ROR: true,
        params: { asset_id: assetId },
        body: preparePayload({ ...data, tags }, {}, { nullifyEmptyStrings: true }),
        schema,
      }),
      action(Model.ASSET_LAYER, 'member.update', {
        ROR: true,
        params: { asset_id: assetId, layer_id: get(data, 'default_layer.id') },
        body: preparePayload(get(data, 'default_layer', {}), {}, { nullifyEmptyStrings: true }),
      }),
    ]);
  });
};

export const saveMm3Asset = async (
  assetId: ItemId,
  { preview_image, ...data }: Partial<IMm3Asset & AmGenericAsset3DigitalSubtitle>,
  schema = assetListSchema,
): Promise<IResult<IMm3Asset & AmGenericAsset3DigitalSubtitle>> => {
  return await chipmunk.run(async ({ action }) => {
    return action(Model.MM3_ASSETS, 'update', {
      params: { asset_id: assetId },
      body: preparePayload(data, {}, { nullifyEmptyStrings: true }),
      schema,
    });
  });
};

export const saveAsset = selectAssetHelper(saveOldAsset, saveMm3Asset);

export const changeAssetAccessImmediate = async (
  data: IAccessChangeImmediateForm,
  schema = assetDetailsSchema,
): Promise<IResult<IMm3Asset | IAsset>> => {
  const asset_ids = data.item_ids;
  const isMm3Assets = getIsMm3Assets();

  const assets = asset_ids.map((asset_id) => ({
    id: asset_id,
    access_level: data.access_level,
    status: data.status,
    skip_access_delegation: data.skip_access_delegation,
    ...(isMm3Assets ? {} : { protection_levels: data.protection_levels }),
    permissions: data.permissions,
  }));

  const model = getAssetModel();

  return chipmunk.run(({ action }) => {
    return action(getAssetModel(), 'update', {
      params: { asset_ids },
      schema,
      body: assets,
      ...(model === Model.ASSETS && { multi: true }),
    });
  });
};

export const getAssetStatistics = (): Promise<IAssetStatistics> => {
  return chipmunk.run(async (chipmunk) => {
    const { objects } = await chipmunk.action(Model.ASSET_STATISTICS_DATA, 'asset.get');

    return objects;
  });
};

export const saveMm3Assets = (assets: Partial<IMm3Asset> | Partial<IMm3Asset>[]): Promise<void> => {
  const assetsList = (Array.isArray(assets) ? assets : [assets]).filter((e) => e.id);

  return chipmunk.run(({ action }) => {
    return action(Model.MM3_ASSETS, 'update', {
      params: { asset_ids: assetsList.map((e) => e.id) },
      body: assetsList,
    });
  });
};

export const saveOldAssets = async (assets: IAsset[]): Promise<void> => {
  return await chipmunk.run(async ({ action }) => {
    const asset_ids = assets?.map((asset) => asset?.id);
    const layer_ids = assets?.map((asset) => asset?.default_layer_id);

    const payload = assets.map((asset) =>
      removeObjectsFromPayload(preparePayload(asset, {}, { nullifyEmptyStrings: true })),
    );

    await Promise.all([
      action(Model.ASSETS, 'collection.update', {
        ROR: true,
        multi: true,
        params: { asset_ids },
        body: payload,
      }),
      action(Model.ASSET_LAYER, 'collection.update', {
        ROR: true,
        multi: true,
        params: { asset_ids, layer_ids },
        body: assets?.map((asset) => preparePayload(asset?.default_layer || {}, {}, { nullifyEmptyStrings: true })),
      }),
    ]);
    return;
  });
};

export const saveAssets = selectAssetHelper(saveOldAssets, saveMm3Assets);

export const assignSubAssets = (
  body: { asset_id?: ItemId; related_asset_id?: ItemId }[],
): Promise<IResult<AmGenericAsset3Relation>> => {
  return chipmunk.run(({ action }) => {
    return action(Model.MM3_ASSETS_RELATIONS, 'create', {
      body,
    });
  });
};

export const unassignSubAssets = (
  body: { asset_id?: ItemId; related_asset_id?: ItemId }[],
): Promise<IResult<AmGenericAsset3Relation>> => {
  return chipmunk.run(({ action }) => action(Model.MM3_ASSETS_RELATIONS, 'delete', { body }));
};

export const queryAssets = <T extends IMm3Asset | IAsset = IAsset>(
  params: IQueryParams,
  additionalFilters: ISearchFilter[] = [],
  schema = assetListSchema,
  parseParams = parseToSearchParams,
): Promise<T[]> => {
  return chipmunk.run(async ({ action }) => {
    const { objects } = await action(getAssetModel(), 'search', {
      body: parseParams(params, additionalFilters),
      params: { per: params?.per || 25 },
      schema,
    });

    return objects;
  });
};

export const queryMainAssets = (
  params: IQueryParams,
  additionalFilters: ISearchFilter[] = [],
  schema = assetListSchema,
  parseParams = parseToSearchParams,
): Promise<IAsset[]> => {
  const isMm3Assets = getIsMm3Assets();
  return queryAssets(
    params,
    [...additionalFilters, ...(isMm3Assets ? [['root', 'eq', true] as ISearchFilter] : [])],
    schema,
    parseParams,
  );
};

export const queryVideoAssets = (
  params: IQueryParams,
  additionalFilters: ISearchFilter[] = [],
  schema = assetListSchema,
  parseParams = parseToSearchParams,
): Promise<IAsset[]> =>
  queryAssets(params, [...additionalFilters, ['type', 'eq', IMm3AssetType.VIDEO]], schema, parseParams);

export const fetchGroupAssets = async <
  T extends IAsset | AmGenericAsset3 = AmGenericAsset3,
  P extends boolean | undefined | void = void,
>(
  groupId?: ItemId | ItemId[],
  params?: IQueryParams,
  opts: { stats?: string; rawResult?: P } = {},
  filters = [] as ISearchFilter[],
): Promise<P extends true ? IResult<T> : T[]> => {
  const groupIds = Array.isArray(groupId) ? groupId : [groupId];

  return chipmunk.run(async ({ action }) => {
    const result = await action<T>(getAssetModel(), 'search', {
      params,
      body: {
        stats: opts.stats,
        search: {
          filters: [['group_ids', 'in', groupIds], ...(filters || [])],
        },
      },
    });

    return opts.rawResult ? result : result.objects;
  });
};

// TODO: verify the mm3 version
export const exportAssets = (params: IQueryParams, filters: ISearchFilter[] = []): Promise<IAsset[]> => {
  const omitParams = omit(params, ['per', 'page']);
  const body = parseToSearchParams(omitParams, filters);
  return chipmunk.run(async ({ action }) => {
    return action(getAssetModel(), 'simple_export', {
      body,
    });
  });
};

export function queryAllAssets<T extends IAsset | IMm3Asset>(
  params: IQueryParams,
  additionalFilters: ISearchFilter[] = [],
  schema = assetListSchema,
  parseParams = parseToSearchParams,
): Promise<T[]> {
  return chipmunk.run(async ({ unfurl }) => {
    const { objects } = await unfurl<T>(getAssetModel(), 'search', {
      body: parseParams(params, additionalFilters),
      params: { per: params?.per || 100 },
      schema,
    });

    return objects;
  });
}

export function queryAllDescendantsAssets<T extends IAsset | IMm3Asset>(
  { asset_id, ...params }: IQueryParams & { asset_id: ItemId },
  additionalFilters: ISearchFilter[] = [],
  schema = assetListSchema,
  actionName = 'descendants',
  parseParams = parseToSearchParams,
): Promise<T[]> {
  return chipmunk.run(async ({ unfurl }) => {
    const { objects } = await unfurl<T>(getAssetModel(), actionName, {
      body: parseParams(params, additionalFilters),
      params: { per: params?.per || 100, asset_id },
      schema,
    });

    return objects;
  });
}

export function queryAllMainAssets<T extends IAsset | IMm3Asset>(
  params: IQueryParams,
  additionalFilters: ISearchFilter[] = [],
  schema = assetListSchema,
  parseParams = parseToSearchParams,
): Promise<T[]> {
  const isMm3Assets = getIsMm3Assets();
  return queryAllAssets(
    params,
    [...additionalFilters, ...(isMm3Assets ? [['root', 'eq', true] as ISearchFilter] : [])],
    schema,
    parseParams,
  );
}

export const getMm3AssetArtifacts = async (asset_id): Promise<IAssetArtifact[]> => {
  return chipmunk.run(async ({ action }) => {
    try {
      const { objects } = await action(Model.MM3_ARTIFACT, 'get', {
        params: { assets_ids: asset_id },
      });

      return objects;
    } catch (error) {
      logger.error('Error fetching MM3 Asset Artifacts:', error);
      throw error;
    }
  });
};

export const getAssetArtifacts = async (asset_id: ItemId | ItemId[]): Promise<IAssetArtifact[]> => {
  const isMm3Assets = getIsMm3Assets();
  if (isMm3Assets) {
    return getMm3AssetArtifacts(asset_id);
  }
  return chipmunk.run(async ({ action }) => {
    const { objects } = await action(Model.ARTIFACT, 'query', {
      params: { asset_ids: asset_id },
    });
    return objects;
  });
};

export const getAssetAggregations = async (
  assetIds?: ItemId[] | null,
  stats?: string,
  filters?: ISearchFilter[],
): Promise<IAssetAggregation> => {
  return chipmunk.run(async ({ action }) => {
    if (!assetIds?.length) {
      return {};
    }
    const { aggregations } = await action(getAssetModel(), 'search', {
      body: parseToSearchParams(
        { ids: assetIds.filter((a): a is ItemId => Boolean(a)), per: 1, page: 1, stats },
        filters,
      ),
    });
    return aggregations;
  });
};

export function getAssetsAggregations<T = IAssetMainClassification>(
  query: IQueryParams,
  filters?: ISearchFilter[],
): Promise<IAssetAggregation<T>> {
  return chipmunk.run(async ({ action }) => {
    const { aggregations } = await action(getAssetModel(), 'search', {
      body: parseToSearchParams({ ...query, per: 1, page: 1 }, filters),
    });
    return aggregations;
  });
}

export async function getOperations(asset_id?: ItemId | null): Promise<IAssetOperation[]> {
  if (!asset_id) {
    return [];
  }

  return chipmunk.run(async ({ action }) => {
    const { objects } = await action<IAssetOperation>(Model.MM3_ASSETS, 'operations', {
      params: { asset_ids: [asset_id] },
    });

    return objects;
  });
}

export const querySubAssets = async <T extends {} = IMm3Asset>(
  { asset_id, ...params }: { asset_id?: ItemId | null } & IQueryParams,
  additionalFilters?: ISearchFilter[],
  schema?: string,
): Promise<T[]> => {
  if (!asset_id) return [];

  return chipmunk.run(async ({ action }) => {
    const result = await action<T>(Model.MM3_ASSETS, 'descendants', {
      params: { ...params, asset_id },
      body: parseToSearchParams(params, additionalFilters),
      schema,
    });

    return result.objects;
  });
};

export const querySubAssetsMultipleAssets = async <T = IMm3Asset>(
  { asset_id, ...params }: { asset_id?: ItemId[] | null } & IQueryParams,
  additionalFilters?: ISearchFilter[],
): Promise<Record<ItemId, T[]>> => {
  if (!asset_id) return {};

  return chipmunk.run(async ({ action }) => {
    const result = await action(Model.MM3_ASSETS, 'descendants_multiple', {
      params: { ...params, asset_id },
      body: parseToSearchParams(params, additionalFilters),
    });

    return result.object;
  });
};

export const queryParentsMultipleAssets = async <T = IMm3Asset>(
  { asset_id, ...params }: { asset_id?: ItemId[] | null } & IQueryParams,
  additionalFilters?: ISearchFilter[],
): Promise<Record<ItemId, T[]>> => {
  if (!asset_id) return {};

  return chipmunk.run(async ({ action }) => {
    const result = await action(Model.MM3_ASSETS, 'parents_multiple', {
      params: { ...params, asset_id },
      body: parseToSearchParams(params, additionalFilters),
    });

    return result.object;
  });
};

export const queryParents = async <T = IMm3Asset>(
  { asset_id, ...params }: { asset_id?: ItemId | null } & IQueryParams,
  additionalFilters?: ISearchFilter[],
  schema?: string,
): Promise<T[]> => {
  if (!asset_id) return [];

  return chipmunk.run(async ({ action }) => {
    const result = await action(Model.MM3_ASSETS, 'parents', {
      params: { ...params, asset_id },
      body: parseToSearchParams(params, additionalFilters),
      schema,
    });

    return result.objects;
  });
};

export const getDescendantsAssetAggregations = async (
  assetIds?: ItemId | null,
  stats?: string,
  filters?: ISearchFilter[],
): Promise<IAssetAggregation> => {
  return chipmunk.run(async ({ action }) => {
    if (!assetIds) {
      return {};
    }
    const { aggregations } = await action(getAssetModel(), 'descendants', {
      params: { asset_id: assetIds },
      body: parseToSearchParams({ per: 1, page: 1, stats }, filters),
    });
    return aggregations;
  });
};
