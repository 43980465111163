export const localizationOrderSchema = `
  id,
  project_name,
  type,
  order_template, 
  owner_organization_id,
  created_at,
  due_date,
  sent_at,
  organization_id,
  status,
  organization,
  delivery_items,
  updated_at,
  recipient_list, 
  owner, 
  product_id,
  product_type, 
  organization_name,
  vendor_project_manager_id,
  vendor_order_ref,
  organization_project_manager_id,
  organization_order_ref,
  organization { id, name },
  owner_name,
  owner {
    id,
    first_name,
    last_name,
    email
  },
  launch_territory,
  delivery_date_from_vendor,
  catalog_count,
  release_cycle,
  license_type,
  licensee,
  content_partner_name,
  content_partner_alias,
  billing_entity,
  subtitles,
  dubbing,
  metadata,
  artwork_season,
  artwork_episode,  
  localized_artwork,
  content_partner_deliverables,
  content_partner_delivery_deadline,
  notes,
  resolution, 
  trailer,
`;
