import React, { useCallback } from 'react';

import { useFutureDescendantsFlag } from 'components/access-privilege';
import { FormProducts, FormCheckbox, IFormMultiSelectOption } from 'helpers/form';
import { Text } from 'components/text';
import { querySelections } from 'utils/apis/groups';
import { fetchProductsWithAncestry } from 'utils/apis/product';
import { parseProducts } from 'components/recommendation/recommendation-shared/utils';

import { IGroup, IIdentifiable, IProduct, IQueryParams, ISearchFilter } from 'types';
import { IGuildFormComponent } from './types';

import './styles.scss';

export const keysProducts = ['product_ids', 'include_descendants', 'delegates_access', 'permit_download'];

export const queryProductSelection = async (
  params: IQueryParams,
  filters: ISearchFilter[] = [],
): Promise<((IProduct | IGroup) & IIdentifiable)[]> => {
  if (params?.ids?.length === 0) {
    return [];
  }
  return querySelections(params, filters, 'products');
};

export const GuildFormProducts: React.FC<IGuildFormComponent> = ({ form }) => {
  useFutureDescendantsFlag(form);
  const { values, formData, handlers } = form;
  const fetchValues = useCallback(
    async ({ q, ids }: IQueryParams, filters: ISearchFilter[]): Promise<(IProduct | IGroup | string)[]> => {
      const productIds = ids?.filter(
        (id) => !values.product_selections.map((selection) => selection.id).includes(id as number),
      );
      const productPromise = fetchProductsWithAncestry({ q, ids: productIds }, filters);
      const selectionPromise = queryProductSelection({ q, ids }, filters);

      const [products, selections] = await Promise.all([productPromise, selectionPromise]);
      return [...products, ...selections];
    },
    [values.product_selections],
  );

  const onProductChange = useCallback(
    (options: IFormMultiSelectOption[]) => {
      handlers.onChange(parseProducts(options));
    },
    [handlers],
  );

  return (
    <div className="d-flex flex-column h-100">
      <div className="guild-form-products">
        <FormProducts
          fetchValues={fetchValues}
          name="productList"
          label="Products"
          {...formData.productList}
          {...handlers}
          fitInParent
          onSelectedItemsChange={onProductChange}
        />
      </div>
      <Text>
        All products will be added with their full ancestry (necessary for the correct display of privileged access on
        the client-facing site).
      </Text>
      <FormCheckbox
        name="include_descendants"
        label="Include descendant product(s)"
        {...formData.include_descendants}
        {...handlers}
        className="mb-0"
      />

      <FormCheckbox
        label="Include the Products’ unpublished Assets"
        name="delegates_access"
        className="mb-0"
        {...formData.delegates_access}
        {...handlers}
      />

      <FormCheckbox
        label="Permit asset download"
        name="permit_download"
        className="mb-0"
        {...formData.permit_download}
        {...handlers}
      />
    </div>
  );
};
