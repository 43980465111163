import React from 'react';
import { omit } from 'lodash';

import { FormSubmitSection } from 'components/form-submit-section';
import { SubtitleFormCommonParts } from 'components/asset/subtitle-form';

import { useForm } from 'helpers/form';
import { Model } from 'helpers/filters/types';
import { IAudioTrack } from 'types';

type IUploadAudioForm = Omit<IAudioTrack, 'language'>;

export interface IEditAudioProps {
  onSubmit: (audio: IUploadAudioForm) => void;
  audio?: IAudioTrack;
}

const EditAudioDialogBody: React.FC<IEditAudioProps> = ({ audio, onSubmit }) => {
  const initialValues = omit(audio, ['language']);

  const {
    formData,
    handlers,
    valid,
    onSubmit: handleSubmit,
  } = useForm<IUploadAudioForm>(initialValues, Model.AUDIO_TRACKS, onSubmit);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <SubtitleFormCommonParts formData={formData} handlers={handlers} />
      </form>
      <FormSubmitSection labels={{ confirm: 'Save' }} submitDisabled={!valid} onSubmit={handleSubmit} />
    </>
  );
};

export default EditAudioDialogBody;
