import React, { useState, useEffect } from 'react';
import { Stepper, Loader, Group } from '@mantine/core';
import cx from 'classnames';

import { MantineIcon } from 'utils/ui';
import { IWizardProps, IWizardStepElement, detectDefaultSelectedStep, filterExistingChildren } from './utils';

import './style.scss';

const Wizard: React.FC<IWizardProps> = (props) => {
  const {
    children,
    footer,
    isLoading,
    loadingMessage,
    className,
    onChange,
    fitInParent = false,
    selectedStepId,
  } = props;

  const existingChildren = filterExistingChildren(children);
  const [active, setActive] = useState<number>(() => detectDefaultSelectedStep(props));

  useEffect(() => {
    setActive(selectedStepId);
  }, [selectedStepId]);

  const updateStepId = (newStepId: number): void => {
    onChange && onChange(newStepId);
  };

  const renderSteps = (step: IWizardStepElement): JSX.Element => {
    const { id, title, description, icon, disabled, loading, panel } = step.props;

    return (
      <Stepper.Step
        key={id}
        label={title}
        description={description}
        icon={<MantineIcon icon={icon} />}
        allowStepClick={!!onChange}
        allowStepSelect={!disabled}
        loading={loading}
      >
        <div className="wizard__step-panel">{panel}</div>
      </Stepper.Step>
    );
  };

  const steps = React.Children.map(existingChildren, renderSteps);

  return (
    <div className={cx('wizzard', className, { 'wizzard--fit': fitInParent })}>
      <Stepper key="stepper" active={active} onStepClick={updateStepId} wrap={false}>
        {steps}
      </Stepper>

      {footer ? (
        <Group key="footer" justify="center" mt="xl" className="wizzard-footer__group">
          {footer}
        </Group>
      ) : null}

      {isLoading && (
        <div key="loader" className="wizard__loader">
          <Loader />
          <div className="py-4">{loadingMessage}</div>
        </div>
      )}
    </div>
  );
};

export default Wizard;
