import { getIsMm3Assets } from 'utils/asset';

const isMm3Assets = getIsMm3Assets();

export const accessPrivilegeListSchema = `
  id,
  access_level,
  am_statistics_data,
  pm_statistics_data,
  created_at,
  updated_at,
  expires_at,
  deleted_at,
  name,
  preview_image {
    url
  },
  users_count,
  owner_id,
  owner,
  owner_organization_id,
  ${
    isMm3Assets
      ? ''
      : `settings {
    protection_levels
  },`
  }
`;
export const accessPrivilegeDetailsSchema = `${accessPrivilegeListSchema}, owner_organization`;
