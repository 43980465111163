import React from 'react';
import { UppyFile } from '@uppy/core';

import { Model } from 'helpers/filters/types';
import { IProductAsset, IPreviewImage, IAsset, IMm3Asset, IQueryParams, IOrientation } from 'types';
import { IMm3AssetType } from 'types';
import { chipmunk, IResult } from './chipmunk';

import { map, flatten, filter, startsWith, startCase } from 'lodash';
import { STORAGE_KEYS, getStorageItem } from './storage';

export enum AssetFilters {
  ALL = 'All',
  IMAGE = 'image',
  VIDEO = 'video',
  AUDIO = 'audio',
  DOCUMENT = 'document',
  IMF = 'imf',
}

export function filterPreviewAssets(assets: IProductAsset[], assetFilter: AssetFilters): IProductAsset[] {
  return (assets || []).filter(
    ({ marketing_use, asset = {} }) =>
      marketing_use &&
      readyStatuses.has(asset?.file_status || '') &&
      (assetFilter === AssetFilters.ALL || asset.classification?.includes(assetFilter)),
  );
}

export function getPreviewImageUrl(preview_image?: IPreviewImage | null): string {
  if (!preview_image?.url && !preview_image?.source_url) {
    return '';
  }
  const { status, url = '', source_url = '' } = preview_image;

  if (status !== undefined) {
    return readyStatuses.has(status || '') ? url : source_url;
  }
  return url || source_url;
}

export const getAvailableTabs = (assets?: IProductAsset[]): { filter: string; title: string }[] => {
  const tabs = [
    { filter: 'ALL', title: 'All' },
    { filter: 'IMF', title: 'Imf' },
    { filter: 'IMAGE', title: 'Images' },
    { filter: 'VIDEO', title: 'Videos' },
    { filter: 'AUDIO', title: 'Audio' },
    { filter: 'DOCUMENT', title: 'Documents' },
  ];
  return tabs.filter(
    ({ filter }) => filter === 'ALL' || filterPreviewAssets(assets || [], AssetFilters[filter]).length,
  );
};

export const readyStatuses = new Set(['ready', 'available']);
export function getIngestStatus(
  asset?: Pick<IAsset | IMm3Asset, 'status' | 'file_status' | 'preview_image' | 'file_size'> &
    Partial<Pick<IMm3Asset, 'type'>>,
): string {
  const { file_status = '', preview_image, file_size, type } = asset || {};
  const { status: previewImageStatus = '' } = preview_image || {};
  if (type === IMm3AssetType.IMF || (readyStatuses.has(file_status) && readyStatuses.has(previewImageStatus))) {
    return 'ready';
  }
  if (!readyStatuses.has(file_status) && file_size === null && !previewImageStatus) {
    return 'shellAsset';
  }
  if (!readyStatuses.has(file_status) || !previewImageStatus) {
    return file_status || '';
  }

  return `preview_${previewImageStatus}`;
}

export const ingestStatuses = new Set(['pending', 'ingesting', 'reingesting', 'created']);
export function isAssetIngesting({
  file_status,
  preview_image,
  file_size,
}: Pick<IAsset | IMm3Asset, 'file_status' | 'preview_image' | 'file_size'>): boolean {
  if (!file_size) return false; // isShellAsset
  return Boolean(ingestStatuses.has(file_status || '') || ingestStatuses.has(preview_image?.status || ''));
}

export const refreshIngestingAssetsDelay = 30 * 1000; // 30 seconds

export const getIsMm3Assets = (): boolean => {
  return getStorageItem<boolean>(STORAGE_KEYS.USES_MM3_ASSETS, 'false');
};

export function withAssetVersion<T, P>(
  OldAssetComponent: React.FC<T>,
  Mm3AssetComponent: React.FC<P>,
): React.FC<{ [S in keyof T]: S extends keyof P ? T[S] | P[S] : T[S] }> {
  const isMm3Assets = getIsMm3Assets();
  if (isMm3Assets) {
    return ((props: P) => <Mm3AssetComponent isMm3Assets={isMm3Assets} {...props} />) as React.FC<{
      [S in keyof T]: S extends keyof P ? T[S] | P[S] : T[S];
    }>;
  }
  return ((props: T) => <OldAssetComponent isMm3Assets={isMm3Assets} {...props} />) as React.FC<{
    [S in keyof T]: S extends keyof P ? T[S] | P[S] : T[S];
  }>;
}

export function selectAssetHelper<T, P>(oldAssetsHelper: T, mm3AssetsHelper: P): T | P {
  const isMm3Assets = getIsMm3Assets();
  return isMm3Assets ? mm3AssetsHelper : oldAssetsHelper;
}

export function getAssetModel(): Model.ASSETS | Model.MM3_ASSETS {
  const isMm3Assets = getIsMm3Assets();

  return isMm3Assets ? Model.MM3_ASSETS : Model.ASSETS;
}
export const getMainClassification = (asset: Partial<IAsset> | Partial<IMm3Asset>): string => {
  return (asset as IAsset)?.main_classification || asset?.classification?.split('/')[0] || '';
};

export const getAssetTypeFromModel = (asset: IAsset | IMm3Asset): string => {
  const isMm3Assets = getIsMm3Assets();

  return isMm3Assets ? (asset as IMm3Asset).type : ((asset as IAsset).main_classification as string);
};

export const getMainType = (asset: IAsset | IMm3Asset): string => {
  const type = getAssetTypeFromModel(asset);

  switch (type) {
    case IMm3AssetType.IMAGE:
      return AssetFilters.IMAGE;
    case IMm3AssetType.DOCUMENT:
      return AssetFilters.DOCUMENT;
    case IMm3AssetType.AUDIO:
      return AssetFilters.AUDIO;
    case IMm3AssetType.VIDEO:
      return AssetFilters.VIDEO;
    case IMm3AssetType.SUBTITLE:
      return 'subtitle';
    case IMm3AssetType.OTHER:
      return 'other';

    default:
      return type;
  }
};
export const isAsset = (type: string): boolean => {
  const assetTypes = ['asset', AssetFilters.IMAGE, AssetFilters.DOCUMENT, AssetFilters.AUDIO, AssetFilters.VIDEO];
  return assetTypes.includes(type.toLowerCase());
};

export const isShell = (asset?: Pick<IMm3Asset, 'type' | 'file_size'> | Pick<IAsset, 'file_size'>): boolean => {
  if (!asset || !(asset as IMm3Asset)?.type) {
    return false;
  }

  return (asset as IMm3Asset).type !== IMm3AssetType.IMF && asset.file_size === null;
};

const autosuggestValues = async (model: string, property: string, query: string): Promise<string[]> => {
  return chipmunk.run(async (ch) => {
    const result = await ch.action(model, 'search', {
      body: {
        per: 1,
        stats: [property],
        search: {
          filters: [[`${property}.fts`, 'q', `${query}*`]],
        },
      },
    });
    const values = map(flatten(map(result.aggregations)), 'value');

    return filter(values, (value) => typeof value === 'string' && startsWith(value.toLowerCase(), query.toLowerCase()));
  });
};

export const getAssetSuggestions = async (
  { q }: IQueryParams,
  property: string,
): Promise<IResult<{ value: string }>> => {
  const a = await autosuggestValues(getAssetModel(), property, q || '');
  const objects = a.map((e) => ({ value: e }));
  return { objects, object: objects[0] };
};

export const createAssetFieldSuggestion = (name: string) => {
  return (params: IQueryParams) => getAssetSuggestions(params, name);
};
export const formatAssetSource = (label: string): string => (label ? label.replace('_', ' ') : label);

export const formatAssetClassification = (classification?: string | null): string => {
  const [mainClassification, ...cl] = (classification || '').split('/');
  if (!cl.length) {
    return mainClassification === 'imf' ? 'IMF/DCP' : startCase(mainClassification);
  }

  return `${startCase(mainClassification)}: ${startCase(cl.join('/'))}`;
};

export const getOrientation = (file: UppyFile<Record<string, unknown>, Record<string, unknown>>): IOrientation => {
  if (file.type?.includes('video')) {
    return 'landscape';
  }

  if ((file.meta?.width as number) < (file.meta?.height as number)) {
    return 'landscape';
  } else if ((file.meta?.width as number) === (file.meta?.height as number)) {
    return 'square';
  }

  return 'landscape';
};

export const getAssetModelByType = (type?: string | null): string => {
  switch (type) {
    case IMm3AssetType.AUDIO:
      return Model.MM3_AUDIO;
    case IMm3AssetType.SUBTITLE:
      return Model.MM3_SUBTITLE;
    case IMm3AssetType.VIDEO:
      return Model.MM3_VIDEO;
    case IMm3AssetType.IMF:
      return Model.MM3_IMF;
    default:
      return Model.MM3_ASSETS;
  }
};
export const getAssetFileExtension = (filename?: string): string => {
  if (!filename) return '';
  const parts = filename.split('.');
  return parts.length > 1 ? parts.pop() ?? '' : '';
};

export const formatAssetPurpose = (purpose: string): string => {
  if (purpose === 'SDH') return purpose;
  return startCase(purpose?.toLowerCase());
};
