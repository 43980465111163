import React, { useCallback, useMemo } from 'react'; //
import { observer } from 'mobx-react-lite';

import { IUseActionsOptionParam, Pages } from 'utils/actions/types';

import { AssetsDataSection } from 'components/asset/assets-data-section';
import { Model } from 'helpers/filters/types';

import { DetailsPageTabs, IAsset, IMm3Asset, ISearchFilter } from 'types';
import { IMm3AssetType } from 'types';
import { IAssetFilters } from 'components/asset/asset-filters';
import { chipmunk, IResult } from 'utils/chipmunk';

interface IChildrenTabPreviewProps {
  asset: IMm3Asset;
  tab: DetailsPageTabs;
}

export const AssetMm3ChildrenTab: React.FC<IChildrenTabPreviewProps> = ({ asset, tab }) => {
  const { id, type } = asset || {};
  const isImf = type === IMm3AssetType.IMF;

  const [persistentFilters, actionsOptions, initFilters] = useMemo<
    [ISearchFilter[], Partial<IUseActionsOptionParam<IMm3Asset>> | undefined, Partial<IAssetFilters> | undefined]
  >(() => {
    let options: Partial<IUseActionsOptionParam<IMm3Asset>> | undefined = { parentId: id || 0 };

    let filters: ISearchFilter[] = [];
    let initialFilters: Partial<IAssetFilters> | undefined;

    switch (tab) {
      case DetailsPageTabs.REPORTS:
        filters = [
          ['classification', 'eq', 'document/report'],
          ['main_classification', 'eq', 'document'],
        ];
        break;
      case DetailsPageTabs.SUB_ASSETS:
        filters = [
          ['classification', 'not_in', ['document/report']],
          ['type', 'not_in', [IMm3AssetType.AUDIO, IMm3AssetType.SUBTITLE, ...(isImf ? [IMm3AssetType.VIDEO] : [])]],
        ];
        break;
      case DetailsPageTabs.SUBTITLES:
        filters = [['type', 'eq', IMm3AssetType.SUBTITLE]];
        options = undefined;
        break;
      case DetailsPageTabs.AUDIO_TRACKS:
        filters = [['type', 'eq', IMm3AssetType.AUDIO]];
        break;
      case DetailsPageTabs.EXPERIENCES:
        filters = [['type', 'eq', IMm3AssetType.VIDEO]];

        break;
    }
    return [filters, options, initialFilters];
  }, [id, isImf, tab]);

  const executor = useCallback(
    (options): Promise<IResult<IAsset>> => {
      return chipmunk.run(async ({ action }) => {
        const result = await action(Model.MM3_ASSETS, 'descendants', {
          ...options,
          params: {
            ...options.params,
            asset_id: id,
          },
        });

        return result;
      });
    },
    [id],
  );

  return (
    <AssetsDataSection
      initFilters={initFilters}
      persistentFilters={persistentFilters}
      actionsOptions={actionsOptions}
      page={Pages.ASSET_SUB_ASSETS_TAB}
      executor={executor}
      hideMainAssetFilter
    />
  );
};

export default observer(AssetMm3ChildrenTab);
