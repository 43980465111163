import React from 'react';
import cx from 'classnames';
import { Checkbox, noop } from '@mantine/core';
import { Link } from 'react-router-dom';

import { Routes } from 'utils/routes';
import { Thumbnail } from 'components/thumbnail';
import { isEntityDeleted } from 'utils/is-entity-deleted';
import { placeholder } from 'utils/ui';
import { Classes } from 'utils/ui';
import { IAssetDataSectionWrappedComponent } from './types';
import useSelectedOption from 'utils/hooks/selected-option';
import { getIngestStatus, getPreviewImageUrl, isShell } from 'utils/asset';
import { AssetStatusIndicator } from 'components/asset/asset-status-indicator/asset-status-indicator';
import { ParentBreadcrumbs } from 'components/parent-breadcrumb';
import { IAsset, IMm3Asset } from 'types';
import { ScheduledChangeIndicator } from 'components/common/scheduled-change-info';
import { useEarlyUploadImageDialog } from 'components/upload-preview-image/upload-preview-image-early';

import { MarketingAssetStar } from './marketing-asset-star';
import { ThumbnailFileStatus } from 'components/file-status';
import { AssetAttributes } from './asset-attributes';
import { AmGenericAsset3DigitalSubtitle } from '@mediafellows/mm3-types';
import { formatType } from 'utils/format-type';
import { useStore } from 'store/index';
import { getIsMm3Assets } from 'utils/asset';

export const AssetDataSectionTilesItem: React.FC<IAssetDataSectionWrappedComponent> = ({
  asset,
  contextMenu,
  handleClick,
  isAllItemsSelected,
  isChecked,
  isActive,
  page,
  disableMarketingStar,
}) => {
  const isMm3Assets = getIsMm3Assets();

  const { main_classification, name, preview_image, access_level } = asset;
  const assetType = isMm3Assets ? formatType((asset as IMm3Asset).type) : main_classification;

  const selectedOption = useSelectedOption({ value: access_level, hideDivisionAccessLevel: false });
  const { id: parentId, name: parentName } = ((asset as IMm3Asset)?.parent || {}) as IMm3Asset;
  const marginBottom = asset.status === 'offline' ? '2px' : '3px';

  const isShellAsset = isShell(asset);
  const {
    assetsFileUploadStore: { submitFile },
  } = useStore();

  const [openUploadDialog] = useEarlyUploadImageDialog({
    onSubmit: submitFile,
    asset: asset || ({} as IAsset | IMm3Asset),
  });

  return (
    <div
      className={cx('asset-list-item', {
        'asset-list-item-tile--active': isActive,
        'entity-list-item--deleted': isEntityDeleted(asset),
      })}
      onClick={handleClick}
    >
      <div className="asset-list-item-tile">
        <div className="asset-list-item-tile__actions mb-2">
          <div className="d-flex justify-content-between align-items-center">
            <div
              className={cx('asset-list-item-tile__checkbox d-flex align-items-center', {
                [`access-level-${selectedOption?.color}--tile`]: Boolean(selectedOption),
              })}
            >
              <Checkbox
                onChange={handleClick}
                checked={isChecked || isAllItemsSelected}
                disabled={isAllItemsSelected}
              />
              <div style={{ marginBottom: marginBottom, marginLeft: '8px' }}>
                <AssetStatusIndicator asset={asset} padded={false} />
              </div>
            </div>
          </div>
          <div className={`asset-list-item-tile__type ${Classes.TEXT_MUTED}`}>{assetType}</div>
          <div className="d-flex align-items-center justify-content-between">
            <div style={{ marginBottom: '3px', marginRight: '3px' }}>
              <ScheduledChangeIndicator entity={asset} />
            </div>
            {Boolean(contextMenu) && contextMenu}
          </div>
        </div>

        <div className="asset-list-item-tile__thumb mb-2">
          <Thumbnail
            onClick={isShellAsset ? openUploadDialog : noop}
            isShellAsset={isShellAsset}
            image={getPreviewImageUrl(preview_image)}
            assetType={isMm3Assets ? (asset as IMm3Asset).type : main_classification}
            placeholder={<ThumbnailFileStatus status={getIngestStatus(asset)} size={25} withBackground />}
          />
        </div>
        <div className="asset-list-item-tile__content">
          <ParentBreadcrumbs id={parentId} to={`${Routes.ASSETS}/${parentId}`} title={parentName} layout="tiles" />

          <div className="entity-list-item-row__title asset-list-item-tile__title d-flex">
            <MarketingAssetStar
              asset={asset as IMm3Asset & AmGenericAsset3DigitalSubtitle}
              page={page}
              disableMarketingStar={disableMarketingStar}
            />
            <Link className="text-truncate" to={`${Routes.ASSETS}/${asset?.id}`}>
              <h4 className="text-truncate">{placeholder(name)}</h4>
            </Link>
          </div>
          <div className={`asset-list-item-tile__attributes ${Classes.TEXT_MUTED}`}>
            <AssetAttributes asset={asset} />
          </div>
        </div>
      </div>
    </div>
  );
};
