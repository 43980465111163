import { IItemId } from 'components/form-selection-items';
import { capitalize } from 'lodash';

export const projectDetailsKeys = [
  'project_name',
  'type',
  'order_template',
  'vendor_project_manager_id',
  'organization_project_manager_id',
];
export const orderItemsKeys = [
  'resolution',
  'subtitle',
  'dubbing',
  'metadata',
  'artwork_season',
  'artwork_episode',
  'duo_date',
];

export enum StepIds {
  ProjectDetails,
  Products,
  Order,
  OrderItems,
  Summary,
}

export const getTabId = (tab: IItemId): number => {
  if (typeof tab === 'string') {
    tab = capitalize(tab);
    return StepIds[tab];
  }

  return tab;
};
