import React from 'react';
import { omit } from 'lodash';

import { FormSubmitSection } from 'components/form-submit-section';
import { SubtitleFormCommonParts } from 'components/asset/subtitle-form';

import { useForm } from 'helpers/form';
import { Model } from 'helpers/filters/types';

import { ISubtitle } from 'types';

type IUploadSubtitleForm = Omit<ISubtitle, 'asset_id' | 'language'>;

export interface IEditSubtitleProps {
  onSubmit: (subtitle: IUploadSubtitleForm) => void;
  subtitle?: ISubtitle;
}

const EditSubtitleDialogBody: React.FC<IEditSubtitleProps> = ({ subtitle, onSubmit }) => {
  const initialValues = omit(subtitle, ['language', 'asset_id', 'file_attachment']);
  const {
    formData,
    handlers,
    valid,
    onSubmit: handleSubmit,
  } = useForm<IUploadSubtitleForm>(initialValues, Model.SUBTITLES, onSubmit);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <SubtitleFormCommonParts formData={formData} handlers={handlers} />
      </form>
      <FormSubmitSection labels={{ confirm: 'Save' }} submitDisabled={!valid} onSubmit={handleSubmit} />
    </>
  );
};

export default EditSubtitleDialogBody;
