import { useCallback, useState } from 'react';
import { toNumber, omit } from 'lodash';

import { ILocalizationOrder } from 'types/localization-order';
import { IUseAction } from 'utils/actions';
import { useStore } from 'store';
import { useSessionStore } from 'store/session-store';

import { copyLocalizationOrder } from 'utils/apis/localization-order';

import { shouldDisplayCopy } from './actions-acl';
import { ILocalizationOrderActionName } from './types';

export const useCopyAction: IUseAction<ILocalizationOrder, ILocalizationOrderActionName> = (items, options) => {
  const { toastStore } = useStore();
  const [loading, setLoading] = useState(false);
  const user = useSessionStore((state) => state.user);

  const handler = useCallback(async (): Promise<void> => {
    try {
      if (!items?.length) {
        return;
      }
      setLoading(true);
      toastStore.info('Copying of the localization order has started!');

      await copyLocalizationOrder({
        ...omit(items[0], ['id', 'sent_at']),
        owner_id: toNumber(user?.id),
        owner_organization_id: toNumber(user?.organization_id),
        status: 'draft',
      });

      toastStore.success('Localization Order copied successfully!');
      setLoading(false);
      return options?.onSuccess?.(ILocalizationOrderActionName.COPY);
    } catch (error) {
      toastStore.error(`Localization Order copy failed: ${error.text}`);
      await options?.onFailure?.();
    }
  }, [toastStore, items, options, user?.id, user?.organization_id]);

  return {
    name: ILocalizationOrderActionName.COPY,
    shouldDisplay: shouldDisplayCopy,
    isDisabled: loading,
    icon: 'duplicate',
    title: 'Copy',
    handler,
  };
};
