import React, { useCallback, useMemo } from 'react';

import { useStore } from 'store';

import { IProduct, IScheduledJob } from 'types';
import { FormDate, FormChecklist, FormAccessLevel, useForm } from 'helpers/form';
import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';
import { ToastError } from 'components/toast';
import { SharedScheduledForm } from 'components/scheduled-job-list/shared-scheduled-form-elements';

import { useScheduledJobs } from './hooks';
import { contextExtension } from './utils';
import { IAccessChangeScheduledFormAccess } from 'utils/actions/product/';
import { minAccessDate, maxAccessDate } from 'utils/constants';
import { Model } from 'helpers/filters/types';

import 'components/scheduled-job-list/style.scss';

const renderDetails = (job: IScheduledJob): JSX.Element => (
  <>
    {job?.access_level}
    <br />
    {job?.permissions?.join(', ')}
  </>
);

export const AccessChangeScheduledAccessForm: React.FC<{
  items: IProduct[];
  showPermissions?: boolean;
  readOnly?: boolean;
}> = ({ items, showPermissions = true, readOnly = false }) => {
  const formId = `product-access-change-scheduled-access`;
  const { toastStore } = useStore();

  const itemIds = useMemo(() => items.map((item) => item.id), [items]);

  const [scheduledJobs, removeJob, scheduleJob] = useScheduledJobs<IAccessChangeScheduledFormAccess>(
    'change_access_level_on_product',
    itemIds,
  );

  const submitForm = useCallback(
    async (data: IAccessChangeScheduledFormAccess): Promise<void> => {
      try {
        if (!items.every((item) => item.division_ids?.length) && data.access_level === 'division') {
          toastStore.error('Does not belong to any divisions');
          return;
        }
        await scheduleJob(data);
        toastStore.success('Access changed');
      } catch (error) {
        toastStore.error(<ToastError error={error} placeholder="Something went wrong" />);
      }
    },
    [items, scheduleJob, toastStore],
  );

  const initialValues =
    items.length > 1
      ? {
          scheduled_for: '',
          access_level: '',
          permissions: [],
          item_ids: itemIds,
        }
      : {
          scheduled_for: '',
          item_ids: itemIds,
          access_level: items[0].access_level,
          permissions: items[0]?.permissions,
        };

  const { formData, handlers, valid, onSubmit } = useForm<IAccessChangeScheduledFormAccess>(
    initialValues,
    Model.ASSETS,
    submitForm,
    contextExtension,
  );

  const disableSubmit = Boolean(
    scheduledJobs.find(({ scheduled_for }) => scheduled_for === formData.scheduled_for.value),
  );

  return (
    <SharedScheduledForm
      readOnly={readOnly}
      renderDetails={renderDetails}
      onSubmit={onSubmit}
      removeJob={removeJob}
      scheduledJobs={scheduledJobs}
      submitDisabled={!valid || Boolean(disableSubmit)}
      title="Scheduled Access"
    >
      <FormSectionWrapperV2 title="Schedule new access" gridTemplateColumns="1fr" padded>
        <FormDate
          name="scheduled_for"
          label="Date"
          large
          withTime={true}
          minDate={minAccessDate}
          maxDate={maxAccessDate}
          {...formData.scheduled_for}
          {...handlers}
        />
        <div>
          <FormAccessLevel
            name="access_level"
            label="Access"
            large
            {...formData.access_level}
            {...handlers}
            placeholder="Select Access"
            withinPortal
          />

          {showPermissions && (
            <FormChecklist
              name="permissions"
              label="Permissions"
              formId={formId}
              large
              {...formData.permissions}
              {...handlers}
            />
          )}
        </div>
      </FormSectionWrapperV2>
    </SharedScheduledForm>
  );
};
