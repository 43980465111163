import React from 'react';
import { observer } from 'mobx-react-lite';

import { FilterDateRange } from 'helpers/filters/fields';
import { IFiltersHandlers } from 'helpers/filters';

import { IAssetFilters } from 'components/asset/asset-filters/utils';

interface IAccessFiltersProps {
  filterHandlers: IFiltersHandlers<IAssetFilters>;
  filterValues: IAssetFilters;
}

export const AssetTimestampsFilters = observer(({ filterValues, filterHandlers }: IAccessFiltersProps) => {
  return (
    <>
      <FilterDateRange
        label="Created at"
        name="created_at"
        filter={filterValues.created_at}
        onChange={filterHandlers.onChange}
      />
      <FilterDateRange
        label="Updated at"
        name="updated_at"
        filter={filterValues.updated_at}
        onChange={filterHandlers.onChange}
      />
    </>
  );
});
