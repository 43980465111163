import React from 'react';
import { AmGenericBaseAffiliationProtectionOptions } from '@mediafellows/mm3-types';
import startCase from 'lodash/startCase';

import { InfoBoxV2 } from 'components/info-box-v2';
import { Row } from 'components/label-value-pair';
import ScrollWrapper from 'components/scroll-wrapper';
import { useCountryNames } from 'utils/hooks';

import './style.scss';
import { IAsset } from 'types';
import { AssetVideoPlayer } from 'components/common';

const formatOnOff = (value?: boolean | null): string => (value ? 'ON' : 'OFF');

export const AffiliationProtectionTab: React.FC<{
  protections: AmGenericBaseAffiliationProtectionOptions;
  sampleAsset?: IAsset;
}> = ({ protections, sampleAsset }) => {
  const { geo_ip, forensic_watermark, visible_watermark, drm, casting_prevention } = protections.meta || {};
  const countries = useCountryNames(geo_ip?.country_ids);

  return (
    <ScrollWrapper>
      <InfoBoxV2
        title="Default Protections"
        wrapperClassName="affiliation-protections__wrapper"
        className="affiliation-protections__section gap-2"
      >
        <InfoBoxV2 gridTemplateColumns="140px 1fr" className="text-no-transform gap-2" padded={false}>
          <Row label="DRM" rawLabel value={formatOnOff(drm?.enabled)} />
          <Row
            label="HDCP"
            rawLabel
            labelClassName="ms-3"
            value={formatOnOff(drm?.HDCP_enabled)}
            hidden={!drm?.enabled}
          />
          <Row label="Casting prevention" rawLabel value={formatOnOff(casting_prevention?.enabled)} />
          <Row label="Forensic watermarks" rawLabel value={formatOnOff(forensic_watermark?.enabled)} />
          <Row label="Visible Watermark" value={formatOnOff(visible_watermark?.enabled)} />
          <Row label="GeoIP" rawLabel value={formatOnOff(geo_ip?.enabled)} />
          <Row
            label="GeoIP List Type"
            labelClassName="ms-3"
            rawLabel
            value={startCase(geo_ip?.list_type || '-')}
            hidden={!geo_ip?.enabled}
          />
          <Row label="GeoIP Countries" rawLabel labelClassName="ms-3" value={countries} hidden={!geo_ip?.enabled} />
        </InfoBoxV2>
        <InfoBoxV2 gridTemplateColumns="1fr" padded={false}>
          {sampleAsset?.id ? <AssetVideoPlayer video={sampleAsset} /> : <></>}
        </InfoBoxV2>
      </InfoBoxV2>
    </ScrollWrapper>
  );
};
