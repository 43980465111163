import React from 'react';
import { observer } from 'mobx-react-lite';
import { CreateShellAssetForm } from 'components/asset/create-shell-asset/create-shell-asset';
import { Page } from 'components/layout';

export const CreateShellAssetPage: React.FC = observer(() => {
  return (
    <Page title="Create a Shell Asset" className="overflow-hidden h-100">
      <CreateShellAssetForm className="create-shell-asset-container p-2" />
    </Page>
  );
});
