import React, { useCallback, useState } from 'react';
import { Button } from '@mantine/core';
import { DeliveriesGenericDeliveryPackage } from '@mediafellows/mm3-types';

import { FormAssets, FormRemoteSelect, IRemoteSelectorFetcher } from 'helpers/form';
import { loadDeliveries } from 'utils/apis/package-delivery';

import { FormActionSection } from 'components/form-submit-section';
import { IFormAddToDeliveryDraftForm } from './types';
import { Classes } from 'utils/ui';

export const AddToDeliveryDraftForm: React.FC<IFormAddToDeliveryDraftForm> = ({ asset_ids, onAdd, onCancel }) => {
  const [deliveryId, setDeliveryId] = useState<null | number>(null);
  const [loading, setLoading] = useState(false);

  const fetchDraftDeliveries = useCallback<IRemoteSelectorFetcher>(async (): Promise<
    DeliveriesGenericDeliveryPackage[]
  > => {
    const filter = ['status', 'eq', 'draft'];
    return loadDeliveries(filter);
  }, []);

  const setDelivery = ({ deliveryId }: { [key: string]: number }): void => setDeliveryId(deliveryId);

  const addToDraft = (): void => {
    setLoading(true);

    onAdd({ delivery_id: deliveryId, asset_ids: asset_ids });
  };

  return (
    <>
      <FormAssets className="mb-4" disabled value={asset_ids} omni name="asset_ids" label="Assets" />

      <FormRemoteSelect
        name="deliveryId"
        label="Deliveries"
        value={deliveryId}
        className="mb-6"
        fetchOptions={fetchDraftDeliveries}
        large
        onChange={setDelivery}
      />

      <FormActionSection className={Classes.DIALOG_FOOTER_ACTIONS}>
        <Button variant="default" size="xs" onClick={onCancel}>
          Cancel
        </Button>
        <Button loading={loading} size="xs" variant="primary" onClick={addToDraft} disabled={loading || !deliveryId}>
          Submit
        </Button>
      </FormActionSection>
    </>
  );
};
