import React, { useCallback } from 'react';

import { IContact } from 'types';
import { useStore } from 'store';
import { IUseActionsOptionParam } from 'utils/actions/types';
import { ToastError } from 'components/toast';

import { FormSelectionItems } from 'components/form-selection-items';

import { createContactSelectionWithItems } from './api';
import { shouldDisplayMultiNotDeleted as shouldDisplay } from 'utils/actions/common/actions-acl';
import { IContactActionConfig, IContactActionName, ICreateSelectionFormValues } from './types';
import { IModalSize } from 'components/dialogs/types';

export const useCreateSelectionAction = (items: IContact[], options: IUseActionsOptionParam): IContactActionConfig => {
  const { dialogStore, toastStore } = useStore();
  const itemIds = items?.map((user) => user.id);

  const onConfirm = useCallback(
    async (values: ICreateSelectionFormValues): Promise<void> => {
      try {
        const selectionParams = {
          name: values.name,
          access_level: values.access_level,
          main_entity_type: 'users',
        };
        await createContactSelectionWithItems(selectionParams, values.item_ids);

        toastStore.success('Success');
        options?.onSuccess?.();
        dialogStore.close();
      } catch (error) {
        toastStore.error(<ToastError error={error} />);
        options?.onFailure?.();
      }
    },
    [options, dialogStore, toastStore],
  );

  const body = useCallback((): JSX.Element => {
    return <FormSelectionItems entityType="users" itemIds={itemIds} onConfirm={onConfirm} omni />;
  }, [itemIds, onConfirm]);

  const handler = (): void => {
    dialogStore.openModal({
      title: 'Create a new Selection',
      body,
      size: IModalSize.S,
    });
  };

  return {
    name: IContactActionName.CREATE_SELECTION,
    shouldDisplay,
    icon: 'select',
    title: 'Create selection',
    handler,
  };
};
