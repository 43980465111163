import React from 'react';
import { isEmpty } from 'lodash';
import cx from 'classnames';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { Routes } from 'utils/routes';

import { useDialogStore } from 'store/hooks';
import { formatDate } from 'utils/date';
import { ITimelineItem } from 'types';
import { MantineIcon } from 'utils/ui/icon';
import { Classes } from 'utils/ui';

import './style.scss';

export interface ITimelineItemDetailsProps {
  item: ITimelineItem;
}

const TimelineItemDetails: React.FC<ITimelineItemDetailsProps> = observer(({ item }) => {
  const { close } = useDialogStore();
  const {
    user_title,
    description,
    effective_at,
    client_ip,
    entity_title,
    other_entity_title,
    other_entity_type,
    other_entity_id,
    diff,
    user,
  } = item;

  const noDifferences = isEmpty(diff);

  const { first_name, last_name, id } = user || {};
  return (
    <div className="timeline-item-details">
      <div>
        <div className={cx(`${Classes.TEXT_MUTED}`, 'mb-1')}>Timestamp</div>
        <div>{formatDate(effective_at)}</div>
      </div>
      <div>
        <div className={cx(`${Classes.TEXT_MUTED}`, 'mb-1')}>User</div>
        <div>
          {user && (
            <Link to={`${Routes.CONTACTS}/${id}`} onClick={close}>
              {first_name} {last_name}
            </Link>
          )}
          {!user && <span>{user_title}</span>}
        </div>
      </div>
      <div>
        <div className={cx(`${Classes.TEXT_MUTED}`, 'mb-1')}>Changed Item</div>
        {entity_title}
      </div>
      <div>
        <div className={cx(`${Classes.TEXT_MUTED}`, 'mb-1')}>Related Item</div>
        {other_entity_type !== 'user' && (
          <Link to={`/${other_entity_type}s/${other_entity_id}`} onClick={close}>
            {other_entity_title}
          </Link>
        )}
        {other_entity_type === 'user' && (
          <Link to={`${Routes.CONTACTS}/${other_entity_id}`} onClick={close}>
            {other_entity_title}
          </Link>
        )}
        {!other_entity_title && <div>—</div>}
      </div>
      <div>
        <div className={cx(`${Classes.TEXT_MUTED}`, 'mb-1')}>Clients IP</div>
        <div>{client_ip}</div>
      </div>
      <div className="timeline-item-details__description">
        <div className={cx(`${Classes.TEXT_MUTED}`, 'mb-1')}>Description</div>
        <div>{description}</div>
      </div>
      {!noDifferences && (
        <div className="timeline-item-details__changeset-wrapper">
          <div className={cx(`${Classes.TEXT_MUTED}`, 'mb-1')}>Full Change Set</div>
          <div className="timeline-item-details__changeset">
            {Object.entries(diff).map((change, index) => {
              return (
                <div key={index}>
                  <div className="timeline-item-details__title mb-1 mt-3">{change[0].replace(/_/g, ' ')}</div>
                  <div className="text-capitalize timeline-item-details__change pb-3">
                    <div className={cx(`${Classes.TEXT_MUTED}`)}>
                      {change[1][0] || <span className="font-italic text-lowercase">empty</span>}
                    </div>

                    <MantineIcon icon="arrow-right" size={12} />
                    <div>{change[1][1]}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
});

export default TimelineItemDetails;
