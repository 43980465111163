import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { debounce, isString, throttle } from 'lodash';
import { DetailsPageTabs } from 'types';
import { useLocation, useParams } from 'react-router-dom';
import { useStore } from 'store';
import useResizeObserver from 'use-resize-observer';
import { DataSectionSidebarTabs } from 'components/data-section-sidebar';
import { useNavigate } from 'react-router';
import { buildPath } from 'utils/url';
import { hasPower } from 'utils/powers';

export * from './analytics';
export { useAssetActionsOptions } from './action-options';
export { useCategories } from './categories';
export { useLanguages, useLanguageVersionInfo } from './languages';
export { useCountryNames } from './use-countries';
export { useProductAncestry } from './product';
export { useOrganizationCountInGroup } from './group';
export { useGetSsoLink } from './sso';
export { useUppyUpload } from './use-uppy-upload';
export { default as useControlDataSection, useClearDataInitialFilters } from './control-data-section';
export type { IFilterOption } from './control-data-section';
export { useResetPagination } from './reset-pagination';
export { useFetchPreviewWithRefreshForIngest } from './refresh-preview-ingest';
interface IUseWindowsSize {
  width: number;
  height: number;
}

const getSize = (): IUseWindowsSize => ({
  width: window?.innerWidth || 0,
  height: window?.innerHeight || 0,
});

export const useWindowSize = (): IUseWindowsSize => {
  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    const handleResize = debounce(() => {
      setWindowSize(getSize());
    }, 150);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

export const useDebounce = <T>(value: T, delay: number): T => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [delay, value]);

  return debouncedValue;
};

type IUseTab = [DetailsPageTabs, (tab: DetailsPageTabs, addEditParam?: boolean) => void];

export const useTab = (
  defaultTab: DetailsPageTabs,
  options: (DetailsPageTabs | { requiredPower: string; tab: DetailsPageTabs })[],
): IUseTab => {
  const { tabLevel1 } = useParams<{ tabLevel1: DetailsPageTabs }>();

  const currentTab = useMemo(() => {
    const optionsWithPowers = options.reduce((acc: DetailsPageTabs[], cur) => {
      if (isString(cur)) {
        acc.push(cur);
      } else if (cur.requiredPower && hasPower(cur.requiredPower)) {
        acc.push(cur.tab);
      }
      return acc;
    }, []);

    return optionsWithPowers.find((item) => item === tabLevel1) || defaultTab;
  }, [options, tabLevel1, defaultTab]);

  const [activeTab, setActiveTab] = useState(currentTab);
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  // always load current tab in URL by default
  useEffect(() => {
    if (!tabLevel1) {
      const isLastSlash = pathname[pathname.length - 1] === '/';
      const updatedPath = isLastSlash ? `${pathname}${defaultTab}` : `${pathname}/${defaultTab}`;
      navigate(updatedPath, { replace: true });
    }
  }, [navigate, tabLevel1, pathname, defaultTab]);

  const updateActiveTab = useCallback(
    (tab: DetailsPageTabs, addEditParam = false): void => {
      setActiveTab(tab);
      const params = new URLSearchParams(search);
      const hasEditParam = params.get('edit');
      params.set('edit', '1');

      const updatedPath = tabLevel1 ? pathname.replace(tabLevel1, tab) : buildPath(pathname, tab);
      const updatedSearch = addEditParam || hasEditParam ? params.toString() : '';
      navigate({ pathname: updatedPath, search: updatedSearch });
    },
    [search, pathname, tabLevel1, navigate],
  );

  // update active tab if tabLevel1 changes - currentTab
  useEffect(() => {
    if (currentTab !== activeTab) {
      setActiveTab(currentTab);
    }
  }, [currentTab, activeTab]);

  return [activeTab, updateActiveTab];
};

interface IFetchDataResponse<T> {
  data: T[];
  totalPages: number;
}

interface IUseFetchPaginatedData<T> {
  loading: boolean;
  currentPage: number;
  totalPages: number;
  data: T[];
  handleLoadMore: () => void;
}

type IFetchData<T> = (page: number) => Promise<IFetchDataResponse<T>>;

export function useFetchPaginatedData<T>(fetchData: IFetchData<T>): IUseFetchPaginatedData<T> {
  const isMounted = useRef(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [data, setData] = useState<T[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { toastStore } = useStore();

  const handleLoadMore = useCallback(() => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  }, [currentPage, totalPages]);

  useEffect(() => {
    const asyncLoacData = async (): Promise<void> => {
      try {
        setLoading(true);
        const { data: result, totalPages }: IFetchDataResponse<T> = await fetchData(currentPage);

        if (isMounted.current) {
          setData((data) => {
            return [...(currentPage === 1 ? [] : data), ...result];
          });
          setTotalPages(totalPages);
        }
      } catch (error) {
        toastStore.error('Failed to load timeline');
      } finally {
        if (isMounted.current) setLoading(false);
      }
    };

    asyncLoacData();
  }, [currentPage, toastStore, fetchData]);

  useEffect(() => {
    return () => {
      // prevent calling life cycle functions if component is unmounted
      isMounted.current = false;
    };
  }, []);

  return { handleLoadMore, loading, currentPage, totalPages, data };
}

export const useEditMode = (): [boolean, (editMode: boolean, updateParam?: boolean) => void] => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const [isEditMode, setIsEditMode] = useState(search.includes('edit'));

  const setMode = useCallback(
    (editMode, updateParam = true) => {
      setIsEditMode(editMode);

      if (updateParam) {
        const params = new URLSearchParams(search);
        params.set('edit', '1');
        const updatedSearch = editMode ? params.toString() : '';
        navigate({ search: updatedSearch }, { replace: true });
      }
    },
    [navigate, search],
  );

  // reset edit state on navigation
  useEffect(() => {
    if (!isEditMode) {
      return;
    }

    const params = new URLSearchParams(search);
    const hasEditParam = params.get('edit') === '1';

    if (!hasEditParam) {
      setIsEditMode(false);
    }
  }, [search, isEditMode]);

  return [isEditMode, setMode];
};

export type IUseRemoteReturn<T> = [T | undefined, boolean, Dispatch<SetStateAction<T>>, () => Promise<void>];

export const useRemote = <T>(fetcher: () => Promise<T | undefined>, defaultValue?: T): IUseRemoteReturn<T> => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<T | undefined>(defaultValue);
  const isMounted = useRef(true);
  const initValues = useRef({ defaultValue });

  const refresh = useCallback(async () => {
    try {
      setIsLoading(true);
      const apiData = await fetcher();

      if (!isMounted.current) {
        return;
      }

      setData(apiData || initValues.current.defaultValue);
      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
  }, [fetcher]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return [data, isLoading, setData, refresh];
};

export const useRefreshDataSection = (): (() => Promise<void>) => {
  const {
    dataSectionStore: { updateStore },
  } = useStore();
  const navigate = useNavigate();
  const location = useLocation();

  const refreshDataSection = useCallback(async (): Promise<void> => {
    updateStore({ activeTab: DataSectionSidebarTabs.FILTERS, checked: [], active: null });
    navigate({ pathname: location.pathname, search: location.search });
  }, [updateStore, navigate, location]);

  return refreshDataSection;
};

type IOnResize = (size: { width: number | undefined; height: number | undefined }) => void;

export const useThrottledResizeObserver = (
  wait: number,
): { width: number; height: number; ref: (instance: HTMLElement | null) => void } => {
  const [size, setSize] = useState({ width: 0, height: 0 });
  const onResize = useMemo(() => throttle(setSize, wait), [wait]) as IOnResize;
  const { ref } = useResizeObserver({ onResize });

  return { ref, ...size };
};
