import React from 'react';
import { useCallback } from 'react';

import { IMarketingEvent } from 'types';
import { useDialogStore } from 'store';

import { IUseAction } from 'utils/actions';
import { useEventCreateForm, useEventSave } from 'components/event-create';
import { StepUsers } from 'components/event-create';
import { FormSubmitSection } from 'components/form-submit-section';

import { shouldDisplayManageAttendees as shouldDisplay } from './actions-acl';
import { IMarketingEventActionName } from './types';
import { IModalSize } from 'components/dialogs/types';

const EditAttendees: React.FC<{ event: IMarketingEvent; onSuccess?: () => void; onFailure?: () => void }> = ({
  event,
  onSuccess,
  onFailure,
}) => {
  const saveEvent = useEventSave({ onSuccess, onFailure });
  const form = useEventCreateForm({
    saveEvent,
    values: {
      ...event,
      eventTiming: event?.is_timed_event ? 'timed' : 'persistent',
      eventType: event?.subevent ? 'subevent' : 'mainevent',
    },
  });

  return (
    <div className="event__manage-attendees__modal">
      <form className="event-manage-attendees-modal__form" onSubmit={form.onSubmit}>
        <StepUsers form={form} />
        <FormSubmitSection submitDisabled={!form.valid} />
      </form>
    </div>
  );
};

export const useManageAttendeesAction: IUseAction<IMarketingEvent, IMarketingEventActionName> = (items, options) => {
  const { openModal, close } = useDialogStore();

  const event = items[0];

  const handler = useCallback(() => {
    openModal({
      title: `Manage attendees`,
      size: IModalSize.XS,
      body: () => (
        <EditAttendees
          event={event}
          onSuccess={() => {
            close();
            options?.onSuccess?.();
          }}
          onFailure={options?.onFailure}
        />
      ),
    });
  }, [event, openModal, options, close]);

  return {
    name: IMarketingEventActionName.MANAGE_ATTENDEES,
    shouldDisplay,
    icon: 'add-to-artifact',
    title: 'Manage attendees',
    handler,
  };
};
