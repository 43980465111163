import React from 'react';
import { FormInput, IUseFormReturn } from 'helpers/form';
import { IContact, IITVStudiosContact } from 'types/contact';

export const CustomContactRowEdit: React.FC<Partial<IUseFormReturn<IContact>>> = () => <></>;
export const CustomContactSettingsRowEdit: React.FC<Partial<IUseFormReturn<IContact>>> = () => <></>;

export const CustomContactBusinessEdit: React.FC<Partial<IUseFormReturn<IITVStudiosContact>>> = ({
  formData,
  handlers,
}) => {
  return (
    <>
      <FormInput
        name="salesforce_id"
        key="salesforce_id"
        label="Salesforce ID"
        {...formData?.salesforce_id}
        {...handlers}
      />
    </>
  );
};
