import { ILocalizationOrder } from 'types/localization-order';
import { IActionConfig, IShouldDisplayActionFunction } from 'utils/actions/types';

export enum ILocalizationOrderActionName {
  EDIT = 'edit',
  SEND = 'send',
  DELETE = 'delete',
  SCHEDULE = 'schedule',
  COPY = 'copy',
  CANCEL = 'cancel',
  RETRIGGER = 'retrigger',
}

export type ILocalizationOrderActionConfig = IActionConfig<ILocalizationOrder, ILocalizationOrderActionName>;
export type IShouldDisplayDeliveryActionFunction = IShouldDisplayActionFunction<
  ILocalizationOrder,
  ILocalizationOrderActionName
>;
