import React from 'react';
import { observer } from 'mobx-react-lite';

import { FilterCheckbox, FilterSlider, FilterSwitch, FilterText, FilterTree } from 'helpers/filters/fields';
import { DynamicTreeDataProvider, unflattenTree } from 'helpers/data-provider/tree-data-provider';
import { Mm3ContextDataProvider, dynamicDataExecutorCache } from 'helpers/data-provider/option-data-provider';
import { IFiltersHandlers } from 'helpers/filters';
import { Model } from 'helpers/filters/types';

import { DataSectionStore } from 'store/data-section-store';
import { IResult, chipmunk } from 'utils/chipmunk';
import { IProduct } from 'types';

import { AdditionalFiltersCmp } from 'components/product-filters/additional-filters';
import { IFilters } from 'components/product-filters/types';
import { LanguageSelector } from 'components/product-filters/language-selector';

interface IEssentialFiltersProps {
  filterValues: IFilters;
  filterHandlers: IFiltersHandlers<IFilters>;
  dataSectionStore: DataSectionStore<IProduct>;
  isSmartGroupFilters?: boolean;
}

const genreTreeProvider = new DynamicTreeDataProvider(
  dynamicDataExecutorCache('genres', () =>
    chipmunk.run(({ action }) => action('pm.layer/motion_picture/standard/category', 'graph')),
  ),
  (data: IResult) =>
    unflattenTree(data.objects[0]['@graph'].map((g) => ({ label: g.name, value: g.id, ancestry: g.ancestry }))),
);
const labelMarks = [
  {
    value: 1946,
    label: '1946',
  },
  {
    value: 1996,
    label: '1996',
  },
];

const typeOptionsProvider = new Mm3ContextDataProvider(Model.PRODUCTS, 'type');

export const ProductEssentialFilters = observer(
  ({ filterValues, filterHandlers, dataSectionStore, isSmartGroupFilters }: IEssentialFiltersProps) => {
    const { searchStore } = dataSectionStore;

    return (
      <>
        {!isSmartGroupFilters && (
          <FilterText
            label="Search List"
            placeholder="Search"
            name="_"
            filter={filterValues._}
            onChange={filterHandlers.onChange}
          />
        )}

        <AdditionalFiltersCmp filterValues={filterValues} filterHandlers={filterHandlers} />

        <FilterCheckbox
          label="Type"
          name="type"
          simplifyCheckboxLabel={true}
          optionsProvider={typeOptionsProvider}
          disabled={searchStore.running}
          filter={filterValues.type}
          onChange={filterHandlers.onChange}
          aggregations={searchStore.aggregationValues('type')}
        />

        {filterValues.no_parent && (
          <FilterSwitch
            label="Show parent products only"
            name="no_parent"
            disabled={searchStore.running}
            filter={filterValues.no_parent}
            onChange={filterHandlers.onChange}
            checkedValue={true}
            notCheckedValue={false}
          />
        )}
        <FilterTree
          label="Genres"
          name="default_layer.meta.category_ids"
          optionsProvider={genreTreeProvider}
          filter={filterValues['default_layer.meta.category_ids']}
          onChange={filterHandlers.onChange}
          aggregations={searchStore.aggregationValues('category_ids')}
          placeholder="Select Genres"
        />
        <LanguageSelector
          filterValues={filterValues}
          filterHandlers={filterHandlers}
          dataSectionStore={dataSectionStore}
        />

        <FilterSlider
          label="Year of Production"
          name="default_layer.meta.year_of_production"
          min={1896}
          max={2040}
          marks={labelMarks}
          filter={filterValues['default_layer.meta.year_of_production']}
          onChange={filterHandlers.onChange}
        />
      </>
    );
  },
);
