import React, { useEffect, useState, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { each, isEmpty, random } from 'lodash';
import { customTheme, IRowData } from 'pages/order-details/order-items-tab/utils';
import { columns } from './utils';
import { ILocalizationOrder } from 'types/localization-order';
import { formatDate } from 'utils/date';
import { useRemote } from 'utils/hooks';
import { fetchProductsDescendants } from 'utils/apis/product';
import { IProduct, ItemId, Product3Types } from 'types';

interface IOrdeItemsTabProps {
  order: ILocalizationOrder;
}

const OrderItemsGrid: React.FC<IOrdeItemsTabProps> = ({ order }) => {
  const [ordersData, setOrdersData] = useState<Partial<IRowData[]>>([]);

  const fetchProduct = useCallback(async (): Promise<IProduct[]> => {
    return await fetchProductsDescendants([order.product_id as ItemId]);
  }, [order.product_id]);

  const [products = []] = useRemote(fetchProduct, []);

  useEffect(() => {
    const data: IRowData[] = [];

    if (order && isEmpty(ordersData)) {
      const season = products?.find((p) => p.type === Product3Types.SEASON);
      const assetTypes = ['Subtitle', 'Dubbing', 'Metadata', 'Subtitle', 'Dubbing', 'Metadata'];

      each(products, (product) => {
        const seriesLabel = product.full_title?.split('-')[0];

        if (product.type === Product3Types.EPISODE)
          data.push({
            assetUpload: '',
            series: seriesLabel as string,
            season: season?.title as string,
            episode: product.title,
            assetType: assetTypes[random(0, assetTypes.length - 1)],
            language: order.subtitles,
            targetDate: formatDate(order?.content_partner_delivery_deadline),
            dueDate: formatDate(order?.due_date),
            deliveryDate: 'TBC',
            status: 'in-progress',
            workType: 'Creation',
            runTime: '00:45:00',
          });
      });

      setOrdersData(data);
    }
  }, [products, order, ordersData]);

  const defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true,
  };

  return (
    <div style={{ height: '500px', width: '100%' }}>
      <AgGridReact
        rowData={ordersData}
        columnDefs={columns}
        defaultColDef={defaultColDef}
        animateRows={true}
        theme={customTheme}
        gridOptions={{ rowHeight: 50 }}
      />
    </div>
  );
};

export default OrderItemsGrid;
