import React, { useEffect, useRef, useMemo } from 'react';

import { Thumbnail } from 'components/thumbnail';
import { parseKeyPathToObject } from 'utils/payload';
import { FormCheckbox, IUseFormReturn, useForm } from 'helpers/form';
import { IUseMm3FormReturn, useMm3Form } from 'helpers/form/use-mm3-form';
import { Model } from 'helpers/filters/types';
import { IAsset, IMm3Asset, ItemId } from 'types';

import {
  AssetSettingsSection,
  AssetIdentifiersSection,
  AssetAboutSection,
  IAssetFormFields,
  AssetSpecificationsSection,
  AssetCreditsSection,
} from './sections';
import {
  assetFormCustomContext,
  editAssetMm3PresetInitialData,
  editAssetOldPresetInitialData,
} from 'components/asset/asset-edit-form/constants';
import { getAssetFormFields } from './utils';
import isEqual from 'lodash/isEqual';
import { getIsMm3Assets, getMainType, withAssetVersion } from 'utils/asset';
import { customValidator } from 'components/asset/asset-edit-form/custom-validator';

interface IAssetEditFormProps {
  asset?: IAsset | IMm3Asset;
  onDataChange: (object: IAsset) => void;
  formId?: ItemId;
  selectable?: boolean;
  setIsValid?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AssetMm3EditFormWrapper: React.FC<IAssetEditFormProps> = (props) => {
  const initialAssetFields = useMemo(
    () => ({ ...editAssetMm3PresetInitialData, ...props.asset } as IMm3Asset),
    [props.asset],
  );
  const model = (props.asset as IMm3Asset)?.$schema || '';
  const form = useMm3Form<IMm3Asset>(initialAssetFields, model, undefined, customValidator);
  return <AssetEditForm initialAssetFields={initialAssetFields} form={form} {...props} />;
};

export const AssetOldEditFormWrapper: React.FC<Omit<IAssetEditFormProps, 'asset'> & { asset: IAsset }> = (props) => {
  const initialAssetFields = getAssetFormFields({ ...editAssetOldPresetInitialData, ...(props.asset as IAsset) });
  const form = useForm<IAssetFormFields>(initialAssetFields, Model.ASSETS, undefined, assetFormCustomContext);

  return <AssetEditForm form={form} initialAssetFields={initialAssetFields} {...props} />;
};

const AssetEditForm: React.FC<
  IAssetEditFormProps & {
    form: IUseMm3FormReturn<IMm3Asset> | IUseFormReturn<IAssetFormFields>;
    initialAssetFields: IMm3Asset | IAssetFormFields;
  }
> = ({ asset, onDataChange, form, setIsValid, selectable = false, formId, initialAssetFields }) => {
  const isMm3Assets = getIsMm3Assets();

  const latestAssetFields = useRef(initialAssetFields);

  const { formData, handlers, valid, values } = form as IUseMm3FormReturn<IMm3Asset & { selected?: boolean }>;

  const latestValues = useRef(values);
  useEffect(() => setIsValid?.(valid), [setIsValid, valid]);

  useEffect(() => {
    if (isEqual(initialAssetFields, latestAssetFields.current)) {
      return;
    }

    latestAssetFields.current = initialAssetFields;
    handlers.onChange(initialAssetFields as IMm3Asset);
  }, [handlers, initialAssetFields]);

  useEffect(() => {
    if (isEqual(values, latestValues.current)) {
      return;
    }

    latestValues.current = values;
    onDataChange?.((isMm3Assets ? values : parseKeyPathToObject(values)) as IAsset);
  }, [values, valid, onDataChange, isMm3Assets]);

  return (
    <form id={`${formId}`} className="asset-edit-modal__form-container">
      <div className="d-flex align-items-center pt-3">
        {selectable && <FormCheckbox name="selected" {...formData.selected} {...handlers} />}
        <Thumbnail style={{ height: '200px', width: '61.5%' }} image={asset?.preview_image?.url} />
        <div className="p-2"> {asset?.name} </div>
      </div>

      <AssetSettingsSection formData={formData} formId={`${formId}`} handlers={handlers} />

      <AssetAboutSection
        formData={formData}
        formId={`${formId}`}
        handlers={handlers}
        values={values}
        assetType={getMainType(asset as IAsset | IMm3Asset)}
      />

      <AssetSpecificationsSection formData={formData} handlers={handlers} />

      <AssetCreditsSection formData={formData} handlers={handlers} />

      <AssetIdentifiersSection formData={formData} handlers={handlers} />
    </form>
  );
};

export default withAssetVersion(AssetOldEditFormWrapper, AssetMm3EditFormWrapper);
