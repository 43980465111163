import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { toNumber, map, find, isEmpty } from 'lodash';
import { Button, Tooltip } from '@mantine/core';
import { CalGenericLocation } from '@mediafellows/mm3-types';
import { CalendarApi } from '@fullcalendar/react';
import { zonedTimeToUtc, getTimezoneOffset } from 'date-fns-tz';
import { addMilliseconds, subMilliseconds } from 'date-fns';
import cx from 'classnames';

import {
  FormDate,
  FormInput,
  FormSelect,
  FormMultiSelect,
  useMm3Form,
  useFetchFieldOptions,
  parseObjectsToOptions,
  FormNumericInput,
  FormRemoteSelect,
  IRemoteSelectorFetcher,
} from 'helpers/form';
import { Row } from 'components/label-value-pair';
import { useStore } from 'store';
import { ToastError } from 'components/toast';
import { Model } from 'helpers/filters/types';
import { IContact, IOption, IQueryParams, ItemId } from 'types';
import { ICalendarInvite, ICalGenericMeeting } from 'types/calendar';
import { Classes, getContactName, MantineIcon } from 'utils/ui';
import { by } from 'utils/general';

import { fetchSchedulerHosts, getHostById, querySelections } from './api';
import {
  customMeetingValidator,
  getInviteesIds,
  getTotalAttendeesCount,
  localTimezone,
  MeetingTypes,
  validateNewInviteeItem,
} from './utils';
import { CreateInviteeElement } from './ui';
import { useSessionStore } from 'store/session-store';
import { validateEmail } from 'components/recommendation/recommendation-shared/utils';
import { searchLocations } from 'utils/apis/location';
import { parseDate15Minutes } from 'utils/date';
import { formatTimeZone } from 'helpers/form/fields/helpers';
import { flags } from 'utils/flags';
import { queryContacts } from 'utils/apis/contacts';
import { loadUserBaskets } from 'utils/apis/lists';
import { updateMeeting, createMeeting, getTimezones } from 'utils/apis/meeting';
import { queryRecipientContacts } from 'utils/apis/recipients';
import { autosuggestValues } from 'utils/autosuggest-values';

import './style.scss';

export interface IMeetingFormData extends ICalGenericMeeting {
  inviteIds: ItemId[];
  place?: string;
}

const dateToUtcString = (starts_at: Date): string => {
  return `${starts_at.getFullYear()}-${('0' + (starts_at.getMonth() + 1)).slice(-2)}-${(
    '0' + starts_at.getDate()
  ).slice(-2)}T${('0' + starts_at.getHours()).slice(-2)}:${('0' + starts_at.getMinutes()).slice(-2)}:00.000Z`;
};

const parseAndFormatLocations = (
  ...params: Parameters<typeof parseObjectsToOptions>
): ReturnType<typeof parseObjectsToOptions> =>
  parseObjectsToOptions(
    params[0].map((object) => {
      let name = object?.name;
      const capacity = object?.['capacity'];
      do {
        if (typeof name !== 'string') break;
        if (!Number.isInteger(capacity) || capacity <= 0) break;
        name = name.replace(/\([^)]*pax[^)]*\)/gi, '');
        return { ...object, name: `${name.trim()} (${capacity} PAX)` };
      } while (0);
      return object;
    }),
    params[1],
  );

export const getMeetingTags = async ({ ids, q }: IQueryParams): Promise<string[]> => {
  if (ids) return ids.map(toString);
  return autosuggestValues(Model.MEETINGS, 'meta.tags', q as string);
};

// FormDate selector converts date to local timezone
export const MeetingForm: React.FC<ICalGenericMeeting & { calendar: CalendarApi }> = (props) => {
  const {
    calendar,
    starts_at,
    ends_at,
    host_id,
    location_id,
    location,
    invites = [],
    mobile_sync_group_id,
    time_zone,
    id,
    ...rest
  } = props;
  const meetingsOptions = [
    { label: 'Regular', value: MeetingTypes.REGULAR },
    { label: 'Personal', value: MeetingTypes.PERSONAL },
  ];
  const place = rest.place ?? '';
  const timezone = useMemo(() => formatTimeZone(time_zone), [time_zone]);

  const [timezones, setTimezones] = useState<string[]>([]);
  const [locationFieldDisabled, setLocationFieldDisabled] = useState<boolean>(false);
  const [invitees, setInvitees] = useState<ICalendarInvite[]>(invites);
  const [inviteIds, setInviteIds] = useState<ItemId[]>(() => getInviteesIds(invites));
  const [currentLocation, setCurrentLocation] = useState<CalGenericLocation>(location);
  const [timeZone, setTimezone] = useState(timezone || localTimezone);
  const [forceUpdateInviteesKey, setForceUpdateInviteesKey] = useState(Math.random());
  const [hostLoading, setHostLoading] = useState(false);

  const { toastStore, dialogStore } = useStore();
  const session = useSessionStore((state) => state.session);
  const { role, user } = session || {};

  const mobileSelectionOptions = useFetchFieldOptions(querySelections);
  const preselectedHostId = role?.name === 'Sales Agent' ? user?.id : location?.host_ids?.[0]; // which one if many host_ids ?

  useEffect(() => {
    async function fetchData(): Promise<void> {
      if (isEmpty(timezones)) {
        const timezones = await getTimezones();
        setTimezones(['local', ...timezones]);
      }
    }
    fetchData();
  }, [timezones]);

  useEffect(() => {
    if (!location?.id || !location?.time_zone) {
      return;
    }

    setTimezone(location.time_zone);
    setCurrentLocation(location);
  }, [location_id, location]);

  const handleSubmit = useCallback(
    async ({ inviteIds: _, ...values }: IMeetingFormData): Promise<void> => {
      try {
        const submit = values.id ? updateMeeting : createMeeting;
        // FormDate returns the date based on timeZone
        const starts_at_utc = zonedTimeToUtc(values?.starts_at, timeZone).toISOString();
        const ends_at_utc = zonedTimeToUtc(values?.ends_at, timeZone).toISOString();
        values = {
          ...values,
          location_id: toNumber(values.location_id),
          starts_at: starts_at_utc,
          ends_at: ends_at_utc,
          invites: invitees,
        };

        await submit(values);
        const { ends_at, starts_at, title, description, host_id } = values;
        const locationId = values.location_id?.toString() || location_id?.toString();
        const sourceId = locationId && locationId !== '0' ? locationId?.toString() : host_id?.toString();

        // delay refetch since BE is not returning the newly created meeting immediately
        setTimeout(() => {
          calendar.addEvent({ start: starts_at, end: ends_at, title, description, resourceId: sourceId }, sourceId);
          calendar.refetchEvents();
        }, 1000);

        toastStore.success(`Meeting has been ${values.id ? 'updated' : 'created'} successfully!`);
        dialogStore.close();
      } catch (error) {
        toastStore.error(<ToastError error={error} />);
      }
    },
    [timeZone, invitees, location_id, toastStore, dialogStore, calendar],
  );

  const initialValues = {
    id,
    starts_at,
    ends_at,
    host_id: host_id || toNumber(preselectedHostId),
    location_id,
    invites,
    inviteIds,
    mobile_sync_group_id,
    timezone,
    time_zone: timeZone,
    ...rest,
    place,
  };
  const { handlers, formData, onSubmit, valid, values } = useMm3Form<IMeetingFormData>(
    initialValues,
    Model.MEETINGS,
    handleSubmit,
    customMeetingValidator,
  );

  const getLocationsWithStartEnd = useCallback(() => {
    const tzOffsetMs = getTimezoneOffset(timeZone);

    let starts_at: Date | string | null = parseDate15Minutes(values.starts_at);
    starts_at = starts_at ? subMilliseconds(starts_at, tzOffsetMs) : null;
    starts_at = starts_at ? dateToUtcString(starts_at) : null;

    let ends_at: Date | string | null = parseDate15Minutes(values.ends_at);
    ends_at = ends_at ? subMilliseconds(ends_at, tzOffsetMs) : null;
    ends_at = ends_at ? dateToUtcString(ends_at) : null;

    return searchLocations(starts_at || undefined, ends_at || undefined);
  }, [values.starts_at, values.ends_at, timeZone]);

  const onStartsAtChanged = (newValues: { starts_at: string }): void => {
    const old_starts_at = values.starts_at;
    const new_starts_at = newValues.starts_at;
    if (old_starts_at === new_starts_at) return;
    const new_starts_at_ms = new Date(new_starts_at).getTime();
    const old_starts_at_ms = new Date(old_starts_at).getTime();
    const ends_at_ms = new Date(values.ends_at).getTime();
    const diffMs = Math.abs(old_starts_at_ms - ends_at_ms);
    handlers.onChange({
      starts_at: new_starts_at,
      ends_at: new_starts_at_ms ? addMilliseconds(new_starts_at_ms, diffMs)?.toISOString() : new_starts_at,
    });
  };

  const locationsOptions = useFetchFieldOptions(
    getLocationsWithStartEnd,
    parseAndFormatLocations,
    true,
  ) as (CalGenericLocation & IOption)[];

  const onChange = useCallback(
    (newValues: { location_id: number }): void => {
      const location = find(locationsOptions, { id: toNumber(newValues?.location_id) });
      setTimezone(location?.time_zone || localTimezone);
      setCurrentLocation(location as CalGenericLocation);

      handlers.onChange({ ...newValues, host_id: undefined });
    },
    [locationsOptions, handlers],
  );

  useEffect(() => {
    if (values.location_id && !locationsOptions.some((loc) => loc.id == values.location_id)) {
      if (id) {
        setLocationFieldDisabled(true);
      } else if (locationsOptions?.length) {
        onChange({
          location_id: 0,
        });
      }
    }
  }, [id, locationsOptions, values.location_id, onChange]);

  const handleUserInvites = useCallback(
    async (props): Promise<void> => {
      const { inviteIds } = props;
      let mappedUsers: ICalendarInvite[] = [];
      const externalInvitees = inviteIds.filter(validateNewInviteeItem);
      const internalInvitees = inviteIds.filter(Number.isInteger);
      const invitesByEmail = by(invites, 'email');
      if (internalInvitees?.length) {
        const users = (await queryRecipientContacts({ ids: internalInvitees })) as IContact[];
        mappedUsers = map(users, (user) => {
          if (invitesByEmail[user.email]) {
            return invitesByEmail[user.email];
          }

          const id = toNumber(user.id);
          return {
            name: getContactName(user),
            user_id: id,
            email: user.email,
            attended: false,
            id,
          };
        });
      }
      if (externalInvitees?.length) {
        const externals = externalInvitees
          .map((name) => {
            const x = name.split(' ');
            const email = x.pop();
            if (!email) return;

            if (invitesByEmail[email]) {
              return invitesByEmail[email];
            }

            return {
              email,
              name: x.join(' '),
              attended: false,
            };
          })
          .filter((a) => a);

        mappedUsers = [...mappedUsers, ...externals];
      }

      setInviteIds(inviteIds);
      setInvitees(mappedUsers);
      handlers.onChange({ invites: mappedUsers, inviteIds });
    },
    [handlers, invites],
  );

  const fetchInvitees = useCallback(async ({ q, ids }) => {
    const externalIds = ids?.filter((e) => !Number.isInteger(e)) || [];
    const internalIds = ids?.filter((e) => Number.isInteger(e));
    const contacts = await queryContacts({ q, ids: internalIds });
    return [...contacts, ...externalIds];
  }, []);

  const fetchSchedulerHostsOverlay: IRemoteSelectorFetcher = useCallback(
    async (q, id) =>
      fetchSchedulerHosts({
        q,
        ids: id
          ? [id]
          : currentLocation?.host_ids?.length && values.type === MeetingTypes.REGULAR
          ? currentLocation?.host_ids
          : undefined,
      }),
    [currentLocation, values.type],
  );

  const fetchBaskets = useCallback(async () => {
    return loadUserBaskets(values.host_id);
  }, [values.host_id]);

  const basketOptions = useFetchFieldOptions(fetchBaskets);

  const [externalInvitee, setExternalInvitee] = useState({
    email: '',
    name: '',
    invalid: false,
    canAdd: false,
  });

  const addExternalInvitee = useCallback(async () => {
    const email = externalInvitee.email;

    if (![...inviteIds, ...invitees].find((invite) => JSON.stringify(invite).includes(email))) {
      const existingUser = (await queryContacts({}, [['email', 'eq', email]]))?.[0];

      if (existingUser) {
        setInviteIds([...inviteIds, existingUser.id]);
        setInvitees([...invitees, existingUser]);
        handleUserInvites({ inviteIds: [...inviteIds, existingUser.id] });
      } else {
        setInviteIds([...inviteIds, [externalInvitee.name, email].filter((a) => a).join(' ')]);
        handleUserInvites({ inviteIds: [...inviteIds, [externalInvitee.name, email].filter((a) => a).join(' ')] });
      }
      setForceUpdateInviteesKey(Math.random());
    }

    setExternalInvitee({
      email: '',
      invalid: false,
      canAdd: false,
      name: '',
    });
  }, [externalInvitee, inviteIds, invitees, handleUserInvites]);

  const updateExternalInvitee = useCallback(
    (value: { [key: string]: string }) => {
      const newState = { ...externalInvitee, ...value };
      const { email } = newState;
      if (email?.trim()?.length) {
        newState.canAdd = validateEmail(email);
        newState.invalid = !newState.canAdd;
      } else {
        newState.canAdd = false;
        newState.invalid = false;
      }
      setExternalInvitee(newState);
    },
    [externalInvitee],
  );

  useEffect(() => {
    const currentUserId = toNumber(user?.id);

    if (values.type === MeetingTypes.REGULAR) {
      handlers.onChange({ host_id: undefined });
      return;
    }

    const checkAndSetHost = async (): Promise<void> => {
      setHostLoading(true);
      const isCurrentUserHost = await getHostById(currentUserId);

      if (values.type === MeetingTypes.PERSONAL) {
        handlers.onChange({
          host_id: isCurrentUserHost ? currentUserId : undefined,
          location_id: undefined,
        });
      }

      setHostLoading(false);
    };

    checkAndSetHost();
  }, [values.type, user?.id, handlers]);

  const hostsLabel = useMemo(
    () => (
      <Tooltip
        multiline
        w={300}
        label={`To add or remove Hosts allowed across Meetings, please use the 'Manage Hosts' button under Meetings >
            Locations. A specific Location's eligible Hosts can be controlled in the Location's Edit form.`}
      >
        <div className="info-sign_container">
          Host* <MantineIcon icon="info-sign" size={11} />
        </div>
      </Tooltip>
    ),
    [],
  );

  return (
    <>
      <div>
        <FormInput className="title-field" label="Title" name="title" {...handlers} {...formData.title} />

        <FormDate
          quarters={true}
          label="Start"
          name="starts_at"
          {...handlers}
          {...formData.starts_at}
          withTime
          onChange={onStartsAtChanged}
          timeZone={timeZone}
        />

        <FormDate
          quarters={true}
          label="End"
          name="ends_at"
          {...handlers}
          {...formData.ends_at}
          withTime
          timeZone={timeZone}
        />

        <FormSelect
          label="Meeting Type"
          name="type"
          {...formData.type}
          options={meetingsOptions}
          onChange={onChange}
          emptyValue={null}
          disabled={!!values.id}
        />

        {values.type === MeetingTypes.REGULAR ? (
          <FormSelect
            label="Market location"
            name="location_id"
            options={locationFieldDisabled ? parseAndFormatLocations([location]) : locationsOptions}
            {...formData.location_id}
            onBlur={handlers.onBlur}
            onChange={onChange}
            disabled={locationFieldDisabled}
            emptyValue={null}
          />
        ) : (
          <FormInput label="Location" name="place" {...formData.place} {...handlers} />
        )}

        {values?.type === MeetingTypes.REGULAR ? (
          <span className="bp3-form-group location-timezone">Timezone: {timeZone}</span>
        ) : (
          <FormSelect
            label="Timezone"
            placeholder="Select Timezone"
            name="time_zone"
            value={timeZone}
            options={timezones}
            onChange={({ time_zone }) => {
              setTimezone(String(time_zone));
              handlers.onChange({ time_zone: String(time_zone) });
            }}
            required
            clearable={false}
          />
        )}

        <Row
          label={hostsLabel}
          value={
            <FormRemoteSelect
              name="host_id"
              fetchOptions={fetchSchedulerHostsOverlay}
              placeholder={hostLoading ? 'Loading Hosts...' : 'Select Hosts'}
              disabled={!currentLocation && values.type === MeetingTypes.REGULAR}
              {...formData.host_id}
              {...handlers}
              large
            />
          }
          size="m"
        />

        <div className=""></div>

        <FormMultiSelect
          key={forceUpdateInviteesKey}
          {...handlers}
          {...formData.inviteIds}
          label="Invitees"
          name="inviteIds"
          value={inviteIds}
          fetchValues={fetchInvitees}
          onChange={handleUserInvites}
          allowNewItems
          validateNewItem={validateNewInviteeItem}
          createTagElement={CreateInviteeElement}
        />

        <div className="calendar-add-external-invitee">
          <FormInput
            placeholder="Type Email*"
            label="Add external invitees"
            name="email"
            touched={true}
            validation={{ valid: !externalInvitee.invalid, errorMessage: 'Please enter a valid email address' }}
            value={externalInvitee.email}
            onChange={updateExternalInvitee}
          />

          <FormInput
            placeholder="Type Name"
            name="name"
            value={externalInvitee.name}
            onChange={updateExternalInvitee}
          />

          <Button
            variant="primary"
            type="submit"
            disabled={!externalInvitee.canAdd}
            onClick={addExternalInvitee}
            size="xs"
          >
            Add
          </Button>
        </div>

        <FormNumericInput
          label="No. of additional attendees"
          name="additional_seats"
          {...handlers}
          {...formData.additional_seats}
        />
        <span className="bp3-form-group location-timezone">
          Total number of attendees: {getTotalAttendeesCount(values)}
        </span>

        {flags.showMobileSelectionsFeature && (
          <FormSelect
            label="Mobile Selection"
            name="mobile_sync_group_id"
            options={mobileSelectionOptions}
            {...formData.mobile_sync_group_id}
            {...handlers}
            emptyValue={null}
          />
        )}

        {flags.showBasketsFeature && (
          <FormSelect
            label="Basket"
            name="basket_id"
            {...formData.basket_id}
            {...handlers}
            options={basketOptions}
            emptyValue={null}
            disabled={!values.host_id}
          />
        )}

        <FormInput label="Description" name="description" textarea {...handlers} {...formData.description} />

        {values?.type === MeetingTypes.PERSONAL && (
          <FormMultiSelect
            name="meta.tags"
            label="Tags"
            placeholder="Add Tags"
            {...formData.meta?.tags}
            {...handlers}
            allowNewItems
            fetchValues={getMeetingTags}
            hideClearIfEmptySelect
          />
        )}
      </div>
      <div className={cx(Classes.DIALOG_FOOTER_ACTIONS, 'modal-buttons')}>
        <Button
          size="xs"
          variant="primary"
          type="submit"
          disabled={!valid || externalInvitee.invalid}
          onClick={onSubmit}
        >
          {id ? 'Update' : 'Create'}
        </Button>
      </div>
    </>
  );
};
