import { addYears, endOfYear, subYears, startOfYear, startOfDay } from 'date-fns';
import { today } from './date';

export const minAccessDate = startOfDay(today);
export const maxAccessDate = endOfYear(addYears(new Date(today.getFullYear(), 11, 31), 10));
export const minBirthDate = startOfYear(subYears(new Date(), 100));
export const maxBirthDate = endOfYear(subYears(new Date(today.getFullYear(), 11, 31), 18));
export const maxDateFiveYears = endOfYear(addYears(new Date(today.getFullYear(), 11, 31), 5));
export const refreshPreviewIngestingDelay = 20 * 1000;

export const FILTER_SECTIONS = {
  ESSENTIALS: 'essentials',
  ACCESS_OWNERSHIP: 'access & ownership',
  SETTINGS: 'settings',
  TIMESTAMPS: 'timestamps',
  ACCREDITATION: 'accreditation',
  ADVANCED: 'advanced',
  CREDITS: 'credits',
} as const;
