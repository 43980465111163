import { map, uniq, chunk } from 'lodash';

import { chipmunk } from 'utils/chipmunk';
import { assetListSchema, productAssetSchema } from 'utils/schemas';
import { queryAllAssets } from 'utils/apis/asset';
import { IAsset, ItemId, IProductAsset, ISearchFilter, IMm3Asset } from 'types';
import { Model, ParametronOrder, ParametronSort } from 'helpers/filters/types';
import { selectAssetHelper } from 'utils/asset';

export async function getProductOldAssets(productId: string, schema = productAssetSchema): Promise<IProductAsset[]> {
  const { objects } = await chipmunk.unfurl(Model.PRODUCT_ASSET, 'query', {
    params: { product_ids: productId, sort: 'sequence_number', order: 'asc' },
    schema,
  });

  // remove assets that user do not have access to
  return objects.filter(({ asset }) => asset?.id) as IProductAsset[];
}

export async function getProductMm3Assets(productId: string, schema = productAssetSchema): Promise<IProductAsset[]> {
  return queryProductMm3Assets({ product_ids: productId }, true, schema);
}

export const getProductAssets = selectAssetHelper(getProductOldAssets, getProductMm3Assets);

export async function queryProductOldAssets(
  params: {
    product_ids?: ItemId | ItemId[];
    asset_id?: ItemId;
    marketing_use?: boolean;
    per?: number;
    page?: number;
    sort?: ParametronSort;
    order?: ParametronOrder;
  },
  allItems = true,
  schema = productAssetSchema,
): Promise<IProductAsset[]> {
  return chipmunk.run(async (cp) => {
    const { objects } = await cp[allItems ? 'unfurl' : 'action']<IProductAsset>(Model.PRODUCT_ASSET, 'query', {
      params,
      schema,
    });

    return objects;
  });
}

export async function queryProductMm3Assets(
  params: {
    product_ids?: ItemId | ItemId[];
    asset_id?: ItemId;
    marketing_use?: boolean;
    per?: number;
    page?: number;
    sort?: ParametronSort;
    order?: ParametronOrder;
  },
  allItems = true,
  schema = productAssetSchema,
): Promise<IProductAsset[]> {
  return chipmunk.run(async (cp) => {
    let { objects } = await cp[allItems ? 'unfurl' : 'action']<IProductAsset>(Model.PRODUCT_ASSET, 'query', {
      params,
      schema,
    });

    // temporary implementation
    objects = objects.filter(({ asset }) => (asset as IMm3Asset)?.root);

    return objects;
  });
}

export const queryProductAssets = selectAssetHelper(queryProductOldAssets, queryProductMm3Assets);

export const unassignProductAsset = (assets: { asset_id: ItemId; product_id: ItemId }[]): Promise<IProductAsset[]> => {
  return chipmunk.run(async ({ action }) => {
    const { objects } = await action(Model.PRODUCT_ASSET, 'unassign', {
      body: { assets },
    });

    return objects;
  });
};

export const setMarketingAsset = (id: ItemId, marketing_use: boolean): Promise<IProductAsset> => {
  return chipmunk.run(async ({ action }) => {
    const { object } = await action(Model.PRODUCT_ASSET, 'member.update', {
      params: { asset_ids: id },
      body: { marketing_use, id: id },
      schema: `id, asset_id, marketing_use, sequence_number, asset { ${assetListSchema} }`,
    });

    return object;
  });
};

export const fetchProductAssets = async (productId: string): Promise<IAsset[]> => {
  const { objects: productAssets } = await chipmunk.unfurl(Model.PRODUCT_ASSET, 'query', {
    params: { product_ids: ~~productId, sort: 'sequence_number', marketing_use: true },
    schema: productAssetSchema,
  });

  return (productAssets as IProductAsset[]).reduce((acc: IAsset[], productAsset: IProductAsset) => {
    if (productAsset?.asset?.id) {
      acc.push({ ...productAsset.asset, product_asset: productAsset } as IAsset);
    }
    return acc;
  }, [] as IAsset[]);
};

export const saveOrder = async (assets: IAsset[]): Promise<IProductAsset[]> => {
  return chipmunk.run(async ({ action }) => {
    const { objects } = await action(Model.PRODUCT_ASSET, 'update', {
      params: { asset_ids: assets.map((asset) => asset?.product_asset?.id) },
      body: assets.map((asset, sequence_number) => ({
        id: asset.product_asset?.id,
        sequence_number,
      })),
      schema: productAssetSchema,
      multi: true,
    });

    return (objects as IProductAsset[]).sort((a, b) => a.sequence_number - b.sequence_number);
  });
};

/**
 * @todo delete product_id and keep only product_ids
 */

const formatVideosOfProducts = (
  assets: IAsset[],
  productAssets: IProductAsset[],
): (IAsset & { product_ids?: number[] })[] => {
  const uniqueAssetsMap = new Map<number, IAsset & { product_ids?: number[] }>();
  return productAssets
    .reduce((acc, productAsset) => {
      const asset = assets.find(({ id }) => String(id) === String(productAsset.asset_id));

      if (asset) {
        const existingAsset = uniqueAssetsMap.get(Number(asset.id));

        if (!existingAsset) {
          const formattedAsset = {
            ...asset,
            product_id: productAsset.product_id,
            product_ids: uniq(
              productAssets.reduce(
                (ids, { asset_id, product_id }) => (String(asset_id) === String(asset.id) ? [...ids, product_id] : ids),
                [],
              ),
            ),
          };
          uniqueAssetsMap.set(Number(asset.id), formattedAsset);
        }
      }
      return acc;
    }, [] as (IAsset & { product_ids?: number[] })[])
    .concat(Array.from(uniqueAssetsMap.values()));
};

export const queryClassificationAssetsOfProducts = async (
  product_ids: ItemId | ItemId[],
  marketingUseOnly: boolean,
  availableOnly = true,
  asset_type = '*video*',
  assetFilters: ISearchFilter[] = [],
): Promise<(IAsset & { product_id?: number; product_ids?: number[] })[]> => {
  if (!product_ids || (Array.isArray(product_ids) && product_ids.length === 0)) {
    return [];
  }
  return chipmunk.run(async ({ unfurl }) => {
    const ids = Array.isArray(product_ids) ? product_ids : [product_ids];
    const chunks = chunk(ids, 80);
    const promises = chunks.map(async (productIds) => {
      const params = {
        product_ids: productIds,
        asset_type,
        ...(marketingUseOnly ? { marketing_use: 'true' } : {}),
        sort: 'sequence_number',
      };
      const { objects } = await unfurl(Model.PRODUCT_ASSET, 'query', {
        params,
      });

      return [...objects];
    });
    const productAssets = (await Promise.all(promises)).flat();

    const assetIds = uniq(map(productAssets, 'asset_id'));

    let assets: IAsset[] = [];
    if (assetIds.length) {
      assets = await queryAllAssets({ ids: assetIds }, [
        ...assetFilters,
        ...(availableOnly ? [['status', 'eq', 'available']] : []),
      ] as ISearchFilter[]);
    }
    return formatVideosOfProducts(assets, productAssets as IProductAsset[]);
  });
};

export const queryAssetsOfProducts = async (
  product_ids: ItemId | ItemId[],
  marketingUseOnly: boolean,
  availableOnly = true,
): Promise<(IAsset & { product_id?: number; product_ids?: number[] })[]> => {
  if (!product_ids || (Array.isArray(product_ids) && product_ids.length === 0)) {
    return [];
  }
  return chipmunk.run(async ({ unfurl }) => {
    const ids = Array.isArray(product_ids) ? product_ids : [product_ids];
    const chunks = chunk(ids, 80);
    const promises = chunks.map(async (productIds) => {
      const params = {
        product_ids: productIds,
        ...(marketingUseOnly ? { marketing_use: 'true' } : {}),
      };
      const { objects } = await unfurl(Model.PRODUCT_ASSET, 'query', {
        params,
      });
      return objects;
    });
    const productAssets = (await Promise.all(promises)).flat();

    const assetIds = uniq(map(productAssets, 'asset_id'));

    let assets: IAsset[] = [];
    if (assetIds.length) {
      assets = await queryAllAssets({ ids: assetIds }, availableOnly ? [['status', 'eq', 'available']] : []);
    }
    return formatVideosOfProducts(assets, productAssets as IProductAsset[]);
  });
};
