import React, { useRef, useState, useEffect } from 'react';
import cx from 'classnames';
import startCase from 'lodash/startCase';
import { Link } from 'react-router-dom';

import { IStyled } from 'types';
import { Loading } from 'components/loading';
import { formatFormLabel } from 'helpers/form';
import './style.scss';

export interface ILabelValuePairProps extends IStyled {
  label: string | React.ReactNode;
  value?: React.ReactNode;
  fallback?: string;
  hideIfNoValue?: boolean;
  size?: string;
  html?: boolean;
  labelClassName?: string;
  valueClassName?: string;
  rawLabel?: boolean;
  rawValue?: boolean;
  /**
   * Used to separate items in case when a list given
   */
  separator?: string;
  loading?: boolean;
  hidden?: boolean;
  link?: string;
  showAsterisk?: boolean;
}

/**
 * Yes/No helper, gets boolean value and returns Yes or No string
 */
export const yn = (value?: unknown): string => (Boolean(value) ? 'Yes' : 'No');

export const LabelValuePair: React.FC<ILabelValuePairProps> = ({
  label,
  value,
  size,
  fallback = '—',
  separator = ', ',
  className,
  hideIfNoValue,
  html = false,
  labelClassName,
  valueClassName,
  rawLabel = false,
  loading = false,
  hidden = false,
  showAsterisk = false,
  link,
  // rawValue = false,
}) => {
  const labelRef = useRef<HTMLDivElement>(null);
  const [isLabelMultilined, setIsLabelMultiline] = useState(false);

  const measureLabel = (): void => {
    const labelEl = labelRef.current;
    if (!labelEl) return;

    const style = window.getComputedStyle(labelEl);
    const lineHeight = parseFloat(style.lineHeight);
    const labelHeight = labelEl.offsetHeight;

    const numberOfLines = Math.round(labelHeight / lineHeight);
    const multiline = numberOfLines > 1;

    setIsLabelMultiline(multiline);
  };

  useEffect(() => {
    measureLabel();

    window.addEventListener('resize', measureLabel);

    return () => {
      window.removeEventListener('resize', measureLabel);
    };
  }, [label]);

  if (hidden || (hideIfNoValue && !value && value !== 0)) {
    return <></>;
  }

  const isValidArray = Array.isArray(value) && value.length > 0;

  const sizeClass = size ? `screen-${size}` : '';

  const isValueGoodEnough = value || value === 0 || isValidArray;
  const simpleValue = isValueGoodEnough ? (Array.isArray(value) ? value.join(separator) || fallback : value) : fallback;
  const htmlValue = html && isValueGoodEnough ? { __html: `${value}` } : null;

  const pairLabel = rawLabel || typeof label !== 'string' ? label : startCase(label);
  const formattedLaabel = typeof label === 'string' ? formatFormLabel(pairLabel as string, showAsterisk) : label;

  return (
    <>
      <div
        className={cx('muted-text', sizeClass, labelClassName, { 'label-multilined': isLabelMultilined })}
        ref={labelRef}
      >
        {formattedLaabel}
      </div>
      {loading ? (
        <div className="w-25">
          <Loading />
        </div>
      ) : (
        <></>
      )}
      {!loading && htmlValue && (
        <div className={cx(sizeClass, className, valueClassName)} dangerouslySetInnerHTML={htmlValue}></div>
      )}

      {!loading && !htmlValue && !link && (
        <div className={cx(sizeClass, className, valueClassName, { 'value-container': isLabelMultilined })}>
          {simpleValue}
        </div>
      )}
      {!loading && !htmlValue && link && (
        <Link to={link} className={cx('muted', sizeClass, className, valueClassName)}>
          {simpleValue}
        </Link>
      )}
    </>
  );
};

export default LabelValuePair;
