import React, { useCallback } from 'react';
import { isEqual } from 'lodash';

import { IScrollableTab } from 'components/scrollable-tabs/scrollable-tabs';
import { AssetGeneralTab } from './asset-general-tab';
import { AssetAnalyticsTab } from './asset-analytics-tab';
import { AssetProductsTab } from './asset-products-tab';
import { AssetTimelineTab } from './asset-timeline-tab';
import { AssetWorkflowTab } from './asset-workflow-tab';
import { AssetOperationsTab } from './asset-operation-tab/asset-operation-tab';
import { AssetArtifactsTab } from './asset-artifacts-tab';

import { getIsMm3Assets, isAssetIngesting } from 'utils/asset';
import { refreshPreviewIngestingDelay } from 'utils/constants';
import { useInterval } from 'utils/hooks/use-interval';
import { getIsTabDisabledWithPowers } from 'utils/general';
import { flags } from 'utils/flags';
import { getAssetMetadataTab } from 'pages/asset-details/get-asset-metadata-tab';

import { IUseFormReturn } from 'helpers/form';
import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';
import { IAssetFormFields } from 'components/asset/asset-edit-form/sections/types';
import { DetailsPageTabs, IAsset, IMm3Asset, IMm3AssetType } from 'types';
import { AmGenericAsset3ProtectionOptions } from '@mediafellows/mm3-types';
import { AssetProtectionsTab } from './asset-protections/asset-protections';
import { amAssetProtectionEdit, amAssetProtectionRead } from 'utils/powers';

export const useRefreshIngestingAsset = (
  asset: IAsset | IMm3Asset | undefined,
  fetchAsset: () => Promise<IAsset | IMm3Asset>,
  setAsset: React.Dispatch<React.SetStateAction<IAsset | IMm3Asset>>,
): void => {
  const refresh = useCallback(
    async (id: number): Promise<void> => {
      if (!asset) return;
      if (!isAssetIngesting(asset)) {
        return clearInterval(id);
      }
      const newValue = await fetchAsset();
      setAsset((prev) => {
        return isEqual(prev, newValue) ? prev : newValue;
      });
    },
    [fetchAsset, setAsset, asset],
  );

  useInterval(refresh, refreshPreviewIngestingDelay);
};

const tabsThatRequirePowers = flags.showVideoProtectionsFeature
  ? [{ tab: DetailsPageTabs.PROTECTIONS, requiredPower: amAssetProtectionRead }]
  : [];

export const tabOptions = [
  DetailsPageTabs.OVERVIEW,
  DetailsPageTabs.METADATA,
  DetailsPageTabs.ANALYTICS,
  DetailsPageTabs.SUB_ASSETS,
  DetailsPageTabs.PRODUCTS,
  DetailsPageTabs.TIMELINE,
  DetailsPageTabs.WORKFLOW,
  DetailsPageTabs.NEWS,
  DetailsPageTabs.EXPERIENCES,
  DetailsPageTabs.SUBTITLES,
  DetailsPageTabs.REPORTS,
  DetailsPageTabs.ARTIFACTS,
  DetailsPageTabs.AUDIO_TRACKS,
  DetailsPageTabs.OPERATIONS,
  ...tabsThatRequirePowers,
];

export const editableTabsWithPowers = [
  { tabName: DetailsPageTabs.OVERVIEW },
  { tabName: DetailsPageTabs.METADATA },
  { tabName: DetailsPageTabs.NEWS },
  { tabName: DetailsPageTabs.PROTECTIONS, requiredPower: amAssetProtectionEdit },
];

const tabToRequiredPower = tabsThatRequirePowers.reduce((acc, { tab, requiredPower }) => {
  acc[tab] = requiredPower;
  return acc;
}, {});

export const getTabsList = ({
  asset,
  editModeEnabled,
  handleTabChange,
  setAsset,
  editForm,
  isVideo,
  getChildTab,
  protections,
  protectionsForm,
}: {
  asset: IAsset | IMm3Asset;
  editModeEnabled: boolean;
  handleTabChange: (tab: string) => void;
  setAsset: React.Dispatch<React.SetStateAction<IAsset>>;
  editForm: IUseFormReturn<IAssetFormFields> | IUseMm3FormReturn<IMm3Asset>;
  isVideo: boolean;
  getChildTab: (tab: DetailsPageTabs) => JSX.Element;
  protections?: AmGenericAsset3ProtectionOptions;
  protectionsForm: IUseMm3FormReturn<AmGenericAsset3ProtectionOptions | undefined>;
}): IScrollableTab[] => {
  const isImf = (asset as IMm3Asset)?.type === IMm3AssetType.IMF;
  const isMm3Assets = getIsMm3Assets();

  return [
    {
      id: DetailsPageTabs.OVERVIEW,
      panel: (
        <AssetGeneralTab
          asset={asset}
          setActiveTab={handleTabChange}
          setAsset={setAsset}
          form={editForm}
          editModeEnabled={editModeEnabled}
        />
      ),
      isVisible: true,
      disabled: getIsTabDisabledWithPowers(DetailsPageTabs.OVERVIEW, editableTabsWithPowers, editModeEnabled),
    },
    {
      id: DetailsPageTabs.ANALYTICS,
      panel: <AssetAnalyticsTab />,
      isVisible: isVideo,
      disabled: getIsTabDisabledWithPowers(DetailsPageTabs.ANALYTICS, editableTabsWithPowers, editModeEnabled),
    },
    getAssetMetadataTab({ asset, editModeEnabled, editForm, isMm3Assets }),
    {
      id: DetailsPageTabs.EXPERIENCES,
      panel: getChildTab(DetailsPageTabs.EXPERIENCES),
      isVisible: isImf,
      disabled: getIsTabDisabledWithPowers(DetailsPageTabs.EXPERIENCES, editableTabsWithPowers, editModeEnabled),
    },
    {
      id: DetailsPageTabs.SUBTITLES,
      panel: getChildTab(DetailsPageTabs.SUBTITLES),
      isVisible: isVideo || isImf,
      disabled: getIsTabDisabledWithPowers(DetailsPageTabs.SUBTITLES, editableTabsWithPowers, editModeEnabled),
    },
    {
      id: DetailsPageTabs.AUDIO_TRACKS,
      panel: getChildTab(DetailsPageTabs.AUDIO_TRACKS),
      isVisible: isVideo || isImf,
      disabled: getIsTabDisabledWithPowers(DetailsPageTabs.AUDIO_TRACKS, editableTabsWithPowers, editModeEnabled),
      formatTitle: () => 'Audios',
    },
    {
      id: DetailsPageTabs.REPORTS,
      panel: getChildTab(DetailsPageTabs.REPORTS),
      isVisible: isMm3Assets && !flags.hasCustomClassifications,
      disabled: getIsTabDisabledWithPowers(DetailsPageTabs.REPORTS, editableTabsWithPowers, editModeEnabled),
      formatTitle: () => 'Reports',
    },
    {
      id: DetailsPageTabs.SUB_ASSETS,
      panel: getChildTab(DetailsPageTabs.SUB_ASSETS),
      isVisible: isMm3Assets,
      disabled: getIsTabDisabledWithPowers(DetailsPageTabs.SUB_ASSETS, editableTabsWithPowers, editModeEnabled),
      formatTitle: () => 'Ancillary Assets',
    },
    {
      id: DetailsPageTabs.ARTIFACTS,
      panel: <AssetArtifactsTab asset={asset as IAsset} />,
      isVisible: isVideo,
      disabled: getIsTabDisabledWithPowers(DetailsPageTabs.ARTIFACTS, editableTabsWithPowers, editModeEnabled),
    },
    {
      id: DetailsPageTabs.PROTECTIONS,
      panel: (
        <AssetProtectionsTab
          form={protectionsForm}
          protections={protections}
          asset={asset as IMm3Asset}
          editModeEnabled={editModeEnabled}
        />
      ),
      isVisible: isMm3Assets && flags.showVideoProtectionsFeature && isVideo,
      disabled: getIsTabDisabledWithPowers(DetailsPageTabs.PROTECTIONS, editableTabsWithPowers, editModeEnabled),
    },
    {
      id: DetailsPageTabs.PRODUCTS,
      panel: <AssetProductsTab />,
      isVisible: true,
      disabled: getIsTabDisabledWithPowers(DetailsPageTabs.PRODUCTS, editableTabsWithPowers, editModeEnabled),
    },
    {
      id: DetailsPageTabs.TIMELINE,
      panel: <AssetTimelineTab />,
      isVisible: true,
      disabled: getIsTabDisabledWithPowers(DetailsPageTabs.TIMELINE, editableTabsWithPowers, editModeEnabled),
    },
    {
      id: DetailsPageTabs.WORKFLOW,
      panel: <AssetWorkflowTab asset={asset as IAsset} />,
      isVisible: !isMm3Assets,
      disabled: getIsTabDisabledWithPowers(DetailsPageTabs.WORKFLOW, editableTabsWithPowers, editModeEnabled),
    },
    {
      id: DetailsPageTabs.OPERATIONS,
      panel: <AssetOperationsTab asset={asset as IMm3Asset} />,
      isVisible: isMm3Assets,
      disabled: getIsTabDisabledWithPowers(DetailsPageTabs.OPERATIONS, editableTabsWithPowers, editModeEnabled),
    },
  ].map((currentTab) => ({ ...currentTab, requiredPower: tabToRequiredPower[currentTab.id] }));
};
