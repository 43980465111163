import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import SectionMessage, { SectionMessageType } from 'components/section-message/section-message';

import { Loading } from 'components/loading';
import { BodyWrapper } from 'components/layout';

import { useRemote } from 'utils/hooks';
import { loadLocalizationOrder } from 'utils/apis/localization-order';
import { Pages } from 'utils/actions';

import { OrderDetails } from './order-details';
import { IOrder } from './utils';

import './style.scss';

export const OrderDetailsContainer: React.FC<{ page?: Pages }> = observer(({ page = Pages.ORDER_DETAILS }) => {
  const { localizationOrderId: orderId } = useParams<{ localizationOrderId: string }>();

  const fetchOrder = useCallback(async () => loadLocalizationOrder(orderId as string), [orderId]);
  const [order, loading, setOrder] = useRemote(fetchOrder);

  if (loading) {
    return (
      <BodyWrapper>
        <Loading text="Loading Order" />
      </BodyWrapper>
    );
  }

  if (!order) {
    return (
      <BodyWrapper>
        <SectionMessage intent={SectionMessageType.EMPTY}>No Localization Order found</SectionMessage>
      </BodyWrapper>
    );
  }

  return <OrderDetails order={order as IOrder} setOrder={setOrder} page={page} />;
});
