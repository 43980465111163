import React, { useCallback } from 'react';

import { useStore } from 'store';
import { IUseActionsOptionParam } from 'utils/actions/types';

import { ConnectionContainer } from 'components/delivery/connections/connection-container';
import { IConnectionActionName, IConnectionActionConfig } from 'utils/actions/connection/types';
import { IDeliveryConnection } from 'components/delivery/connections/types';
import { IModalSize } from 'components/dialogs/types';

export const useEditConnectionAction = (
  connection: IDeliveryConnection,
  options: IUseActionsOptionParam,
): IConnectionActionConfig => {
  const { dialogStore } = useStore();

  const handleFinish = useCallback(async () => {
    await options?.onSuccess?.();

    dialogStore.close();
  }, [options, dialogStore]);

  const body = useCallback((): JSX.Element => {
    return (
      <ConnectionContainer
        connection={connection}
        organizationId={connection.organization_id}
        onFinish={handleFinish}
      />
    );
  }, [connection, handleFinish]);

  const handler = (): void => {
    dialogStore.openModal({
      title: 'Edit Connection',
      body,
      size: IModalSize.XS,
    });
  };

  return {
    name: IConnectionActionName.EDIT,
    icon: 'edit',
    title: 'Edit Connection',
    handler,
  };
};
