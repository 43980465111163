import React from 'react';
import { observer } from 'mobx-react-lite';
import { IObservableArray } from 'mobx';

import { FilterCheckbox, FilterSelect, FilterTree } from 'helpers/filters/fields';
import { IFiltersHandlers, organizationOptionsProvider, ownersOptionsProvider } from 'helpers/filters';
import { Mm3ContextDataProvider, dynamicDataExecutorCache } from 'helpers/data-provider/option-data-provider';
import { DynamicTreeDataProvider, unflattenTree } from 'helpers/data-provider/tree-data-provider';
import { Model } from 'helpers/filters/types';

import { IFilters } from 'components/product-filters/types';
import { IResult, chipmunk } from 'utils/chipmunk';
import { IDivision, IProduct } from 'types';
import { DataSectionStore } from 'store/data-section-store';

interface IAccessFiltersProps {
  filterValues: IFilters;
  filterHandlers: IFiltersHandlers<IFilters>;
  divisions: IObservableArray<IDivision>;
  dataSectionStore: DataSectionStore<IProduct>;
}

const accessOptionsProvider = new Mm3ContextDataProvider(Model.PRODUCTS, 'access_level');
const divisionTreeProvider = new DynamicTreeDataProvider(
  dynamicDataExecutorCache('divisions', () => chipmunk.run(({ action }) => action(Model.DIVISIONS, 'query'))),
  (data: IResult<IDivision>) =>
    unflattenTree(data.objects.map((d) => ({ label: d.name, value: d.id, ancestry: d.ancestry || '' }))),
);

export const ProductAccessAndOwnershipFilters = observer(
  ({ filterValues, filterHandlers, divisions, dataSectionStore }: IAccessFiltersProps) => {
    const { searchStore } = dataSectionStore;

    return (
      <>
        <FilterCheckbox
          label="Access Level"
          name="access_level"
          optionsProvider={accessOptionsProvider}
          filter={filterValues.access_level}
          onChange={filterHandlers.onChange}
          disabled={searchStore.running}
          aggregations={searchStore.aggregationValues('access_level')}
        />
        <FilterSelect
          label="Owner"
          name="owner_id"
          placeholder="Select Owner"
          optionsProvider={ownersOptionsProvider}
          filter={filterValues.owner_id}
          onChange={filterHandlers.onChange}
        />
        {!!divisions.length && (
          <FilterTree
            label="Divisions"
            name="division_ids"
            optionsProvider={divisionTreeProvider}
            filter={filterValues.division_ids}
            onChange={filterHandlers.onChange}
            aggregations={searchStore.aggregationValues('division_ids')}
          />
        )}
        <FilterSelect
          label="Organization"
          name="owner_organization_id"
          placeholder="Select Organization"
          optionsProvider={organizationOptionsProvider}
          filter={filterValues.owner_organization_id}
          onChange={filterHandlers.onChange}
        />
      </>
    );
  },
);
