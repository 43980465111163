import React from 'react';
import { Tabs } from '@mantine/core';
import cx from 'classnames';

import { DetailsPageTabs, ProductAccessTabSubTabs } from 'types';
import { hasPower } from 'utils/powers';

const TabPanel: React.FC<{
  value: DetailsPageTabs | ProductAccessTabSubTabs;
  content: JSX.Element;
  className?: string;
  requiredPower?: string;
}> = ({ value, content, className, requiredPower }) => {
  if (requiredPower && !hasPower(requiredPower)) {
    return <></>;
  }

  return (
    <Tabs.Panel value={value} className={cx(className)}>
      {content}
    </Tabs.Panel>
  );
};

export default TabPanel;
