import React, { useCallback } from 'react';
import { FormNumericInput, FormRemoteSelect, IFormRemoteSelectOption, useMm3Form } from 'helpers/form';
import { IFormSubmitHandler } from 'helpers/form/use-mm3-form';
import { ICustomItemRendererProps, IProduct } from 'types';
import { Model } from 'helpers/filters/types';
import { useSearchForPotentialParents } from 'utils/hooks/product';
import { FormSubmitSection } from 'components/form-submit-section';
import { customValidator } from 'pages/product-create/utils';
import { getProductSearchModifiers } from 'utils/product';

interface IProductEditAboutSectionProps {
  handleSubmit: IFormSubmitHandler<IProduct>;
  products: IProduct[];
}
type IReassignForm = Pick<IProduct, 'id' | 'type' | 'parent_id' | 'sequence_number'>;

export const ProductEditParentForm: React.FC<IProductEditAboutSectionProps> = ({ products, handleSubmit }) => {
  const { id, parent_id, sequence_number, type } = products[0];
  const { formData, handlers, onSubmit, valid, values } = useMm3Form<IReassignForm>(
    { id, parent_id, sequence_number, type },
    Model.PRODUCTS,
    handleSubmit,
    customValidator,
  );

  const { onParentChange, fetchParents } = useSearchForPotentialParents({ values, handlers });

  const hint =
    'Assigning multiple products to a new Parent will set their sequence numbers to 0. Upon confirming, you will be re-directed to the new Parent for further editing.';
  const isSubmitDisabled = !valid || values.parent_id === products[0].parent_id || values.parent_id === products[0].id;

  const parseItemRenderProps = useCallback(
    (item: IFormRemoteSelectOption): ICustomItemRendererProps => {
      const productType = Array.isArray(values) ? values[0]?.type : values.type;
      let itemProps;
      if (productType && item.value) {
        const { isDisabled, isNavigationEnabled } = getProductSearchModifiers(productType, item as IProduct);
        itemProps = {
          disabled: isDisabled,
          isNavigationEnabled,
        };
      }

      return itemProps;
    },
    [values],
  );
  return (
    <>
      <form onSubmit={onSubmit}>
        <FormRemoteSelect
          name="parent_id"
          key="parent_id"
          label="Parent"
          fetchOptions={fetchParents}
          {...formData.parent_id}
          {...handlers}
          onChange={onParentChange}
          isAncestryMode
          large
          parseItemRenderProps={parseItemRenderProps}
        />

        {products.length > 1 ? (
          <FormNumericInput
            name="sequence_number"
            key="sequence_number"
            label="Sequence Number"
            value={0}
            disabled
            helperText={hint}
          />
        ) : (
          <FormNumericInput
            name="sequence_number"
            key="sequence_number"
            label="Sequence Number"
            {...formData.sequence_number}
            {...handlers}
          />
        )}
      </form>
      <FormSubmitSection submitDisabled={isSubmitDisabled} onSubmit={onSubmit} />
    </>
  );
};

export default ProductEditParentForm;
