import React from 'react';
import { observer } from 'mobx-react-lite';

import { FilterDateRange } from 'helpers/filters/fields';
import { IFiltersHandlers } from 'helpers/filters';

import { IContactFilters } from 'components/contact-filters/contact-filters';

interface ITimestampsFilters {
  filterValues: IContactFilters;
  filterHandlers: IFiltersHandlers<IContactFilters>;
}

export const ContactTimestampFilters = observer(({ filterValues, filterHandlers }: ITimestampsFilters) => {
  return (
    <>
      <FilterDateRange
        label="Created at"
        name="created_at"
        filter={filterValues.created_at}
        onChange={filterHandlers.onChange}
      />

      <FilterDateRange
        label="Activated at"
        name="activated_at"
        filter={filterValues.activated_at}
        onChange={filterHandlers.onChange}
      />

      <FilterDateRange
        label="Last login at"
        name="last_login_at"
        filter={filterValues.last_login_at}
        onChange={filterHandlers.onChange}
      />
    </>
  );
});
