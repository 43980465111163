import React from 'react';

import { IScrollableTab } from 'components/scrollable-tabs/scrollable-tabs';
import { AssetMetadataTab, AssetMetadataEditTab } from 'pages/asset-details/asset-metadata-tab';

import AssetMm3MetadataTab from 'pages/asset-details/asset-metadata-tab/asset-metadata-mm3-tab/asset-metadata-mm3-tab';

import { IUseFormReturn } from 'helpers/form';
import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';
import { IAssetFormFields } from 'components/asset/asset-edit-form/sections/types';
import { DetailsPageTabs, IAsset, IMm3Asset } from 'types';
import { editableTabsWithPowers } from 'pages/asset-details/utils';
import { getIsTabDisabledWithPowers } from 'utils/general';

// can be removed once we migrate crunchyroll and amazonus metadata tabs to new UI
export const getAssetMetadataTab = ({
  asset,
  editModeEnabled,
  editForm,
  isMm3Assets,
}: {
  asset: IAsset | IMm3Asset;
  editModeEnabled: boolean;
  isMm3Assets?: boolean | null;
  editForm: IUseFormReturn<IAssetFormFields> | IUseMm3FormReturn<IMm3Asset>;
}): IScrollableTab => ({
  id: DetailsPageTabs.METADATA,
  panel: isMm3Assets ? (
    <AssetMm3MetadataTab
      asset={asset as IMm3Asset}
      form={editForm as IUseMm3FormReturn<IMm3Asset>}
      editModeEnabled={editModeEnabled}
    />
  ) : editModeEnabled ? (
    <AssetMetadataEditTab asset={asset} form={editForm} />
  ) : (
    <AssetMetadataTab asset={asset} />
  ),
  isVisible: true,
  disabled: getIsTabDisabledWithPowers(DetailsPageTabs.METADATA, editableTabsWithPowers, editModeEnabled),
});
