import { getIsMm3Assets } from './asset';
import { flags } from 'utils/flags';
import { ISidebarSection } from 'store/profile-store';

import { Routes } from './routes';

const isMm3Assets = getIsMm3Assets();

export const hideSideBarFlags: Partial<Record<Routes | ISidebarSection, boolean>> = {
  [Routes.CREATE_SHELL_ASSET]: !isMm3Assets,
  [Routes.ACCESS_GROUPS]: !flags.showAccessGroupFeature,
  [Routes.CREATE_ACCESS_GROUP]: !flags.showAccessGroupFeature,
  [Routes.CREATE_ACCESS_PRIVILEGE]: !flags.showAccessPrivilegeFeature,
  [Routes.ACCESS_PRIVILEGES]: !flags.showAccessPrivilegeFeature,
  [Routes.CREATE_ASSET_RECOMMENDATION]: !flags.showAssetRecommendationsFeature,
  [Routes.BASKETS]: !flags.showBasketsFeature,
  [Routes.CAST_CREW]: !flags.showCastCrewFeature,
  [Routes.COLLECTIONS]: !flags.showCollectionsFeature,
  [Routes.CREATE_NEW_CONFERENCE]: !flags.showConferenceFeature,
  [Routes.CONFERENCES]: !flags.showConferenceFeature,
  [ISidebarSection.fulfillment]: !flags.showDeliveryFeature && !flags.showOrderManagementFeature,
  [Routes.DELIVERIES]: !flags.showDeliveryFeature,
  [Routes.CREATE_DELIVERY_PACKAGE]: !flags.showDeliveryFeature,
  [Routes.CREATE_EVENT]: !flags.showEventFeature,
  [Routes.EVENTS]: !flags.showEventFeature,
  [Routes.GUILDS]: !flags.showGuildsFeature,
  [Routes.CREATE_GUILD]: !flags.showGuildsFeature,
  [ISidebarSection.meetings]: !flags.showMeetingsFeature,
  [Routes.CALENDAR]: !flags.showMeetingsFeature,
  [Routes.LOCATIONS]: !flags.showMeetingsFeature,
  [Routes.CREATE_MOBILE_APP_SYNC]: !flags.showMobileSelectionsFeature,
  [Routes.MOBILE_APP_SYNC]: !flags.showMobileSelectionsFeature,
  [ISidebarSection.producerPortal]: !flags.showProducerPortalFeature,
  [Routes.PRODUCER_PORTAL]: !flags.showProducerPortalFeature,
  [Routes.CREATE_PRODUCT_RECOMMENDATION]: !flags.showRecommendationFeature,
  [Routes.ANALYTICS_RECOMMENDATIONS]: !flags.showRecommendationFeature && !flags.showAssetRecommendationsFeature,
  [Routes.RECOMMENDATIONS]: !flags.showRecommendationFeature && !flags.showAssetRecommendationsFeature,
  [Routes.CREATE_SCREENING_ROOM]: !flags.showScreeningRoomFeature,
  [Routes.SCREENING_ROOMS]: !flags.showScreeningRoomFeature,
  [Routes.CREATE_SMART_GROUP]: !flags.showSmartGroupFeature,
  [Routes.SMART_GROUP]: !flags.showSmartGroupFeature,
  [Routes.ORDER_MANAGEMENT]: !flags.showOrderManagementFeature,
  [Routes.CREATE_LOCALIZATION_ORDER]: !flags.showOrderManagementFeature,
  [ISidebarSection.marketing]:
    !flags.showScreeningRoomFeature &&
    !flags.showEventFeature &&
    !flags.showConferenceFeature &&
    !flags.showRecommendationFeature &&
    !flags.showAssetRecommendationsFeature &&
    !flags.showAccessPrivilegeFeature &&
    !flags.showMobileSelectionsFeature &&
    !flags.showAccessGroupFeature &&
    !flags.showGuildsFeature,
  [ISidebarSection.website]: !flags.showWebsiteFeature,
  [Routes.ADVERTISEMENTS]: !flags.showAdvertisementsFeature,
};
