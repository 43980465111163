import { chipmunk, IResult } from 'utils/chipmunk';
import { Model } from 'helpers/filters/types';
import { ILocalizationOrder } from 'types/localization-order';
import { ItemId, IThumbnailImage } from 'types';
import { queryProducts } from './product';
import { localizationOrderSchema } from 'utils/schemas';
import { cookie } from 'utils/cookie';

const sessionId = cookie.get('sessionId');
const roleId = cookie.get('roleId');
const affiliationId = cookie.get('affiliationId');

export const downloadTemplate = async (): Promise<void> => {
  const params = { product_type: 'series', order_template: 'library_tv' };
  // return chipmunk.run(async ({ action }) => {
  //   const { object } = await action(Model.LOCALIZATION_ORDERS_SERIES, 'download_template', {
  //     params,
  //   });
  //   return object;
  // });
  try {
    const response = await fetch(
      `https://am.api.mediastore.dev/v2021/localization_orders/download_template?product_type=${encodeURIComponent(
        params.product_type,
      )}&order_template=${encodeURIComponent(params.order_template)}`,
      {
        method: 'GET',
        headers: {
          'affiliation-id': affiliationId,
          'role-id': roleId,
          'session-id': sessionId,
        },
      },
    );

    if (!response.ok) {
      throw new Error('Failed to download file');
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;

    link.download = 'report.xlsx';

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    throw error;
  }
};

export const uploadTemplate = async (data: FormData): Promise<{ status: string }> => {
  // return chipmunk.run(async ({ action }) => {
  //   const { objects } = await action(Model.LOCALIZATION_ORDERS_SERIES, 'parse_template_upload', { body: data });

  //   return objects;
  // });

  try {
    const response = await fetch('https://am.api.mediastore.dev/v2021/localization_orders/parse_template_upload/', {
      method: 'POST',
      body: data,
      headers: {
        'affiliation-id': affiliationId,
        'role-id': roleId,
        'session-id': sessionId,
      },
    });
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
};

export const fetchOrders = async (): Promise<void> => {
  return chipmunk.run(async ({ action }) => {
    const { objects } = await action(Model.LOCALIZATION_ORDERS_SERIES, 'search');

    return objects;
  });
};

export const loadLocalizationOrder = async (id: string): Promise<ILocalizationOrder> => {
  return chipmunk.run(async ({ action }) => {
    const { object } = await action(Model.LOCALIZATION_ORDERS_SERIES, 'get', {
      params: { id },
      schema: localizationOrderSchema,
    });

    return object;
  });
};

export const createOrder = async (data: Partial<ILocalizationOrder>): Promise<ILocalizationOrder> => {
  return chipmunk.run(async ({ action }) => {
    const { object } = await action(Model.LOCALIZATION_ORDERS_SERIES, 'create', {
      body: data,
    });

    return object;
  });
};

export const updateOrder = async (data: Partial<ILocalizationOrder>): Promise<ILocalizationOrder> => {
  return chipmunk.run(async ({ action }) => {
    const { object } = await action(Model.LOCALIZATION_ORDERS_SERIES, 'update', {
      params: { id: data.id },
      body: data,
    });

    return object;
  });
};

export const sendOrder = async (data: Partial<ILocalizationOrder>): Promise<ILocalizationOrder> => {
  return chipmunk.run(async ({ action }) => {
    const { object } = await action(Model.LOCALIZATION_ORDERS_SERIES, 'send_order', {
      params: { id: [data.id] },
    });

    return object;
  });
};

export const deleteOrder = async (ids?: (number | null | undefined)[]): Promise<IResult | void> => {
  return chipmunk.run(async ({ action }) => {
    return action(Model.LOCALIZATION_ORDERS, 'delete', {
      params: { id: ids },
    });
  });
};

export const loadOrderThumbnails = async (id?: ItemId | null): Promise<IThumbnailImage[]> => {
  if (!id) {
    return [];
  }

  const products = await queryProducts({ ids: [id] });

  return products.reduce((acc, product) => {
    const url = product?.inherited_preview_image?.url;
    return url ? [{ image: url }, ...acc] : acc;
  }, []);
};

export const copyLocalizationOrder = ({
  project_name = '',
  ...order
}: ILocalizationOrder): Promise<ILocalizationOrder> => {
  return createOrder({ ...order, project_name: `${project_name} (Copy)` });
};
