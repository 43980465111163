import React, { useCallback } from 'react';
import { PmGenericCastCrew } from '@mediafellows/mm3-types';
import { observer } from 'mobx-react-lite';

import { FormSubmitSection } from 'components/form-submit-section';
import { ICreateCastCrewFormData, CustomInput, customLabels } from 'components/cast-crew/create-cast-crew/utils';
import { ToastError } from 'components/toast';

import { Model } from 'helpers/filters/types';
import { FormImage, FormInput, useMm3Form } from 'helpers/form';
import { ingestPreviewImage } from 'utils/apis/preview-image';
import { createCastCrew } from 'utils/apis/cast-crew';
import { useRefreshDataSection } from 'utils/hooks';
import { useStore } from 'store/index';

const labels = { confirm: 'Create' };
const initialValues = { name: '', imageUrl: '', meta: { description: '', description_spanish: '' } };

const createCastCrewWithAvatar = async ({
  name,
  meta,
  imageUrl,
}: ICreateCastCrewFormData): Promise<PmGenericCastCrew> => {
  const previewImage = imageUrl ? await ingestPreviewImage(imageUrl) : undefined;

  return createCastCrew({ name, meta, preview_image_id: previewImage?.id });
};

export const CreateCastCrewForm: React.FC = observer(() => {
  const { toastStore, dialogStore } = useStore();
  const refreshDataSection = useRefreshDataSection();

  const handleCreateCastCrew = useCallback(
    async (values: ICreateCastCrewFormData) => {
      try {
        toastStore.info('processing...');
        await createCastCrewWithAvatar(values);
        toastStore.clearAll();
        toastStore.success('Cast & Crew member created successfully!');
        dialogStore.close();
        setTimeout(refreshDataSection, 1000);
      } catch (error) {
        toastStore.clearAll();
        toastStore.error(<ToastError error={error} />);
      }
    },
    [refreshDataSection, toastStore, dialogStore],
  );

  const { handlers, formData, onSubmit, valid } = useMm3Form<ICreateCastCrewFormData>(
    initialValues,
    Model.CAST_CREW,
    handleCreateCastCrew,
  );

  return (
    <>
      <form onSubmit={onSubmit}>
        <FormImage name="imageUrl" label="Avatar" {...handlers} {...formData.imageUrl} />
        <FormInput className="cast-crew-form__name" name="name" label="Name" {...handlers} {...formData.name} />
        <CustomInput formData={formData} handlers={handlers} />
        <FormInput
          name="meta.description"
          label={customLabels.description}
          textarea
          {...handlers}
          {...formData.meta?.description}
        />
      </form>
      <FormSubmitSection labels={labels} submitDisabled={!valid} onSubmit={onSubmit} />
    </>
  );
});
