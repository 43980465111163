import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { pick } from 'lodash';

import { useAssetsFileUploadStore, useMultiAssetsUploadStore, useAssetsUploadToSelectionStore } from 'store/hooks';
import AssetUploadShortcut from 'components/dialogs/upload-dialog/assets-upload-shortcut/assets-upload-shortcut';
import AssetFileUploadShortcut from 'components/dialogs/upload-dialog/assets-upload-shortcut/assets-file-upload-shortcut';
import { useUploadAssetToSelectionDialog } from 'components/upload-asset-to-selection-dialog';
import AssetUploadShortcutDetailed from './assets-upload-shortcut/assets-upload-shortcut-detailed';

import { Routes } from 'utils/routes';

import './style.scss';

export const UploadDialog: React.FC<{}> = () => {
  const { multiAssetsUploaderStores } = useMultiAssetsUploadStore();
  const assetUploadToSelectionStore = useAssetsUploadToSelectionStore();
  const selectionId = assetUploadToSelectionStore.getCurrentSelection()?.id;
  const assetsFileUploadStore = useAssetsFileUploadStore();
  const [openUploadAssetToSelection] = useUploadAssetToSelectionDialog();
  const navigate = useNavigate();

  const openAssetFileUpload = useCallback(() => {
    const assetId = assetsFileUploadStore.asset?.id;
    assetId ? navigate(`${Routes.ASSETS}/${assetId}`) : navigate(`${Routes.ASSETS}`);
  }, [assetsFileUploadStore.asset, navigate]);

  const openAssetUploadToSelection = useCallback(() => {
    openUploadAssetToSelection();
  }, [openUploadAssetToSelection]);

  return (
    <div className={'upload-dialog__wrapper'}>
      {Object.entries(multiAssetsUploaderStores).map(([id, assetUploadStore]) => (
        <AssetUploadShortcutDetailed
          key={id}
          {...pick(assetUploadStore, ['files', 'progress', 'isUploadPending', 'uploadedFiles'])}
          storeId={id}
        />
      ))}
      <AssetUploadShortcut
        key="selection-details-assets-uploader"
        onClick={openAssetUploadToSelection}
        {...pick(assetUploadToSelectionStore, ['files', 'progress', 'isUploadPending', 'uploadedFiles'])}
        customMessage={
          selectionId ? (
            <>
              Asset upload to <Link to={`${Routes.ASSET_SELECTIONS}/${selectionId}`}>Selection</Link>
            </>
          ) : undefined
        }
      />

      <AssetFileUploadShortcut
        onClick={openAssetFileUpload}
        {...pick(assetsFileUploadStore, [
          'files',
          'progress',
          'isUploadPending',
          'asset',
          'removeFile',
          'isSending',
          'setAsset',
          'isSubmitted',
          'cleanUp',
          'uploadFinished',
          'isCancelled',
        ])}
      />
    </div>
  );
};

export default observer(UploadDialog);
