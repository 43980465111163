import React, { useEffect, useState } from 'react';
import { each, isEmpty, random } from 'lodash';
import { AgGridReact } from 'ag-grid-react';
import { customTheme } from 'pages/order-details/order-items-tab/utils';
import { ILocalizationOrderStep } from 'components/localization-orders/types';
import { Product3Types } from 'types';
import { formatDate } from 'utils/date';
import { ColDef } from 'ag-grid-community';

interface IRowData {
  series?: string;
  season?: string;
  episode?: string;
  file_type: string;
  language: Array<string>;
  target_date: string;
  due_date: string;
}

export const columns: ColDef<IRowData>[] = [
  { field: 'series', type: 'string', filter: 'agTextColumnFilter' },
  { field: 'season', type: 'string' },
  { field: 'episode', type: 'string' },
  { field: 'file_type', type: 'string', headerName: 'File Type' },
  { field: 'language', type: 'string', headerName: 'Language' },
  { field: 'target_date', type: 'dateString', headerName: 'Target Date' },
  { field: 'due_date', type: 'dateString', headerName: 'Due Date' },
];

export const OrderItemsGridStep: React.FC<ILocalizationOrderStep> = ({ orderForm: { values } }) => {
  const [rowData, setRowData] = useState<IRowData[]>([]);

  useEffect(() => {
    const data: IRowData[] = [];

    if (values && isEmpty(rowData)) {
      const { products, subtitles, content_partner_delivery_deadline, due_date } = values;
      const series = products?.find((p) => p.type === Product3Types.SERIES);
      const season = products?.find((p) => p.type === Product3Types.SEASON);
      const randomizeData = ['SRT', 'WAV', '.XML'];

      each(products, (product) => {
        if (product.type === Product3Types.EPISODE)
          data.push({
            series: series?.title,
            season: season?.title,
            episode: product.title,
            file_type: randomizeData[random(0, randomizeData.length - 1)],
            language: subtitles,
            target_date: formatDate(content_partner_delivery_deadline),
            due_date: formatDate(due_date),
          });
      });

      setRowData(data);
    }
  }, [values, rowData]);

  return (
    <div className="localization-order-project__details h-100">
      <AgGridReact
        rowData={rowData}
        columnDefs={columns}
        defaultColDef={{
          sortable: true,
          filter: true,
          resizable: true,
        }}
        theme={customTheme}
      />
    </div>
  );
};
