import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Button, useMantineColorScheme } from '@mantine/core';
import { v4 as uuidv4 } from 'uuid';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';

import { useAssetsFileUploadStore, useStore } from 'store';

import { getIsMm3Assets } from 'utils/asset';
import { Classes } from 'utils/ui';

import type { IUploadType } from './types';
import { IAsset, IFile, IMm3Asset } from 'types';
import { IModalSize } from 'components/dialogs/types';

import './index.scss';

interface IUploadImageProps {
  onProgressUpdate?: (progress: number) => void;
  onUploadFinished: (file?: IFile) => void;
  message?: string;
  type?: IUploadType;
  extensions?: string[] | null;
  disableSubmit?: boolean;
  handleSubmit: React.FormEventHandler<HTMLFormElement>;
  setFileUrl?: (url: string) => void;
  asset: IAsset | IMm3Asset;
  closeModal: () => void;
  onSuccess?: (asset: IAsset | IMm3Asset) => void | Promise<void> | undefined;
}

interface IUseUploadImageDialog {
  onSubmit: (file?: IFile | null) => void;
  customMessage?: string;
  extensions?: string[] | null;
  type?: IUploadType;
  successMessage?: string;
  setFileUrl?: (url: string) => void;
  asset: IAsset | IMm3Asset;
  onSuccess?: (asset: IAsset | IMm3Asset) => void | Promise<void> | undefined;
}

const UploadImageView: React.FC<IUploadImageProps> = observer(
  ({
    onProgressUpdate,
    onUploadFinished,
    type,
    extensions,
    handleSubmit,
    setFileUrl,
    asset,
    closeModal,
    onSuccess,
  }) => {
    const { progress, initializeUppy, cleanUp, setIsUploadPending, disableSubmit, uploadFinished } =
      useAssetsFileUploadStore();

    const dropAreaId = useMemo(() => `upload-file-drop-area-${uuidv4()}`, []);
    const { colorScheme } = useMantineColorScheme();
    useEffect(() => {
      initializeUppy({
        target: dropAreaId,
        type,
        extensions,
        setFileUrl,
        onUploadFinished,
        asset,
        onSuccess,
        theme: colorScheme,
      });
    }, [asset, colorScheme, dropAreaId, extensions, initializeUppy, onSuccess, onUploadFinished, setFileUrl, type]);

    useEffect(() => {
      onProgressUpdate?.(progress);
    }, [progress, onProgressUpdate]);

    const onSubmit = useCallback(
      async (evt) => {
        if (uploadFinished) {
          await handleSubmit(evt);
          setIsUploadPending(true);
        } else {
          setIsUploadPending(true);
          closeModal();
        }
      },
      [closeModal, handleSubmit, setIsUploadPending, uploadFinished],
    );

    return (
      <form onSubmit={onSubmit} className="d-flex flex-column">
        <div className="preview-image-uploader-container flex-column align-items-start">
          <div id={dropAreaId} className="w-100" />
          <div className={`image-uploader-progress-container__hint my-3 ${Classes.TEXT_MUTED}`}>
            Clicking &apos;Submit&apos; will automatically send the file for ingest after the upload is complete
          </div>
        </div>

        <div className={cx(Classes.DIALOG_FOOTER_ACTIONS, 'gap-2')}>
          <Button
            variant="default"
            size="xs"
            onClick={() => {
              closeModal();
              cleanUp();
            }}
          >
            Cancel
          </Button>
          <Button size="xs" variant="primary" onClick={onSubmit} disabled={disableSubmit}>
            Submit
          </Button>
        </div>
      </form>
    );
  },
);

export const useEarlyUploadImageDialog = (props: IUseUploadImageDialog): [() => void] => {
  const {
    onSubmit,
    customMessage,
    type,
    extensions,
    successMessage = 'Preview processing in progress.',
    setFileUrl,
    asset,
    onSuccess,
  } = props;
  const {
    dialogStore: { openModal, close },
    toastStore,
  } = useStore();
  const imageExtensions = getIsMm3Assets() ? ['image/png', 'image/jpeg'] : ['image/png', 'image/jpeg', 'image/gif'];
  const fileExtenstions = extensions || imageExtensions;

  const uploadedFile = useRef<IFile | undefined | null>(null);
  const handleSubmit = useCallback<React.FormEventHandler<HTMLFormElement>>(
    async (e) => {
      try {
        e.preventDefault();
        await onSubmit?.(uploadedFile.current);
        toastStore.clearAll();
        toastStore.success(successMessage);
        return close();
      } catch (error) {
        toastStore.error(error.text);
      }
    },
    [onSubmit, toastStore, close, successMessage],
  );

  const body = useCallback(() => {
    return (
      <UploadImageView
        type={type}
        extensions={fileExtenstions}
        onUploadFinished={(file: IFile) => {
          uploadedFile.current = file;
        }}
        setFileUrl={setFileUrl}
        message={customMessage}
        handleSubmit={handleSubmit}
        closeModal={close}
        asset={asset}
        onSuccess={onSuccess}
      />
    );
  }, [type, fileExtenstions, setFileUrl, customMessage, handleSubmit, close, asset, onSuccess]);

  const openDialog = (): void => {
    openModal({
      title: 'Upload',
      body,
      className: 'upload-asset-file-dialog',
      withCloseButton: false,
      size: IModalSize.XS,
    });
  };

  return [openDialog];
};
