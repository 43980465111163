import React from 'react';
import { capitalize } from 'lodash';
import { observer } from 'mobx-react-lite';

import { useDataSectionStore } from 'store';
import { Routes } from 'utils/routes';
import { Row } from 'components/label-value-pair';
import { ILocalizationOrder } from 'types/localization-order';
import { PreviewInfo } from 'components/preview-tab';
import { DetailsPageTabs, IProduct } from 'types';
import { formatDate } from 'utils/date';
import { formatFullName } from 'utils/general';
import { DetailCountLink } from 'utils/ui';

interface IOrderPreviewAttributesProps {
  product: IProduct;
}

export const OrderPreviewAttributes: React.FC<IOrderPreviewAttributesProps> = observer(({ product }) => {
  const { activeItem: order } = useDataSectionStore<ILocalizationOrder>();
  const { id, created_at, due_date, sent_at, organization_name, owner, status, recipient_list } = order || {};
  const detailsPageLink = `${Routes.ORDER_MANAGEMENT}/${id}`;

  return (
    <PreviewInfo>
      <Row label="Title" value={product?.title} rawLabel />
      <Row label="Status" value={capitalize(status as string)} rawLabel />
      <Row label="Created Date" value={formatDate(created_at)} rawLabel />
      <Row label="Target Date" value={formatDate(due_date)} rawLabel />
      <Row label="Sent at" value={formatDate(sent_at)} rawLabel />
      <Row label="Organization" value={organization_name} rawLabel />
      <Row label="Sender" value={formatFullName(owner)} rawLabel />
      <DetailCountLink
        entityCount={recipient_list?.length}
        link={`${detailsPageLink}/${DetailsPageTabs.RECIPIENTS}`}
        label="Notification Recipients"
      />
    </PreviewInfo>
  );
});
