import React, { useState } from 'react';
import { VideoAsset, VideoPlayer } from '@mediafellows/videoplayer';

import { IAsset, IMm3Asset, IMm3AssetType } from 'types';
import { getIngestStatus, getIsMm3Assets, getPreviewImageUrl, readyStatuses } from 'utils/asset';
import { ThumbnailFileStatus } from 'components/file-status';
import { ThumbnailEntity } from 'components/thumbnail';

import './style.scss';

interface IAssetVideoPlayer {
  video?: IAsset | IMm3Asset;
  customTag?: string;
}

export const AssetVideoPlayer: React.FC<IAssetVideoPlayer> = (props) => {
  const { video, customTag } = props;
  const { classification, preview_image, file_status } = video || {};
  const isMm3Assets = getIsMm3Assets();

  // const isVideo = classification?.startsWith('video') || classification?.startsWith('audio');
  const isVideo = classification?.startsWith('video') || (video as IMm3Asset)?.type === IMm3AssetType.VIDEO;
  const isAudio = classification?.startsWith('audio') || (video as IMm3Asset)?.type === IMm3AssetType.AUDIO;
  const isFileReady = readyStatuses.has(file_status || '');

  const [showStatus, setShowStatus] = useState<boolean>(true);

  if (!isVideo && !isAudio) {
    return null;
  }

  if (!isFileReady) {
    return <ThumbnailEntity entity={video} />;
  }

  return (
    <div className="asset-video-player__wrapper">
      <div className="asset-video-player__status-wrapper">
        {showStatus && <ThumbnailFileStatus status={getIngestStatus(video)} withBackground />}
      </div>

      <VideoPlayer
        video={video as VideoAsset}
        autoplay={false}
        posterUrl={getPreviewImageUrl(preview_image)}
        onPlay={() => setShowStatus(false)}
        isMm3Assets={isMm3Assets}
      />
      {Boolean(customTag) && showStatus && (
        <div className="thumbnail__type text-uppercase p-1 text-truncate">{customTag}</div>
      )}
    </div>
  );
};
