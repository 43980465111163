import { IFiltersDefinition } from 'helpers/filters';
import pick from 'lodash/pick';
import { getRootStore } from 'store';

export function clearActiveFiltersSection<T extends IFiltersDefinition>(filterAttributes: (keyof T)[], onChange): void {
  const rootStore = getRootStore();
  const { defaultFilters, initFilters } = rootStore?.dataSectionStore || {};
  if (!defaultFilters) {
    return;
  }

  const updatedFilters = pick({ ...defaultFilters, ...(initFilters || {}) }, filterAttributes);

  onChange(updatedFilters);
}
