import { useCallback } from 'react';
import { map } from 'lodash';

import { useStore } from 'store';
import { IActionConfig, IUseActionsOptionParam } from 'utils/actions/types';
import { IContact } from 'types';
import { Intent } from 'utils/ui';

import { shouldDisplayForcePasswordReset } from './actions-acl';
import { IContactActionName } from './types';
import { forcePasswordReset } from './api';
import { pluralEntityInflect } from 'utils/general';

export const useForcePasswordResetAction = (
  contacts: IContact[],
  options: IUseActionsOptionParam,
): IActionConfig<IContact, IContactActionName> => {
  const { toastStore, dialogStore } = useStore();
  const contactIds = map(contacts, 'id');
  const { entityWithDemonstrative } = pluralEntityInflect('Contact', contacts.length);

  const executeForcePasswordReset = useCallback(async () => {
    try {
      await forcePasswordReset(contactIds);
      toastStore.success(`The password for ${entityWithDemonstrative} was reset successfully`);
      await options?.onSuccess?.(IContactActionName.FORCE_PASSWORD_RESET);
    } catch (error) {
      toastStore.error(`Password reset failed: ${error.text}`);
      await options?.onFailure?.();
    }
  }, [options, contactIds, entityWithDemonstrative, toastStore]);

  const handler = useCallback(() => {
    dialogStore.openConfirm({
      title: 'Confirm Force Password Reset',
      body: `Are you sure you want to force reset the password for ${entityWithDemonstrative}?`,
      onConfirm: executeForcePasswordReset,
    });
  }, [dialogStore, entityWithDemonstrative, executeForcePasswordReset]);

  return {
    name: IContactActionName.FORCE_PASSWORD_RESET,
    shouldDisplay: shouldDisplayForcePasswordReset,
    icon: 'reset',
    title: `Force Reset Password`,
    intent: Intent.DANGER,
    handler,
  };
};
