import React, { useCallback } from 'react';

import { DashboardBreadcrumbs } from 'components/dashboard-breadcrumbs';
import { Loading } from 'components/loading';
import { SectionMessage, SectionMessageType } from 'components/section-message';
import AffiliationLoaded from './affiliation-loaded';
import { queryVideoAssets } from 'utils/apis/asset';
import { useRemote } from 'utils/hooks';
import { IAsset } from 'types';

import { loadAffiliation } from 'utils/apis/affiliation';
import { useRefreshPreviewForImageIngest } from './utils';
import { fetchAffiliationProtections } from 'utils/apis/protections';
import { amAffiliationProtectionRead, hasPower } from 'utils/powers';

const fetchSampleVideoAsset = async (): Promise<IAsset | undefined> => {
  if (!hasPower(amAffiliationProtectionRead)) {
    return;
  }

  const [asset] = await queryVideoAssets({ per: 1, page: 1 }, [
    ['status', 'eq', 'available'],
    ['file_status', 'eq', 'ready'],
  ]);

  return asset;
};

const Affiliation: React.FC = () => {
  const fetcher = useCallback(async () => {
    return loadAffiliation();
  }, []);
  const [affiliation, loadingAffiliation, setAffiliation, refresh] = useRemote(fetcher);
  const [protections, loadingProtection, setProtections] = useRemote(fetchAffiliationProtections);
  const [sampleAsset, sampleAssetLoading] = useRemote(fetchSampleVideoAsset);
  useRefreshPreviewForImageIngest(setAffiliation, affiliation);
  const loading = loadingAffiliation || loadingProtection || sampleAssetLoading;

  return (
    <div className="d-flex flex-column w-100 h-100">
      <div className="background-wrapper__index w-100 h-100">
        <DashboardBreadcrumbs className="mb-3" />
        {loading && <Loading text="Loading Affiliation" />}
        {affiliation?.id && protections?.id && !loading && (
          <AffiliationLoaded
            affiliation={affiliation}
            refresh={refresh}
            setProtections={setProtections}
            protections={protections}
            sampleAsset={sampleAsset}
          />
        )}
        {(!affiliation?.id || !protections?.id) && !loading && <SectionMessage intent={SectionMessageType.EMPTY} />}
      </div>
    </div>
  );
};

export default Affiliation;
