import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Accordion, Switch } from '@mantine/core';
import { useLocation } from 'react-router-dom';
import cx from 'classnames';

import { submitFilters, ISubmitFiltersParams, toggleParam } from 'helpers/filters/helpers';
import { useFilters } from 'helpers/filters';

import { useStore } from 'store';
import { parseQueryParams } from 'utils/general';
import { defaultParamComponentValues, IAssetFilters } from './utils';
import { IAsset } from 'types/index';

import { DataSectionPreset } from 'components/data-section-preset';
import { SharedAssetFilters } from 'components/asset/asset-filters/shared-filters';
import { essentialFilters } from 'components/asset/asset-filters/utils';
import { getActiveFiltersCount } from 'components/product-filters/utils';
import { FilterAccordionItem } from 'components/filter-accordion-item/filter-accordion-item';
import { AssetEssentialFilters } from 'components/asset/asset-filters/asset-essential-filters';
import { FILTER_SECTIONS } from 'utils/constants';
import { clearActiveFiltersSection } from 'utils/filters';

export const AssetFilters: React.FC<{
  customSubmitFilter?: (obj: ISubmitFiltersParams) => void;
  withMainAssetsFilter?: boolean;
}> = observer(({ customSubmitFilter = submitFilters, withMainAssetsFilter }) => {
  const location = useLocation();

  const { dataSectionStore } = useStore<IAsset>();
  const queryParams = parseQueryParams(location.search);
  const [activeAccordion, setActiveAccordion] = useState<string | null>(FILTER_SECTIONS.ESSENTIALS);

  const [paramComponentValues, setParamComponentValues] = useState(defaultParamComponentValues(queryParams));

  const handleSubmit = useCallback(
    (action): void => {
      customSubmitFilter({
        action,
        setParamComponentValues,
        customParams: {
          include_deleted: false,
        },
      });
    },
    [customSubmitFilter],
  );

  const [filterValues, filterHandlers] = useFilters<IAssetFilters>(dataSectionStore, handleSubmit);
  const handleToggleParam = (event): void => {
    toggleParam({
      event,
      paramComponentValues,
      setParamComponentValues,
    });
  };
  const activeEssentialFiltersCount = getActiveFiltersCount(filterValues, essentialFilters);

  return (
    <div className="entity-filters">
      <Accordion
        chevronPosition="left"
        classNames={{
          chevron: cx({
            'active-accordion__element': activeAccordion === FILTER_SECTIONS.ESSENTIALS,
          }),
        }}
        value={activeAccordion}
        onChange={setActiveAccordion}
      >
        <DataSectionPreset filterHandlers={filterHandlers} />
        <div className="entity-filters__new-filter">
          <FilterAccordionItem
            value={FILTER_SECTIONS.ESSENTIALS}
            title={FILTER_SECTIONS.ESSENTIALS}
            activeAccordion={activeAccordion}
            activeFiltersCount={activeEssentialFiltersCount}
            onClearFilters={(e) => {
              e.stopPropagation();
              clearActiveFiltersSection(essentialFilters, filterHandlers.onChange);
            }}
          >
            <AssetEssentialFilters
              filterValues={filterValues}
              filterHandlers={filterHandlers}
              dataSectionStore={dataSectionStore}
              withMainAssetsFilter={withMainAssetsFilter}
            />
          </FilterAccordionItem>
          <SharedAssetFilters
            dataSectionStore={dataSectionStore}
            filterHandlers={filterHandlers}
            filterValues={filterValues}
            activeAccordion={activeAccordion}
          />
          <Switch
            label="Include deleted"
            data-param={'include_deleted'}
            onChange={handleToggleParam}
            checked={paramComponentValues.include_deleted}
          />
        </div>
      </Accordion>
    </div>
  );
});
