import { IProduct } from 'types';

import { IconAlias } from 'icons';
import { useGuildDialog } from 'components/guilds';
import { IUseActionsOptionParam } from 'utils/actions';
import { IShouldDisplayGroupActionFunction } from 'utils/actions/common/add-entities-to-group';

import { IGuildActionConfig, IGuildActionName } from './types';

interface IUseCreateGuildAction extends IUseActionsOptionParam {
  shouldDisplay: IShouldDisplayGroupActionFunction;
}
export const useCreateGuildAction = (items: IProduct[], options?: IUseCreateGuildAction): IGuildActionConfig => {
  const [openDialog] = useGuildDialog({
    products: items,
    onConfirm: () => options?.onSuccess?.(),
  });

  return {
    name: IGuildActionName.CREATE_GUILD,
    shouldDisplay: options?.shouldDisplay,
    icon: IconAlias.GUILDS,
    title: 'Create Guild',
    handler: openDialog,
  };
};
