import React, { useMemo } from 'react';
import { map } from 'lodash';

import { FormSubmitSection } from 'components/form-submit-section';
import { ShareBasketForm } from './share-basket-form';

import { useForm } from 'helpers/form';

import { IGroup } from 'types';
import { IAddToGroupForm, IAddToGroupEntityType } from './types';
import { Model } from 'helpers/filters/types';

interface IFormAddToGroupItems {
  entity: IAddToGroupEntityType;
  onConfirm: (values: IAddToGroupForm) => Promise<void>;
  items?: Array<{ label: string; value: number | string }>;
  group?: IGroup;
  groupTitle: string;
}

const createInitialValues = (
  itemIds: Array<string | number>,
  groupIds: string | number | undefined,
): IAddToGroupForm => ({
  group_id: groupIds || '',
  item_ids: itemIds || [],
});

const customContext = { properties: { item_ids: { validations: [{ length: { minimum: 1, required: true } }] } } };

const ShareBasketWrapper: React.FC<IFormAddToGroupItems> = ({ items, onConfirm, entity, group, groupTitle }) => {
  const initialIds = useMemo(() => map(items, 'value'), [items]);

  const form = useForm<IAddToGroupForm>(
    createInitialValues(initialIds, group?.name),
    Model.GROUPS,
    onConfirm,
    customContext,
  );
  const { onSubmit, valid } = form;

  return (
    <>
      <form onSubmit={onSubmit}>
        <ShareBasketForm form={form} entity={entity} group={group} groupTitle={groupTitle} omni />
      </form>
      <FormSubmitSection submitDisabled={!valid} onSubmit={onSubmit} />
    </>
  );
};

export { ShareBasketWrapper };
