import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox } from '@mantine/core';
import cx from 'classnames';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { useStore } from 'store';
import { ILocalizationOrder } from 'types/localization-order';
import { handleItemClick } from 'components/data-section';
import { isEntityDeleted } from 'utils/is-entity-deleted';
import { loadOrderThumbnails } from 'utils/apis/localization-order';
import { useRemote } from 'utils/hooks';
import { MemoizedMultiThumbnail } from 'components/multi-component/multi-component';

import { IItem, ItemId } from 'types';

import { Thumbnail } from 'components/thumbnail';
import DateListElement from 'components/date-list-element';

import './style.scss';

interface IOrderListItemProps {
  className?: string;
  order: ILocalizationOrder & { sent_at: string };
  contextMenu: React.ReactElement;
}

const OrderListItem: React.FC<IOrderListItemProps> = observer((props) => {
  const {
    dataSectionStore: { checked, active, isAllItemsSelected },
  } = useStore();
  const { pathname } = useLocation();
  const { className, order, contextMenu } = props;
  const { id, project_name, owner, created_at, due_date, status, vendor } = order || {};

  const detailsPageLink = `${pathname}/${id}`;

  const isChecked = checked.some((checked) => checked.id === order.id);
  const isActive = active === order.id;

  const fetchThumbnails = useCallback(() => {
    return loadOrderThumbnails(order?.product_id as ItemId);
  }, [order?.product_id]);

  const [thumbnails = []] = useRemote(fetchThumbnails);

  const handleClick = useCallback(
    (e): void => {
      handleItemClick(e, id as number);
    },
    [id],
  );
  const [itemChecked, setItemChecked] = useState(isChecked);

  useEffect(() => {
    setItemChecked(isChecked);
  }, [isChecked]);

  return (
    <div
      className={cx('entity-list-item-row', className, {
        'entity-list-item--active': isActive,
        'entity-list-item--deleted': isEntityDeleted(order as IItem),
      })}
      onClick={handleClick}
    >
      <div className="order-list-item__checkbox">
        <Checkbox onChange={handleClick} checked={itemChecked || isAllItemsSelected} disabled={isAllItemsSelected} />
      </div>

      <div className="entity-list-item-row__multi-thumb">
        {thumbnails?.length ? <MemoizedMultiThumbnail thumbnailImages={thumbnails} /> : <Thumbnail showType image="" />}
      </div>

      <div className="entity-list-item-row__content">
        <div className="entity-list-item-row__title" title={project_name}>
          <div className="entity-list-item-row__title-icon">
            <Link to={`${detailsPageLink}`}>
              <h4 className="text-nowrap text-truncate delivery-name">{project_name}</h4>
            </Link>
          </div>

          {Boolean(contextMenu) && contextMenu}
        </div>
        <div className="delivery-list-item__attributes mfx-text-muted">
          <div className="text-truncate delivery-list-item__status">{status}</div>
          <div className="text-truncate order-list-item__created-at">
            <DateListElement date={created_at} mfxIcon="sent-at" />
          </div>
          <div className="text-truncate delivery-list-item__sent">
            <DateListElement date={due_date} mfxIcon="sent-at" />
          </div>
          <div className="text-truncate delivery-list-item__owner">
            {owner?.first_name ? owner?.first_name + ' ' + owner?.last_name : '-'}
          </div>
          <div className="text-truncate delivery-list-item__recipients">{vendor ?? '-'}</div>
        </div>
      </div>
    </div>
  );
});

export default OrderListItem;
