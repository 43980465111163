import React, { useCallback } from 'react';

import { FormMultiSelect, IFormMultiSelectProps } from 'helpers/form';
import { queryAssets } from 'utils/apis/asset';
import { IAsset, IQueryParams, ISearchFilter } from 'types';
import { getIsMm3Assets } from 'utils/asset';

const tagInputProps = { inputProps: { placeholder: 'Add more' } };

export const FormAssets: React.FC<IFormMultiSelectProps & { filters?: ISearchFilter[]; includeSubAssets?: boolean }> = (
  props,
) => {
  const { placeholder = 'Select assets', label = 'Assets', name, filters, includeSubAssets, ...other } = props;

  const fetchAssets = useCallback(
    ({ q, ids }: IQueryParams): Promise<IAsset[]> => {
      const isMmm3Assets = getIsMm3Assets();
      return queryAssets(
        { q, ids },
        includeSubAssets
          ? filters
          : [...(filters || []), ...(isMmm3Assets ? [['root', 'eq', true] as ISearchFilter] : [])],
      );
    },
    [filters, includeSubAssets],
  );

  return (
    <FormMultiSelect
      name={name}
      label={label}
      omni
      fetchValues={fetchAssets}
      tagInputProps={tagInputProps}
      placeholder={placeholder}
      {...other}
    />
  );
};
