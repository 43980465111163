import { useCallback } from 'react';
import { ILocalizationOrder } from 'types/localization-order';

import { useStore } from 'store';
import { IUseAction } from 'utils/actions';
import { pluralEntityInflect } from 'utils/general';
import { shouldDisplaySend as shouldDisplay } from './actions-acl';
import { ILocalizationOrderActionName } from './types';
import { sendOrder } from 'utils/apis/localization-order';

export const useSendAction: IUseAction<ILocalizationOrder, ILocalizationOrderActionName> = (items, options) => {
  const { toastStore, dialogStore } = useStore();
  const { entityWithDemonstrative, entityWithCount } = pluralEntityInflect('Order', items.length);
  const orderDraft = items[0];
  const toasterSucess = `${entityWithCount} scheduled for delivery`;

  const executeSend = useCallback(async () => {
    try {
      const order = await sendOrder(orderDraft);
      toastStore.success(toasterSucess);
      return await options?.onSuccess?.(ILocalizationOrderActionName.SEND, order);
    } catch (error) {
      toastStore.error(`Something went wrong: ${error.text}`);
      await options?.onFailure?.();
    }
  }, [options, orderDraft, toastStore, toasterSucess]);

  const confirmTitle = 'Confirm Send Localization Order';
  const confirmBody = `Are you sure you want to send ${entityWithDemonstrative}?`;
  const handler = useCallback(() => {
    dialogStore.openConfirm({
      title: confirmTitle,
      body: confirmBody,
      onConfirm: executeSend,
    });
  }, [confirmBody, confirmTitle, dialogStore, executeSend]);

  return {
    name: ILocalizationOrderActionName.SEND,
    shouldDisplay,
    icon: 'send-message',
    title: 'Send',
    handler,
  };
};
