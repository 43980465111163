import React, { useCallback, useState } from 'react';
import { ActionIcon, Loader, Tooltip } from '@mantine/core';
import { MantineIcon } from 'utils/ui/icon';
import { Star, StarEmpty, Video } from 'blueprint5-icons';

import { IAsset, IMm3Asset, IMm3AssetType, IProductAsset } from 'types';
import { IParametronData } from '@mediafellows/parametron';
import { setMarketingAsset } from 'utils/apis/product-asset';
import { useDataSectionStore } from 'store/hooks';
import { Pages } from 'utils/actions/types';
import { getAssets, saveMm3Asset } from 'utils/apis/asset';
import { assetDetailsSchema } from 'utils/schemas';
import { AmGenericAsset3DigitalSubtitle } from '@mediafellows/mm3-types';
import { LoaderSize } from 'utils/ui';

interface IMarketingStartProps {
  asset: Partial<(IAsset | (IMm3Asset & AmGenericAsset3DigitalSubtitle)) & { product_asset?: IProductAsset }>;
  page?: Pages;
  disableMarketingStar?: boolean;
  setProductAssets?: React.Dispatch<React.SetStateAction<IProductAsset[]>>;
}

export const MarketingAssetStar: React.FC<IMarketingStartProps> = ({
  asset,
  page,
  disableMarketingStar,
  setProductAssets,
}) => {
  const [starIconLoading, setStarIconLoading] = useState(false);
  const { searchStore } = useDataSectionStore<IAsset>();
  const [isAvailableOnPlayer, setIsAvailableOnPlayer] = useState(
    Boolean((asset as IMm3Asset & AmGenericAsset3DigitalSubtitle)?.meta?.show_in_player),
  );

  const setMarketingUse = useCallback(async () => {
    if (!asset?.product_asset?.id || !searchStore?.update) {
      return;
    }
    setStarIconLoading(true);
    const product_asset = await setMarketingAsset(asset.product_asset.id, !asset.product_asset.marketing_use);

    searchStore.update({
      objects: searchStore.objects.map((item) => {
        if (item.id === asset.id) {
          return { ...item, product_asset };
        }
        return item;
      }),
    } as IParametronData);
    if (page === Pages.PRODUCT_ASSETS_TAB) {
      setProductAssets?.((productAssets) => {
        return [...productAssets.filter((productAsset) => product_asset.id !== productAsset.id), product_asset];
      });
    }
    setStarIconLoading(false);
  }, [asset, searchStore, page, setProductAssets]);

  const setShowInPlayer = useCallback(async () => {
    const mm3Asset = asset as IMm3Asset & AmGenericAsset3DigitalSubtitle;
    if (!mm3Asset?.id || mm3Asset?.root || !mm3Asset?.meta || !searchStore?.update) {
      return;
    }

    const [assetWithFullMeta] = await getAssets<IMm3Asset & AmGenericAsset3DigitalSubtitle>([mm3Asset.id], 'id, meta');
    if (!assetWithFullMeta?.meta) {
      return;
    }
    const { object: result } = await saveMm3Asset(
      mm3Asset.id,
      {
        id: mm3Asset.id,
        type: mm3Asset.type,
        meta: { ...assetWithFullMeta?.meta, show_in_player: !mm3Asset.meta?.show_in_player },
      },
      assetDetailsSchema,
    );

    setIsAvailableOnPlayer(Boolean(result.meta?.show_in_player));
    searchStore.update({
      objects: searchStore.objects.map((item) => {
        return item.id !== result.id ? item : result;
      }),
    });
  }, [asset, searchStore]);

  const showInPlayerIcon =
    [IMm3AssetType.AUDIO, IMm3AssetType.SUBTITLE].includes((asset as IMm3Asset)?.type as IMm3AssetType) ||
    Boolean(asset?.classification?.match(/audio|subtitle/i));

  if (showInPlayerIcon && page !== Pages.LIST) {
    return (
      <Tooltip label="Make available in video player" position="bottom">
        <ActionIcon variant="subtle" color="gray.5" onClick={setShowInPlayer}>
          <MantineIcon icon={<Video color={isAvailableOnPlayer ? 'white' : 'gray'} />} />
        </ActionIcon>
      </Tooltip>
    );
  }
  if (!asset?.product_asset?.id && page === Pages.PRODUCT_ASSETS_TAB) {
    return (
      <div className="marketing-star__descendants-icon">
        <MantineIcon variant="subtle" icon="inheritance" />
      </div>
    );
  }

  if (!asset?.product_asset?.id) {
    return null;
  }

  if (disableMarketingStar) {
    return (
      <div className="marketing-star__descendants-icon">
        <MantineIcon icon="star" />
      </div>
    );
  }

  return (
    <Tooltip label={'Marketing use'}>
      <ActionIcon variant="subtle" color="gray.5" onClick={setMarketingUse}>
        {starIconLoading ? (
          <Loader size={LoaderSize.SMALL} />
        ) : (
          <MantineIcon
            icon={
              asset.product_asset.marketing_use || (asset as AmGenericAsset3DigitalSubtitle).meta?.show_in_player ? (
                <Star />
              ) : (
                <StarEmpty />
              )
            }
          />
        )}
      </ActionIcon>
    </Tooltip>
  );
};
