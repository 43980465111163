import React, { useCallback, useMemo } from 'react';
import { useStore } from 'store';
import { IAsset, IMm3Asset } from 'types';
import { FormDate, FormCheckbox, useForm, useMm3Form } from 'helpers/form';

import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';
import { ToastError } from 'components/toast';

import { IAccessChangeScheduledAccessDelegation } from 'utils/actions/asset';

import { useScheduledJobs } from './hooks';
import { SharedScheduledForm } from 'components/scheduled-job-list/shared-scheduled-form-elements';
import { contextExtension } from './utils';
import { minAccessDate, maxAccessDate } from 'utils/constants';
import { Model } from 'helpers/filters/types';
import { getValidIds } from 'utils/general';
import { getIsMm3Assets } from 'utils/asset';

import 'components/scheduled-job-list/style.scss';

const renderDetails = (job): JSX.Element => <>Prevent delegation: {job.skip_access_delegation ? 'Yes' : 'No'}</>;

export const AccessChangeScheduledAccessDelegationForm: React.FC<{
  items: (IAsset | IMm3Asset)[];
  readOnly?: boolean;
}> = ({ items, readOnly = false }) => {
  const isMm3Assets = getIsMm3Assets();

  const formId = `asset-access-change-scheduled-access-delegation`;
  const { toastStore } = useStore();
  const itemIds = useMemo(() => getValidIds(items), [items]);

  const [scheduledJobs, removeJob, scheduleJob] = useScheduledJobs<IAccessChangeScheduledAccessDelegation>(
    'change_access_delegation_on_asset',
    itemIds,
  );

  const submitForm = useCallback(
    async (data: IAccessChangeScheduledAccessDelegation) => {
      try {
        await scheduleJob(data);
        toastStore.success('Access delegation changed');
      } catch (error) {
        toastStore.error(<ToastError error={error} placeholder={'Sorry, something went wrong'} />);
      }
    },
    [toastStore, scheduleJob],
  );
  const initialValues = {
    scheduled_for: '',
    skip_access_delegation: false,
    item_ids: itemIds,
  };
  const mm3Form = useMm3Form<IAccessChangeScheduledAccessDelegation>(initialValues, Model.MM3_ASSETS, submitForm);

  const coreForm = useForm<IAccessChangeScheduledAccessDelegation>(
    initialValues,
    Model.ASSETS,
    submitForm,
    contextExtension,
  );
  const currentForm = isMm3Assets ? mm3Form : coreForm;
  const { formData, handlers, valid, onSubmit } = currentForm;
  const disableSubmit = scheduledJobs.find(({ scheduled_for }) => scheduled_for === formData.scheduled_for.value);

  return (
    <SharedScheduledForm
      readOnly={readOnly}
      renderDetails={renderDetails}
      onSubmit={onSubmit}
      removeJob={removeJob}
      scheduledJobs={scheduledJobs}
      submitDisabled={!valid || Boolean(disableSubmit)}
      title="Scheduled Access Delegation Prevention"
    >
      <FormSectionWrapperV2 title="Schedule new Access Delegation" gridTemplateColumns="1fr">
        <FormDate
          name="scheduled_for"
          label="Date"
          large
          withTime={true}
          minDate={minAccessDate}
          maxDate={maxAccessDate}
          {...formData.scheduled_for}
          {...handlers}
        />
        <FormCheckbox
          name="skip_access_delegation"
          label="Prevent (Product) Access Delegation"
          formId={formId}
          {...formData.skip_access_delegation}
          {...handlers}
        />
      </FormSectionWrapperV2>
    </SharedScheduledForm>
  );
};
