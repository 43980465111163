import React from 'react';

import { FormMultiSelect, FormInput } from 'helpers/form';

import { withAssetVersion } from 'utils/asset';
import { IAssetMm3AboutSectionProps, IAssetOldAboutSectionProps } from './types';
import { getMm3Tags } from 'components/asset/edit-sections/utils';
import FormSectionWrapperV2 from 'components/form-section-wrapper-v2/form-section-wrapper-v2';

export const AssetOldIdentifiersSection: React.FC<IAssetOldAboutSectionProps> = ({ formData, handlers }) => {
  return (
    <FormSectionWrapperV2 gridTemplateColumns="1fr 1fr 1fr" title="Identifiers">
      <FormMultiSelect
        name="tags"
        label="Tags"
        allowNewItems
        {...formData.tags}
        {...handlers}
        options={formData.tags?.value}
        noResults={null}
        hideClearIfEmptySelect
      />
      <FormInput name="external_reference" label="External Reference" {...formData.external_reference} {...handlers} />
      <FormInput name="archive_number" label="Archive Number" {...formData.archive_number} {...handlers} />
    </FormSectionWrapperV2>
  );
};

export const AssetMm3IdentifiersSection: React.FC<IAssetMm3AboutSectionProps> = ({ formData, handlers }) => {
  return (
    <FormSectionWrapperV2 gridTemplateColumns="1fr 1fr 1fr" title="Identifiers">
      <FormMultiSelect
        name="tags"
        label="Tags"
        placeholder="Add Tags"
        allowNewItems
        {...formData.tags}
        {...handlers}
        fetchValues={getMm3Tags}
        noResults={null}
        hideClearIfEmptySelect
      />
      <FormInput
        label="External Reference"
        name="external_reference"
        placeholder="Type External Reference"
        {...formData.external_reference}
        {...handlers}
      />

      <FormInput
        label="Asset Identifier"
        name="meta.asset_identifier"
        placeholder="Type Asset Identifier"
        {...formData.meta?.asset_identifier}
        {...handlers}
      />

      <FormInput
        label="Archive Number"
        name="meta.archive_number"
        placeholder="Type Archive Number"
        {...formData.meta?.archive_number}
        {...handlers}
      />
    </FormSectionWrapperV2>
  );
};

export default withAssetVersion(AssetOldIdentifiersSection, AssetMm3IdentifiersSection);
