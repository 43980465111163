import React from 'react';
import { Loader, noop } from '@mantine/core';
import cx from 'classnames';

import { ThumbnailPlaceholderWrapper } from 'components/thumbnail';
import { MantineIcon } from 'utils/ui/icon';
import { Intent } from 'utils/ui';
import { readyStatuses } from 'utils/asset';

import './style.scss';

const notReadyStatuses = ['ingesting', 'available', 'pending', 'reingesting', 'created'];

const showLoadingIcon = (status: string): boolean => {
  return notReadyStatuses.some((s) => status.includes(s));
};

interface IFileStatusProps {
  status?: string | null;
  small?: boolean;
  size?: number;
  withBackground?: boolean;
  type?: string;
  isShellAsset?: boolean;
  onClick?: () => void;
}

const previewPrefix = 'preview_';
const fileStatusToLabel = {
  invalid: 'File ingest failed',
  ingesting: 'Ingesting',
  available: 'Generating preview',
  pending: 'Pending',
  reingesting: 'Reingesting',
  created: 'Created',

  [`${previewPrefix}invalid`]: 'Preview ingest failed',
  [`${previewPrefix}ingesting`]: 'Preview ingesting',
  [`${previewPrefix}available`]: 'Generating preview',
  [`${previewPrefix}pending`]: 'Preview generation pending',
  [`${previewPrefix}reingesting`]: 'Preview reingesting',
  [`${previewPrefix}created`]: 'Preview ingesting',

  shellAsset: 'Upload File',
};

export const FileStatus: React.FC<IFileStatusProps> = ({
  status,
  small = false,
  withBackground = false,
  size = 15,
  type = '',
  isShellAsset,
  onClick,
}) => {
  if (!status || readyStatuses.has(status || '')) {
    return <></>;
  }

  const fileStatus = !type || status.startsWith('preview_') ? status : `${type}_${status}`;
  const isShellAssetLabel = isShellAsset && fileStatusToLabel[fileStatus] === 'Upload File';

  return (
    <div
      className={cx('d-flex align-items-center justify-content-center', {
        'file-status--with-bg': withBackground,
        'file-status ': !withBackground,
        'file-status--shell-asset': isShellAssetLabel,
      })}
    >
      {showLoadingIcon(status) && !isShellAsset && (
        <Loader size={size} className="file-status_spinner" variant={Intent.PRIMARY} />
      )}
      {status === 'invalid' && <MantineIcon size={size} icon="issue" />}
      {status === 'cancelled' && <div className="file-status__text mx-2">Cancelled</div>}
      {!small && fileStatusToLabel[fileStatus] && (
        <div className={cx('file-status__text mx-2')} onClick={isShellAssetLabel ? onClick : noop}>
          {fileStatusToLabel[fileStatus]}
        </div>
      )}
    </div>
  );
};

export const ThumbnailFileStatus: React.FC<IFileStatusProps> = (props) => {
  if (readyStatuses.has(props.status || '')) {
    return null;
  }

  return (
    <ThumbnailPlaceholderWrapper className="align-items-center">
      <FileStatus {...props} />
    </ThumbnailPlaceholderWrapper>
  );
};
export default FileStatus;
