import React from 'react';
import { observer } from 'mobx-react-lite';

import { FilterCheckbox, FilterSelect } from 'helpers/filters/fields';
import { IFiltersHandlers } from 'helpers/filters';

import { IAsset } from 'types';
import { DataSectionStore } from 'store/data-section-store';
import {
  IAssetFilters,
  protectionLevelsOptionsProvider,
  statusOptionsProvider,
} from 'components/asset/asset-filters/utils';
import { getIsMm3Assets } from 'utils/asset';

interface IAccessFiltersProps {
  dataSectionStore: DataSectionStore<IAsset>;
  filterHandlers: IFiltersHandlers<IAssetFilters>;
  filterValues: IAssetFilters;
}

export const AssetSettingsFilters = observer(
  ({ filterValues, filterHandlers, dataSectionStore }: IAccessFiltersProps) => {
    const { searchStore } = dataSectionStore;
    const isMm3Assets = getIsMm3Assets();

    return (
      <>
        <FilterCheckbox
          label="Status"
          name="status"
          optionsProvider={statusOptionsProvider}
          disabled={searchStore.running}
          filter={filterValues.status}
          onChange={filterHandlers.onChange}
          aggregations={searchStore.aggregationValues('status')}
        />

        {isMm3Assets ? (
          <></>
        ) : (
          <FilterSelect
            label="Protection Levels"
            name="protection_levels"
            placeholder="Select Protection Level"
            optionsProvider={protectionLevelsOptionsProvider}
            filter={filterValues.protection_levels}
            onChange={filterHandlers.onChange}
            aggregations={searchStore.aggregationValues('protection_levels')}
          />
        )}
      </>
    );
  },
);
