import React from 'react';
import { filter } from 'lodash';
import { ActionIcon } from '@mantine/core';

import { useStore } from 'store/index';
import { IMeetingPropsFilters } from './calendar';
import { MantineIcon } from 'utils/ui/icon';
import { FormInput, FormSelect } from 'helpers/form';

type IFilterItem = {
  id: number;
  name: string;
  filters: IMeetingPropsFilters;
};

interface IProps {
  filters: IMeetingPropsFilters;
  onChange: (filters: IMeetingPropsFilters) => void;
  matchingFilter?: IFilterItem;
  filtersToString?: (filters: IMeetingPropsFilters) => string;
  savedFilters?: IFilterItem[];
  busy?: boolean;
  setBusy?: (busy: boolean) => void;
  removeSchedulerFilter?: (id: number) => Promise<boolean>;
  setSavedFilters?: (filters: IFilterItem[]) => void;
  presetName?: string;
  setPresetName?: (name: string) => void;
  updateSchedulerFilter?: (
    filterItem: IFilterItem,
    newFilters: IMeetingPropsFilters,
  ) => Promise<IFilterItem | undefined>;
  saveSchedulerFilter?: (name: string, filters: IMeetingPropsFilters) => Promise<IFilterItem | undefined>;
}

export const SaveFilterPreset: React.FC<IProps> = (props: IProps) => {
  const {
    filters,
    matchingFilter,
    filtersToString,
    savedFilters,
    busy,
    setBusy,
    setSavedFilters,
    presetName,
    setPresetName,
    updateSchedulerFilter,
    saveSchedulerFilter,
  } = props;

  const isEmptyFilter = filtersToString && !filtersToString(filters);
  const { toastStore } = useStore();

  const matchingFilterName = (
    filterName: string,
  ): { id: number; name: string; filters: IMeetingPropsFilters } | undefined =>
    savedFilters?.find((filter) => filter.name === filterName);

  const saveFilter = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault();
    if (
      isEmptyFilter ||
      !filtersToString ||
      !saveSchedulerFilter ||
      !updateSchedulerFilter ||
      !setPresetName ||
      !setBusy ||
      !setSavedFilters
    )
      return;
    if (matchingFilter) return;
    const cleanFilterName = presetName?.trim();
    if (!cleanFilterName) return;
    const filterToUpdate = matchingFilterName(cleanFilterName);
    setBusy(true);
    const newItem = filterToUpdate
      ? await updateSchedulerFilter(filterToUpdate, filters)
      : await saveSchedulerFilter(cleanFilterName, filters);

    if (newItem) {
      toastStore.success(`New preset "${cleanFilterName}" saved`);
      setSavedFilters([...(filter(savedFilters, (filter) => filter.id !== newItem.id) || []), newItem]);
      setPresetName('');
      setBusy(false);
    } else {
      setBusy(false);
    }
  };

  return (
    <div className="filter-preset-selector-wrapper">
      <>
        <FormInput
          name="presetName"
          placeholder="Type Preset Name"
          onChange={({ presetName }) => setPresetName && setPresetName(presetName || '')}
          value={presetName || ''}
          disabled={busy}
          size="xs"
          className="filter-preset-save__selector"
          showClearButton
        />
        <ActionIcon
          onClick={saveFilter}
          disabled={!presetName?.trim().length || busy}
          className="filter-preset-selector__save-btn"
          loading={busy}
          variant="subtle"
          color="gray.5"
        >
          <MantineIcon icon="floppy-disk" />
        </ActionIcon>
      </>
    </div>
  );
};

export const SelectFilterPreset: React.FC<IProps> = (props: IProps) => {
  const { onChange, matchingFilter, savedFilters, busy, setBusy, removeSchedulerFilter, setSavedFilters } = props;

  const { toastStore } = useStore();

  const setFilterName = (id: string): void => {
    if (!id) {
      onChange({});
      return;
    }

    const selectedFilters = savedFilters?.find((savedFilter) => savedFilter.id === parseInt(id, 10));
    if (selectedFilters) onChange(selectedFilters.filters);
  };

  const removeFilter = async (): Promise<void> => {
    if (!matchingFilter || !removeSchedulerFilter || !setBusy || !setSavedFilters) return;
    setBusy(true);
    if (await removeSchedulerFilter(matchingFilter.id)) {
      toastStore.success(`Preset "${matchingFilter.name}" removed`);
      setSavedFilters(savedFilters?.filter((filter) => filter.id !== matchingFilter.id) || []);
      onChange({});
      setBusy(false);
    } else {
      setBusy(false);
    }
  };

  return (
    <>
      <div className="meetings-filter__preset">
        <FormSelect
          name="preset"
          placeholder="Select preset"
          value={matchingFilter?.id || null}
          options={savedFilters?.map((savedFilter) => ({ value: savedFilter.id, label: savedFilter.name }))}
          disabled={!savedFilters?.length || busy}
          onChange={(newValue) => {
            if (!newValue || !newValue['preset']) {
              setFilterName('');
            } else {
              setFilterName(String(newValue['preset']));
            }
          }}
          size="xs"
          onDeleteItem={removeFilter}
        />
      </div>
    </>
  );
};
