import React, { useMemo, useState, useRef, useCallback } from 'react';
import { ActionIcon, Button } from '@mantine/core';
import { omit, isEmpty, map, extend } from 'lodash';

import FullCalendar, { EventSourceFunc, PluginDef, DateSelectArg, EventInput } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { ResourceFunc, ResourceInput } from '@fullcalendar/resource-common';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import cn from 'classnames';

import { STORAGE_KEYS, getStorageItem } from 'utils/storage';

import {
  headerToolbar,
  renderEventContent,
  views,
  handleMeetingsSearch,
  localTimezone,
  businessHours,
  resourceViewKey,
  onCalendarMouseMove,
  onCalendarClick,
  MeetingTypes,
  updateHostsCache,
} from './utils';

import { useCreateMeeting, useExportCalendar, usePreviewMeeting, useTimezone } from './hooks';
import { SCHEDULER_LICENSE_KEY } from './constants';
import MeetingFilters from './meeting-filters';
import { fetchRooms, fetchSchedulerHosts } from './api';
import { resourceLabelContent } from './ui';
import { emailValidation } from 'utils/validations';
import { DashboardBreadcrumbs } from 'components/dashboard-breadcrumbs';

import { CustomListView } from './list-view';
import { Loading } from 'components/loading';
import { MantineIcon } from 'utils/ui/icon';
import { Export } from 'blueprint5-icons';
import { ItemId } from 'types/utility';
import { IContact } from 'types';

const plugins: PluginDef[] = [
  dayGridPlugin,
  interactionPlugin,
  timeGridPlugin,
  listPlugin,
  resourceTimelinePlugin,
  momentTimezonePlugin,
];

export interface IMeetingPropsFilters {
  starts_at?: [Date, Date];
  host_id?: string | null;
  location_id?: string | null;
  invites_id?: string | null;
  participant_ids?: string | null;
  only_me?: boolean;
  type?: MeetingTypes;
}

const isMediumScreen = window.matchMedia('(max-width: 1500px)').matches;

export const Scheduler: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const calendarRef = useRef<FullCalendar>(null);
  const handleEventClick = usePreviewMeeting(calendarRef);
  const handleExport = useExportCalendar();
  const [filters, setFilters] = useState<IMeetingPropsFilters>({ type: MeetingTypes.REGULAR });
  const [timezone, setTimezone] = useTimezone();

  const createMeeting = useCreateMeeting();
  const [events, setEvents] = useState<EventInput[]>([]);
  const [isListView, setIsListView] = useState(false);
  const calendarApi = calendarRef.current?.getApi();

  const inviteesFilter = useMemo(() => {
    const isId = !Number.isNaN(Number(filters.invites_id));
    const isValidEmail = emailValidation({}, filters.invites_id || '')[0];

    if (isId) return { 'invites.user_id': filters.invites_id };
    if (!isId && isValidEmail) return { 'invites.email': filters.invites_id };

    return {};
  }, [filters.invites_id]);

  const fetchEvents = useCallback<EventSourceFunc>(
    (info, successCallback, failureCallback) => {
      const { start, end } = info;
      let params = { ...info, starts_at: [start, end] };

      const handleSuccess = (events: EventInput[]): void => {
        setEvents(events);
        successCallback(events);
      };

      const parsedFilters = Object.assign({ ...omit(filters, 'invites_id', 'only_me') }, inviteesFilter);
      params = { ...params, timeZone: timezone, ...parsedFilters };

      return handleMeetingsSearch(params, handleSuccess, failureCallback);
    },
    [filters, timezone, inviteesFilter],
  );

  const handleCreateEvent = useCallback(() => {
    const { current: calendarDom } = calendarRef;
    if (!calendarDom) {
      return;
    }

    const API = calendarDom.getApi();
    createMeeting(API, timezone);
  }, [createMeeting, timezone]);

  const onExport = useCallback(() => {
    handleExport(filters);
  }, [filters, handleExport]);

  const getHosts = useCallback(async (hostIds: ItemId[]): Promise<IContact[]> => {
    const cachedHosts = getStorageItem(STORAGE_KEYS.MEETING_HOSTS) as unknown as IContact[];
    if (cachedHosts?.length) {
      return Promise.resolve(cachedHosts);
    }

    const hostPromise = fetchSchedulerHosts({ ids: hostIds as ItemId[] })
      .then((hosts) => {
        updateHostsCache(hosts);
        return hosts;
      })
      .catch((error) => {
        throw error;
      });

    return hostPromise;
  }, []);

  const resourcesCache = useRef({});
  const fetchResources = useCallback<ResourceFunc>(
    async (info, successCallback, failureCallback): Promise<void> => {
      const dateKey = `${info.startStr}-${info.endStr}`;
      const withoutFilters = !(filters.location_id && filters.host_id);
      const cacheKey = withoutFilters
        ? `calendar-resources-${dateKey}`
        : `calendar-resources-${filters.location_id}-${filters.host_id}-${dateKey}`;

      // Check if cache exists and is valid for the current date range
      if (resourcesCache.current[cacheKey] && !!filters.type) {
        return successCallback(resourcesCache.current[cacheKey]);
      }

      const eventHostIds = events.map((event) => event.host?.id.filter(Boolean));
      const hostIds = filters?.host_id ? [filters?.host_id] : eventHostIds;

      try {
        const [locations, hosts] = await Promise.all([
          fetchRooms(info, filters.location_id || ''),
          getHosts(hostIds as ItemId[]),
        ]);

        const mappedLocations = map(locations, (o) => extend({ type: 'locations' }, o));
        const mappedHosts = map(hosts, (o) => extend({ type: 'hosts' }, o));

        const filteredLocations = filters.host_id
          ? mappedLocations.filter(
              ({ host_ids }: ResourceInput) => !host_ids?.length || host_ids.includes(filters.host_id),
            )
          : mappedLocations;
        const resources = [...filteredLocations, ...mappedHosts];
        resourcesCache.current[cacheKey] = resources;
        successCallback(resources);
      } catch (error) {
        failureCallback(error);
      }
    },
    [filters.location_id, filters.host_id, filters.type, events, getHosts],
  );

  const onSelect = useCallback(
    (info: DateSelectArg): void => {
      if (!calendarRef.current) {
        return;
      }
      const calendarApi = calendarRef.current.getApi();
      const isResourceView = calendarApi.view.type === resourceViewKey;
      return createMeeting(info, isResourceView ? undefined : calendarApi.getOption('timeZone'));
    },
    [createMeeting],
  );

  const onViewMount = useCallback((e) => {
    const isOnListView = e?.view?.type === 'list';
    setIsListView(isOnListView);

    if (!isOnListView) {
      return;
    }

    if (!calendarRef.current) {
      return;
    }

    const calendarApi = calendarRef.current.getApi();
    calendarApi.refetchEvents();
  }, []);

  return (
    <>
      {isLoading && <Loading text="Loading Data" className="calendar-wrapper-preloader" />}
      <div className={cn('scheduler-view', isLoading && 'scheduler-view-loading')}>
        <div className="d-flex justify-content-between mb-3">
          <DashboardBreadcrumbs className="d-inline-block mb-3" />
          <div className="calendar-actions">
            <Button size="xs" leftSection={<MantineIcon icon="Plus" />} onClick={handleCreateEvent} variant="primary">
              {'Create a Meeting'}
            </Button>
            <ActionIcon className="export-button" variant="subtle" color="gray.5" onClick={onExport}>
              <MantineIcon icon={<Export />} />
            </ActionIcon>
          </div>
        </div>

        <div className="calendar-wrapper-outer pb-1">
          <MeetingFilters
            setFilters={setFilters}
            setTimezone={setTimezone}
            timezone={timezone}
            filters={filters}
            events={events}
          />

          <div className="calendar-wrapper-inner">
            <div
              className="calendar m-3"
              onMouseMove={(e) => onCalendarMouseMove(e, calendarRef)}
              onClick={(e) => onCalendarClick(e, calendarRef)}
            >
              <FullCalendar
                weekNumbers={true}
                navLinks={true}
                timeZone={timezone === localTimezone ? 'local' : timezone}
                locale="en-GB"
                ref={calendarRef}
                schedulerLicenseKey={SCHEDULER_LICENSE_KEY}
                refetchResourcesOnNavigate
                resources={fetchResources}
                resourceOrder={'name, last_name'}
                views={views}
                plugins={plugins}
                initialView={resourceViewKey}
                resourcesInitiallyExpanded={false}
                select={onSelect}
                headerToolbar={headerToolbar}
                events={fetchEvents}
                eventClick={handleEventClick}
                selectConstraint="businessHours"
                businessHours={businessHours}
                eventContent={renderEventContent}
                resourceLabelContent={resourceLabelContent}
                resourceGroupField="type"
                slotMinWidth={50}
                editable
                selectable
                loading={setIsLoading}
                selectMirror
                weekends
                eventMaxStack={isMediumScreen ? 5 : 10}
                dayMaxEvents={3}
                nowIndicator
                eventStartEditable={false}
                eventResizableFromStart
                eventDurationEditable
                stickyHeaderDates
                contentHeight="auto"
                viewDidMount={onViewMount}
              />
              {isListView && !isEmpty(events) && !isLoading && (
                <CustomListView
                  events={events}
                  calendar={calendarRef}
                  date={calendarApi?.view?.title}
                  timeZone={timezone}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
