import React from 'react';

import { getEntityType } from 'utils/general';

import { InfoBoxV2 } from 'components/info-box-v2';
import { LabelValuePair as Row } from 'components/label-value-pair';
import { AssetFilters, withAssetVersion } from 'utils/asset';
import { FormInput } from 'helpers/form';
import type {
  IAssetMm3MetadataEditTabProps,
  IAssetOldMetadataEditTabProps,
} from 'components/asset/edit-sections/types';

export const AssetOldMetadataCreditsSection: React.FC<IAssetOldMetadataEditTabProps> = ({ asset, form }) => {
  const { formData, handlers } = form;

  const { classification } = asset || {};

  const assetItemType = getEntityType(classification);

  return (
    <InfoBoxV2 gridTemplateColumns="180px 1fr" title="Credits" hidden={assetItemType !== AssetFilters.IMAGE}>
      <Row
        label="copyright_notice"
        value={
          <FormInput
            name="default_layer.copyright_notice"
            type="textarea"
            placeholder="Type Copyright Notice"
            {...formData['default_layer.copyright_notice']}
            {...handlers}
          />
        }
        size="m"
        hideIfNoValue
      />
    </InfoBoxV2>
  );
};

export const AssetMm3MetadataCreditsSection: React.FC<IAssetMm3MetadataEditTabProps> = ({ asset, form }) => {
  const { formData, handlers } = form;

  const { classification } = asset || {};

  const assetItemType = getEntityType(classification);

  return (
    <InfoBoxV2 gridTemplateColumns="180px 1fr" title="Credits" hidden={assetItemType !== AssetFilters.IMAGE}>
      <Row
        label="copyright_notice"
        value={
          <FormInput
            name="meta.copyright_notice"
            type="textarea"
            placeholder="Type Copyright Notice"
            {...formData.meta?.copyright_notice}
            {...handlers}
          />
        }
        size="m"
        hideIfNoValue
      />
    </InfoBoxV2>
  );
};

export const AssetMetadataEditCreditsSection = withAssetVersion(
  AssetOldMetadataCreditsSection,
  AssetMm3MetadataCreditsSection,
);
