import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { AmGenericAsset3ProtectionOptions } from '@mediafellows/mm3-types';

import { SectionMessage, SectionMessageType } from 'components/section-message';
import { DataSectionPage } from 'components/layout';
import { Loading } from 'components/loading';

import { useRemote } from 'utils/hooks';
import { loadAsset, queryParents, querySubAssets } from 'utils/apis/asset';
import { getAssetProtections } from 'utils/apis/protections';
import { getIsMm3Assets } from 'utils/asset';
import { flags } from 'utils/flags';
import { amAssetProtectionRead, hasPower } from 'utils/powers';

import { IAsset, IMm3Asset } from 'types';

import { AssetDetailsLoaded } from './asset-details-loaded';

import './style.scss';

const AssetDetails: React.FC<{}> = observer(() => {
  const { assetId = '' } = useParams<{ assetId: string }>();

  const fetchAsset = useCallback(async (): Promise<IAsset | IMm3Asset> => {
    const assetPromise = loadAsset<IMm3Asset>(assetId);
    const isMm3Assets = getIsMm3Assets();
    if (!isMm3Assets) {
      const asset = await assetPromise;
      return asset;
    }
    const parentsPromise = queryParents({ asset_id: assetId, per: 1, page: 1 }, [], 'id, name');
    const childrenPromise = querySubAssets({ asset_id: assetId }, [], 'id, classification, type');
    const [asset, children, parents] = await Promise.all([assetPromise, childrenPromise, parentsPromise]);

    const parent = (parents || [])[0];
    return { ...asset, children, parent, parent_id: parent?.id };
  }, [assetId]);

  const [asset, loadingAsset, setAsset, refreshAsset] = useRemote(fetchAsset);

  const getProtections = useCallback(async (): Promise<AmGenericAsset3ProtectionOptions | undefined> => {
    if (!flags.showVideoProtectionsFeature || !hasPower(amAssetProtectionRead)) {
      return;
    }
    return getAssetProtections(assetId);
  }, [assetId]);

  const [protections, loadingProtections, , refreshProtections] = useRemote(getProtections);

  const loading = loadingAsset || loadingProtections;

  const refresh = useCallback(async () => {
    await Promise.all([refreshAsset(), refreshProtections()]);
    return;
  }, [refreshAsset, refreshProtections]);

  return (
    <DataSectionPage refresh={refresh} hideToggleButton>
      {loading && <Loading text="Loading Asset" />}
      {asset?.id && !loading && (
        <AssetDetailsLoaded
          asset={asset}
          setAsset={setAsset}
          protections={protections}
          refreshAsset={refresh}
          fetchAsset={fetchAsset}
        />
      )}
      {!loading && !asset?.id && <SectionMessage intent={SectionMessageType.EMPTY} />}
    </DataSectionPage>
  );
});

export default AssetDetails;
