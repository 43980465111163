import React, { useCallback } from 'react';
import { flatten } from 'lodash';
import { useStore } from 'store';
import { getAssetArtifacts } from 'utils/apis/asset';
import { loadDelivery, updateDeliveryDraft } from 'utils/apis/package-delivery';
import { DeliveriesGenericDeliveryPackage } from '@mediafellows/mm3-types';

import { ToastError } from 'components/toast';
import { buildDeliveryContent } from 'components/delivery/utils';
import { AddToDeliveryDraftForm } from './add-to-delivery-draft-form';
import { IAddToDelivery } from './types';
import type { ICreateWorkFlowDeliveryPayload } from 'utils/actions/types';
import { IModalSize } from 'components/dialogs/types';
import { getIsMm3Assets } from 'utils/asset';

export const useAddToDeliveryDraftDialog = ({ asset_ids }: IAddToDelivery): [() => void] => {
  const { dialogStore, toastStore } = useStore();
  const isMm3Assets = getIsMm3Assets();

  const onSubmit = useCallback(
    async ({ delivery_id, asset_ids }) => {
      const delivery = await loadDelivery(delivery_id);
      const artefacts = isMm3Assets
        ? await getAssetArtifacts(asset_ids)
        : await Promise.all(asset_ids.map(getAssetArtifacts));
      const formatedArtifacts = isMm3Assets ? artefacts : flatten(artefacts);

      const deliveryItems = buildDeliveryContent({ asset_ids, artefacts: formatedArtifacts });
      const updatedDelivery = {
        ...delivery,
        delivery_items: [...delivery.delivery_items, ...deliveryItems],
      } as ICreateWorkFlowDeliveryPayload<DeliveriesGenericDeliveryPackage>;

      try {
        await updateDeliveryDraft(updatedDelivery);
        dialogStore.close();
        toastStore.success('The Selected Assets were added to the package!');
      } catch (error) {
        toastStore.error(<ToastError error={error} />);
      }
    },
    [dialogStore, isMm3Assets, toastStore],
  );

  const body = useCallback((): JSX.Element => {
    return <AddToDeliveryDraftForm asset_ids={asset_ids} onAdd={onSubmit} onCancel={dialogStore.close} />;
  }, [asset_ids, onSubmit, dialogStore.close]);

  const openDialog = (): void => {
    dialogStore.openModal({
      title: 'Add Asset(s) to Delivery Package',
      body,
      size: IModalSize.M,
    });
  };

  return [openDialog];
};
