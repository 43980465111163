import React, { useCallback } from 'react';
import { ISelectionStandardized } from 'types';
import { useStore } from 'store';
import { IUseActionsOptionParam } from 'utils/actions';

import { SelectionAccessChangeForm } from './selection-access-change-form';
import { IModalSize } from 'components/dialogs/types';

export const useAccessChangeDialog = (
  items: ISelectionStandardized[] = [],
  options: IUseActionsOptionParam,
): [() => void] => {
  const { dialogStore } = useStore();

  const body = useCallback((): JSX.Element => {
    return <SelectionAccessChangeForm items={items} options={options} />;
  }, [items, options]);

  const openDialog = (): void => {
    dialogStore.openModal({
      title: `Change selection access`,
      body,
      size: IModalSize.XS,
    });
  };

  return [openDialog];
};
