import React from 'react';

import { InfoBoxV2 } from 'components/info-box-v2';
import { Row, yn } from 'components/label-value-pair';
import { TagsList } from 'components/entity-list';

import { formatAssetType } from 'utils/general';
import { useLanguages } from 'utils/hooks';
import { formatAssetPurpose, formatAssetSource } from 'utils/asset';

import { IMm3Asset, IMm3AssetType } from 'types';

export const AssetMm3MetadataGeneralSubTab: React.FC<{ asset: IMm3Asset }> = ({ asset }) => {
  const { name, main_classification, classification, type, source, tags, external_reference } = asset || {};
  const {
    language_id,
    user_md5,
    copyright_notice,
    notes,
    asset_identifier,
    archive_number,
    ai_generated,
    ai_notes,
    auto_generated,
    show_in_player,
    label,
    purpose,
  } = asset?.meta || {};
  const language = useLanguages([language_id]);
  const isImage: boolean = type === IMm3AssetType.IMAGE;
  const isAudio: boolean = type === IMm3AssetType.AUDIO;
  const isSubtitle: boolean = type === IMm3AssetType.SUBTITLE;
  const isAudioOrSubtitle = isAudio || isSubtitle;
  return (
    <div className="asset-metadata-tab h-100">
      <div className="asset-metadata-tab__infoboxes">
        <InfoBoxV2 gridTemplateColumns="180px 1fr" title="Essentials">
          <Row label="Name" value={name} size="m" />
          <Row label="Type" value={main_classification} size="m" />
          <Row label="Asset Type" value={formatAssetType(classification)} size="m" />
          <Row label="MD 5 User Provided" value={user_md5} size="m" />
          <Row label="Language" value={language} size="m" />
          <Row
            label="Language Approved"
            value={yn(asset?.meta?.language_id_approved)}
            size="m"
            hidden={!isAudioOrSubtitle}
          />
          <Row label="Copyright Notice" value={copyright_notice} size="m" hideIfNoValue hidden={!isImage} />
          <Row label="Notes" value={notes} size="m" html />
        </InfoBoxV2>
        <InfoBoxV2 gridTemplateColumns="180px 1fr" title="Identifiers">
          <TagsList tags={tags} className={'asset-metadata-tab__tag me-1'} />
          <Row label="External Reference" value={external_reference} size="m" />
          <Row label="Asset Identifier" value={asset_identifier} size="m" />
          <Row label="Archive Number" value={archive_number} size="m" />
        </InfoBoxV2>
        <InfoBoxV2 gridTemplateColumns="180px 1fr" title="Provenance">
          <Row label="Source" value={formatAssetSource(source || '')} size="m" />
          <Row label="AI-generated" rawLabel value={yn(ai_generated)} size="m" />
          <Row label="AI Notes" rawLabel value={ai_notes} size="m" />
          <Row label="Auto-generated" rawLabel value={yn(auto_generated)} size="m" hidden={!(isAudio || isSubtitle)} />
        </InfoBoxV2>
        {isAudioOrSubtitle && (
          <InfoBoxV2 gridTemplateColumns="180px 1fr" title="Player Settings">
            <Row label="Show in player" value={yn(show_in_player)} size="m" />
            <Row label="Label" value={label} size="m" />
            <Row label="Purpose" value={purpose && formatAssetPurpose(purpose)} size="m" />
          </InfoBoxV2>
        )}
      </div>
    </div>
  );
};

export default AssetMm3MetadataGeneralSubTab;
