import React, { useCallback } from 'react';

import { FormAssets, IFormMultiSelectProps } from 'helpers/form';

import { queryAssets } from 'utils/apis/asset';
import { queryAssetSelections } from 'utils/apis/selection';
import { IItem, IQueryParams } from 'types';

export const FormMultiAssets: React.FC<IFormMultiSelectProps & { includeSubAssets?: boolean }> = ({
  includeSubAssets,
  ...props
}) => {
  const queryAssetsAndSelections = useCallback(
    async ({ q, ids }: IQueryParams): Promise<IItem[]> => {
      const assets = await queryAssets({ q, ids }, includeSubAssets ? [] : [['root', 'eq', true]]);
      const selections = await queryAssetSelections({ q, ids });
      return [...assets, ...selections];
    },
    [includeSubAssets],
  );
  return <FormAssets fetchValues={queryAssetsAndSelections} {...props} />;
};
