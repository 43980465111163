import React, { useCallback } from 'react';
import { IRecommendationFormProps } from 'components/recommendation/recommendation-shared/types';
import { FormMultiSelect, IFormMultiSelectOption } from 'helpers/form';
import { getRecommendationAssetsFilters } from 'utils/recommendation';
import { queryAssets, querySubAssetsMultipleAssets } from 'utils/apis/asset';
import { queryAssetSelections } from 'utils/apis/selection';
import { IAsset, IGroup, IIdentifiable, IQueryParams, ISearchFilter } from 'types';
import { parseAssets } from './utils';
import { getValidIds } from 'utils/general';

export const queryAssetsSelections = async (
  params: IQueryParams,
  filters: ISearchFilter[] = [],
): Promise<((IAsset | IGroup) & IIdentifiable)[]> => {
  if (params?.ids?.length === 0) {
    return [];
  }
  return queryAssetSelections(params, filters);
};

export const RecommendProductsStepAssets: React.FC<IRecommendationFormProps> = ({ form: { formData, handlers } }) => {
  const assetFilters = getRecommendationAssetsFilters();
  const setAssets = useCallback(
    async (options: IFormMultiSelectOption[]) => {
      const ids = getValidIds(options);
      const result = await querySubAssetsMultipleAssets({ asset_id: ids });
      let subAssets = Object.values(result).flat();
      subAssets = subAssets.length ? subAssets.map((e) => ({ ...e, value: e.id, label: e.name as string })) : [];

      handlers.onChange(parseAssets([...options, ...(subAssets as IFormMultiSelectOption[])]));
    },
    [handlers],
  );

  const fetchAssets = useCallback(
    ({ q, ids }: IQueryParams): Promise<(IAsset | IGroup | string)[]> => {
      let assetPromise;
      let selectionPromise;
      if (!ids?.length) {
        assetPromise = queryAssets({ q }, assetFilters);
        selectionPromise = queryAssetsSelections({ q });
      } else {
        assetPromise = queryAssets({ ids }, assetFilters);
        selectionPromise = queryAssetsSelections({ ids });
      }

      return Promise.all([assetPromise, selectionPromise]).then((result) => result.flatMap((item) => item));
    },
    [assetFilters],
  );

  return (
    <FormMultiSelect
      name="assetList"
      label="Assets"
      fitInParent
      omni
      {...handlers}
      {...formData.assetList}
      onSelectedItemsChange={setAssets}
      fetchValues={fetchAssets}
    />
  );
};
