import React, { useCallback } from 'react';
import {
  FormInput,
  FormMultiSelect,
  FormOrganization,
  FormRemoteSelect,
  FormSelect,
  IFormMultiSelectOption,
} from 'helpers/form';
import { fetchRecipients, parseRecipients, validateEmail } from 'components/delivery/utils';
import { ILocalizationOrderStep } from 'components/localization-orders/types';
import { IContact } from 'types';
import { getContacts, queryContacts } from 'utils/apis/contacts';

import '../style.scss';

export const ProjectDetailStep: React.FC<ILocalizationOrderStep> = ({ orderForm }) => {
  const setRecipients = useCallback(
    (options: IFormMultiSelectOption[]): void => {
      const { recipient_list, recipients } = parseRecipients(options);
      orderForm.handlers.onChange({ recipient_list, recipients });
    },
    [orderForm.handlers],
  );

  const fetchContacts = async (q: string, id: number): Promise<IContact[]> => {
    if (id) {
      return getContacts({ contactIds: id, schema: 'id,first_name,last_name,email' });
    }

    const params: { q?: string } = { q };

    return queryContacts(params);
  };

  const setOrderType = useCallback(
    ({ type }) => {
      if (type.match(/Season|Series/)) {
        if (type.match(/Series/)) {
          orderForm.handlers.onChange({ type, order_template: 'LibraryTv', product_type: 'Series' });
        } else {
          orderForm.handlers.onChange({ type, order_template: 'LibraryTv', product_type: 'Season' });
        }
      } else if (type.match(/Film/)) {
        orderForm.handlers.onChange({ type, order_template: 'LibraryFilm', product_type: 'Film' });
      }
    },
    [orderForm.handlers],
  );

  return (
    <div className="localization-order-project__details h-100">
      <div className="project-details">
        <FormInput
          name="project_name"
          label="Project Name"
          {...orderForm.formData.project_name}
          {...orderForm.handlers}
        />
        <FormOrganization
          name="owner_organization_id"
          label="Organization"
          {...orderForm.formData.owner_organization_id}
          {...orderForm.handlers}
        />
        <FormSelect
          name="type"
          label="Release Type"
          large
          {...orderForm.formData.type}
          {...orderForm.handlers}
          options={[
            { value: 'LocalizationOrder::Series::LibraryTv', label: 'Series' },
            { value: 'LocalizationOrder::Season::LibraryTv', label: 'Season' },
            { value: 'LocalizationOrder::Film::LibraryFilm', label: 'Film' },
          ]}
          onChange={setOrderType}
        />
        <FormInput
          name="order_template"
          label="Order Template"
          {...orderForm.formData.order_template}
          {...orderForm.handlers}
          options={['LibraryTv, LibraryFilm']}
        />
      </div>

      <div className="order-details">
        <div className="vendor-details">
          <FormInput name="vendor" label="Vendor" {...orderForm.formData.vendor} {...orderForm.handlers} />
          <FormInput
            name="vendor_order_ref"
            label="Vendor Order Reference"
            {...orderForm.formData.vendor_order_ref}
            {...orderForm.handlers}
          />
          <FormRemoteSelect
            name="vendor_project_manager_id"
            label="Vendor Project Manager"
            {...orderForm.formData.vendor_project_manager_id}
            {...orderForm.handlers}
            fetchOptions={fetchContacts}
            large
            required
          />
          <FormRemoteSelect
            name="organization_project_manager_id"
            label="Organization Project Manager"
            {...orderForm.formData.organization_project_manager_id}
            {...orderForm.handlers}
            fetchOptions={fetchContacts}
            large
            required
          />
          <FormInput
            name="organization_order_ref"
            label="Organization Order Reference"
            {...orderForm.formData.organization_order_ref}
            {...orderForm.handlers}
          />
        </div>

        <div className="recipient-details">
          <FormMultiSelect
            className="recipients-selector"
            fetchValues={fetchRecipients}
            name="recipients"
            label="Notification Recipients"
            rawLabel
            omni
            allowNewItems
            onSelectedItemsChange={setRecipients}
            validateNewItem={validateEmail}
            {...orderForm.formData.recipients}
            {...orderForm.handlers}
            fitInParent
          />
        </div>
      </div>
    </div>
  );
};
