import React, { useEffect } from 'react';
import { Text } from '@mantine/core';

import { FormAssetTreeSelector, FormAssets } from 'helpers/form';
import { getRecommendationAssetsFilters } from 'utils/recommendation';
import { IMobileAppSyncAssetsForm } from './types';
import { querySubAssetsMultipleAssets } from 'utils/apis/asset';
import { getValidIds } from 'utils/general';

export const MobileAppSyncFormAssets: React.FC<IMobileAppSyncAssetsForm> = ({
  form,
  isEditingProducts,
  showMetadataHover,
  setLoadingAssets,
}) => {
  useEffect(() => {
    // for independent assets
    const fetchSubAssets = async (): Promise<void> => {
      if (!form.values.independentAssetIds) return;

      const result = await querySubAssetsMultipleAssets({ asset_id: form.values.independentAssetIds });
      const subAssetIds = getValidIds<number, string>(Object.values(result).flat());

      form.handlers.onChange({ independentAssetSubIds: subAssetIds });
    };

    fetchSubAssets();
  }, [form.values.independentAssetIds, form.values.independentAssetSubIds, form.handlers]);

  return (
    <div className="d-flex flex-column gap-1 h-100">
      <div className="mobile-app-sync-wizard__assets-container">
        <div>
          <Text>Product Assets</Text>
          <FormAssetTreeSelector
            form={form}
            allSelected
            isEditingProducts={isEditingProducts}
            showMetadataHover={showMetadataHover}
            setLoadingAssets={setLoadingAssets}
            className="mobile-app-sync-wizard__asset-tree-selector"
          />
        </div>

        <FormAssets
          name="independentAssetIds"
          label="Other Assets"
          fitInParent
          omni
          {...form.formData.independentAssetIds}
          {...form.handlers}
          filters={getRecommendationAssetsFilters()}
        />
      </div>
    </div>
  );
};
