import React, { useCallback } from 'react';
import { Button } from '@mantine/core';
import { observer } from 'mobx-react-lite';

import { AssetsUploaderStore } from 'store/assets-upload-store';
import { byId, pluralEntityInflect } from 'utils/general';
import { DetailsPageTabs, IUploadedFile } from 'types';
import { useStore } from 'store';
import { SectionHeader } from 'components/section-header';
import { ToastError } from 'components/toast';
import { createAssets } from 'pages/assets-create/api';
import { useNavigate } from 'react-router';
import { Routes } from 'utils/routes';
import { tabOptions as assetTabOptions } from 'pages/asset-details/utils';

interface ISubmitButtonProps {
  assetsUploaderStore: AssetsUploaderStore;
}

const getRouteFromStoreId = (storeId: string): string => {
  const idRegex = /\d/g;
  const entityId = storeId.match(idRegex)?.join('');
  const isSubAssetUpload = storeId?.startsWith('asset-details');
  const subAssetType = isSubAssetUpload && storeId.split('_')?.pop();
  const isValidTab = assetTabOptions.includes(subAssetType as DetailsPageTabs);

  if (storeId?.startsWith('product-details'))
    return entityId ? `${Routes.PRODUCTS}/${entityId}/${DetailsPageTabs.ASSETS}` : `${Routes.PRODUCTS}`;
  if (isSubAssetUpload && entityId) {
    return subAssetType && isValidTab ? `${Routes.ASSETS}/${entityId}/${subAssetType}` : `${Routes.ASSETS}/${entityId}`;
  }
  return `${Routes.ASSETS}`;
};

export const SubmitButton: React.FC<ISubmitButtonProps> = observer(({ assetsUploaderStore }) => {
  const {
    isValid,
    disableSubmit,
    files,
    filesRemote,
    cleanUp,
    showErrors,
    isSending,
    setIsSending,
    dropFileAreaSelector,
  } = assetsUploaderStore;

  const { toastStore } = useStore();
  const navigate = useNavigate();

  const handleSubmit = useCallback(async (): Promise<void> => {
    try {
      if (isSending) {
        return;
      }
      if (!isValid) {
        return showErrors();
      }
      setIsSending(true);
      const filesRemoteById = byId<IUploadedFile>(filesRemote);
      const successRoute = getRouteFromStoreId(dropFileAreaSelector);
      await createAssets(files, filesRemoteById);
      const assetsCount = files.length;
      const { entity } = pluralEntityInflect('Asset', assetsCount);
      toastStore.success(`${entity} ${assetsCount > 1 ? 'were' : 'was'} created successfully`);
      cleanUp();
      navigate(successRoute);
    } catch (error) {
      toastStore.error(<ToastError error={error} />);
    } finally {
      setIsSending(false);
    }
  }, [
    cleanUp,
    dropFileAreaSelector,
    files,
    filesRemote,
    isSending,
    isValid,
    navigate,
    setIsSending,
    showErrors,
    toastStore,
  ]);

  const cancel = useCallback(() => {
    cleanUp();
    navigate(`${Routes.ASSETS}`);
  }, [cleanUp, navigate]);

  return (
    <>
      <Button size="xs" variant="default" onClick={cancel} className="me-4">
        Cancel
      </Button>
      <Button size="xs" onClick={handleSubmit} variant="primary" disabled={disableSubmit}>
        Finish
      </Button>
    </>
  );
});

export const FormSubmitButton: React.FC<ISubmitButtonProps> = observer(({ assetsUploaderStore }) => {
  const CustomRightRenderer = useCallback(() => {
    return <SubmitButton assetsUploaderStore={assetsUploaderStore} />;
  }, [assetsUploaderStore]);

  return <SectionHeader customRightRenderer={CustomRightRenderer} useBackground />;
});

export default React.memo(FormSubmitButton);
