import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { isEqual } from 'lodash';

import { by } from 'utils/general';
import { formatFormLabel } from 'helpers/form/fields/helpers';
import { FormGroup } from 'helpers/form/fields/form-group';
import { IFieldData, IFieldHandlers } from 'helpers/form/types';
import { IOmniTreeNode, IOmniTreeSelectOption } from 'helpers/form/fields/form-select';

import {
  createDataTree,
  getAllCheckedNodes,
  getChildrenCheckedNodes,
  getParentCheckedNodes,
} from 'helpers/form/fields/form-select/tree-selector-utils';
import CheckboxTree from 'components/checkbox-tree/checkbox-tree';
import { IOmniCheckboxOption, prepareOmniOptions } from './utils';
import { Intent } from 'utils/ui';

import './style.scss';

interface IFormOmniCheckbox extends IFieldData<string[], IOmniCheckboxOption[]>, IFieldHandlers<string[]> {
  label: string;
  inline?: boolean;
  name: string;
  skipError?: boolean;
  onlyChildrenIds?: boolean;
}

const FormOmniCheckbox: React.FC<IFormOmniCheckbox> = ({
  onBlur,
  onChange,
  label,
  inline = false,
  name,
  options,
  required,
  touched,
  validation,
  value,
  skipError = false,
  onlyChildrenIds = false,
}) => {
  const showError = Boolean(touched && !validation?.valid && validation?.errorMessage);
  const intent = showError ? Intent.DANGER : undefined;
  const flatOptionsList = useMemo<IOmniTreeSelectOption[]>(() => prepareOmniOptions(options), [options]);
  const optionsTree = useMemo<IOmniTreeNode[]>(() => createDataTree(flatOptionsList), [flatOptionsList]);
  const [expanded, setExpanded] = useState<string[]>([]);
  const [checked, setChecked] = useState<string[]>([]);
  const [selectedItems, setSelectedItems] = useState<IOmniTreeSelectOption[]>([]);

  const handleBlur = useCallback(() => {
    onBlur?.(name);
  }, [name, onBlur]);

  const handleCheck = useCallback(
    (selectedValues) => {
      setChecked(selectedValues);
      const parentCheckedNodes = getParentCheckedNodes(optionsTree, selectedValues);
      const childrenCheckedNodes = getChildrenCheckedNodes(optionsTree, selectedValues);
      onChange?.({ [name]: onlyChildrenIds ? childrenCheckedNodes : parentCheckedNodes });

      const optionsByValue = by(flatOptionsList, 'value');
      const itemsSelected = parentCheckedNodes.map((value) => optionsByValue[value]);
      setSelectedItems(itemsSelected);

      handleBlur?.();
    },
    [optionsTree, onChange, name, onlyChildrenIds, flatOptionsList, handleBlur],
  );

  useEffect(() => {
    const oldValue = selectedItems.map(({ value }) => value).sort();
    const newValue = (value || []).sort();
    if (!optionsTree?.length || isEqual(newValue, oldValue)) {
      return;
    }

    handleCheck(getAllCheckedNodes(optionsTree, value));
  }, [handleCheck, optionsTree, selectedItems, value]);

  useEffect(() => {
    setExpanded(flatOptionsList[0]?.value ? [flatOptionsList[0].value] : []);
  }, [flatOptionsList]);

  return (
    <FormGroup
      label={formatFormLabel(label, required)}
      labelFor={name}
      intent={intent}
      helperText={showError && !skipError ? validation?.errorMessage : ''}
      inline={inline}
      className="form-omni-checkbox--container"
    >
      <CheckboxTree
        nodes={optionsTree}
        checked={checked}
        expanded={expanded}
        setExpanded={setExpanded}
        onCheck={handleCheck}
      />
    </FormGroup>
  );
};

export { FormOmniCheckbox };
