import React from 'react';

import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';
import { FormChecklist, FormCheckbox, FormAccessLevel, FormAssetStatus } from 'helpers/form';

import { hasPower, amWriteProtectedAttributes } from 'utils/powers';

import { IAssetMm3AboutSectionProps, IAssetOldAboutSectionProps } from './types';
import { withAssetVersion } from 'utils/asset';
import { useSessionStore } from 'store/session-store';
import { hasUserRoleAmWriteProtectedAttributesPower } from 'components/asset/asset-edit-form/utils';
import { UserRoles } from 'types';

export const AssetOldSettingsSection: React.FC<IAssetOldAboutSectionProps> = ({ formData, handlers, formId }) => {
  return (
    <FormSectionWrapperV2 gridTemplateColumns="1fr 1fr 1fr" title="Settings">
      <FormAccessLevel name="access_level" label="Access Level" large {...formData.access_level} {...handlers} />
      <FormAssetStatus name="status" label="Status" large {...formData.status} {...handlers} />

      <FormCheckbox
        name="sharable"
        mainLabel="Sharable"
        description="Allow non-Administrator contacts to share this asset with other contacts"
        formId={formId}
        {...formData.sharable}
        {...handlers}
        disabled={!hasPower(amWriteProtectedAttributes)}
        hideLabel
      />

      <FormChecklist
        name="permissions"
        label="Permissions"
        formId={formId}
        large
        {...formData.permissions}
        {...handlers}
      />

      <FormChecklist
        name="protection_levels"
        label="Protection Levels"
        formId={formId}
        large
        {...formData.protection_levels}
        {...handlers}
        className="asset-edit-modal__checklist--inline"
      />
    </FormSectionWrapperV2>
  );
};

export const AssetMm3SettingsSection: React.FC<IAssetMm3AboutSectionProps> = ({ formData, handlers, formId }) => {
  const { user } = useSessionStore();
  const isUserRoleAllowed = hasUserRoleAmWriteProtectedAttributesPower(user?.role_name as UserRoles);

  return (
    <FormSectionWrapperV2 gridTemplateColumns="1fr 1fr 1fr" title="Settings">
      <FormAccessLevel name="access_level" label="Access Level" large {...formData.access_level} {...handlers} />
      <FormAssetStatus name="status" label="Status" large {...formData.status} {...handlers} />

      <FormCheckbox
        name="sharable"
        mainLabel="Sharable"
        description="Allow non-Administrator contacts to share this asset with other contacts"
        formId={formId}
        {...formData.sharable}
        {...handlers}
        disabled={!isUserRoleAllowed}
        hideLabel
      />

      <FormChecklist
        name="permissions"
        label="Permissions"
        formId={formId}
        large
        {...formData.permissions}
        {...handlers}
      />
    </FormSectionWrapperV2>
  );
};

export default withAssetVersion(AssetOldSettingsSection, AssetMm3SettingsSection);
