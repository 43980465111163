import React, { useCallback, useState } from 'react';
import omit from 'lodash/omit';
import { Model } from 'helpers/filters/types';
import { useForm } from 'helpers/form';
import { createGuild } from 'utils/apis/guild';
import { useStore } from 'store';

import { initialValues } from 'components/guilds/initial-values';
import { prepareProductList } from 'components/recommendation/recommendation-shared/utils';

import { GuildWizard } from './guild-wizard';
import { IGuild } from './types';
import { IGroupAddContact } from 'types/group';
import { ItemId } from 'types/utility';
import { IProduct, ISelectionStandardized } from 'types';

interface IGuildWizardContainerProps {
  productIds?: ItemId[];
  assetIds?: ItemId[];
  organizationIds?: ItemId[];
  userIds?: ItemId[];
  userSelectionIds?: ItemId[];
  productSelections?: ISelectionStandardized[];
  contacts?: IGroupAddContact[];
  products?: IProduct[];
  onSuccess?: () => void;
  fitInParent?: boolean;
}

const makePartialOptions = (ids, type: 'contact-selection' | 'contact'): IGroupAddContact[] => {
  if (!ids) return [];
  return ids.map((id) => ({ id, type }));
};

export const GuildWizardContainer: React.FC<IGuildWizardContainerProps> = ({
  onSuccess,
  assetIds = [],
  organizationIds = [],
  userIds = [],
  contacts = [],
  products = [],
  userSelectionIds = [],
  productSelections = [],
  fitInParent = false,
}) => {
  const { toastStore } = useStore();
  const [isCreating, setIsCreating] = useState(false);

  const createAction = useCallback(
    async (values) => {
      setIsCreating(true);
      toastStore.info('Creating');
      const product_ids = (await prepareProductList<IGuild>(values)).product_ids;
      const payload = omit({ ...values, product_ids }, ['productList', 'product_selections', 'assetList']) as IGuild;
      await createGuild(payload);
      toastStore.clearAll();
      toastStore.success('Guild was created successfully');
      setIsCreating(false);
      onSuccess?.();
    },
    [onSuccess, toastStore],
  );

  const form = useForm<IGuild>(
    {
      ...initialValues,
      product_ids: products?.map(({ id }) => id) || [],
      productList: [...(products || []).map((e) => e.id), ...(productSelections || []).map((s) => s.id)] || [],
      product_selections: productSelections || [],
      asset_ids: assetIds,
      organization_ids: organizationIds,
      contacts: [
        ...contacts,
        ...makePartialOptions(userIds, 'contact'),
        ...makePartialOptions(userSelectionIds, 'contact-selection'),
      ],
    },
    Model.GUILDS,
    createAction,
  );

  return <GuildWizard form={form} isCreating={isCreating} fitInParent={fitInParent} />;
};
