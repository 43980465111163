import React, { useCallback } from 'react';
import { UmGenericListShowcase } from '@mediafellows/mm3-types';

import { FormInput, IFormMultiSelectOption, IFormHandlers, IFieldData, IFieldOptions } from 'helpers/form';
import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';
import { formatCollectionValueToOption } from 'utils/list';
import { slugify } from 'utils/slug';

import { IGenericListCollectionForm, formatItemTypesOptions } from './utils';
import { IFormData } from 'helpers/form/mm3';

import { flags } from 'utils/flags';
import { ItemTypes } from 'helpers/form/fields/select-helpers';
import { hasPower } from 'utils/powers';

export function useShowcaseFormHandlers<
  T extends Pick<UmGenericListShowcase, 'sublist' | 'allowed_item_types'> = UmGenericListShowcase,
>(
  isCollection: boolean,
  values: T,
  formData: IFormData<T>,
  handlers: Omit<IFormHandlers<T>, 'onSetFields'>,
): {
  allowedItemTypes: ({ ids }: { ids: number[] }) => Promise<IFormMultiSelectOption[]>;
  onAllowedItemTypesChange: ({ allowed_item_types }: Partial<T>) => void;
} {
  const allowedItemTypes = useCallback(
    async ({ ids }): Promise<IFormMultiSelectOption[]> => {
      if (ids?.length) {
        return ids.map(formatItemTypesOptions);
      }

      let options: ItemTypes[] = [];

      if (isCollection) {
        return [ItemTypes.SEPARATOR, ItemTypes.PRODUCT, ...(values?.sublist ? [] : [ItemTypes.LIST_COLLECTION])].map(
          formatCollectionValueToOption,
        );
      } else {
        const hideCollection = !flags.showCollectionsFeature;
        options = (
          ((formData?.allowed_item_types as IFieldData<T['allowed_item_types'], IFieldOptions>)
            .options as ItemTypes[]) || []
        ).filter((option) => {
          return hideCollection
            ? ![ItemTypes.SEPARATOR, ItemTypes.LIST_COLLECTION].includes(option)
            : option !== ItemTypes.SEPARATOR;
        });
      }
      return options.map(formatItemTypesOptions);
    },
    [formData?.allowed_item_types, isCollection, values?.sublist],
  );

  const onAllowedItemTypesChange = useCallback(
    ({ allowed_item_types }: Partial<T>) => {
      handlers?.onChange({
        allowed_item_types,
        ...(values?.sublist && allowed_item_types?.includes(ItemTypes.LIST_COLLECTION) ? { sublist: false } : {}),
      } as T);
    },
    [handlers, values?.sublist],
  );

  return { allowedItemTypes, onAllowedItemTypesChange };
}
const ShowcaseCommonForm: React.FC<
  Partial<IUseMm3FormReturn<UmGenericListShowcase | IGenericListCollectionForm>> & {
    children: JSX.Element | JSX.Element[];
  }
> = ({ handlers, formData, values, children }) => {
  const onNameChange = useCallback(
    ({ name }) => {
      handlers?.onChange?.({ name, ...(!formData?.purpose?.touched ? { purpose: slugify(name) } : {}) });
    },
    [formData?.purpose?.touched, handlers],
  );

  const disablePurposeField =
    Boolean(values?.id) && values?.type === 'List::Showcase' && !hasPower('um/list_delete_showcases');

  return (
    <div className="d-flex flex-column justify-content-between h-100">
      <FormInput name="name" label="Name" {...handlers} {...formData?.name} onChange={onNameChange} />
      <FormInput
        name="purpose"
        label="Identifier"
        {...handlers}
        {...formData?.purpose}
        disabled={disablePurposeField}
      />
      {children}
    </div>
  );
};

export { ShowcaseCommonForm };
