import React, { useCallback } from 'react';
import {
  Combobox,
  Pill,
  PillsInput,
  CloseButton,
  Loader,
  Menu,
  Button,
  Flex,
  ComboboxOptionProps,
  ComboboxStore,
  MantineSize,
} from '@mantine/core';

import { compact } from 'lodash';

import { getFieldPlaceholder } from 'helpers/form/fields/helpers';
import { IContact, ICustomItemRendererProps, IProduct, ItemId } from 'types';
import { GroupTagElement, exactEmailMatch, filterSelectedItems } from './utils';
import { itemRenderer, IFormMultiSelectOption } from 'helpers/form/fields/select-helpers';
import { by } from 'utils/general';

import { AncestryDropdown, IBreadcrumbProps } from './ancestry-dropdown/ancestry-dropdown';
import { Classes, MantineIcon } from 'utils/ui';

import './style.scss';
import { MenuItemProps } from '@blueprintjs/core';
import { IFilterOption } from 'utils/hooks';

const InputRightSection: React.FC<{
  handleClearAll: (name: string) => void;
  hideClearAllButton?: boolean;
  inputValue: string[];
  isEmptyMultiSelect: boolean;
  isLoading: boolean;
  isWrapInputDisabled?: boolean;
  name: string;
}> = ({ handleClearAll, hideClearAllButton, inputValue, isEmptyMultiSelect, isLoading, isWrapInputDisabled, name }) => {
  if (isLoading) return <Loader size={18} />;
  if (hideClearAllButton || isEmptyMultiSelect || inputValue[0]) return <></>;

  return (
    <CloseButton
      size="md"
      onMouseDown={(event) => event.preventDefault()}
      onClick={() => handleClearAll(name)}
      aria-label="Clear field"
      disabled={isEmptyMultiSelect || isWrapInputDisabled}
      className="form-multi-select__clear-btn"
    />
  );
};

export const MultiSelectSelectedItems: React.FC<{
  combobox: ComboboxStore;
  isLoading: boolean;
  omni: boolean;
  hideClearAllButton?: boolean;
  inputSize: MantineSize;
  disabled?: boolean;
  handleBlur: VoidFunction;
  inputValue: string[];
  selectedItems: IFormMultiSelectOption[];
  name: string;
  hideSelectedItems: boolean;
  tags: React.ReactNode[];
  isRemoteInitialized?: boolean | null;
  inputRef: React.RefObject<HTMLInputElement>;
  setInputValue: React.Dispatch<React.SetStateAction<string[]>>;
  handleAdd: VoidFunction;
  isRemoteMode: boolean;
  setCanLoadRemoteOptions: React.Dispatch<React.SetStateAction<boolean>>;
  allowNewItems: boolean;
  placeholder?: string;
  formattedLabel: string;
  setSelectedItems: React.Dispatch<React.SetStateAction<IFormMultiSelectOption[]>>;
  onChange?: (newValue: { [key: string]: ItemId[] | null }) => void;
}> = ({
  combobox,
  isLoading,
  omni,
  hideClearAllButton,
  inputSize,
  disabled,
  handleBlur,
  inputValue,
  selectedItems,
  name,
  hideSelectedItems,
  tags,
  isRemoteInitialized,
  inputRef,
  setInputValue,
  handleAdd,
  isRemoteMode,
  setCanLoadRemoteOptions,
  allowNewItems,
  placeholder,
  formattedLabel,
  setSelectedItems,
  onChange,
}) => {
  const handleClearAll = useCallback(
    (name: string): void => {
      setSelectedItems([]);
      onChange?.({ [name]: [] });
    },
    [onChange, setSelectedItems],
  );
  const handleEnter = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>): void => {
      if (e.key === 'Enter') {
        e.stopPropagation();
        e.preventDefault();
        handleAdd();
      }
    },
    [handleAdd],
  );

  const handleInputPropsClick = useCallback((): void => {
    if (!isRemoteMode || disabled) return;
    setCanLoadRemoteOptions(true);
  }, [disabled, isRemoteMode, setCanLoadRemoteOptions]);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      combobox.updateSelectedOptionIndex();
      setInputValue([event.currentTarget.value]);
    },
    [combobox, setInputValue],
  );

  const handlePasteInput = useCallback(
    (e: React.ClipboardEvent<HTMLInputElement>): void => {
      e.stopPropagation();
      e.preventDefault();

      const pastedText = e.clipboardData.getData('text/plain');
      setInputValue((curr) => [(curr || '') + pastedText]);
    },
    [setInputValue],
  );

  const isWrapInputDisabled = !isRemoteInitialized || disabled;

  const isEmptyMultiSelect = !selectedItems.length;

  const formattedPlaceholder = getFieldPlaceholder({
    placeholder,
    disabled: isWrapInputDisabled,
    defaultPlaceholder: allowNewItems ? `Add ${formattedLabel}` : `Search ${formattedLabel}`,
  });

  if (omni) {
    return (
      <Flex align="center" gap="xs">
        <Combobox.DropdownTarget>
          <PillsInput
            onClick={() => combobox.openDropdown()}
            rightSectionWidth={!isLoading && !hideClearAllButton ? 'max-content' : undefined}
            size={inputSize}
            disabled={disabled}
          >
            <Combobox.EventsTarget>
              <PillsInput.Field
                ref={inputRef}
                onFocus={() => {
                  combobox.openDropdown();
                  handleInputPropsClick();
                }}
                onBlur={handleBlur}
                value={inputValue[0]}
                onChange={handleInputChange}
                onKeyDown={handleEnter}
                placeholder={selectedItems.length > 0 ? undefined : formattedPlaceholder}
                onPaste={handlePasteInput}
              />
            </Combobox.EventsTarget>
          </PillsInput>
        </Combobox.DropdownTarget>
        <Button
          leftSection={<MantineIcon icon="cross" />}
          size="sm"
          onMouseDown={(event) => event.preventDefault()}
          onClick={() => handleClearAll(name)}
          aria-label="Clear field"
          disabled={isEmptyMultiSelect || isWrapInputDisabled}
          variant="filled"
          className="form-multi-select--large__clear-btn"
        >
          Clear all
        </Button>
      </Flex>
    );
  }

  return (
    <Combobox.DropdownTarget>
      <PillsInput
        onClick={() => combobox.openDropdown()}
        rightSection={
          <InputRightSection
            handleClearAll={handleClearAll}
            hideClearAllButton={hideClearAllButton}
            inputValue={inputValue}
            isEmptyMultiSelect={isEmptyMultiSelect}
            isLoading={isLoading}
            isWrapInputDisabled={isWrapInputDisabled}
            name={name}
          />
        }
        size={inputSize}
        disabled={disabled}
      >
        <Pill.Group>
          {hideSelectedItems ? null : tags}
          <Combobox.EventsTarget>
            <PillsInput.Field
              ref={inputRef}
              onFocus={() => {
                combobox.openDropdown();
                handleInputPropsClick();
              }}
              onBlur={handleBlur}
              value={inputValue[0]}
              onChange={handleInputChange}
              onKeyDown={handleEnter}
              placeholder={selectedItems.length > 0 ? undefined : formattedPlaceholder}
              onPaste={handlePasteInput}
            />
          </Combobox.EventsTarget>
        </Pill.Group>
      </PillsInput>
    </Combobox.DropdownTarget>
  );
};

interface IExtendedMenuItemProps extends MenuItemProps {
  text: string;
  onClick?: () => void;
}

export const ItemListRender: React.FC<{
  selectedItems: IFormMultiSelectOption[];
  inputValue: string[];
  items: IFormMultiSelectOption[];
  isRemoteMode: boolean;
  isAncestryMode: boolean;
  isLoading: boolean;
  handleChange: (values: IFormMultiSelectOption[]) => void;
  breadcrumbs: IBreadcrumbProps[];
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  onItemSelect: (val: string, itemProps?: ComboboxOptionProps) => void;
  setBreadcrumbs: React.Dispatch<React.SetStateAction<IBreadcrumbProps[]>>;
  setInputValue: React.Dispatch<React.SetStateAction<string[]>>;
  validateNewItem: (query: string) => boolean;
  customRenderItemParser?: (item: IFormMultiSelectOption) => ICustomItemRendererProps;
  createNewItemFromQuery?: (query: string) => {
    label: string;
    value: string;
  };
  allowNewItems?: boolean;
  filters: React.MutableRefObject<IFilterOption[] | undefined>;
  handleAdd: () => void;
  CreateTagElement: React.FC<
    | IExtendedMenuItemProps
    | {
        text: string;
        onClick: VoidFunction;
      }
  >;
}> = ({
  selectedItems,
  inputValue,
  items,
  isRemoteMode,
  isAncestryMode,
  isLoading,
  handleChange,
  breadcrumbs,
  setIsLoading,
  onItemSelect,
  setBreadcrumbs,
  setInputValue,
  validateNewItem,
  customRenderItemParser,
  createNewItemFromQuery,
  allowNewItems,
  filters,
  handleAdd,
  CreateTagElement,
}) => {
  const itemsById = by<IFormMultiSelectOption>(selectedItems, 'value');
  const searchQuery = new RegExp(inputValue[0], 'i');

  let filtered = items;
  if (!isRemoteMode) {
    filtered = items.filter(({ value, label }) => !itemsById[value] && searchQuery.test(label));
  }

  const handleGroupAdd = (parsedInputItems): void => {
    const filteredUserEmails = parsedInputItems.filter((item) => {
      const emailMatch = exactEmailMatch([...items, ...selectedItems], item);

      if (emailMatch || selectedItems.some((e) => e.value === item.value)) {
        return;
      }

      return item;
    });

    handleChange([...filteredUserEmails, ...selectedItems]);
  };

  const handleItemRenderer = useCallback(
    (item: IFormMultiSelectOption) => {
      const hashData = item?.id && new Map(selectedItems.map((item) => [item.id, item]));
      const isItemSelected = hashData && hashData?.has(item?.id);
      let itemProps = customRenderItemParser ? customRenderItemParser(item) : { disabled: false };
      itemProps = {
        ...itemProps,
        disabled: isItemSelected || itemProps.disabled,
      };
      return itemRenderer(item, onItemSelect, itemProps, { isAncestryMode });
    },
    [customRenderItemParser, onItemSelect, isAncestryMode, selectedItems],
  );

  const filteredItems = filterSelectedItems(filtered, selectedItems).filter((item) => item != null);
  const hasItems = filteredItems.length > 0;
  const handleBreadcrumbClick = async (e): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();

    const target = e.target;
    const menuItem = target.closest(`.${Classes.MENU_ITEM}`);
    const isLink = target.classList.contains(Classes.BREADCRUMB);
    let parentId;

    if (isLink) {
      parentId = target.getAttribute('href');
    }

    if (!isLink && menuItem) {
      parentId = menuItem.getAttribute('href');
    }

    if (!parentId) return;

    filters.current = parentId === 'root' ? undefined : [['parent_id', 'eq', parentId]];

    const breadcrumbIndex = breadcrumbs.findIndex((item) => item.href === parentId);
    const updated = breadcrumbs.slice(0, breadcrumbIndex + 1);

    setIsLoading(true);
    setBreadcrumbs(updated);
    setInputValue(['']);
  };

  if (isAncestryMode) {
    return (
      <AncestryDropdown
        isLoading={isLoading}
        items={filteredItems as IProduct[]}
        renderItem={handleItemRenderer}
        breadcrumbs={breadcrumbs}
        onBreadcrumbClick={handleBreadcrumbClick}
      />
    );
  }

  if (isLoading) {
    return <Combobox.Empty>Loading...</Combobox.Empty>;
  }

  // identifies emails separated by comma and space
  const listRegex = new RegExp('([^,\\s]+@[^,\\s]{2,}\\.[^,\\s]{2,})+', 'ig');
  const inputItems = inputValue.toString()?.match(listRegex) ?? [];

  let parsedInputItems = inputItems.map((email) => {
    email = email.trim();
    const systemUser = filteredItems.find((item) => (item as IContact)?.email === email);
    if (systemUser) return systemUser;

    if (!validateNewItem(email)) return;

    return createNewItemFromQuery?.(email);
  });
  parsedInputItems = compact(parsedInputItems);

  const trimmedValue = inputValue[0].trim();
  const emailMatch = exactEmailMatch([...filteredItems, ...selectedItems], trimmedValue);

  const showCreateTag = allowNewItems && validateNewItem(trimmedValue) && !emailMatch;
  const isGroupTag = !showCreateTag && parsedInputItems.length > 1;

  if (hasItems) {
    return (
      <>
        {showCreateTag && <CreateTagElement text={inputValue[0]} onClick={handleAdd} />}
        {isGroupTag ? (
          <GroupTagElement
            text={`${parsedInputItems.length} recipients`}
            onClick={() => handleGroupAdd(parsedInputItems)}
          />
        ) : (
          <Menu>{filteredItems.map(handleItemRenderer)}</Menu>
        )}
      </>
    );
  }

  if (showCreateTag) {
    return (
      <Menu>
        <CreateTagElement text={inputValue[0]} onClick={handleAdd} />
      </Menu>
    );
  }

  return <Combobox.Empty> Nothing found.</Combobox.Empty>;
};
