import React, { useCallback } from 'react';
import { useNavigate } from 'react-router';
import cx from 'classnames';

import { IAssetPresetFields, IMm3Asset, IMm3AssetType, IStyled } from 'types';
import { FormInput, FormSelect, useMm3Form } from 'helpers/form';
import { Model } from 'helpers/filters/types';
import { chipmunk, IResult } from 'utils/chipmunk';
import { formatType } from 'utils/format-type';
import { assignSubAssets } from 'utils/apis/asset';
import { Routes } from 'utils/routes';
import { parseKeyPathToObject } from 'utils/payload';
import { FormSubmitSection } from 'components/form-submit-section';
import { AssetPresetFields } from 'components/asset/asset-upload-components/preset-form/preset-form';
import ScrollWrapper from 'components/scroll-wrapper';
import { AssetMm3IMFSection } from 'components/asset/edit-sections/identifiers';
import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';
import { useStore } from 'store';

import './style.scss';
import { ToastError } from 'components/toast';

export type IAssetFormFields = Omit<IAssetPresetFields, 'watermark'> & {
  name: string;
};

const labels = { confirm: 'Create' };
const file: IAssetFormFields = {
  access_level: 'company',
  name: '',
  burned_in_subtitles_language_id: '',
  classification: null,
  ['meta.ai_generated']: false,
  ['meta.language_id']: '',
  ['layer.notes']: '',
  ['meta.ai_notes']: '',
  type: null,
  division_ids: [],
  status: '',
  sharable: true,
  products: [],
  ['product.marketing_use']: false,
  parent_id: null,
  permissions: [],
  languages: [],
};

const createShellAsset = ({
  ['product.marketing_use']: marketing_use,
  ['layer.notes']: notes,
  products,
  parent_id,
  ...values
}: IAssetFormFields): Promise<IMm3Asset> => {
  return chipmunk.run(async ({ action }) => {
    const result = await action<IMm3Asset>(Model.MM3_ASSETS, 'create', {
      body: parseKeyPathToObject({ ...values, ['meta.notes']: notes }),
    });
    const id = result?.object?.id;
    const asset = result?.object;
    if (!id) {
      return result;
    }
    let promises: Promise<IResult>[] = [];
    if (parent_id) {
      promises.push(assignSubAssets([{ asset_id: parent_id, related_asset_id: id }]));
    }

    if (products?.length) {
      promises = promises.concat(
        products.map((product_id) =>
          action(Model.PRODUCT_ASSET, 'create', {
            params: { rawRequest: true },
            body: {
              asset_id: id,
              product_id,
              name: asset.name,
              access_level: asset.access_level,
              preview_image_id: asset.preview_image_id,
              asset_type: asset.type,
              classification: asset.classification,
              marketing_use,
              sharable: asset.sharable,
              include_descendants: false,
            },
          }),
        ),
      );
    }

    await Promise.all(promises);

    return result?.object;
  });
};

export const CreateShellAssetForm: React.FC<
  IStyled & { initialValues?: Partial<IAssetFormFields>; onSuccess?: VoidFunction }
> = ({ className, onSuccess, style, initialValues }) => {
  const navigate = useNavigate();
  const { toastStore } = useStore();
  const handleSubmit = useCallback(
    async (values) => {
      try {
        const asset = await createShellAsset(values);
        toastStore.success('Shell Asset has been successfully created!');
        if (onSuccess) {
          return onSuccess();
        }
        navigate(`${Routes.ASSETS}/${asset.id}`);
      } catch (error) {
        toastStore.error(<ToastError error={error} placeholder="Sorry, Something went wrong..." />);
      }
    },

    [navigate, onSuccess, toastStore],
  );

  const form = useMm3Form<IAssetFormFields>({ ...file, ...initialValues }, Model.MM3_IMF, handleSubmit);
  const { formData, values, handlers, valid, onSubmit } = form;

  const handleTypeChange = useCallback(
    ({ type }) => {
      handlers.onChange({ type, classification: null, parent_id: null });
    },
    [handlers],
  );

  return (
    <form className={cx(className, 'h-100 create-shell-asset__wrapper')} style={style} onSubmit={onSubmit}>
      <ScrollWrapper>
        <div className="create-shell-asset__head-section">
          <FormInput name="name" {...formData.name} {...handlers} label="Name" />
          <FormSelect
            name="type"
            {...formData.type}
            {...handlers}
            onChange={handleTypeChange}
            label="File Type"
            formatLabel={formatType}
          />
        </div>
        <AssetPresetFields
          formId="create-shell-asset"
          formData={formData}
          values={values}
          handlers={handlers}
          shouldShowErrors
          withParentField={values.type !== IMm3AssetType.IMF}
        />
        <AssetMm3IMFSection form={form as unknown as IUseMm3FormReturn<IMm3Asset>} />
      </ScrollWrapper>
      <FormSubmitSection submitDisabled={!valid} labels={labels} />
    </form>
  );
};
