import React from 'react';
import { CloseButton, Tooltip } from '@mantine/core';
import cx from 'classnames';

import { Thumbnail } from 'components/thumbnail';
import { AssetAttributes } from 'components/asset/asset-data-section-item';

import { AssetMetadataPopover } from './asset-search-item-popover-metadata';
import useSelectedOption from 'utils/hooks/selected-option';
import { Classes } from 'utils/ui';
import { IFormMultiSelectOption } from 'helpers/form';

import { IAsset, IMm3Asset } from 'types';
import { getMainType } from 'utils/asset';

import './style.scss';

interface IAssetSearchItem {
  asset: IAsset | IMm3Asset;
  handleSelect: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  showMetadata?: boolean;
  onRemove?: (item: IFormMultiSelectOption) => void;
}

const AssetSearchItemContent: React.FC<IAssetSearchItem> = ({ asset, handleSelect, onRemove }) => {
  const { name, classification, access_level, id, preview_image, type } = asset as IMm3Asset;

  const selectedOption = useSelectedOption({ value: access_level, hideDivisionAccessLevel: false });
  if (!asset) {
    return <></>;
  }

  return (
    <div
      key={`${id}-asset`}
      className={cx('entity-search-list-item', {
        [`access-level-${selectedOption?.color}`]: Boolean(selectedOption),
      })}
    >
      <div
        className={cx(`searchbox__asset ${Classes.MENU_ITEM}`, { 'searchbox__with-delete': onRemove })}
        data-id={id}
        data-item-type={getMainType(asset)}
        onClick={handleSelect}
      >
        <div className="searchbox__thumb">
          <Thumbnail image={preview_image?.url} assetType={type || (classification as string)} showType />
        </div>
        <div>
          <div className="text-truncate">{name}</div>
          <div className={Classes.TEXT_MUTED}>
            <AssetAttributes asset={asset} />
          </div>
        </div>
        {onRemove && <CloseButton onClick={() => onRemove(asset as IFormMultiSelectOption)} />}
      </div>
    </div>
  );
};

const AssetSearchItem: React.FC<IAssetSearchItem> = ({ asset, handleSelect, onRemove, showMetadata = false }) => {
  return showMetadata ? (
    <Tooltip
      label={<AssetMetadataPopover asset={asset as IAsset} />}
      withArrow
      withinPortal
      position="top"
      style={{
        background: 'var(--mfx-gray-7--dark)',
        boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.4), 0 8px 24px rgba(0, 0, 0, 0.4)',
        padding: 0,
      }}
    >
      <div>
        <AssetSearchItemContent asset={asset} handleSelect={handleSelect} onRemove={onRemove} />
      </div>
    </Tooltip>
  ) : (
    <AssetSearchItemContent asset={asset} handleSelect={handleSelect} onRemove={onRemove} />
  );
};

export { AssetSearchItem };
