import { IIdentifiable, IGroup } from 'types';
import { IActionConfig, IShouldDisplayActionFunction } from 'utils/actions';

export enum IGuildActionName {
  CHANGE_ACCESS = 'change-access',
  COPY = 'copy',
  EDIT = 'edit',
  DELETE = 'delete',
  RESTORE = 'restore',
  GENERATE_CODES = 'generate-codes',
  CREATE_GUILD = 'create-guild',
}

export type IGuildActionConfig = IActionConfig<IIdentifiable, IGuildActionName>;

export type IShouldDisplayGuildActionFunction = IShouldDisplayActionFunction<IGroup, IGuildActionName>;
