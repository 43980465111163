import React from 'react';
import { FormInput, FormAccessLevel, useForm } from 'helpers/form';

import { FormSubmitSection } from 'components/form-submit-section';
import { ISelectionEntityType } from 'utils/actions/types';
import entityTypeToForm from './entity-forms';
import { IItemId, IFormSelectionItemsForm } from './types';
import { makeInitialFormValues } from './utils';
import { Model } from 'helpers/filters/types';

export interface IFormSelectionItems {
  entityType: ISelectionEntityType;
  itemIds: IItemId[];
  onConfirm: (values: IFormSelectionItemsForm) => Promise<void>;
  omni?: boolean;
}

export const FormSelectionItems: React.FC<IFormSelectionItems> = ({ itemIds, entityType, onConfirm, omni }) => {
  const { formData, handlers, valid, onSubmit } = useForm<IFormSelectionItemsForm>(
    makeInitialFormValues(itemIds),
    Model.GROUPS,
    onConfirm,
  );
  const EntityForm = entityTypeToForm[entityType];

  return (
    <>
      <form onSubmit={onSubmit}>
        <FormInput label="Name" name="name" {...formData.name} {...handlers} />
        <FormAccessLevel name="access_level" label="Access Level" large {...formData.access_level} {...handlers} />
        <EntityForm formData={formData} handlers={handlers} omni={omni} />
      </form>
      <FormSubmitSection submitDisabled={!valid} onSubmit={onSubmit} />
    </>
  );
};
