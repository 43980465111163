import React from 'react';
import cx from 'classnames';

import './style.scss';

interface ISmartGroupFilterWrapperProps {
  title: string;
  children: React.ReactNode;
  childrenClassName?: string;
  wrapperClassName?: string;
}

export const SmartGroupFilterWrapper: React.FC<ISmartGroupFilterWrapperProps> = ({
  title,
  children,
  childrenClassName,
  wrapperClassName,
}) => (
  <div className={cx('smart-group__filter-wrapper', wrapperClassName)}>
    <div className="entity-filters__title smart-group__filters-title mb-3">
      <h4 className="d-flex align-items-center mb-0">{title}</h4>
    </div>
    <div className={cx('smart-group__filters', childrenClassName)}>{children}</div>
  </div>
);
