import React, { useCallback, useState, useMemo } from 'react';
import { uniq, map, filter } from 'lodash';

import CheckboxTree from 'components/checkbox-tree/checkbox-tree';

import { formatFormLabel } from 'helpers/form/fields/helpers';
import { IAssetTreeOption, IOmniTreeNode } from 'helpers/form/fields/form-select';
import { FormGroup } from 'helpers/form/fields/form-group';

import { createDataTree, getAllChildrenNodes } from 'helpers/form/fields/form-select/tree-selector-utils';

import { IOnCheckNode } from 'components/checkbox-tree/types';
import { EmptySectionMessage } from 'components/section-message';
import { IFieldData, IFieldHandlers } from 'helpers/form/types';

import { ItemId } from 'types/utility';
import { byId } from 'utils/general';
import { AssetMainClassificationFilters } from './asset-main-classification-filters';
import { getAllValues, parseMainClassificationCount, prepareOmniOptions } from './utils';

import './style.scss';

interface IFormOmniCheckbox extends IFieldData<ItemId[], IAssetTreeOption[]>, IFieldHandlers<ItemId[]> {
  label?: string;
  inline?: boolean;
  name: string;
  onlyChildrenIds?: boolean;
  className?: string;
  showMetadataHover?: boolean;
  expandAll?: boolean;
}

const FormAllAssetOmniCheckboxWithFilters: React.FC<IFormOmniCheckbox> = ({
  onBlur,
  onChange,
  label,
  inline = false,
  name,
  options,
  required,
  value,
  className,
  showMetadataHover = false,
  expandAll = true,
}) => {
  const optionsTree = useMemo<IOmniTreeNode[]>(
    () => parseMainClassificationCount(createDataTree(prepareOmniOptions(options, showMetadataHover)), value),
    [options, value, showMetadataHover],
  );
  const initialExpanded = useMemo(
    () => (expandAll ? getAllValues(optionsTree) : map(optionsTree, 'value')),
    [expandAll, optionsTree],
  );
  const [expanded, setExpanded] = useState<string[]>(initialExpanded);

  const buildParentChildArray = useCallback(
    (values) => {
      const children = filter(options, (opt) => !!opt['asset']);
      const parentChildObj = values.map((value) => {
        const foundAsset = children.find((child) => child['asset'].id == value);
        if (foundAsset) return foundAsset;
      });

      return parentChildObj;
    },
    [options],
  );

  const handleBlur = useCallback(() => {
    onBlur?.(name);
  }, [name, onBlur]);

  const handleChange = useCallback(
    (value: ItemId[]): void => {
      const parentChildObj = buildParentChildArray(value);
      onChange?.({ [name]: value, parent_child: parentChildObj });
      handleBlur();
    },
    [name, handleBlur, onChange, buildParentChildArray],
  );

  const handleCheck = useCallback(
    (selectedValues: string[], node?: IOnCheckNode): void => {
      if (!node) {
        return;
      }

      const safeValue = value || [];
      let newValue: ItemId[] = [];

      if (node.isLeaf) {
        const id = node.value.split('-')[2];
        newValue = node.checked ? uniq(safeValue.concat(id)) : safeValue.filter((e) => e !== id);
      } else if (node.isChild) {
        const childrenIds = (node.children || []).reduce((acc, e) => (e?.id ? [...acc, e.id] : acc), []);
        const childrenByIds = byId(node.children || []);
        newValue = node.checked ? uniq(safeValue.concat(childrenIds)) : safeValue.filter((id) => !childrenByIds[id]);
      } else {
        const children = getAllChildrenNodes([node]);
        const childrenIds = children.reduce((acc, e) => (e?.id ? [...acc, e.id] : acc), []);
        const childrenByIds = byId(children);
        newValue = node.checked ? uniq(safeValue.concat(childrenIds)) : safeValue.filter((id) => !childrenByIds[id]);
      }

      handleChange(newValue);
    },
    [handleChange, value],
  );

  const checked = useMemo(() => {
    const valueSet = new Set((value || []).map(String));

    return getAllChildrenNodes(optionsTree).reduce((acc: string[], node: IOmniTreeNode): string[] => {
      if (node.id && valueSet.has(node.id)) {
        acc.push(node.value);
      }
      return acc;
    }, []);
  }, [optionsTree, value]);

  if (!options?.length) return <EmptySectionMessage />;

  return (
    <div className={className}>
      <AssetMainClassificationFilters {...{ optionsTree, value, handleChange }} />
      <FormGroup
        label={formatFormLabel(label, required)}
        labelFor={name}
        inline={inline}
        className="form-omni-checkbox--container"
      >
        <CheckboxTree
          nodes={optionsTree}
          checked={checked}
          expanded={expanded}
          setExpanded={setExpanded}
          onCheck={handleCheck}
        />
      </FormGroup>
    </div>
  );
};
export { FormAllAssetOmniCheckboxWithFilters };
