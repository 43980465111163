import React from 'react';
import cx from 'classnames';
import { ActionIcon, RingProgress } from '@mantine/core';
import { MantineIcon } from 'utils/ui';
import { Classes } from 'utils/ui';
import { IAssetFile, IFile } from 'types';
import { IObservableArray } from 'mobx';

import './index.scss';

export interface IAssetUploadDialog {
  onClick: () => void;
  files: IAssetFile[];
  uploadedFiles: IObservableArray<IFile>;
  progress: number;
  isUploadPending: boolean;
  customMessage?: JSX.Element;
}

export const AssetUploadShortcut: React.FC<IAssetUploadDialog> = ({
  files,
  progress,
  isUploadPending,
  uploadedFiles,
  onClick,
  customMessage,
}) => {
  if (!isUploadPending) {
    return null;
  }

  return (
    <div className={cx('assets-upload-modal')}>
      <div className="asset-upload-modal__progress-indicator">
        <RingProgress
          variant="primary"
          sections={[{ value: progress, color: 'var(--mfx-primary)' }]}
          size={65}
          thickness={4}
          roundCaps
          label={<div className="asset-upload-modal__progress-text">{progress}%</div>}
        />
      </div>
      <div className={`assets-upload-modal__text ${Classes.TEXT_MUTED}`}>
        {customMessage}
        <div>{`${uploadedFiles.length} out of ${files.length}`}</div>
        <div>files uploaded</div>
      </div>
      <ActionIcon variant="subtle" color="gray.5" className="open-assets-upload-page-btn" onClick={onClick}>
        <MantineIcon icon="maximize" />
      </ActionIcon>
    </div>
  );
};

export default AssetUploadShortcut;
