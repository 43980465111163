import React, { useState } from 'react';
import { Badge } from '@mantine/core';
import { Button, FileButton } from '@mantine/core';

export enum Status {
  completed = 'success',
  pending = 'warning',
  'in-progress' = 'warning',
  failed = 'danger',
}

export const StatusBadge = (props): JSX.Element => {
  const status = props?.value || props?.data?.status;
  if (!status) return <></>;

  return <Badge variant={Status[status as string]}>{status?.replace(/-/g, ' ')}</Badge>;
};

export const UploadRenderer = (): JSX.Element => {
  const [file, setFile] = useState<File | null>(null);

  return (
    <>
      <FileButton onChange={setFile} accept="image/png,image/jpeg">
        {(props) => (
          <Button variant="filled" color="gray" {...props}>
            {file?.name || 'Upload File'}
          </Button>
        )}
      </FileButton>
    </>
  );
};

export const DateEditor = (props): JSX.Element => {
  return (
    <input
      type="date"
      defaultValue={props.value}
      className="bg-gray-800 text-white border border-gray-700 rounded px-2 py-1"
      onChange={(e) => props.setValue(e.target.value)}
    />
  );
};
