import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';

import { Routes } from 'utils/routes';
import { Page } from 'components/layout';

import { LocalizationOrderWizardContainer } from 'components/localization-orders/order-wizard-container';
import { ILocalizationOrder } from 'types/localization-order';

const LocalizationOrderCreate: React.FC = observer(() => {
  const navigate = useNavigate();
  const onFinish = useCallback(
    (order: ILocalizationOrder): void => {
      navigate(`${Routes.ORDER_MANAGEMENT}/${order.id}`);
    },
    [navigate],
  );

  return (
    <Page title="Create New Localization Order">
      <LocalizationOrderWizardContainer onFinish={onFinish} />
    </Page>
  );
});

export default LocalizationOrderCreate;
