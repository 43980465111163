import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { UmGenericListItem, UmGenericListShowcase } from '@mediafellows/mm3-types';

import { useStore } from 'store';
import { useDialogStore, useSortDataSectionStore } from 'store/hooks';

import { SortDataSection } from 'components/sort-data-section';
import { customShowcaseFormValidator } from 'components/showcase-form';
import { FormSubmitSection } from 'components/form-submit-section';
import { ShowcaseItemForm } from 'components/showcase-edit-form/list-item-form';
import ShowcaseDetailForm from 'components/showcase-edit-form/showcase-detail-form';
import { ToastError } from 'components/toast';

import { Model } from 'helpers/filters/types';
import { useMm3Form } from 'helpers/form/use-mm3-form';
import { itemRenderer, labels, saveShowcase } from 'components/showcase-edit-form/utils';
import { getPreviewImage } from 'utils/list-item';
import { fetchListItems } from 'utils/apis/showcase';
import { IShowcaseActionName } from 'utils/actions/showcase/types';
import { IActionName } from 'utils/actions/types';
import { IListItem, IWebsitePageValues } from 'types';
import { IExtendedGenericList } from 'components/website-data-section/utils';

import './style.scss';

const onSave = async (): Promise<void> => {
  return;
};

const ShowcaseEditForm: React.FC<{
  showcase: UmGenericListShowcase;
  refresh?: (action: IActionName, showcase: IExtendedGenericList, items?: IListItem[]) => Promise<void> | void;
  items?: IListItem[];
  section?: IWebsitePageValues;
}> = observer(({ showcase, refresh, items, section }) => {
  const { initStore, updateStore, list, active } = useSortDataSectionStore<IListItem>();

  const fetcher = useCallback(async (): Promise<IListItem[]> => {
    if (!items && showcase.id) {
      const { objects } = await fetchListItems({ listIds: [showcase.id] });
      return objects as IListItem[];
    }

    return items || [];
  }, [items, showcase.id]);
  const [itemsReordered, setItemsReorderd] = useState(false);

  useEffect(() => {
    initStore({
      fetcher,
      onSave,
      updateParser: (list) => {
        setItemsReorderd(true);
        return list.map((e) => ({ ...e, edited: true }));
      },
    });
  }, [fetcher, initStore]);

  const handleItemRemove = useCallback(
    (id: number) => {
      if (!list?.length || !id) {
        return;
      }

      if (list[list.length - 1]?.id !== id) {
        setItemsReorderd(true);
      }

      updateStore(list?.map((item) => (item?.id === id ? { ...item, isRemoved: true } : item)));
    },
    [list, updateStore],
  );

  const { close } = useDialogStore();
  const { toastStore } = useStore();

  const onSubmit = useCallback(
    async (values: IExtendedGenericList): Promise<void> => {
      try {
        const [, updateListItems] = await saveShowcase(values, list, showcase, itemsReordered);
        refresh?.(IShowcaseActionName.EDIT, values, updateListItems as IListItem[]);
        close();
      } catch (e) {
        const error = e?.response?.body?.error;
        if (error?.text === 'Purpose has already been taken') {
          toastStore.error(<ToastError placeholder={'Identifier has already been taken'} />);
        } else {
          if (error?.status === 422) {
            error.text = 'Identifier has already been taken';
          }
          toastStore.error(<ToastError error={error} placeholder={'Sorry, something went wrong'} />);
        }
      }
    },
    [list, showcase, itemsReordered, refresh, close, toastStore],
  );

  const form = useMm3Form<UmGenericListShowcase>(showcase, Model.SHOWCASES, onSubmit, customShowcaseFormValidator);

  const syncData = useCallback(
    (item: UmGenericListItem): void => {
      updateStore(list.map((e) => (e.id === item.id ? { ...e, ...item, edited: true } : e)));
    },
    [updateStore, list],
  );

  return (
    <>
      <form onSubmit={form.onSubmit} className="showcase-edit-form">
        <SortDataSection
          itemRenderer={itemRenderer}
          onItemRemove={handleItemRemove}
          withCustomSorting
          className="h-100 showcase-edit-form__sort-section"
        />
        {!active && <ShowcaseDetailForm form={form} section={section} />}
        {active?.id && (
          <ShowcaseItemForm
            previewImage={getPreviewImage(active as IListItem)}
            item={active as IListItem}
            syncData={syncData}
            type={showcase.type}
          />
        )}
        <div />
      </form>
      <FormSubmitSection labels={labels} submitDisabled={!form.valid} onSubmit={form.onSubmit} />
    </>
  );
});

export default ShowcaseEditForm;
