import React, { useCallback, useState, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from '@mantine/core';

import { useAssetsEditStore, useDialogStore } from 'store/hooks';

import { FormSelect, IFormHandlers, IUseFormReturn, useForm, useMm3Form } from 'helpers/form';
import { Model } from 'helpers/filters/types';
import { IAsset, IAssetPresetFields, IMm3Asset } from 'types';
import { IAssetFormFields } from 'components/asset/asset-edit-form/sections';
import { editAssetPresetInitialData as initialValues } from 'components/asset/asset-edit-form/constants';
import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';
import { withAssetVersion } from 'utils/asset';
import { getAssetFormFields } from 'components/asset/asset-edit-form/utils';
import { Classes } from 'utils/ui';
import { getIsMm3Assets } from 'utils/asset';

import PresetForm from './preset-form';

import '../index.scss';

type IPresetOption = {
  presetName: string;
};

interface IPresetSelectorProps extends IPresetOption {
  onPresetChange: (option: IPresetOption) => void;
}

type IAssetPresetFormProps = Pick<
  IUseFormReturn<IAssetFormFields> | IUseMm3FormReturn<IMm3Asset>,
  'formData' | 'handlers' | 'values'
>;

export const AssetPresetForm: React.FC<IAssetPresetFormProps> = ({ formData, handlers, values }) => {
  return <PresetForm formId="select-preset-form" {...{ formData, handlers, values, asset: initialValues }} />;
};

const PresetSelector: React.FC<IPresetSelectorProps> = observer(({ presetName, onPresetChange }) => {
  const { presetsRecord } = useAssetsEditStore();
  const presetOptions = useMemo(() => Object.keys(presetsRecord), [presetsRecord]);

  return (
    <div className="asset-edit__select-preset-modal-header section-header--use-border">
      <FormSelect
        label=" "
        validation={{ valid: true }}
        touched={false}
        inline
        required={false}
        name="presetName"
        placeholder="Select Preset"
        value={presetName}
        onChange={onPresetChange}
        options={presetOptions}
      />
    </div>
  );
});

export interface ISelectAssetPresetProps {
  opened: boolean;
  onClose: () => void;
  presets: Record<string, IAssetPresetFields | IMm3Asset>;
}

export const SelectAssetPreset: React.FC<{ form: IUseFormReturn<IAssetFormFields> | IUseMm3FormReturn<IMm3Asset> }> =
  observer(({ form }) => {
    const isMm3Assets = getIsMm3Assets();
    const { applyPreset, presetsRecord } = useAssetsEditStore();
    const { close } = useDialogStore();
    const [presetName, setPresetName] = useState<string>('');

    const { formData, handlers, values } = form;
    const onSetFields =
      (form as IUseMm3FormReturn<IMm3Asset>).onSetFields || (handlers as IFormHandlers<IAssetFormFields>).onSetFields;

    const onPresetChange = useCallback(
      ({ presetName }: IPresetOption) => {
        setPresetName(presetName);
        const preset = presetName ? presetsRecord[presetName] : initialValues;
        onSetFields((isMm3Assets ? preset : getAssetFormFields(preset as IAsset)) as IMm3Asset);
      },
      [isMm3Assets, onSetFields, presetsRecord],
    );

    const handleApplyPreset = useCallback(() => {
      applyPreset({ ...initialValues, ...values } as unknown as IAsset);
      close();
    }, [applyPreset, close, values]);

    return (
      <div className="d-grid overflow-hidden h-100">
        <PresetSelector {...{ onPresetChange, presetName }} />
        <div className="asset-edit__select-preset-modal-container">
          <AssetPresetForm {...{ formData, handlers, values }} />
        </div>
        <div className={`asset-edit__select-preset-modal-footer ${Classes.DIALOG_FOOTER_ACTIONS}`}>
          <Button variant="default" size="xs" onClick={close}>
            Cancel
          </Button>
          <Button onClick={handleApplyPreset} size="xs" variant="primary">
            Apply
          </Button>
        </div>
      </div>
    );
  });

export const SelectAssetOldPresetWrapper: React.FC<{}> = () => {
  const form = useForm<IAssetFormFields>(getAssetFormFields(initialValues as IAsset), Model.ASSETS);

  return <SelectAssetPreset form={form} />;
};

export const SelectAssetMm3PresetWrapper: React.FC<{}> = () => {
  const form = useMm3Form<IMm3Asset>(initialValues as IMm3Asset, Model.MM3_ASSETS);

  return <SelectAssetPreset form={form} />;
};

export default withAssetVersion(SelectAssetOldPresetWrapper, SelectAssetMm3PresetWrapper);
