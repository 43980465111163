import React, { useCallback, useMemo } from 'react';
import { ActionIcon } from '@mantine/core';
import { observer } from 'mobx-react-lite';

import { useStore } from 'store';
import { MantineIcon } from 'utils/ui/icon';
import { AssetsUploaderStore } from 'store/assets-upload-store';

export const AssetUploadModalHeader: React.FC<{ assetsUploadStore?: AssetsUploaderStore; title?: string }> = observer(
  ({ assetsUploadStore, title = 'Upload Asset(s)' }) => {
    const { dialogStore } = useStore();
    const { files, setIsUploadPending } = assetsUploadStore || {};

    const iconName = useMemo(() => (files?.length ? 'minimize' : 'cross'), [files?.length]);

    const onHeaderButtonClick = useCallback(() => {
      if (files?.length) {
        setIsUploadPending?.(true);
        dialogStore.close();
      } else {
        dialogStore.close();
      }
    }, [dialogStore, files?.length, setIsUploadPending]);

    if (!assetsUploadStore) return null;

    return (
      <>
        <h3 className="mantine-Modal-title">{title}</h3>
        <ActionIcon color="gray.5" variant="subtle" onClick={onHeaderButtonClick}>
          <MantineIcon icon={iconName} />
        </ActionIcon>
      </>
    );
  },
);
