import React, { useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Accordion, Switch } from '@mantine/core';
import cx from 'classnames';
import { useLocation } from 'react-router-dom';

import { ParametronSort } from 'helpers/filters/types';
import { toggleParam, submitFilters, ISubmitFiltersParams } from 'helpers/filters/helpers';
import { useFilters } from 'helpers/filters';

import { defaultSearchParams } from 'utils/default-search-param';
import { parseQueryParams } from 'utils/general';

import { useStore } from 'store';
import { IProduct } from 'types';

import { DataSectionPreset } from 'components/data-section-preset';
import { SharedProductFilters } from 'components/product-filters/shared-product-filters';
import { IFilters } from 'components/product-filters/types';
import { essentialFilters, getActiveFiltersCount } from 'components/product-filters/utils';
import { FilterAccordionItem } from 'components/filter-accordion-item/filter-accordion-item';
import { ProductEssentialFilters } from 'components/product-filters/product-essential-filters';

import './style.scss';
import { FILTER_SECTIONS } from 'utils/constants';
import { clearActiveFiltersSection } from 'utils/filters';

const defaultParamComponentValues = (queryParams): typeof defaultSearchParams => ({
  include_deleted: queryParams['include_deleted'] === true,
  sort: ParametronSort.UPDATED_AT,
});

const ProductFilters: React.FC<{ customSubmitFilter?: (obj: ISubmitFiltersParams) => void }> = observer(
  ({ customSubmitFilter = submitFilters }) => {
    const { dataSectionStore } = useStore<IProduct>();

    const location = useLocation();

    const [activeAccordion, setActiveAccordion] = useState<string | null>('essentials');

    const queryParams = parseQueryParams(location.search);
    const [paramComponentValues, setParamComponentValues] = useState(defaultParamComponentValues(queryParams));

    const handleSubmit = useCallback(
      (action): void => {
        customSubmitFilter?.({
          action,
          setParamComponentValues,
          customParams: defaultSearchParams,
        });
      },
      [customSubmitFilter],
    );

    const [filterValues, filterHandlers] = useFilters<IFilters>(dataSectionStore, handleSubmit);

    const handleToggleParam = (event): void => {
      toggleParam({
        event,
        paramComponentValues,
        setParamComponentValues,
      });
    };

    const activeEssentialFiltersCount = getActiveFiltersCount(filterValues, essentialFilters);

    return (
      <div className="entity-filters">
        <DataSectionPreset filterHandlers={filterHandlers} />
        <Accordion
          chevronPosition="left"
          classNames={{
            chevron: cx({
              'active-accordion__element': activeAccordion === FILTER_SECTIONS.ESSENTIALS,
            }),
          }}
          value={activeAccordion}
          onChange={setActiveAccordion}
        >
          <div className="entity-filters__new-filter">
            <FilterAccordionItem
              value={FILTER_SECTIONS.ESSENTIALS}
              title={FILTER_SECTIONS.ESSENTIALS}
              activeAccordion={activeAccordion}
              activeFiltersCount={activeEssentialFiltersCount}
              onClearFilters={(e) => {
                e.stopPropagation();
                clearActiveFiltersSection(essentialFilters, filterHandlers.onChange);
              }}
            >
              <ProductEssentialFilters
                filterValues={filterValues}
                filterHandlers={filterHandlers}
                dataSectionStore={dataSectionStore}
              />
            </FilterAccordionItem>

            <SharedProductFilters
              dataSectionStore={dataSectionStore}
              filterHandlers={filterHandlers}
              filterValues={filterValues}
              activeAccordion={activeAccordion}
            />

            <Switch
              label="Include deleted"
              data-param={'include_deleted'}
              onChange={handleToggleParam}
              checked={paramComponentValues.include_deleted}
            />
          </div>
        </Accordion>
      </div>
    );
  },
);

export default ProductFilters;
