import React from 'react';

import { InfoBoxV2 } from 'components/info-box-v2';
import { LabelValuePair as Row } from 'components/label-value-pair';
import { IAsset, IMm3Asset } from 'types';
import { formatDuration } from 'utils/date';
import { getEntityType, formatAssetType } from 'utils/general';
import { formatAssetClassification, withAssetVersion } from 'utils/asset';
import { AssetMm3GeneralTabAboutSection } from 'pages/asset-details/asset-general-tab/asset-mm3-about-section';

interface IAssetGeneralTabAboutSectionProps {
  asset?: IAsset | IMm3Asset;
}

export const AssetOldGeneralTabAboutSection: React.FC<{
  asset: IAsset;
}> = ({ asset }) => {
  const { classification, duration, name, main_classification } = asset || {};
  return (
    <InfoBoxV2 gridTemplateColumns="minmax(135px, 1fr) 2fr" title="About" wrapperClassName="mt-3">
      <Row label="Name" value={name} size="m" />
      <Row label="File Type" value={getEntityType(classification)} size="m" />
      <Row label="Type" value={formatAssetClassification(main_classification)} size="m" />
      <Row label="Asset Type" value={formatAssetType(classification)} size="m" />
      <Row label="duration" hideIfNoValue value={formatDuration(duration, 'seconds')} size="m" />
    </InfoBoxV2>
  );
};

export const AssetGeneralTabAboutSection = withAssetVersion(
  AssetOldGeneralTabAboutSection,
  AssetMm3GeneralTabAboutSection,
) as React.FC<IAssetGeneralTabAboutSectionProps>;
