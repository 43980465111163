import React from 'react';
import { observer } from 'mobx-react-lite';

import { FilterSwitch } from 'helpers/filters/fields';
import { IFiltersHandlers } from 'helpers/filters';

import {
  IAssetFilters,
  settingsFilters,
  accessAndOwnershipFilters,
  timestampsFilters,
} from 'components/asset/asset-filters/utils';
import { getActiveFiltersCount } from 'components/product-filters/utils';
import { FilterAccordionItem } from 'components/filter-accordion-item/filter-accordion-item';
import { AssetAccessAndOwnershipFilters } from 'components/asset/asset-filters/asset-access-ownership-filters';
import { AssetSettingsFilters } from 'components/asset/asset-filters/asset-settings-filters';
import { AssetTimestampsFilters } from 'components/asset/asset-filters/asset-timestamps-filters';

import { IAsset } from 'types';
import { DataSectionStore } from 'store/data-section-store';
import { useBasicStore } from 'store/hooks';
import { FILTER_SECTIONS } from 'utils/constants';
import { clearActiveFiltersSection } from 'utils/filters';

export const SharedAssetFilters: React.FC<{
  dataSectionStore: DataSectionStore<IAsset>;
  filterHandlers: IFiltersHandlers<IAssetFilters>;
  filterValues: IAssetFilters;
  activeAccordion: string | null;
}> = observer(({ dataSectionStore, filterValues, filterHandlers, activeAccordion }) => {
  const { divisions } = useBasicStore();

  const activeAccessAndOwnershipFiltersCount = getActiveFiltersCount(filterValues, accessAndOwnershipFilters);
  const activeSettingsFiltersCount = getActiveFiltersCount(filterValues, settingsFilters);
  const activeTimestampsFiltersCount = getActiveFiltersCount(filterValues, timestampsFilters);

  return (
    <>
      <FilterAccordionItem
        value={FILTER_SECTIONS.ACCESS_OWNERSHIP}
        title={FILTER_SECTIONS.ACCESS_OWNERSHIP}
        activeAccordion={activeAccordion}
        activeFiltersCount={activeAccessAndOwnershipFiltersCount}
        onClearFilters={(e) => {
          e.stopPropagation();
          clearActiveFiltersSection(accessAndOwnershipFilters, filterHandlers.onChange);
        }}
      >
        <AssetAccessAndOwnershipFilters
          filterValues={filterValues}
          filterHandlers={filterHandlers}
          dataSectionStore={dataSectionStore}
          divisions={divisions}
        />
      </FilterAccordionItem>

      <FilterAccordionItem
        value={FILTER_SECTIONS.SETTINGS}
        title={FILTER_SECTIONS.SETTINGS}
        activeAccordion={activeAccordion}
        activeFiltersCount={activeSettingsFiltersCount}
        onClearFilters={(e) => {
          e.stopPropagation();
          clearActiveFiltersSection(settingsFilters, filterHandlers.onChange);
        }}
      >
        <AssetSettingsFilters
          filterValues={filterValues}
          filterHandlers={filterHandlers}
          dataSectionStore={dataSectionStore}
        />
      </FilterAccordionItem>

      <FilterAccordionItem
        value={FILTER_SECTIONS.TIMESTAMPS}
        title={FILTER_SECTIONS.TIMESTAMPS}
        activeAccordion={activeAccordion}
        activeFiltersCount={activeTimestampsFiltersCount}
        onClearFilters={(e) => {
          e.stopPropagation();
          clearActiveFiltersSection(timestampsFilters, filterHandlers.onChange);
        }}
        mb={20}
      >
        <AssetTimestampsFilters filterValues={filterValues} filterHandlers={filterHandlers} />
      </FilterAccordionItem>

      <FilterSwitch
        label="Unassigned assets only"
        name="products_count"
        filter={filterValues.products_count}
        onChange={filterHandlers.onChange}
        checkedValue={0}
        notCheckedValue={false}
      />
    </>
  );
});
