import { useState, useEffect } from 'react';
import { useDataSectionStore } from 'store/hooks';
import { IFilterOptionValue } from 'utils/hooks/control-data-section';

export const useIsInPersistentFilter = (name: string): [boolean, IFilterOptionValue | undefined] | [boolean] => {
  const { searchStore, filters } = useDataSectionStore();
  const [isInPersistentFilter, setIsInPersistentFilter] = useState(false);
  const [filterValue, setFilterValue] = useState<IFilterOptionValue | undefined>();

  useEffect(() => {
    if (filters?.[name]?.attribute && searchStore?.persistentFilters?.length) {
      const currentFilter = filters[name];
      const { attribute, method } = currentFilter;

      const valueFound = searchStore?.persistentFilters?.find(([key, val]) => key === attribute && val === method);
      setFilterValue(valueFound?.[2]);
      setIsInPersistentFilter(Boolean(valueFound));
    }
  }, [filters, name, searchStore?.persistentFilters]);

  return [isInPersistentFilter, filterValue];
};
