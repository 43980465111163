import React, { useState, useCallback, useEffect } from 'react';
import { Tabs } from '@mantine/core';

import AssetMm3MetadataGeneralSubTab from './asset-metadata-mm3-general-subtab';
import AssetMm3MetadataTechnicalSubTab from './asset-metadata-mm3-technical-subtab';
import { AssetMm3MetadataGeneralEditTab } from './asset-metadata-edit-mm3-tab';

import { AssetMetadataTabSubTabs } from 'pages/asset-details/asset-metadata-tab/utils';
import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';

import { IMm3Asset } from 'types';

import 'pages/asset-details/asset-metadata-tab/style.scss';

export const AssetMm3MetadataTab: React.FC<{
  asset: IMm3Asset;
  form: IUseMm3FormReturn<IMm3Asset>;
  editModeEnabled: boolean;
}> = ({ asset, form, editModeEnabled }) => {
  const [activeTab, setActiveTab] = useState(AssetMetadataTabSubTabs.GENERAL);

  const handleSubTabChange = useCallback((tab) => {
    setActiveTab(tab);
  }, []);

  useEffect(() => {
    if (editModeEnabled) {
      handleSubTabChange(AssetMetadataTabSubTabs.GENERAL);
      return;
    }
  }, [editModeEnabled, handleSubTabChange]);

  return (
    <Tabs className="asset-metadata__tabs h-100 w-100" value={activeTab} onChange={handleSubTabChange}>
      <Tabs.List className="d-flex flex-nowrap" grow>
        <Tabs.Tab value={AssetMetadataTabSubTabs.GENERAL} className="asset-metadata__tab h-100">
          {AssetMetadataTabSubTabs.GENERAL}
        </Tabs.Tab>
        <Tabs.Tab
          value={AssetMetadataTabSubTabs.TECHNICAL_SPECIFICATIONS}
          className="asset-metadata__tab h-100"
          disabled={editModeEnabled}
        >
          {AssetMetadataTabSubTabs.TECHNICAL_SPECIFICATIONS}
        </Tabs.Tab>
      </Tabs.List>
      <Tabs.Panel value={AssetMetadataTabSubTabs.GENERAL} className="h-100">
        {editModeEnabled ? (
          <AssetMm3MetadataGeneralEditTab asset={asset} form={form} />
        ) : (
          <AssetMm3MetadataGeneralSubTab asset={asset} />
        )}
      </Tabs.Panel>
      <Tabs.Panel value={AssetMetadataTabSubTabs.TECHNICAL_SPECIFICATIONS} className="h-100">
        <AssetMm3MetadataTechnicalSubTab asset={asset} />
      </Tabs.Panel>
    </Tabs>
  );
};

export default AssetMm3MetadataTab;
