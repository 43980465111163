import React, { useMemo } from 'react';
import { map } from 'lodash';

import { FormSubmitSection } from 'components/form-submit-section';
import { useForm } from 'helpers/form';
import { Model } from 'helpers/filters/types';

import { FormAddToGroupItemsForm } from './form-add-to-group-items-form';
import { IAddToGroupForm, IFormAddToGroupItems } from './types';
import { createInitialAddToGroupValues } from './utils';

const customContext = {
  properties: {
    group_id: { required: true },
    item_ids: { required: true },
  },
};

const FormAddToGroupItems: React.FC<IFormAddToGroupItems> = ({
  items,
  onConfirm,
  entity,
  group,
  groupType,
  groupLabel,
  hideProductsParams,
  addSelectedProductAncestors,
  omni = false,
}) => {
  const initialIds = useMemo(() => map(items, 'value'), [items]);

  const form = useForm<IAddToGroupForm>(
    createInitialAddToGroupValues(initialIds, group?.id),
    Model.GROUPS,
    onConfirm,
    customContext,
  );
  const { valid, onSubmit } = form;

  return (
    <>
      <form onSubmit={onSubmit}>
        <FormAddToGroupItemsForm
          form={form}
          entity={entity}
          group={group}
          groupType={groupType}
          groupLabel={groupLabel}
          disabledGroupSelector={Boolean(group?.id)}
          disabledItemsSelector={!!initialIds.length}
          hideProductsParams={hideProductsParams}
          addSelectedProductAncestors={addSelectedProductAncestors}
          omni={omni}
        />
      </form>
      <FormSubmitSection submitDisabled={!valid} onSubmit={onSubmit} />
    </>
  );
};

export { FormAddToGroupItems };
