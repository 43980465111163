import type { IAsset, ItemId, IScheduledJob } from 'types';
import { IActionConfig } from 'utils/actions/types';

export enum IAssetActionName {
  UPLOAD_PREVIEW = 'upload-preview',
  UPLOAD_ASSET = 'upload-asset',
  ASSIGN_TO_ASSET = 'assign-to-asset',
  UNASSIGN_FROM_ASSET = 'unassign-from-asset',
  ASSIGN_TO_PRODUCT = 'assign-to-product',
  CREATE_SELECTION = 'create-selection',
  APPROVE = 'approve',
  REJECT = 'reject',
  UPDATE = 'update',
  DELETE = 'delete',
  REINGEST = 'reingest',
  UNANRCHIVE = 'unarchive',
  DELETE_PREVIEW = 'delete-preview',
  ARCHIVE = 'archive',
  RESTORE = 'restore',
  EXTRACT_PREVIEW = 'extract-preview',
  DOWNLOAD = 'download',
  ADD_TO_SELECTION = 'add-to-selection',
  GRAND_ACCESS = 'grand-access',
  CHANGE_ACCESS = 'change-access',
  UNASSIGN = 'unassign',
  REMOVE_FROM_GROUP = 'remove-from-group',
  REMOVE_FROM_CONFERENCE = 'remove-from-conference',
  SET_UP_CONFERENCE = 'set-up-conference',
  UPLOAD_SUBTITLE = 'upload-subtitle',
  EXPORT_TO_MB = 'export-to-mediabench',
  RECOMMEND = 'recommend',
  CREATE_SCREENING_ROOM = 'create-screening-room',
  DELIVER = 'deliver',
  ADD_TO_DELIVERY = 'add-to-delivery',
  TRANSCODE = 'transcode',
  GENERATE_SUBTITLES = 'generate-subtitles',
}

export type IAssetActionConfig = IActionConfig<IAsset, IAssetActionName>;

export interface IAccessChangeImmediateForm {
  item_ids: (number | string)[];
  status?: string;
  access_level?: string;
  permissions?: string[] | null;
  protection_levels?: string[] | null;
  skip_access_delegation?: boolean;
}

export interface IAccessChangeScheduledForm {
  item_ids: (number | string)[];
  scheduled_for: string;
}

export interface IAccessChangeScheduledAccess extends IAccessChangeScheduledForm {
  access_level: string;
  permissions: string[];
}

export interface IAccessChangeScheduledProtection extends IAccessChangeScheduledForm {
  protection_levels?: string[];
}

export interface IAccessChangeScheduledAccessDelegation extends IAccessChangeScheduledForm {
  skip_access_delegation: boolean;
}

export interface IAccessChangeScheduledStatus extends IAccessChangeScheduledForm {
  status: string;
}

export type IAssetScheduledJobName =
  | 'change_status_on_asset'
  | 'change_access_delegation_on_asset'
  | 'change_protection_levels_on_asset'
  | 'change_access_level_on_asset';

export interface IAssetScheduledJob extends IScheduledJob {
  asset_id: ItemId;
  name: IAssetScheduledJobName;
}

export interface IAssetScheduledJobStatus extends IAssetScheduledJob {
  workflow_status: string;
}

export interface IAssetScheduledJobAccessDelegation extends IAssetScheduledJob {
  skip_access_delegation: boolean;
}

export interface IAssetScheduledJobProtection extends IAssetScheduledJob {
  protection_levels: string[];
}

export interface IAssetScheduledJobAccess extends IAssetScheduledJob {
  access_level: string;
  permissions: string[];
}
