import React, { useCallback } from 'react';

import { useStore } from 'store';
import ReplaceSubtitleDialogBody, { IReplaceSubtitleProps } from './replace-subtitle-body';
import { IModalSize } from 'components/dialogs/types';

export const useReplaceSubtitleDialog = (props: IReplaceSubtitleProps): [() => void] => {
  const { onSubmit, type, message } = props;
  const { dialogStore } = useStore();

  const body = useCallback(() => {
    return <ReplaceSubtitleDialogBody onSubmit={onSubmit} type={type} message={message} />;
  }, [onSubmit, type, message]);

  const openDialog = (): void => {
    dialogStore.openModal({
      title: 'Replace subtitle',
      body,
      size: IModalSize.XS,
    });
  };

  return [openDialog];
};
