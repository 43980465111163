import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { useBasicStore } from 'store/hooks';

import { InfoBoxV2 } from 'components/info-box-v2';

import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';
import { FormCheckbox, FormHtmlInput, FormInput, FormMultiSelect, FormSelect } from 'helpers/form';
import { formatAssetType } from 'utils/general';
import { getMm3Tags } from 'components/asset/edit-sections/utils';
import { formatAssetPurpose, formatAssetSource } from 'utils/asset';

import { IMm3Asset, IMm3AssetType } from 'types';

import 'pages/asset-details/asset-metadata-tab/style.scss';

interface IAssetMetadataEditTabProps {
  asset?: IMm3Asset;
  form: IUseMm3FormReturn<IMm3Asset>;
}

export const AssetMm3MetadataGeneralEditTab: React.FC<IAssetMetadataEditTabProps> = observer(({ asset, form }) => {
  const { formData, handlers } = form;
  const { main_classification, type } = asset || {};
  const { languagesDataOptions } = useBasicStore();

  const classificationOptions = useMemo(() => {
    if (!main_classification) {
      return formData.classification?.options || [];
    }
    return (formData.classification?.options || []).filter((classification) =>
      classification.startsWith(main_classification),
    );
  }, [main_classification, formData.classification?.options]);

  const filteredSources = useMemo(
    () => formData.source?.options?.filter((source) => source !== 'extracted_ingest'),
    [formData.source?.options],
  );
  const isImage: boolean = type === IMm3AssetType.IMAGE;
  const isAudio: boolean = type === IMm3AssetType.AUDIO;
  const isSubtitle: boolean = type === IMm3AssetType.SUBTITLE;
  const isAudioOrSubtitle = isAudio || isSubtitle;
  return (
    <div className="asset-metadata-tab h-100">
      <div className="asset-metadata-tab__infoboxes">
        <InfoBoxV2 gridTemplateColumns="1fr" title="Essentials">
          <FormInput name="name" label="Name" {...formData.name} {...handlers} />

          <FormSelect
            name="classification"
            label="Asset Type"
            {...formData.classification}
            {...handlers}
            large
            options={classificationOptions}
            formatLabel={formatAssetType}
          />
          <FormInput
            label="MD 5 User Provided"
            name="meta.user_md5"
            type="textarea"
            {...formData.meta?.user_md5}
            {...handlers}
            validation={{
              valid: Boolean(formData.meta?.user_md5?.validation?.valid),
              errorMessage:
                'A 32-character code using only numbers and lowercase letters in the [a-f] range, with no spaces or symbols',
            }}
          />
          <FormSelect
            name="meta.language_id"
            label="Language"
            {...formData.meta?.language_id}
            {...handlers}
            options={languagesDataOptions}
            large
            required
          />
          {isAudioOrSubtitle && (
            <FormCheckbox
              name="meta.language_id_approved"
              label="Language Approved"
              {...formData.meta?.language_id_approved}
              {...handlers}
            />
          )}
          {isImage && (
            <FormInput
              name="meta.copyright_notice"
              label="Copyright Notice"
              type="textarea"
              placeholder="Type Copyright Notice"
              {...formData.meta?.copyright_notice}
              {...handlers}
            />
          )}
          <FormHtmlInput label="Notes" name="meta.notes" large {...handlers} {...formData?.meta?.notes} />
        </InfoBoxV2>
        <InfoBoxV2 gridTemplateColumns="1fr" title="Identifiers">
          <FormMultiSelect
            name="tags"
            label="Tags"
            allowNewItems
            {...formData.tags}
            {...handlers}
            fetchValues={getMm3Tags}
            noResults={null}
            hideClearIfEmptySelect
          />

          <FormInput
            label="External Reference"
            name="external_reference"
            placeholder="Type External Reference"
            {...formData.external_reference}
            {...handlers}
          />
          <FormInput
            name="meta.asset_identifier"
            label="Asset Identifier"
            placeholder="Type Asset Identifier"
            {...formData.meta?.asset_identifier}
            {...handlers}
          />
          <FormInput
            label="Archive Number"
            name="meta.archive_number"
            placeholder="Type Archive Number"
            {...formData.meta?.archive_number}
            {...handlers}
          />
        </InfoBoxV2>
        <InfoBoxV2 gridTemplateColumns="1fr" title="Provenance">
          <FormSelect
            name="source"
            label="Source"
            placeholder="Select Source"
            {...formData.source}
            {...handlers}
            large
            options={filteredSources}
            formatLabel={formatAssetSource}
          />

          <FormCheckbox label="AI-generated" name="meta.ai_generated" {...handlers} {...formData?.meta?.ai_generated} />
          <FormInput
            label="AI Notes"
            name="meta.ai_notes"
            {...handlers}
            {...formData?.meta?.ai_notes}
            disabled={Boolean(!formData?.meta?.ai_generated?.value)}
          />
          {isAudioOrSubtitle && (
            <FormCheckbox
              label="Auto-generated"
              name="meta.auto_generated"
              {...handlers}
              {...formData?.meta?.auto_generated}
            />
          )}
        </InfoBoxV2>
        {isAudioOrSubtitle && (
          <InfoBoxV2 gridTemplateColumns="1fr" title="Player Settings">
            <FormCheckbox
              name="meta.show_in_player"
              label="Show in player"
              {...formData.meta?.show_in_player}
              {...handlers}
            />
            <FormInput label="Label" name="meta.label" {...handlers} {...formData?.meta?.label} />
            <FormSelect
              name="meta.purpose"
              label="Purpose"
              {...formData.meta?.purpose}
              {...handlers}
              large
              formatLabel={formatAssetPurpose}
            />
          </InfoBoxV2>
        )}
      </div>
    </div>
  );
});
