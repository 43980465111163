import React, { useCallback } from 'react';
import omit from 'lodash/omit';

import { useStore } from 'store';
import { IAsset, IMm3Asset } from 'types';
import { FormSubmitSection } from 'components/form-submit-section';
import { FormNumericInput, useForm } from 'helpers/form';
import { Model } from 'helpers/filters/types';
import { IAssetActionConfig, IAssetActionName } from 'utils/actions/asset/types';
import { isAssetIngesting } from 'utils/asset';

import { extractPreviewImage } from './api';
import { shouldDisplayExtractPreviewImage as shouldDisplay } from './actions-acl';
import { ToastError } from 'components/toast';
import { IModalSize } from 'components/dialogs/types';

interface IExtractPreviewFormFields {
  positionOffset: number;
}

const initialValues: IExtractPreviewFormFields = { positionOffset: 0 };

export interface IExtractPreviewImageProps {
  title?: string;
  onSubmit: (positionOffset?: number) => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export interface IExtractPreviewImageForm {
  onSubmit: (values: IExtractPreviewFormFields) => void;
}

const customContext = {
  properties: {
    positionOffset: {
      validations: [
        {
          numericality: {
            greater_than_or_equal_to: 0,
          },
        },
      ],
    },
  },
};

const ExtractPreviewImageForm: React.FC<IExtractPreviewImageForm> = ({ onSubmit }) => {
  const {
    formData,
    handlers,
    valid,
    onSubmit: onFormSubmit,
  } = useForm<IExtractPreviewFormFields>(initialValues, Model.ASSETS, onSubmit, customContext);

  return (
    <>
      <form onSubmit={onFormSubmit}>
        <FormNumericInput
          formId="extract-preview-image"
          name="positionOffset"
          label="Position Offset (%)"
          {...formData.positionOffset}
          {...omit(handlers, 'onSetFields')}
        />
      </form>
      <FormSubmitSection labels={{ confirm: 'Save' }} submitDisabled={!valid} onSubmit={onFormSubmit} />
    </>
  );
};

export const useExtractPreviewAction = (items: (IAsset | IMm3Asset)[], options): IAssetActionConfig => {
  const { dialogStore, toastStore } = useStore();

  const handleSubmit = useCallback(
    async (values: IExtractPreviewFormFields) => {
      try {
        extractPreviewImage(items?.[0]?.id, values.positionOffset);
        toastStore.success(
          'You have successfully requested to extract an image from this video. We will notify you via email when the extraction process is completed.',
        );
        options?.onSuccess(IAssetActionName.EXTRACT_PREVIEW, items[0]);
        return dialogStore.close();
      } catch (error) {
        toastStore.error(<ToastError error={error} />);
      }
    },
    [items, toastStore, options, dialogStore],
  );

  const body = useCallback(() => <ExtractPreviewImageForm onSubmit={handleSubmit} />, [handleSubmit]);

  const openExtractPreviewDialog = useCallback((): void => {
    dialogStore.openModal({
      title: `Extract Preview Image from Video: ${items[0]?.name}`,
      body,
      size: IModalSize.S,
    });
  }, [body, dialogStore, items]);

  return {
    name: IAssetActionName.EXTRACT_PREVIEW,
    shouldDisplay,
    icon: 'unarchive',
    title: 'Extract preview image',
    isDisabled: !items?.[0]?.id || isAssetIngesting(items[0]),
    handler: openExtractPreviewDialog,
  };
};
