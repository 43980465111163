import React from 'react';
import { ActionIcon, Tooltip, useMantineColorScheme } from '@mantine/core';
import { MantineIcon } from 'utils/ui';

import { useColorModeToggle } from 'utils/hooks/use-color-mode-toggle';

const ToggleThemeButton: React.FC = (): JSX.Element => {
  const { colorScheme } = useMantineColorScheme();
  const toggleColorMode = useColorModeToggle();

  return (
    <Tooltip
      label={colorScheme === 'dark' ? 'Switch to light mode' : 'Switch to dark mode'}
      position="bottom"
      withArrow
    >
      <ActionIcon onClick={toggleColorMode} title="Toggle theme" className="toggle-theme__button me-3">
        {colorScheme === 'dark' ? (
          <MantineIcon icon="flash" size={16} color="var(--mfx-muted-text)" />
        ) : (
          <MantineIcon icon="moon" size={16} color="var(--mfx-gray-3)" />
        )}
      </ActionIcon>
    </Tooltip>
  );
};

export default ToggleThemeButton;
