import React from 'react';
import { Stack, Tooltip } from '@mantine/core';

import { defaultFormValues } from 'components/assign-asset-to-product-form/const';
import { FormSubmitSection } from 'components/form-submit-section';

import { IAssetToProduct, IRequireAtLeastOne, ItemId } from 'types';
import { MantineIcon } from 'utils/ui';
import { Model } from 'helpers/filters/types';
import { FormAssets, FormCheckbox, FormProducts, useForm } from 'helpers/form';

import './style.scss';

interface IAssetProductFormProps {
  assetIds: ItemId[];
  productIds: ItemId[];
  subAssetsId?: ItemId[];
  onSubmit: (values: IAssetToProduct) => Promise<void>;
}

const customContext = {
  properties: {
    asset_ids: { required: true },
    product_ids: { required: true },
  },
};

export const AssetProductForm: React.FC<
  IRequireAtLeastOne<IAssetProductFormProps, 'assetIds' | 'productIds' | 'subAssetsId'>
> = (props) => {
  const { assetIds = [], productIds = [], onSubmit } = props;

  const {
    formData,
    handlers,
    valid,
    onSubmit: handleSubmit,
  } = useForm<IAssetToProduct>(
    {
      ...defaultFormValues,
      asset_ids: assetIds,
      product_ids: productIds,
    } as IAssetToProduct,
    Model.ASSETS,
    onSubmit,
    customContext,
  );

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="mb-3 assign-product-asset__container">
          <FormAssets name="asset_ids" label="Assets" {...formData.asset_ids} {...handlers} fitInParent />
          <FormProducts name="product_ids" label="Products" {...formData['product_ids']} {...handlers} fitInParent />
        </div>
        <Stack gap={0}>
          <div className="d-flex justify-content-space-between">
            <FormCheckbox
              name="marketing_use"
              label="Use as Marketing Asset"
              className="mb-0"
              {...formData['marketing_use']}
              {...handlers}
            />
            <Tooltip label={'Will be flagged for marketing use ★ on the selected Product(s)'}>
              <div className="px-1">
                <MantineIcon icon="info-sign" size={11} />
              </div>
            </Tooltip>
          </div>
          <FormCheckbox
            name="include_descendants"
            label="Assign to product(s) including descendants"
            className="mt-0"
            {...formData['include_descendants']}
            {...handlers}
          />
        </Stack>
      </form>
      <FormSubmitSection submitDisabled={!valid} labels={{ confirm: 'Assign' }} onSubmit={handleSubmit} />
    </>
  );
};
