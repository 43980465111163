import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { useStore } from 'store';

import { AccessPrivilegeProductSettingsChange } from 'components/access-privilege';

import { IProduct } from 'types';
import { IUseActionsOptionParam } from 'utils/actions';
import { IProductActionConfig, IProductActionName } from 'utils/actions/product/types';
import { pluralEntityInflect } from 'utils/general';

import { shouldDisplayAccessPrivilegeProductAccessChange as shouldDisplay } from './actions-acl';
import { IModalSize } from 'components/dialogs/types';

export const useAccessPrivilegeProductAccessSettingsAction = (
  items: IProduct[],
  options: IUseActionsOptionParam,
): IProductActionConfig => {
  const { dialogStore } = useStore();
  const params = useParams<{ groupId: string }>();
  const groupId = params[options.groupParamName ?? ''];

  const { close, openModal } = dialogStore;

  const body = useCallback(() => {
    if (!groupId) {
      return <></>;
    }
    const productIds = items.map(({ id }) => id);
    return <AccessPrivilegeProductSettingsChange productIds={productIds} groupIds={[groupId]} onSubmit={close} />;
  }, [items, close, groupId]);

  const openDialog = (): void => {
    const { entity } = pluralEntityInflect('Product', items.length);

    openModal({
      title: `Change ${entity} settings in this Access Privilege`,
      body,
      size: IModalSize.XS,
    });
  };

  return {
    name: IProductActionName.CHANGE_ACCESS_PRIVILEGE_ACCESS,
    shouldDisplay,
    icon: 'key',
    title: `Change access settings`,
    handler: openDialog,
  };
};
