import React from 'react';
import { set } from 'mobx';
import {
  UmGenericListShowcase,
  UmGenericListCollection,
  McGenericRecommendationProduct,
  UmGenericListItem,
  DeliveriesGenericConnection,
  DeliveriesGenericDeliveryPackage,
  CalGenericLocation,
  PmGenericCastCrew,
  AmGenericNews,
  ProducerGenericReport,
} from '@mediafellows/mm3-types';
import { Menu } from '@mantine/core';

import { IDivisionTreeItem, IConference, IGenreTreeItem, IGroup, IMm3Asset, ISubtitle } from 'types';
import { IGuild } from 'components/guilds';

import {
  useAssetActions,
  useContactActions,
  useEventActions,
  useOrganizationActions,
  useProductActions,
  useShowcaseActions,
  useAccessPrivilegeActions,
  useBasketActions,
  useRecommendationActions,
  useConnectionActions,
  useDeliveryActions,
  IAction,
  useConferenceActions,
} from 'utils/actions';
import { IActionName } from 'utils/actions/types';
import {
  IAsset,
  IContact,
  IMarketingEvent,
  IOrganization,
  IBasketStandardized,
  IProduct,
  IAccessPrivilege,
  IIdentifiable,
  IMobileAppSync,
  IAccessGroup,
  IAudioTrack,
} from 'types';
import { getRootStore } from 'store';

import { IItemActionsMenuProps } from './utils';
import { useMobileAppSyncActions } from 'utils/actions/mobile-app-sync';
import { useLocationActions } from 'utils/actions/location';
import { useGenreActions } from 'utils/actions/genre';

export { ActionMenuItem } from './utils';
export {
  SelectionListItemActionsList,
  AssetSelectionActionsList,
  ContactSelectionActionsList,
  ProductSelectionActionsList,
} from './selection';
export { WorkflowActionsList } from './workflow';
import type { IConnectionDeliveryPayload, ICreateWorkFlowDeliveryPayload } from 'utils/actions/types';
import { useAccessGroupActions } from 'utils/actions/access-group';
import { renderMenuActionItems } from 'utils/ui';
import { IExtendedGenericList } from 'components/website-data-section/utils';
import { useGildsActions } from 'utils/actions/guilds';
import { useDivisionActions } from 'utils/actions/division';
import { useAudioActions } from 'utils/actions/audio';
import { useCastCrewActions } from 'utils/actions/cast-crew';
import { useListItemActions } from 'utils/actions/list-item';
import { useNewsActions } from 'utils/actions/news';
import { useProducerPortalActions } from 'utils/actions/producer-portal';
import { useScreeningRoomActions } from 'utils/actions/screening-room';
import { useSubtitleActions } from 'utils/actions/subtitle';
import { useLocalizationOrderActions } from 'utils/actions/localization-order';
import { ILocalizationOrder } from 'types/localization-order';
interface IEntityActionListWrapperProps {
  actions: IAction<IActionName>[];
}
export const EntityActionListWrapper: React.FC<IEntityActionListWrapperProps> = ({ actions }) => {
  if (!actions?.length)
    return (
      <Menu disabled>
        <Menu.Item disabled>No actions available</Menu.Item>
      </Menu>
    );
  return <>{renderMenuActionItems(actions)}</>;
};

export const AssetActionsList: React.FC<IItemActionsMenuProps<IAsset | IMm3Asset>> = (props) => {
  const { items, options } = props;
  const actions = useAssetActions(items, options);
  return <EntityActionListWrapper actions={actions} />;
};

export const refresh = (action: IActionName, showcase: UmGenericListShowcase): void => {
  if (!showcase?.id || showcase.type !== 'List::Showcase') {
    return;
  }

  const {
    dataSectionStore: { searchStore },
  } = getRootStore();
  const item = searchStore?.objects.find((s) => s.id === showcase.id);
  set(item, { ...showcase, updated_at: new Date().toISOString() });
};

export const ShowcaseActionsList: React.FC<
  IItemActionsMenuProps<
    ((UmGenericListShowcase | UmGenericListCollection) & { listItem?: UmGenericListItem }) | IExtendedGenericList
  >
> = (props) => {
  const { items, options } = props;
  if (!options.onSuccess) options.onSuccess = refresh;

  const actions = useShowcaseActions(items, options);

  return <EntityActionListWrapper actions={actions} />;
};

export const ProductActionsList: React.FC<IItemActionsMenuProps<IProduct & { listItem?: UmGenericListItem }>> = (
  props,
) => {
  const { items, options } = props;

  const actions = useProductActions(items, options);

  return <EntityActionListWrapper actions={actions} />;
};

export const LocationActionsList: React.FC<IItemActionsMenuProps<CalGenericLocation>> = (props) => {
  const { items, options } = props;

  const actions = useLocationActions(items, options);

  return <EntityActionListWrapper actions={actions} />;
};

export const ContactActionsList: React.FC<IItemActionsMenuProps<IContact>> = (props) => {
  const { items, options } = props;

  const actions = useContactActions(items, options);

  return <EntityActionListWrapper actions={actions} />;
};

export const OrganizationActionsList: React.FC<
  IItemActionsMenuProps<IOrganization & IIdentifiable & { listItem?: UmGenericListItem }>
> = (props) => {
  const { items, options } = props;

  const actions = useOrganizationActions(items, options);

  return <EntityActionListWrapper actions={actions} />;
};

export const EventActionsList: React.FC<IItemActionsMenuProps<IMarketingEvent>> = (props) => {
  const { items, options } = props;

  const actions = useEventActions(items, options);

  return <EntityActionListWrapper actions={actions} />;
};

export const BasketActionsList: React.FC<IItemActionsMenuProps<IBasketStandardized>> = (props) => {
  const { items, options } = props;

  const actions = useBasketActions(items, options);

  return <EntityActionListWrapper actions={actions} />;
};

export const AccessPrivilegeActions: React.FC<IItemActionsMenuProps<IAccessPrivilege>> = (props) => {
  const { items, options } = props;

  const actions = useAccessPrivilegeActions(items, options);

  return <EntityActionListWrapper actions={actions} />;
};

export const MobileAppSyncActions: React.FC<IItemActionsMenuProps<IMobileAppSync>> = (props) => {
  const { items, options } = props;

  const actions = useMobileAppSyncActions(items, {
    messageSuccess: 'Mobile Selection has been copied',
    ...options,
  });

  return <EntityActionListWrapper actions={actions} />;
};

export const RecommendationActions: React.FC<IItemActionsMenuProps<McGenericRecommendationProduct>> = (props) => {
  const { items, options } = props;

  const actions = useRecommendationActions(items, options);
  return <EntityActionListWrapper actions={actions} />;
};

export const ConnectionActionsList: React.FC<
  IItemActionsMenuProps<IConnectionDeliveryPayload<DeliveriesGenericConnection>>
> = (props) => {
  const { items, options } = props;

  const actions = useConnectionActions(items, options);

  return <EntityActionListWrapper actions={actions} />;
};

export const DeliveryActionsList: React.FC<
  IItemActionsMenuProps<ICreateWorkFlowDeliveryPayload<DeliveriesGenericDeliveryPackage>>
> = (props) => {
  const { items, options } = props;

  const actions = useDeliveryActions(items, options);

  return <EntityActionListWrapper actions={actions} />;
};

export const AccessGroupActions: React.FC<IItemActionsMenuProps<IAccessGroup>> = (props) => {
  const { items, options } = props;

  const actions = useAccessGroupActions(items, options);

  return <EntityActionListWrapper actions={actions} />;
};

export const GuildActions: React.FC<IItemActionsMenuProps<IGuild & IIdentifiable>> = (props) => {
  const { items, options } = props;

  const actions = useGildsActions(items, options);

  return <EntityActionListWrapper actions={actions} />;
};

export const GenreActionsList: React.FC<IItemActionsMenuProps<IGenreTreeItem>> = (props) => {
  const { items, options } = props;

  const actions = useGenreActions(items, options);

  return <EntityActionListWrapper actions={actions} />;
};

export const AudioActionsList: React.FC<IItemActionsMenuProps<IAudioTrack>> = (props) => {
  const { items, options } = props;
  const actions = useAudioActions(items, options);
  return <EntityActionListWrapper actions={actions} />;
};

export const CastCrewActionsList: React.FC<IItemActionsMenuProps<PmGenericCastCrew>> = (props) => {
  const { items, options } = props;
  const actions = useCastCrewActions(items, options);
  return <EntityActionListWrapper actions={actions} />;
};

export const ConferenceActionsList: React.FC<IItemActionsMenuProps<IConference>> = (props) => {
  const { items, options } = props;
  const actions = useConferenceActions(items, options);
  return <EntityActionListWrapper actions={actions} />;
};

export const ListItemActionsList: React.FC<IItemActionsMenuProps<UmGenericListItem>> = (props) => {
  const { items, options } = props;
  const actions = useListItemActions(items, options);
  return <EntityActionListWrapper actions={actions} />;
};

export function NewsActionsList(props: IItemActionsMenuProps<AmGenericNews>): JSX.Element {
  const { items, options } = props;
  const actions = useNewsActions(items, options);
  return <EntityActionListWrapper actions={actions} />;
}

export const ProducerPortalActionsList: React.FC<IItemActionsMenuProps<ProducerGenericReport>> = (props) => {
  const { items, options } = props;
  const actions = useProducerPortalActions(items, options);
  return <EntityActionListWrapper actions={actions} />;
};

export const ScreeningRoomActionsList: React.FC<IItemActionsMenuProps<IGroup>> = (props) => {
  const { items, options } = props;
  const actions = useScreeningRoomActions(items, options);
  return <EntityActionListWrapper actions={actions} />;
};

export const SubtitleActionsList: React.FC<IItemActionsMenuProps<ISubtitle>> = (props) => {
  const { items, options } = props;
  const actions = useSubtitleActions(items, options);
  return <EntityActionListWrapper actions={actions} />;
};

export const DivisionActionsList: React.FC<IItemActionsMenuProps<IDivisionTreeItem>> = (props) => {
  const { items, options } = props;
  const actions = useDivisionActions(items, options);
  return <EntityActionListWrapper actions={actions} />;
};

export const LocalizationOrderActionsList: React.FC<IItemActionsMenuProps<ILocalizationOrder>> = (props) => {
  const { items, options } = props;

  const actions = useLocalizationOrderActions(items, options);

  return <EntityActionListWrapper actions={actions} />;
};
