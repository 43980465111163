import React from 'react';
import ContactDetails from './contact-details';
import { ChangePasswordModal } from './change-password-modal';

export const ContactDetailsWithModal: React.FC = () => {
  return (
    <>
      <ContactDetails />
      <ChangePasswordModal />
    </>
  );
};
