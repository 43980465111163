import { Model } from 'helpers/filters/types';
import { IQueryParams } from 'types';
import { autosuggestValues } from 'utils/autosuggest-values';

export const getSuggestions = async ({ ids, q }: IQueryParams, property: string): Promise<string[]> => {
  if (ids) {
    return ids as string[];
  }
  return autosuggestValues(Model.ASSETS, property, q as string);
};

export const getTags = (params: IQueryParams): Promise<string[]> => getSuggestions(params, 'tags');

export enum AssetMetadataTabSubTabs {
  GENERAL = 'General',
  TECHNICAL_SPECIFICATIONS = 'Technical Specifications',
}
