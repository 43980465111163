import React from 'react';
import { observer } from 'mobx-react-lite';
import { isEqual } from 'lodash';
import { Switch } from '@mantine/core';

import { EqFilter, ExistFilter, NotExistFilter, InFilter, NotInFilter } from 'helpers/filters/types';
import { useIsInPersistentFilter } from 'helpers/filters/fields/utils';

type ISwitchFilterDefinition = EqFilter | ExistFilter | NotExistFilter | InFilter | NotInFilter;

interface IFilterSwitchProps {
  name: string;
  label: string;
  large?: boolean;
  disabled?: boolean;
  checkedValue: number | string | boolean | boolean[] | string[];
  notCheckedValue: number | string | boolean | boolean[] | string[];

  filter: ISwitchFilterDefinition;
  onChange?: (newValue: { [key: string]: ISwitchFilterDefinition }) => void;
}

const FilterSwitch: React.FC<IFilterSwitchProps> = observer((props) => {
  const { name, filter, checkedValue, notCheckedValue, onChange, disabled, ...restProps } = props;

  const [isInPersistentFilter] = useIsInPersistentFilter(name);
  const isDisabled = isInPersistentFilter || disabled;
  const { value } = filter || {};
  const handleChange: React.FormEventHandler<HTMLInputElement> = (ev): void => {
    const newValue = ev.currentTarget.checked ? checkedValue : notCheckedValue;

    onChange?.({ [name]: { ...filter, value: newValue } as ISwitchFilterDefinition });
  };

  return (
    <Switch
      mb="sm"
      checked={isEqual(value, checkedValue)}
      onChange={handleChange}
      disabled={isDisabled}
      {...restProps}
    />
  );
});

export default FilterSwitch;
