import merge from 'lodash/merge';

import { getRequiredError, IValidationResult } from 'helpers/form/mm3';
import { Product3Types, IProduct, IProductLayer } from 'types';
import { cleanUp } from 'utils/payload';
import { slugify } from 'utils/slug';

export interface ICreateFormData {
  product: IProduct;
  layer: IProductLayer;
}

export function customValidator(
  { type, parent_id, access_level, division_ids }: IProduct,
  validations: IValidationResult<IProduct>,
): IValidationResult<IProduct> {
  const divisionsValidations = {
    required: access_level === 'division',
    ...(access_level === 'division' && !division_ids?.length ? getRequiredError() : {}),
  };

  if ([Product3Types.FORMAT, Product3Types.PROGRAM, Product3Types.SERIES].includes(type))
    return merge({}, validations, {
      division_ids: divisionsValidations,
    }) as IValidationResult<IProduct>;

  return merge({}, validations, {
    parent_id: {
      required: true,
      ...(parent_id ? {} : getRequiredError()),
    },
    division_ids: divisionsValidations,
  }) as IValidationResult<IProduct>;
}

export const prepareProductPayload = ({ product, layer }: ICreateFormData): ICreateFormData => {
  const slug = slugify(layer.title);
  const clanedUpValues = cleanUp({ product: { ...product, meta: { ...product.meta, slug } }, layer });
  const formattedIds = clanedUpValues.product?.division_ids?.map(Number);
  return { ...clanedUpValues, product: { ...clanedUpValues.product, division_ids: formattedIds } };
};
export const customLabel = {
  title: 'Title',
};
