import { action, observable } from 'mobx';
import { logger } from 'utils/logger';

import { AssetsUploaderStore } from './assets-upload-store';

export class MultiAssetsUploaderStore {
  @observable public multiAssetsUploaderStores: Record<string, AssetsUploaderStore> = {};

  @action.bound
  addUploaderStore(id: string): void {
    if (this.multiAssetsUploaderStores[id]) logger.error(`this is already exists, it will be overwritten: ${id}`);
    this.multiAssetsUploaderStores[id] = new AssetsUploaderStore(id);
  }
  @action.bound
  removeUploaderStore(id: string): void {
    this.multiAssetsUploaderStores[id].cleanUp();
    delete this.multiAssetsUploaderStores[id];
  }
  @action.bound
  getUploaderStore(id: string): AssetsUploaderStore {
    return this.multiAssetsUploaderStores[id];
  }
  @action.bound
  getOrCreateUploaderStore(id: string): AssetsUploaderStore {
    if (!this.multiAssetsUploaderStores[id]) {
      this.addUploaderStore(id);
    }
    return this.multiAssetsUploaderStores[id];
  }
}
