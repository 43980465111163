import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { endOfDay, startOfDay, subDays } from 'date-fns';

import { Routes } from 'utils/routes';
import { AssetVideoPlayer } from 'components/common';
import { Thumbnail } from 'components/thumbnail';
import { AssetFilters, getIsMm3Assets, isShell, readyStatuses } from 'utils/asset';
import { PreviewBarChart } from 'components/preview-bar-chart';
import { SectionHeader } from 'components/section-header';
import { ThumbnailFileStatus } from 'components/file-status';
import { PreviewTab } from 'components/preview-tab';
import { AssetAudioPlayer } from 'components/audio-player/audio-player';
import { Row, yn } from 'components/label-value-pair';
import { InfoBoxV2 } from 'components/info-box-v2';

import { useDataSectionStore, useStore } from 'store';

import { IAsset, IMm3Asset, IHistogramData, DetailsPageTabs, IMm3AssetType } from 'types';

import { changeAssetAccessImmediate } from 'utils/apis/asset';
import { formatType, getEntityType } from 'utils/general';
import { useRemote } from 'utils/hooks';
import { LinkWithArrow } from 'utils/ui';
import { assetListSchema } from 'utils/schemas/asset';
import { getHistogramData } from 'utils/apis/analytics';
import { changeAccessLevelSlider } from 'utils/change-access';
import { getIngestStatus, getPreviewImageUrl } from 'utils/asset';
import { AssetPreviewAttributes } from 'components/asset/asset-preview-tab/asset-preview-attributes';
import { ParentBreadcrumbs } from 'components/parent-breadcrumb';

import { useGroupAssetItemInfo } from './utils';

import './style.scss';
import { useEarlyUploadImageDialog } from 'components/upload-preview-image/upload-preview-image-early';
import { noop } from '@mantine/core';

const AssetPreviewTab: React.FC = observer(() => {
  const { active, activeItem: asset, searchStore } = useDataSectionStore<IAsset | IMm3Asset>();
  const isMm3Assets = getIsMm3Assets();

  const end = React.useMemo(() => endOfDay(new Date()), []);
  const start = React.useMemo(() => subDays(startOfDay(end), 13), [end]);

  const { id, access_level, name, classification, deleted_at } = (asset || {}) as IMm3Asset;

  const { groupItem, groupName } = useGroupAssetItemInfo(id);
  const detailsPageLink = `${Routes.ASSETS}/${id}`;

  const assetItemType = getEntityType(classification);
  const isVideo = assetItemType === AssetFilters.VIDEO;
  const isShellAsset = isShell(asset);

  const fetchAssetAnalytics = useCallback(async (): Promise<IHistogramData[]> => {
    const { data } = await getHistogramData({
      entity: 'asset',
      queryName: isVideo ? 'video::histogram' : 'asset::histogram',
      entityId: active,
      dateRange: [start, end],
    });
    return data;
  }, [active, end, isVideo, start]);

  const [histogram] = useRemote(fetchAssetAnalytics);

  const handleAccessLevelChange = useCallback(
    async (newAccessLevel: string) => {
      const result = await changeAccessLevelSlider({
        apiCall: (data) => changeAssetAccessImmediate(data, assetListSchema),
        divisionIds: asset?.division_ids,
        newAccessLevel,
      });
      if (typeof result === 'object' && searchStore) {
        const updatedAsset = result as IAsset;
        const assets = searchStore?.objects || [];
        const index = assets.findIndex((asset) => asset.id === updatedAsset.id);

        const updatedAssets =
          index !== -1
            ? [...assets.slice(0, index), { ...assets[index], ...updatedAsset }, ...assets.slice(index + 1)]
            : [];

        updatedAssets.length &&
          searchStore.update({
            objects: updatedAssets,
          });
      }

      return result;
    },
    [asset?.division_ids, searchStore],
  );

  const CustomRightRenderer = useCallback(
    (): JSX.Element => <LinkWithArrow to={`${detailsPageLink}/${DetailsPageTabs.ANALYTICS}`} text="View All" />,
    [detailsPageLink],
  );

  const customThumbnail = useMemo(() => {
    const assetItemType = isMm3Assets ? formatType((asset as IMm3Asset)?.type) : getEntityType(asset?.classification);

    let isVideo = false;
    let isAudio = false;
    if (isMm3Assets) {
      isVideo = (asset as IMm3Asset)?.type === IMm3AssetType.VIDEO;
      isAudio = (asset as IMm3Asset)?.type === IMm3AssetType.AUDIO;
    } else {
      isVideo = assetItemType === AssetFilters.VIDEO;
      isAudio = assetItemType === AssetFilters.AUDIO;
    }

    const isFileReady = readyStatuses.has(asset?.file_status || '');
    if (isVideo && isFileReady) {
      return <AssetVideoPlayer video={asset} customTag={AssetFilters.VIDEO} />;
    }
    if (isAudio) {
      return <AssetAudioPlayer asset={asset} key={asset?.id} customTag={AssetFilters.AUDIO} />;
    }
    return (
      <Thumbnail
        isShellAsset={isShellAsset}
        image={getPreviewImageUrl(asset?.preview_image)}
        showType
        assetType={(asset as IMm3Asset)?.type || asset?.classification}
        placeholder={
          <ThumbnailFileStatus isShellAsset={isShellAsset} status={getIngestStatus(asset)} size={35} withBackground />
        }
      />
    );
  }, [asset, isMm3Assets, isShellAsset]);

  const { parent } = (asset as IMm3Asset) || {};
  const {
    assetsFileUploadStore: { submitFile },
  } = useStore();
  const [openUploadDialog] = useEarlyUploadImageDialog({
    onSubmit: submitFile,
    asset: asset || ({} as IAsset | IMm3Asset),
  });

  return (
    <PreviewTab
      title={name}
      onClick={isShellAsset ? openUploadDialog : noop}
      breadcumbs={
        <ParentBreadcrumbs
          title={parent?.name}
          id={parent?.id}
          to={`${Routes.ASSETS}/${parent?.id}`}
          layout="preview"
        />
      }
      showBreadcrumbs={parent}
      accessLevel={access_level}
      detailsLink={deleted_at ? '' : `${detailsPageLink}/${DetailsPageTabs.OVERVIEW}`}
      handleAccessLevelChange={handleAccessLevelChange}
      customThumbnail={customThumbnail}
      hideDivisionAccessLevel={false}
    >
      {groupItem && (
        <div className="p-3">
          <InfoBoxV2
            title={`${groupName}: Settings`}
            padded
            wrapperClassName="pt-3 pb-3 group-preview__section"
            gridTemplateColumns={'minmax(145px, 1fr) 1fr'}
          >
            <Row
              hideIfNoValue
              label="Download permission"
              value={groupItem?.id && yn(groupItem.permissions?.includes('download'))}
            />
          </InfoBoxV2>
        </div>
      )}

      <AssetPreviewAttributes />
      <div className="asset-preview__analytics">
        <SectionHeader
          useBackground
          title={isVideo ? 'Screening Activity' : 'Download Activity'}
          {...(!deleted_at && { CustomRightRenderer })}
          fileStatusOnClick={isShellAsset ? openUploadDialog : noop}
        />
        <div className="asset-preview__analytics__graph">
          <PreviewBarChart
            data={histogram}
            title={isVideo ? 'Screening Activity' : 'Download Activity'}
            startDate={start}
            endDate={end}
            unit={isVideo ? 'screening' : 'download'}
          />
        </div>
      </div>
    </PreviewTab>
  );
});
export default AssetPreviewTab;
