import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { v4 as uuidv4 } from 'uuid';
import omit from 'lodash/omit';
import { UppyFile } from '@uppy/core';
import { Button } from '@mantine/core';

import { SectionHeader } from 'components/section-header';
import { IAssetPresetFields } from 'types';
import { FormSelect, useForm, useMm3Form } from 'helpers/form';
import { IFormHandlers, IFormData } from 'helpers/form/types';
import { formatFileFields } from 'utils/assets-upload';
import { getIsMm3Assets } from 'utils/asset';

import PresetForm from 'components/asset/asset-upload-components/preset-form/preset-form';
import { Model } from 'helpers/filters/types';
import { useDialogStore } from 'store/hooks';
import { presetKeysToOmit } from 'components/asset/asset-upload-components/constants';
import { Classes } from 'utils/ui';
import { AssetsUploaderStore } from 'store/assets-upload-store';

import './index.scss';

export interface ISelectAssetPresetProps {
  shouldShowErrors: boolean;
  applyPreset: (preset: IAssetPresetFields) => void;
  presets: Record<string, IAssetPresetFields>;
  withParentField?: boolean;
  assetsUploaderStore: AssetsUploaderStore;
}

type IPresetOption = {
  presetName: string;
};

interface IAssetPresetFormProps {
  formData: IFormData<IAssetPresetFields>;
  handlers: IFormHandlers<IAssetPresetFields>;
  values: IAssetPresetFields;
  shouldShowErrors: boolean;
  withParentField?: boolean;
  assetsUploaderStore: AssetsUploaderStore;
}

export const AssetPresetForm: React.FC<IAssetPresetFormProps> = ({
  formData,
  handlers,
  shouldShowErrors,
  values,
  withParentField,
  assetsUploaderStore,
}) => {
  return (
    <PresetForm
      formId="select-preset-form"
      {...{ formData, handlers, values, shouldShowErrors, withParentField }}
      {...assetsUploaderStore}
    />
  );
};

interface IPresetSelectorProps extends IPresetOption {
  onPresetChange: (option: IPresetOption) => void;
  presetOptions: string[];
}

const PresetSelector: React.FC<IPresetSelectorProps> = ({ presetName, onPresetChange, presetOptions }) => (
  <div className="asset-upload-components__select-preset-modal-header">
    <FormSelect
      label=""
      validation={{ valid: true }}
      touched={false}
      required={false}
      name="presetName"
      placeholder="Select Preset"
      value={presetName}
      onChange={onPresetChange}
      options={presetOptions}
    />
  </div>
);
export const SelectAssetPreset: React.FC<ISelectAssetPresetProps> = observer((props) => {
  const { shouldShowErrors, applyPreset, presets, withParentField, assetsUploaderStore } = props;
  const [presetName, setPresetName] = useState<string>('');
  const initializeValues = omit(formatFileFields({} as UppyFile), presetKeysToOmit);
  const [customKey, setCustomKey] = useState(uuidv4());
  const isMm3Assets = getIsMm3Assets();

  const { formData, handlers, values } = isMm3Assets
    ? useMm3Form(initializeValues, Model.MM3_ASSETS)
    : useForm<IAssetPresetFields>(initializeValues as IAssetPresetFields, Model.ASSET_DIGITAL_IMAGE);
  const { close } = useDialogStore();

  const onPresetChange = useCallback(
    ({ presetName }: IPresetOption) => {
      setPresetName(presetName);
      setCustomKey(uuidv4());
      const preset = presetName ? presets[presetName] : initializeValues;
      isMm3Assets ? handlers.onChange(preset) : (handlers as IFormHandlers<IAssetPresetFields>).onSetFields(preset);
    },
    [handlers, initializeValues, isMm3Assets, presets],
  );
  const handleApplyPreset = useCallback(() => {
    applyPreset(values as IAssetPresetFields);
    close();
  }, [applyPreset, close, values]);

  const presetOptions = Object.keys(presets);
  return (
    <>
      <SectionHeader
        customRightRenderer={() => <PresetSelector {...{ presetOptions, presetName, onPresetChange }} />}
        className="asset-upload__preset-selector-container"
      />
      <div className="asset-upload-components__select-preset-modal-container px-3">
        <AssetPresetForm
          {...({
            formData,
            handlers,
            values,
            shouldShowErrors,
            withParentField,
          } as unknown as IAssetPresetFormProps)}
          key={customKey}
          assetsUploaderStore={assetsUploaderStore}
        />
      </div>
      <div className={`${Classes.DIALOG_FOOTER_ACTIONS} asset-upload-components__select-preset-modal-footer px-3`}>
        <Button onClick={handleApplyPreset} size="xs" variant="primary">
          Apply
        </Button>
        <Button onClick={close} variant="default" size="xs" className="me-4">
          Cancel
        </Button>
      </div>
    </>
  );
});

export default SelectAssetPreset;
