import React from 'react';
import { ActionIcon } from '@mantine/core';
import { MantineIcon } from 'utils/ui/icon';

interface IPasswordVisibilityToggleProps {
  showPassword: boolean;
  onToggle: () => void;
  color?: string;
  variant?: string;
}

export const PasswordVisibilityToggle: React.FC<IPasswordVisibilityToggleProps> = ({
  showPassword,
  onToggle,
  color = 'gray.5',
  variant = 'subtle',
}) => {
  return (
    <ActionIcon variant={variant} color={color} onClick={onToggle}>
      <MantineIcon icon={showPassword ? 'eye-off' : 'eye-on'} />
    </ActionIcon>
  );
};
