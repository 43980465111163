import React from 'react';

import { DetailsPageTabs, IAsset, IMm3Asset } from 'types';

import { OldAssetAudiosTab } from 'pages/asset-details/asset-audios-tab/asset-audios-tab';
import { AssetOldSubtitlesTab } from 'pages/asset-details/asset-subtitles-tab/asset-old-subtitles-tab';

import { AssetMm3ChildrenTab } from './asset-children-tab-mm3';
import { getIsMm3Assets } from 'utils/asset';

interface IChildrenTabPreviewProps {
  asset: IAsset | IMm3Asset;
  tab: DetailsPageTabs;
  setAsset: React.Dispatch<React.SetStateAction<IAsset | IMm3Asset>>;
}

export const AssetChildrenTab: React.FC<IChildrenTabPreviewProps> = ({ asset, tab, setAsset }) => {
  const isMm3Assets = getIsMm3Assets();

  if (!isMm3Assets && tab === DetailsPageTabs.AUDIO_TRACKS) {
    return <OldAssetAudiosTab asset={asset as IAsset} />;
  }
  if (!isMm3Assets && tab === DetailsPageTabs.SUBTITLES) {
    return <AssetOldSubtitlesTab asset={asset as IAsset} setAsset={setAsset} />;
  }
  return <AssetMm3ChildrenTab asset={asset as IMm3Asset} tab={tab} />;
};
