import React from 'react';
import { IconName } from 'blueprint5-icons';

export interface IModalLabels {
  confirm?: string;
  cancel?: string;
}

export enum IModalSize {
  // old values
  XL = '85%',
  L = '75%',
  M = '900px',
  S = '65%',
  XS = '45%',
}

interface IDialogProps {
  title?: string;
  className?: string;
  icon?: IconName | JSX.Element;
  canOutsideClickClose?: boolean;
}

export interface IInfoDialogProps extends IDialogProps {
  body: React.ReactNode;
}

export interface IConfirmDialogProps extends IDialogProps {
  onConfirm: () => void;
  body: React.ReactNode;
}

export interface IModalDialogProps extends IDialogProps {
  body: () => JSX.Element;
  header?: () => JSX.Element;
  footer?: () => JSX.Element;
  labels?: IModalLabels;
  size: IModalSize;
  fitInView?: boolean;
  withCloseButton?: boolean;
}
