import React, { useCallback } from 'react';

import { useStore } from 'store';
import { IUseActionsOptionParam } from 'utils/actions/types';

import { shouldDisplayGenerate as shouldDisplay } from './actions-acl';

import { FormSubmitSection } from 'components/form-submit-section';
import { FormNumericInput, useForm } from 'helpers/form';
import { Model } from 'helpers/filters/types';
import { IGuild } from 'components/guilds';
import { IGuildActionConfig, IGuildActionName } from './types';
import { guildCodeCountUpdate } from 'utils/apis/guild';
import { IIdentifiable } from 'types/utility';
import { IModalSize } from 'components/dialogs/types';

const initialValues = {
  requested_codes_count: 0,
};

export interface IGenerateCodesForm {
  onSubmit: (values) => void;
  codeCount: number;
}

const maxCopies = '100.000';

const hint = `There is a maximum increase of ${maxCopies} per request.`;

const GenerateCodesForm: React.FC<IGenerateCodesForm> = ({ onSubmit, codeCount }) => {
  const {
    formData,
    handlers: { onSetFields: _, ...handlers },
    valid,
    onSubmit: handlersSubmit,
  } = useForm(initialValues, Model.GUILDS, onSubmit);

  return (
    <>
      <form onSubmit={handlersSubmit}>
        <div className="mb-4">{`The number of already requested codes is ${codeCount}.`}</div>
        <FormNumericInput
          formId="increase-codes"
          label="Number of additional Codes"
          name="requested_codes_count"
          {...formData.requested_codes_count}
          {...handlers}
          helperText={hint}
        />
      </form>
      <FormSubmitSection labels={{ confirm: 'Submit' }} submitDisabled={!valid} onSubmit={handlersSubmit} />
    </>
  );
};

export const useGenerateCodesAction = (
  items: (IGuild & IIdentifiable)[],
  options: IUseActionsOptionParam,
): IGuildActionConfig => {
  const { dialogStore, toastStore } = useStore();

  const handleSubmit = useCallback(
    async (value) => {
      try {
        await guildCodeCountUpdate({
          ...items[0],
          requested_codes_count: (items[0].requested_codes_count as number) + parseInt(value.requested_codes_count),
        });
        toastStore.success(`New Codes were generated for ${items[0].name}`);
        await options?.onSuccess?.(IGuildActionName.GENERATE_CODES);
        dialogStore.close();
      } catch (error) {
        toastStore.error(`New Codes Generate action failed: ${error.text}`);
        await options?.onFailure?.();
      }
    },
    [toastStore, dialogStore, items, options],
  );

  const body = useCallback(
    () => <GenerateCodesForm onSubmit={handleSubmit} codeCount={items[0].requested_codes_count as number} />,
    [handleSubmit, items],
  );

  const openDialog = useCallback((): void => {
    dialogStore.openModal({
      title: 'Generate Additional Codes',
      body,
      size: IModalSize.XS,
    });
  }, [body, dialogStore]);

  return {
    name: IGuildActionName.GENERATE_CODES,
    shouldDisplay,
    icon: 'key',
    title: 'Generate Additional Codes',
    handler: openDialog,
  };
};
