import React from 'react';
import moment from 'moment';
import { merge, map, compact, uniq } from 'lodash';
import FullCalendar, { DateSelectArg, EventContentArg, EventSourceFunc } from '@fullcalendar/react';
import { HoverCard, Group, Modal } from '@mantine/core';
import { CalGenericLocation } from '@mediafellows/mm3-types';
import { ICalGenericMeeting } from 'types/calendar';

import { ICalendarInvite } from 'types/calendar';
import { ItemId } from 'types/utility';
import { MeetingPreview } from './meeting-preview';
import { loadMeetings } from './api';
import { getRequiredError, IValidationResult } from 'helpers/form/mm3';
import { IMeetingFormData } from './meeting-form';
import { useSessionStore } from 'store/session-store';
import cx from 'classnames';
import { stringToColour } from 'components/avatar/avatar-utils';
import { MantineIcon } from 'utils/ui/icon';
import { MeetingActions, meetingTags } from './ui';
import { Emoji } from 'utils/ui';
import { IContact } from 'types';
import { STORAGE_KEYS, setStorageItem } from 'utils/storage';
import { IMeetingPropsFilters } from './calendar';

export const meetingHostPurpose = 'meetings_hosts';

export const formatTime = (time?: Date | null, options: Intl.DateTimeFormatOptions = {}): string => {
  if (!time) {
    return '';
  }

  const { timeZone, timeStyle = 'short', hourCycle = 'h12', ...rest } = options;
  const parsedOptions: Intl.DateTimeFormatOptions = { ...rest, timeZone, timeStyle, hourCycle };
  if (!timeZone || timeZone === 'local') {
    parsedOptions.timeZone = localTimezone;
  }

  return time.toLocaleTimeString('en-GB', parsedOptions);
};

export const renderEventContent = (eventContent: EventContentArg): JSX.Element => {
  const { id, title, start, end, extendedProps } = eventContent?.event || {};
  const { host, location, invites, meta } = extendedProps || {};
  const { tags } = meta || {};

  const session = useSessionStore.getState().session;
  const userId = session?.user?.id;
  const isListView = eventContent.view.type === 'list';

  const timeZone = eventContent.view.calendar.getOption('timeZone');

  const iAmHost = host?.id === userId;
  const tagEmojis = uniq(compact(map(tags, (tag: string) => meetingTags[tag.toLowerCase()])));

  return (
    <Group justify="center">
      <HoverCard shadow="md" portalProps={{ className: 'meeting-preview__popover' }}>
        <HoverCard.Target>
          <div
            style={{ '--locationColour': stringToColour(`${location?.name}`) } as React.CSSProperties}
            className={cx('meeting-display w-100', { 'meeting-display--host': iAmHost })}
          >
            <b className="text-truncate me-1 meeting-display-row">
              {iAmHost && <MantineIcon className="align-middle" icon="app-header" size={10} />}
              {title}
              {tagEmojis && tagEmojis.map((emj, index) => <Emoji label="tag" key={index} symbol={emj as string} />)}
            </b>
            {!isListView && (
              <div className="text-truncate d-block meeting-display-row">
                {formatTime(start, { timeZone })}
                {' - '}
                {formatTime(end, { timeZone })}
              </div>
            )}

            <div className={cx('text-truncate d-block meeting-display-row', isListView ? 'text-muted' : '')}>
              {location?.name && (
                <span>
                  {location.name} <span className="mx-2">|</span>
                </span>
              )}
              {host && (
                <span>
                  {host.first_name} {host.last_name} <span className="mx-2">|</span>
                </span>
              )}
              <span>
                {invites?.length} Invintee{invites?.length > 1 && 's'}
              </span>
            </div>
          </div>
        </HoverCard.Target>
        <HoverCard.Dropdown className="meeting-preview__popover__dropdown">
          <MeetingPreview
            id={parseInt(id)}
            title={title}
            {...(eventContent.event.extendedProps as Omit<ICalGenericMeeting, 'id' | 'title'>)}
            padded={false}
          />
        </HoverCard.Dropdown>
      </HoverCard>
    </Group>
  );
};

export const resourceViewKey = 'locationsViewKey';

export const headerToolbar = {
  left: `${resourceViewKey},list today`,
  center: 'prev title next',
  right: 'dayGridMonth,timeGridWeek,timeGridFiveDays,timeGridThreeDays,timeGridDay',
};

export const views = {
  [resourceViewKey]: {
    type: 'resourceTimeline',
    duration: { days: 1 },
    buttonText: 'Grid',
  },
  timeGridThreeDays: {
    type: 'timeGrid',
    duration: { days: 3 },
    buttonText: '3 Days',
  },
  timeGridFiveDays: {
    type: 'timeGrid',
    duration: { days: 5 },
    buttonText: '5 Days',
  },
};

export const handleMeetingsSearch: EventSourceFunc = (fetchInfo, successCallback, failureCallback) => {
  loadMeetings(fetchInfo).then(successCallback, failureCallback);
};

// converts date to date + timezone
/* eslint-disable  @typescript-eslint/no-explicit-any */
export function convertToMoment(
  input: any,
  timeZone: string,
  timeZoneOffset: number | null,
  locale: string,
): moment.Moment {
  let mom: moment.Moment;

  if (timeZone === 'local') {
    mom = moment(input);
  } else if (timeZone === 'UTC') {
    mom = moment.utc(input);
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  } else if ((moment as any).tz) {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    mom = (moment as any).tz(input, timeZone);
  } else {
    mom = moment.utc(input);

    if (timeZoneOffset != null) {
      mom.utcOffset(timeZoneOffset);
    }
  }

  mom.locale(locale);

  return mom;
}

export const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getInviteesIds = (invitees: ICalendarInvite[]): ItemId[] => {
  return (invitees || []).reduce((acc, { user_id, email, name }) => {
    if (user_id) {
      return [...acc, user_id];
    }

    if (email) {
      return [...acc, formatInviteeName({ name, email } as ICalendarInvite)];
    }

    return acc;
  }, []);
};

// validates the text is this format "Invitee Name email@email.com"
const externalInviteesValidation = /^(?:[^\s]+\s+)*[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const validateNewInviteeItem = (text: string): boolean => externalInviteesValidation.test(text);
export const getValidNewItem = (text: string): string | undefined => text.match(externalInviteesValidation)?.[0];
export const formatInviteeName = ({ name = '', email = '' }: ICalendarInvite): string => {
  if (!name) {
    return email;
  }
  return `${name} <${email}>`;
};

export const businessHours = {
  daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
  startTime: '00:00',
  endTime: '24:00',
};

export function getTotalAttendeesCount({ additional_seats, invites, host_id }: ICalGenericMeeting): number {
  return (additional_seats || 0) + (invites || []).length + (host_id ? 1 : 0);
}

export function customMeetingValidator(
  values: IMeetingFormData,
  validation: IValidationResult<IMeetingFormData>,
): IValidationResult<IMeetingFormData> {
  if (values.type === MeetingTypes.PERSONAL) {
    return merge({}, validation, {
      inviteIds: { required: false },
      additional_seats: { required: false },
      location_id: { validation: { valid: true } },
    });
  }

  if (values.additional_seats || values.inviteIds?.length) {
    return validation;
  }

  if (values.location_id) {
    return merge({}, validation, {
      location_id: { validation: { valid: true } },
    }) as IValidationResult<IMeetingFormData>;
  }

  if (!values.additional_seats && values.type === MeetingTypes.REGULAR) {
    return merge({}, validation, {
      inviteIds: { required: true, ...getRequiredError() },
      additional_seats: { required: false },
    }) as IValidationResult<IMeetingFormData>;
  }

  return merge({}, validation, {
    additional_seats: { required: true, ...getRequiredError() },
    inviteIds: { required: false },
  }) as IValidationResult<IMeetingFormData>;
}

export function getLocationFromEvent(id: number, { resource }: DateSelectArg): CalGenericLocation | void {
  if (!id || !resource?.extendedProps) {
    return;
  }

  return {
    ...(resource.extendedProps as Omit<CalGenericLocation, 'id' | 'name'>),
    id,
    name: resource.title,
  };
}

export const onCalendarClick = (event: React.MouseEvent, calendarRef: React.RefObject<FullCalendar>): void => {
  const api = calendarRef?.current?.getApi();
  if (!api) return;

  const target = event.target;
  if (!(target instanceof HTMLElement)) return;

  const monthEl = target.closest('.fc-toolbar-title .month');
  const dayEl = target.closest('.fc-toolbar-title .day');

  if (monthEl instanceof HTMLElement) {
    const time = parseInt(monthEl.dataset.ms || '', 10);
    if (!Number.isInteger(time)) return;
    api.changeView('dayGridMonth', new Date(time));
  } else if (dayEl instanceof HTMLElement) {
    const index = parseInt(dayEl.dataset?.index || '', 10);
    const headers = Array.from(document.querySelectorAll('th[role="columnheader"]'));
    const date = new Date(
      (index === 0 ? headers?.[0] : index === 1 ? headers?.[headers?.length - 1] : undefined)?.getAttribute(
        'data-date',
      ) || NaN,
    );
    if (Number.isInteger(date.getTime())) {
      api.changeView('timeGridDay', date);
    }
  }
};

export const onCalendarMouseMove = (event: React.MouseEvent, calendarRef: React.RefObject<FullCalendar>): void => {
  const target = event.target;
  if (!(target instanceof HTMLElement)) return;
  const toolbarTitle = target.closest('.fc-toolbar-title');
  if (!(toolbarTitle instanceof HTMLElement)) return;
  if (toolbarTitle.innerHTML.includes('<span')) return;

  const api = calendarRef?.current?.getApi();
  const viewType = api?.view?.type || '';

  const allowMonth = ['list', 'timeGridDay', 'locationsViewKey', 'timeGridWeek'].includes(viewType);
  const allowDay = ['timeGridWeek'].includes(viewType);
  if (!allowMonth) return;

  let result = toolbarTitle.innerText;

  let index = 0;
  result = result.replace(/\b([a-z]+)\b/gi, (substring: string) => {
    if (index++ === 0) {
      return `<span class="month" data-ms="${api?.view?.activeStart?.getTime()}">${substring}</span>`;
    } else {
      return `<span class="month" data-ms="${api?.view?.activeEnd?.getTime()}">${substring}</span>`;
    }
  });

  if (allowDay) {
    let index = 0;
    result = result.replace(
      /\b([0-9]{1,2})\b/gi,
      (substring: string) => `<span class="day" data-index="${index++}">${substring}</span>`,
    );
  }

  toolbarTitle.innerHTML = result;
};

export const enum MeetingTypes {
  REGULAR = 'Meeting::Regular',
  PERSONAL = 'Meeting::Personal',
}

export const renderModalHeader = (event, calendar): JSX.Element => (
  <>
    <Modal.Title>{'Meeting Details: ' + event.title}</Modal.Title>
    <div className="custom-modal-actions">
      <MeetingActions info={event} calendar={calendar} />
      <Modal.CloseButton />
    </div>
  </>
);

export const processLocations = (
  filteredLocations,
  start,
  end,
  timeZone,
): Omit<Partial<CalGenericLocation>, 'id'>[] => {
  const startMoment = moment(start);
  const endMoment = moment(end);

  const timeOptions: Intl.DateTimeFormatOptions = { timeZone, hourCycle: 'h23' };

  const defaultBusinessHours = {
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
  };

  const closedBusinessHours = {
    daysOfWeek: [],
  };

  return filteredLocations.map((loc) => {
    const locEndMoment = moment(loc.ends_at);
    const locStartMoment = moment(loc.starts_at);

    const isClosed = locEndMoment.isBefore(startMoment) || locStartMoment.isAfter(endMoment);

    if (isClosed) {
      return {
        ...loc,
        id: String(loc.id),
        title: loc.name,
        timezone: loc.time_zone,
        businessHours: closedBusinessHours,
      };
    }

    const businessHours = {
      ...defaultBusinessHours,
      startTime: locStartMoment.isBetween(startMoment, endMoment)
        ? formatTime(new Date(loc.starts_at), timeOptions)
        : '00:00',
      endTime: locEndMoment.isBetween(startMoment, endMoment)
        ? formatTime(new Date(loc.ends_at), timeOptions)
        : '24:00',
    };

    return {
      ...loc,
      id: String(loc.id),
      title: loc.name,
      timezone: loc.time_zone,
      businessHours,
    };
  });
};

export const updateHostsCache = (data: Partial<IContact>[]): void => {
  try {
    setStorageItem(STORAGE_KEYS.MEETING_HOSTS, data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error saving hosts cache to localStorage:', error);
  }
};

export const countActiveCalendarFilters = (filters: IMeetingPropsFilters, timezone: string): number => {
  if (!filters) return 0;

  const activeFilters = [
    Boolean(timezone?.length),
    Boolean(filters.location_id),
    Boolean(filters.host_id),
    Boolean(filters.invites_id),
    Boolean(filters.participant_ids),
    Boolean(filters.only_me),
    filters.type === undefined,
  ];

  return activeFilters.filter(Boolean).length;
};
