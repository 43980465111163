import { chipmunk, IResult } from 'utils/chipmunk';
import { updateProduct, getProduct } from 'utils/apis/product';
import { IPassOnDataFields } from 'utils/actions/types';
import { IAccessChangeImmediateForm } from 'utils/actions/asset';
import { Model } from 'helpers/filters/types';
import { ItemId, IScheduledJob, IProduct } from 'types';

import { IProductScheduledJobName } from './types';
import { productDetailsSchema } from 'utils/schemas';
import { getIsMm3Assets } from 'utils/asset';

export interface ICopyProductFormFields {
  number_of_copies: number;
  deep_clone: boolean;
  continue_numbering: boolean;
}

export const cloneProducts = async (product_ids: (string | number)[], values): Promise<IResult> => {
  return chipmunk.run(async ({ action }) => {
    return await action(Model.PRODUCTS, 'clone', {
      params: { product_ids, ...values },
    });
  });
};

export const deleteProducts = async (product_ids: (string | number)[]): Promise<IResult> => {
  return chipmunk.run(async ({ action }) => {
    return await action(Model.PRODUCTS, 'delete', {
      schema: `id`,
      params: { product_ids },
    });
  });
};

export const restoreProducts = async (product_ids: (string | number)[]): Promise<IResult> => {
  return chipmunk.run(async ({ action }) => {
    return await action(Model.PRODUCTS, 'restore', {
      schema: `id`,
      params: { product_ids },
    });
  });
};

export const passOnDataToProducts = async (
  product_ids: (string | number)[],
  checkedFields: IPassOnDataFields,
): Promise<IResult> => {
  const fields = [
    {
      layers: checkedFields.fields,
    },
  ];
  return chipmunk.run(async ({ action }) => {
    await action(Model.PRODUCTS, 'update_tree', {
      params: { product_ids },
      body: { fields },
    });
  });
};

export const changeProductAccessImmediate = async (
  data: IAccessChangeImmediateForm,
  schema?: string,
): Promise<IResult> => {
  const isMm3Assets = getIsMm3Assets();
  const product_ids = data.item_ids;

  const products = product_ids.map((product_id) => ({
    id: product_id,
    access_level: data.access_level,
    status: data.status,
    skip_access_delegation: data.skip_access_delegation,
    ...(isMm3Assets ? {} : { protection_levels: data.protection_levels }),
    permissions: data.permissions,
  }));

  return chipmunk.run(({ action }) => {
    return action(Model.PRODUCTS, 'update', {
      params: { product_ids },
      schema,
      body: products,
    });
  });
};

export const createScheduledJobs = async (
  name: IProductScheduledJobName,
  { item_ids, ...payload }: { item_ids: ItemId[] },
): Promise<IScheduledJob[]> => {
  return chipmunk.run(({ action }) => {
    return Promise.all(
      item_ids.map(async (item_id) => {
        const body = {
          ...payload,
          name,
          product_id: item_id,
        };

        return (
          await action('pm.scheduled_job', 'create', {
            body,
          })
        ).object;
      }),
    ).catch((error) => {
      throw error;
    });
  });
};

export const loadAllScheduledJobs = async (item_ids: ItemId[] = []): Promise<IScheduledJob[]> => {
  if (item_ids.length === 0) {
    return [];
  }
  return chipmunk.run(async ({ action }) => {
    const ids_value = item_ids.join(',');

    const data = {
      params: {
        q: `status:("scheduled")
            AND product_id:(${ids_value})`,
      },
    };
    return (await action('pm.scheduled_job', 'query', data)).objects;
  });
};

export const loadScheduledJobs = async (
  name: IProductScheduledJobName,
  item_ids: ItemId[] = [],
): Promise<IScheduledJob[]> => {
  if (item_ids.length === 0) {
    return [];
  }
  return chipmunk.run(async ({ action }) => {
    const ids_value = item_ids.join(',');

    const data = {
      params: {
        q: `status:("scheduled")
            AND product_id:(${ids_value})
            AND name:("${name}")`,
      },
    };
    return (await action('pm.scheduled_job', 'query', data)).objects;
  });
};

export const removeScheduledJob = (id: ItemId): Promise<IResult> => {
  return chipmunk.run((chipmunk) => {
    return chipmunk.action('pm.scheduled_job', 'delete', {
      params: {
        id,
      },
    });
  });
};

export const associateProduct = async (product: IProduct, newValues: number[]): Promise<IProduct | null> => {
  if (!product.id || !product.meta) {
    return null;
  }

  const { meta } = product;
  const initialRelatedProducts = meta.related_product_ids || [];
  const updatedRelated = [...newValues, ...initialRelatedProducts];
  const updatedProduct: IProduct = { ...product, meta: { ...meta, related_product_ids: updatedRelated } };
  return updateProduct(updatedProduct, productDetailsSchema);
};

export const removeRelatedProducts = async (dissociatedIds: ItemId[], product_id: ItemId): Promise<IProduct | null> => {
  if (!product_id || !dissociatedIds) {
    return null;
  }
  return getProduct(product_id).then((product) => {
    const { meta } = product;
    const updatedRelated = product.meta.related_product_ids.filter((id) => !dissociatedIds.includes(id));
    const updatedProduct: IProduct = { ...product, meta: { ...meta, related_product_ids: updatedRelated } };
    return updateProduct(updatedProduct, productDetailsSchema);
  });
};
