import { ISelectionStandardized } from 'types';
import { IUseActionsOptionParam } from 'utils/actions';

import { ISelectionActionConfig, ISelectionActionName } from './types';
import { shouldDisplayCreateGuild } from './actions-acl';

import { IconAlias } from 'icons';
import { useGuildDialog } from 'components/guilds';

export const useCreateGuildAction = (
  items: ISelectionStandardized[],
  options?: IUseActionsOptionParam,
): ISelectionActionConfig => {
  const [openDialog] = useGuildDialog({
    productSelections: items,
    onConfirm: () => options?.onSuccess?.(),
  });

  return {
    name: ISelectionActionName.CREATE_GUILD,
    shouldDisplay: shouldDisplayCreateGuild,
    icon: IconAlias.GUILDS,
    title: 'Create Guild',
    handler: openDialog,
  };
};
