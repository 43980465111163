import React from 'react';
import { observer } from 'mobx-react-lite';
import { IObject } from '@mediafellows/chipmunk/dist/src/action';

import { FilterCheckbox, FilterSwitch } from 'helpers/filters/fields';
import { IFiltersHandlers } from 'helpers/filters';
import { ContextDataProvider } from 'helpers/data-provider/option-data-provider';
import { Model } from 'helpers/filters/types';

import { DataSectionStore } from 'store/data-section-store';

import { IContactFilters } from 'components/contact-filters/contact-filters';

interface ISettingsFilters {
  filterValues: IContactFilters;
  filterHandlers: IFiltersHandlers<IContactFilters>;
  dataSectionStore: DataSectionStore<IObject>;
  isSmartGroupFilters?: boolean;
}

const statusOptionsProvider = new ContextDataProvider(Model.CONTACTS, 'status');

export const ContactSettingsFilters = observer(
  ({ filterValues, filterHandlers, dataSectionStore, isSmartGroupFilters }: ISettingsFilters) => {
    const { searchStore } = dataSectionStore;

    return (
      <>
        <FilterCheckbox
          label="Status"
          name="status"
          optionsProvider={statusOptionsProvider}
          disabled={searchStore.running}
          filter={filterValues.status}
          onChange={filterHandlers.onChange}
          aggregations={searchStore.aggregationValues('status')}
        />
        {!isSmartGroupFilters && (
          <>
            <FilterSwitch
              label="Mobile App Access"
              name="mobile_sync"
              filter={filterValues.mobile_sync}
              onChange={filterHandlers.onChange}
              checkedValue={true}
              notCheckedValue={false}
            />
            <FilterSwitch
              label="Bouncing emails"
              name="bouncing"
              filter={filterValues.bouncing}
              onChange={filterHandlers.onChange}
              checkedValue={true}
              notCheckedValue={false}
            />
          </>
        )}
      </>
    );
  },
);
