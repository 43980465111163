import React, { useCallback } from 'react';

import { ISubtitle } from 'types';
import { useStore } from 'store';

import { updateSubtitle } from 'utils/apis/subtitle';
import { IconAlias } from 'icons';
import { useEditSubtitleDialog } from 'components/asset/edit-subtitle-dialog';
import { ToastError } from 'components/toast';

import { IUseActionsOptionParam } from 'utils/actions/types';

import { ISubtitleActionConfig, ISubtitleActionName } from './types';

export const useEditSubtitleAction = (
  items: ISubtitle[],
  options: IUseActionsOptionParam<ISubtitle>,
): ISubtitleActionConfig => {
  const { toastStore, dialogStore } = useStore();
  const subtitle = items[0];

  const onSubmit = useCallback(
    async (values: ISubtitle) => {
      try {
        await updateSubtitle({ ...values, file_attachment: subtitle.file_attachment });
        toastStore.success('Updates applied successfully!');
        options.onSuccess?.(ISubtitleActionName.EDIT, values);
        return dialogStore.close();
      } catch (error) {
        toastStore.error(<ToastError error={error} />);
      }
    },
    [dialogStore, options, subtitle.file_attachment, toastStore],
  );
  const [openDialog] = useEditSubtitleDialog({
    onSubmit,
    subtitle,
  });

  return {
    name: ISubtitleActionName.EDIT,
    icon: IconAlias.EDIT,
    title: 'Edit',
    handler: openDialog,
  };
};
