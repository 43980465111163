import React, { useCallback } from 'react';

import { FormSegment, IFieldHandlers } from 'helpers/form';

import { IFormSegment } from 'helpers/form/fields/form-segment/form-segment';

const segments = [
  { value: 'on', label: 'ON' },
  { value: 'off', label: 'OFF' },
];

export const FormEnabled: React.FC<
  Omit<IFormSegment, 'segments' | 'value'> & IFieldHandlers<boolean> & { value?: boolean | null }
> = ({ label, name, onChange, onBlur, value, ...rest }) => {
  const handleChange = useCallback(
    (values: { [name: string]: string }) => {
      const enabled = values[name] === 'on';

      return onChange?.({ [name]: enabled });
    },
    [onChange, name],
  );

  return (
    <FormSegment
      name={name}
      label={label}
      {...rest}
      segments={segments}
      onChange={handleChange}
      onBlur={onBlur}
      value={value ? 'on' : 'off'}
    />
  );
};
