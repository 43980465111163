import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { ActionIcon } from '@mantine/core';

import { Model } from 'helpers/filters/types';
import { FormInput, useForm } from 'helpers/form';
import { useAssetsEditStore, useDialogStore } from 'store';
import { MantineIcon } from 'utils/ui';

interface ISavePresetFormFields {
  name: string;
}

const initialValues: ISavePresetFormFields = { name: '' };

export const SaveAssetPresetForm: React.FC<{}> = observer(() => {
  const { close } = useDialogStore();
  const { setPresetsRecord, preset } = useAssetsEditStore();
  const handleSubmit = useCallback(
    ({ name }: ISavePresetFormFields): void => {
      setPresetsRecord(name, preset);
      close();
    },
    [close, preset, setPresetsRecord],
  );

  const { formData, handlers, onSubmit } = useForm(initialValues, Model.ASSETS, handleSubmit);

  return (
    <form onSubmit={onSubmit} style={{ display: 'grid', gridTemplateColumns: '1fr 20px' }} className="mb-4">
      <FormInput label="Add Preset Name" name="name" placeholder="Type Preset Name" {...formData.name} {...handlers} />
      <ActionIcon variant="subtle" color="gray.5" type="submit" mt="30px">
        <MantineIcon icon="floppy-disk" />
      </ActionIcon>
    </form>
  );
});
