import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from '@mantine/core';

import { AssetsUploaderStore } from 'store/assets-upload-store';

import 'components/upload-asset-dialog/index.scss';

export interface IAssetsFormFooter {
  onSubmit: () => void;
  onCancel: () => void;
  assetsUploaderStore: AssetsUploaderStore;
}

export const AssetsFormFooter: React.FC<IAssetsFormFooter> = observer(({ onSubmit, onCancel, assetsUploaderStore }) => {
  const { files, disableSubmit } = assetsUploaderStore;

  if (files.length === 0) {
    return null;
  }

  return (
    <div className="upload-assets-form-footer__wrapper">
      <Button size="xs" onClick={onCancel} variant="default" className="upload-assets-form-footer__button-upload">
        Cancel
      </Button>
      <Button
        size="xs"
        onClick={onSubmit}
        disabled={disableSubmit}
        variant="primary"
        className="upload-assets-form-footer__button-upload"
      >
        Upload
      </Button>
    </div>
  );
});

export default React.memo(AssetsFormFooter);
