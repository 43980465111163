import React, { useCallback } from 'react';
import { Button } from '@mantine/core';

import { ItemId } from 'types';
import { useForm } from 'helpers/form';
import { Model } from 'helpers/filters/types';
import { useStore } from 'store';
import { sendAccessPrivilegeEmails, loadAccessPrivilegeAllUserIds } from 'utils/apis/access-privilege';

import { AccessPrivilegeGrantFormEmail } from 'components/access-privilege-grant';
import type { IAccessPrivilegeEmail } from 'components/access-privilege-grant';
import { Classes } from 'utils/ui';

interface IAccessPrivilegeEmailSetupProps {
  itemIds: ItemId[];
  onSuccess?: () => void;
  onFailure?: () => void;
}

const customContext = {
  properties: {
    email_subject: { required: true, validations: [{ length: { maximum: 300 } }] },
    email_message: { required: true, validations: [{ length: { maximum: 2000 } }] },
  },
};

export const AccessPrivilegeEmailSetup: React.FC<IAccessPrivilegeEmailSetupProps> = ({
  onSuccess,
  onFailure,
  itemIds,
}) => {
  const { toastStore } = useStore();

  const group_id = itemIds[0];

  const sendEmail = useCallback(
    async (values: IAccessPrivilegeEmail) => {
      try {
        const { email_subject, email_message } = values;
        toastStore.info('Submitting');
        const user_ids = await loadAccessPrivilegeAllUserIds(group_id);
        await sendAccessPrivilegeEmails({
          group_id,
          user_ids,
          subject: email_subject,
          message: email_message,
        });

        toastStore.clearAll();
        toastStore.success('Email notification(s) sent successfully');

        onSuccess?.();
      } catch {
        onFailure?.();
      }
    },
    [onSuccess, onFailure, toastStore, group_id],
  );
  const form = useForm<IAccessPrivilegeEmail>(
    {
      email_subject: '',
      email_message: '',
    },

    Model.ACCESS_PRIVILEGES,
    sendEmail,
    customContext,
  );
  return (
    <>
      <AccessPrivilegeGrantFormEmail form={form} />
      <Button
        variant="primary"
        size="md"
        onClick={form.onSubmit}
        disabled={!form.valid}
        className={`${Classes.DIALOG_FOOTER_ACTIONS} m-3 justify-content-center`}
      >
        Notify all access privilege users
      </Button>
    </>
  );
};
