import React, { useCallback } from 'react';
import { IProduct } from 'types';
import { useStore } from 'store';
import { IUseActionsOptionParam } from 'utils/actions';

import { ProductAccessChangeForm } from './product-access-change-form';
import { IModalSize } from 'components/dialogs/types';

export const useAccessChangeDialog = (items: IProduct[] = [], options: IUseActionsOptionParam): [() => void] => {
  const { dialogStore } = useStore();

  const body = useCallback((): JSX.Element => {
    return <ProductAccessChangeForm items={items} options={options} />;
  }, [items, options]);

  const openDialog = (): void => {
    dialogStore.openModal({
      title: 'Change product access',
      body,
      size: IModalSize.XS,
    });
  };

  return [openDialog];
};
