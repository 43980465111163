import React, { useCallback } from 'react';

import { InfoBoxV2 } from 'components/info-box-v2';
import { Row } from 'components/label-value-pair';
import { Loading } from 'components/loading';

import { formatResolution, formatType, parseDataSize } from 'utils/general';

import { formatDuration } from 'utils/date';
import { useLanguages } from 'utils/hooks/languages';
import { getMediaInfo } from 'utils/apis/asset';
import { useRemote } from 'utils/hooks';

import { IMm3Asset, IMm3AssetType } from 'types';

export const AssetMm3MetadataTechnicalSubTab: React.FC<{ asset: IMm3Asset }> = ({ asset }) => {
  const { type, file_size, ingest_notes, id } = asset || {};
  const { canonical_name, ingest_language_id, ingest_asset_id, track_id, created_date, modified_date } =
    asset?.meta || {};
  const {
    file_name,
    format,
    file_extension,
    content_type,
    width,
    height,
    duration,
    orientation,
    color_space,
    codec,
    audio_layout,
    aspect_ratio,
    frame_rate,
    codec_name,
  } = asset?.source_meta || {};
  const isImage: boolean = type === IMm3AssetType.IMAGE;
  const isVideo: boolean = type === IMm3AssetType.VIDEO;
  const isAudio: boolean = type === IMm3AssetType.AUDIO;
  const extractedLanguage = useLanguages([ingest_language_id]);
  const mediaInfoFetcher = useCallback(() => getMediaInfo(id), [id]);
  const [mediaInfo, loading] = useRemote(mediaInfoFetcher);
  const mediaInfoSummaryLength = JSON.stringify(mediaInfo, null, 2)?.split('\n').slice(0, 4).join('\n').length;
  return (
    <div className="asset-metadata-tab h-100">
      <div className="asset-metadata-tab__infoboxes">
        <InfoBoxV2 gridTemplateColumns="180px 1fr" title="Source File">
          <Row label="File Type" value={formatType(type)} />
          <Row label="File name" value={file_name} />
          <Row label="File size" value={parseDataSize(file_size)} />
          <Row label="Format" value={format} />
          <Row label="File Extension" value={file_extension} />
          <Row label="Content Type" value={content_type} />
          <Row label="Width" value={width && `${width}px`} hidden={!(isImage || isVideo)} />
          <Row label="Height" value={height && `${height}px`} hidden={!(isImage || isVideo)} />
          <Row
            label="Resolution"
            value={`${formatResolution({ width, height })}`}
            size="m"
            hidden={!(isImage || isVideo)}
          />
          <Row label="Duration" value={formatDuration(duration, 'seconds')} hidden={!(isVideo || isAudio)} />
          <Row label="Orientation" value={orientation} hidden={!isImage} />
          <Row label="Color Space" value={color_space} hidden={!isImage} />
          <Row label="Codec" value={codec} hidden={!isVideo} />
          <Row label="Aspect Ratio" value={aspect_ratio} hidden={!isVideo} />
          <Row label="Frame Rate" value={frame_rate} hidden={!isVideo} />
          <Row label="Codec Name" value={codec_name} hidden={!isVideo} />
          <Row label="File created at" rawLabel value={created_date} />
          <Row label="File modified at" rawLabel value={modified_date} />
        </InfoBoxV2>
        <InfoBoxV2 gridTemplateColumns="1fr" padded={false} gap="15px">
          <InfoBoxV2 gridTemplateColumns="180px 1fr" title="Ingest">
            <Row label="Ingest Notes" value={ingest_notes} />
            <Row label="Canonical Name" value={canonical_name} />
            <Row label="Extracted Language" value={extractedLanguage} hidden={!isAudio} />
            <Row label="Source Asset" value={ingest_asset_id} hidden={!isAudio} />
            <Row label="Track Number" value={track_id} hidden={!isAudio} />
            <Row label="Audio Layout" value={audio_layout} hidden={!isAudio} />
          </InfoBoxV2>
          <InfoBoxV2 gridTemplateColumns="1fr" title="Media Info">
            {!loading && mediaInfo ? (
              <details className="entity-detail-content--padded entity-detail-content__code mw-0">
                <summary>
                  <pre className="entity-detail-content__summary">
                    {JSON.stringify(mediaInfo, null, 2).slice(0, mediaInfoSummaryLength)}
                  </pre>
                </summary>
                <pre className="entity-detail-content__rest">
                  {JSON.stringify(mediaInfo, null, 2).slice(mediaInfoSummaryLength)}
                </pre>
              </details>
            ) : loading ? (
              <Loading text="Loading..." />
            ) : (
              <pre>
                <code>JSON is not available</code>
              </pre>
            )}
          </InfoBoxV2>
        </InfoBoxV2>
      </div>
    </div>
  );
};

export default AssetMm3MetadataTechnicalSubTab;
