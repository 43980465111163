import React, { useCallback } from 'react';

import { useStore } from 'store';
import { ToastError } from 'components/toast';

import { IListItemActionConfig, IListItemActionName } from './types';
import { IUseActionsOptionParam } from 'utils/actions/types';

import { shouldDisplayEditTitle } from './acl';
import { updateListItems } from 'utils/apis/showcase-item';
import { UmGenericListItem } from '@mediafellows/mm3-types';
import { FormInput, useMm3Form } from 'helpers/form';
import { Model } from 'helpers/filters/types';
import { FormSubmitSection } from 'components/form-submit-section';
import { IModalSize } from 'components/dialogs/types';

interface ISeparatorTitleFormProps {
  handleSubmit: (values: UmGenericListItem) => Promise<void>;
  listItem: UmGenericListItem;
}

const labels = { confirm: 'Save' };

export const SeparatorTitleForm: React.FC<ISeparatorTitleFormProps> = ({ handleSubmit, listItem }) => {
  const { formData, handlers, onSubmit, valid } = useMm3Form<UmGenericListItem>(
    listItem,
    Model.LIST_ITEMS,
    handleSubmit,
  );
  return (
    <>
      <form onSubmit={onSubmit}>
        <FormInput name="meta.title" {...formData.meta?.title} {...handlers} label="Title" />
      </form>
      <FormSubmitSection labels={labels} onSubmit={onSubmit} submitDisabled={!valid} />
    </>
  );
};

export function useEditTitleAction(
  items: UmGenericListItem[],
  options: IUseActionsOptionParam<UmGenericListItem>,
): IListItemActionConfig<UmGenericListItem> {
  const { toastStore, dialogStore } = useStore();

  const handleSubmit = useCallback(
    async (values: UmGenericListItem): Promise<void> => {
      try {
        if (!values?.id) {
          return;
        }

        const result = await updateListItems([values.list_id], [values]);
        await options?.onSuccess?.(IListItemActionName.UPDATE, result[0]);

        toastStore.success('title successfully updated!');
        dialogStore.close();
      } catch (error) {
        toastStore.error(
          <ToastError error={error} placeholder={`Sorry, something went wrong, could not update this title...`} />,
        );
        await options?.onFailure?.();
      }
    },
    [dialogStore, options, toastStore],
  );

  const handler = useCallback(() => {
    dialogStore.openModal({
      title: 'Edit Title',
      body: () => <SeparatorTitleForm handleSubmit={handleSubmit} listItem={items[0]} />,
      size: IModalSize.XS,
    });
  }, [dialogStore, handleSubmit, items]);

  return {
    name: IListItemActionName.UPDATE,
    icon: 'edit',
    title: 'Edit',
    shouldDisplay: shouldDisplayEditTitle,
    handler,
  };
}
