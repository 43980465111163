import React, { useCallback } from 'react';
import { useStore } from 'store';

import { CreateCastCrewForm } from './create-cast-crew-form';
import { IModalSize } from 'components/dialogs/types';

export const useCreateCastCrew = (): React.MouseEventHandler<HTMLButtonElement> => {
  const { dialogStore } = useStore();
  const openCreateCastCrewDialog = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      return dialogStore.openModal({
        title: 'Create Cast & Crew',
        body: () => <CreateCastCrewForm />,
        size: IModalSize.XS,
      });
    },
    [dialogStore],
  );

  return openCreateCastCrewDialog;
};
