import React, { useCallback } from 'react';
import { IMm3Asset } from 'types';

import { useStore } from 'store';

import { IAssetActionName } from 'utils/actions/asset/types';
import { IActionConfig, IUseActionsOptionParam } from 'utils/actions';
import { getValidIds, pluralEntityInflect } from 'utils/general';

import { shouldDisplayUnassignToAsset as shouldDisplay } from './actions-acl';
import { ToastError } from 'components/toast';
import { unassignSubAssets } from 'utils/apis/asset';

export const useUnassignToAssetAction = (
  items: IMm3Asset[],
  options?: IUseActionsOptionParam<IMm3Asset>,
): IActionConfig<IMm3Asset, IAssetActionName> => {
  const { toastStore, dialogStore } = useStore();

  const assetIds = getValidIds(items);
  const { entity } = pluralEntityInflect('Sub-Asset', items.length);

  const onConfirm = useCallback(async () => {
    try {
      if (!options?.parentId || !assetIds?.length) {
        return;
      }
      await unassignSubAssets(assetIds.map((id) => ({ asset_id: options.parentId, related_asset_id: id })));
      toastStore.success(`${entity} will be unassigned`);
      options?.onSuccess?.(IAssetActionName.ASSIGN_TO_ASSET);
    } catch (error) {
      toastStore.error(<ToastError error={error} />);
    }
  }, [options, assetIds, toastStore, entity]);

  const openDialog = (): void => {
    dialogStore.openConfirm({
      title: 'Unassign from Asset',
      body: `${entity} will be unassigned from this Asset`,
      onConfirm,
    });
  };

  return {
    name: IAssetActionName.UNASSIGN_FROM_ASSET,
    shouldDisplay,
    icon: 'cut',
    title: 'Unassign from Asset',
    handler: openDialog,
  };
};
