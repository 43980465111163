import { IUseActionsOptionParam, IUseActionsResponse, useActions, useGrantAccessPrivilegeAction } from 'utils/actions';
import { ISelectionStandardized } from 'types';

import { useCopyAction } from 'utils/actions/common/copy-action';
import { useAddEntitiesToGroupAction } from 'utils/actions/selection/add-entities-to-group-action';
import { useChangeAccessAction } from 'utils/actions/selection/change-access';
import { ISelectionActionName } from 'utils/actions/selection/types';
import { useRestoreAction } from 'utils/actions/selection/restore-action';
import { useUploadAssetToSelectionAction } from 'utils/actions/selection/upload-asset-to-this-selection';
import {
  useAddContactSelectionsAction,
  contactSelectionsToItems,
  IShouldDisplayAccessPrivilegeActionFunction,
} from 'utils/actions/access-privilege';

import { useDeleteAction } from './delete-action';
import { selectionDividerAction as selectionActionDivider } from 'utils/actions';

import { shouldDisplayAccessPrivilege, shouldDisplayCreateGuild } from './actions-acl';
import type { ISelectionActionConfig } from './types';
import { useRecommendAction } from './recommend';
import { useDeliveryAction } from './deliver-action';
import { useCreateGuildAction } from './create-guild';
import {
  productSelectionsToItems,
  useAddProductSelectionsToGuildAction,
} from 'utils/actions/guilds/add-entities-actions';
export { useDeleteAction, useAddEntitiesToGroupAction };
export type { IShouldDisplaySelectionActionFunction } from './types';

export const useSelectionActions = (
  entities: ISelectionStandardized[] = [],
  options: IUseActionsOptionParam,
): IUseActionsResponse<ISelectionActionName> => {
  const uploadAssetToSelectionAction = useUploadAssetToSelectionAction(entities, options);
  const addToThisSelectionAction = useAddEntitiesToGroupAction(entities, options);
  const copySelectionAction = useCopyAction(entities, options);
  const deleteSelectionAction = useDeleteAction(entities, options);
  const restoreSelectionAction = useRestoreAction(entities, options);
  const changeAccess = useChangeAccessAction(entities, options);

  const addToAccessPrivilegeAction = useAddContactSelectionsAction({
    options: {
      ...options,
      items: contactSelectionsToItems(entities),
      shouldDisplay: shouldDisplayAccessPrivilege,
    },
  });

  const createAccessPrivilegeAction = useGrantAccessPrivilegeAction(
    { userSelections: entities },
    {
      ...options,
      shouldDisplay: shouldDisplayAccessPrivilege as IShouldDisplayAccessPrivilegeActionFunction,
    },
  );

  const recommendAction = useRecommendAction(entities, options);

  const deliveryAction = useDeliveryAction(entities, options);
  const createGuild = useCreateGuildAction(entities, options);
  const addProductsToGuild = useAddProductSelectionsToGuildAction({
    options: {
      ...options,
      items: productSelectionsToItems(entities),
      shouldDisplay: shouldDisplayCreateGuild,
    },
  });
  const actions = [
    selectionActionDivider,
    uploadAssetToSelectionAction,
    copySelectionAction,
    addToThisSelectionAction,
    changeAccess,
    restoreSelectionAction,
    addToAccessPrivilegeAction,
    recommendAction,
    deliveryAction,
    createAccessPrivilegeAction,
    addProductsToGuild,
    createGuild,
    deleteSelectionAction,
  ];

  return useActions(actions as ISelectionActionConfig[], options, entities);
};
