import React from 'react';
import { IconAlias } from 'icons';
import { McGenericRecommendationAsset } from '@mediafellows/mm3-types';

import { Wizard, WizardStep, useWizardSteps } from 'components/wizard';
import { Row } from 'components/label-value-pair';
import {
  messageKeys,
  settingsKeys,
  RecommendProductsStepMessage,
  RecommendProductsStepRecipients,
  RecommendProductsStepSettings,
  RecommendProductsStepSummary,
  AssetStepIds as StepIds,
  WizardButtons,
  getTabId,
} from 'components/recommendation/recommendation-shared';
import { CheckPowers } from 'components/check-power';

import { FormCheckbox, isValidMM3 } from 'helpers/form';

import { IRecommendAssetsStep } from './types';
import { allowAssetDownloadPower } from 'components/recommendation/recommend-assets/initial-values';
import { RecommendProductsStepAssets } from './recommend-assets-step-assets';
import { MantineIcon } from 'utils/ui/icon';

import './style.scss';

const assetKeys = ['assetList'];
const AssetWizardButtons = WizardButtons<McGenericRecommendationAsset>;

export const RecommendAssetsWizard: React.FC<IRecommendAssetsStep> = ({ form, tab = StepIds.Assets, onFinish }) => {
  const { recipients, allows_asset_download } = form.values || {};
  const invalidRecipients = recipients.length < 1;

  const steps = [
    { id: StepIds.Assets, disabled: !isValidMM3(form.formData, assetKeys) },
    { id: StepIds.Message, disabled: !isValidMM3(form.formData, messageKeys) },
    {
      id: StepIds.Recipients,
      disabled: invalidRecipients,
    },
    { id: StepIds.Settings, disabled: !isValidMM3(form.formData, settingsKeys) },

    {
      id: StepIds.Summary,
      nextButtonTitle: 'Save draft',
      action: (evt: React.MouseEvent<HTMLElement, MouseEvent>) => {
        form.onSubmit(evt);
      },
    },
  ];

  const { nextButton, backButton, currentStepId, setCurrentStepId, isStepDisabled } = useWizardSteps(steps, {
    defaultStepId: getTabId(tab),
  });

  const { handlers, formData } = form || {};
  return (
    <Wizard
      selectedStepId={currentStepId}
      onChange={setCurrentStepId}
      showStepTitleIndex
      fitInParent
      footerPadded={false}
      footer={<AssetWizardButtons {...{ form, onFinish, nextButton, backButton, currentStepId }} />}
      className="recommendation-assets__wizard"
    >
      <WizardStep
        id={StepIds.Assets}
        title="Assets"
        icon={IconAlias.ASSET}
        disabled={isStepDisabled(steps[0].id)}
        panel={<RecommendProductsStepAssets form={form} />}
      />

      <WizardStep
        id={StepIds.Message}
        title="Message"
        icon="chat"
        disabled={isStepDisabled(steps[1].id)}
        panel={<RecommendProductsStepMessage form={form} />}
      />

      <WizardStep
        id={StepIds.Recipients}
        title="Recipients"
        icon={IconAlias.CONTACT}
        disabled={isStepDisabled(steps[2].id)}
        panel={<RecommendProductsStepRecipients form={form} />}
      />

      <WizardStep
        id={StepIds.Settings}
        title="Settings"
        icon={IconAlias.SETTINGS}
        disabled={isStepDisabled(steps[3].id)}
        panel={
          <RecommendProductsStepSettings form={form}>
            <CheckPowers requiredPowers={allowAssetDownloadPower}>
              <FormCheckbox
                label="Permit download"
                name="allows_asset_download"
                {...handlers}
                {...formData.allows_asset_download}
              />
            </CheckPowers>
          </RecommendProductsStepSettings>
        }
      />

      <WizardStep
        id={StepIds.Summary}
        title="Summary"
        icon="menu"
        disabled={isStepDisabled(steps[4].id)}
        panel={
          <RecommendProductsStepSummary form={form} isEditingRecipients={currentStepId === StepIds.Recipients}>
            <Row
              label="Permit Download"
              labelClassName="wizard_summary__label"
              valueClassName="wizard_summary__value--border"
              value={
                <MantineIcon
                  icon={allows_asset_download ? 'tick' : 'cross'}
                  fill={allows_asset_download ? 'green' : 'red'}
                />
              }
            />
          </RecommendProductsStepSummary>
        }
      />
    </Wizard>
  );
};
