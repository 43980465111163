import React, { useCallback } from 'react';
import { ActionIcon } from '@mantine/core';
import { Plus } from 'blueprint5-icons';
import { observer } from 'mobx-react-lite';
import { toNumber } from 'lodash';
import { useStore } from 'store';
import { useRefreshDataSection } from 'utils/hooks';
import { ConnectionContainer } from 'components/delivery/connections/connection-container';
import { initialValues } from 'components/delivery/connections/initial-values';
import { MantineIcon } from 'utils/ui/icon';
import { IModalSize } from 'components/dialogs/types';

export const CreateConnectionButton = observer(({ organizationId }: { organizationId: string }): JSX.Element => {
  const { dialogStore } = useStore();
  const onSuccess = useRefreshDataSection();

  const handleFinish = useCallback(async () => {
    await onSuccess?.();

    dialogStore.close();
  }, [dialogStore, onSuccess]);

  const body = useCallback((): JSX.Element => {
    const newConnection = {
      ...initialValues,
      organization_id: toNumber(organizationId),
    };

    return (
      <ConnectionContainer
        connection={newConnection}
        organizationId={newConnection.organization_id}
        onFinish={handleFinish}
      />
    );
  }, [organizationId, handleFinish]);

  const handler = (): void => {
    dialogStore.openModal({
      title: 'Create Connection',
      body,
      size: IModalSize.XS,
    });
  };

  return (
    <ActionIcon title="Create Connection" variant="subtle" color="gray.5" onClick={handler} className="mx-2">
      <MantineIcon icon={<Plus />} />
    </ActionIcon>
  );
});
