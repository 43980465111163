import React, { useCallback, useEffect, Suspense, useState } from 'react';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';
import { Modal, ModalProps, Loader, ActionIcon } from '@mantine/core';
import { IconName } from 'blueprint5-icons';

import { useDialogStore } from 'store/hooks';
import { IModalSize } from 'components/dialogs/types';
import { MantineIcon } from 'utils/ui';
import './modal-wrapper.scss';

interface IModalWrapperProps extends ModalProps {
  className?: string;
  header?: () => JSX.Element;
  body?: () => JSX.Element;
  icon?: IconName | JSX.Element;
  canOutsideClickClose?: boolean;
  size?: IModalSize;
  fitInView?: boolean;
}

const defaultHeader = ({ title, icon }): JSX.Element => (
  <>
    <Modal.Title>{title}</Modal.Title>
    <div>
      {icon && (
        <ActionIcon variant="subtle">
          <MantineIcon icon={icon} />
        </ActionIcon>
      )}
      <Modal.CloseButton />
    </div>
  </>
);

export const ModalWrapper: React.FC<IModalWrapperProps> = observer((props) => {
  const { close } = useDialogStore();
  const [isOpen, setOpen] = useState(false);
  const {
    opened,
    onClose,
    header,
    body,
    icon,
    className,
    title,
    canOutsideClickClose = false,
    size,
    fitInView,
    withCloseButton = true,
  } = props;

  // hack to get the transitions working
  useEffect(() => {
    setOpen(opened);
  }, [opened]);

  const handleClose = useCallback((): void => {
    close();
    onClose?.();
  }, [close, onClose]);

  return (
    <Modal.Root
      className={cx(className, { 'dialog--fit-in-view': fitInView })}
      opened={isOpen}
      onClose={handleClose}
      size={size || 'auto'}
      zIndex={20}
      centered
      transitionProps={{ duration: 300, timingFunction: 'ease', transition: 'scale' }}
      closeOnClickOutside={canOutsideClickClose}
    >
      <Modal.Overlay />
      <Modal.Content>
        {withCloseButton && <Modal.Header>{header ? header?.() : defaultHeader({ title, icon })}</Modal.Header>}
        <Modal.Body>
          <Suspense fallback={<Loader />}>{body?.()}</Suspense>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
});

export default React.memo(ModalWrapper);
