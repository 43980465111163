import React, { useCallback } from 'react';
import { useStore } from 'store';

import { updateAudioTrack } from 'utils/apis/audio-track';
import { IAudioActionConfig, IAudioActionName } from './types';
import { IconAlias } from 'icons';
import { useEditAudioDialog } from 'components/asset/edit-audio-dialog';
import { IUseActionsOptionParam } from '..';
import { IAudioTrack } from 'types';
import { ToastError } from 'components/toast';

export const useEditAudioAction = (
  items: IAudioTrack[],
  options: IUseActionsOptionParam<IAudioTrack>,
): IAudioActionConfig => {
  const { toastStore, dialogStore } = useStore();
  const audio = items[0];

  const onSubmit = useCallback(
    async (audio: IAudioTrack) => {
      try {
        await updateAudioTrack(audio);
        toastStore.success('Updates applied successfully!');
        options.onSuccess?.(IAudioActionName.EDIT, audio);
        return dialogStore.close();
      } catch (error) {
        toastStore.error(<ToastError error={error} />);
      }
    },
    [dialogStore, options, toastStore],
  );

  const [openDialog] = useEditAudioDialog({
    onSubmit,
    audio,
  });

  return {
    name: IAudioActionName.EDIT,
    icon: IconAlias.EDIT,
    title: 'Edit',
    handler: openDialog,
  };
};
