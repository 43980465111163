import React, { useCallback } from 'react';
import { map } from 'lodash';

import { IProduct } from 'types';
import { useStore } from 'store';
import { IUseActionsOptionParam } from 'utils/actions/types';

import { shouldDisplayClone as shouldDisplay } from './actions-acl';

import { cloneProducts, ICopyProductFormFields } from './api';

import { FormSubmitSection } from 'components/form-submit-section';
import { FormCheckbox, FormNumericInput, useForm } from 'helpers/form';
import { Model } from 'helpers/filters/types';
import { IProductActionConfig, IProductActionName } from 'utils/actions/product/types';
import { pluralEntityInflect } from 'utils/general';
import { IModalSize } from 'components/dialogs/types';

const initialValues: ICopyProductFormFields = {
  number_of_copies: 1,
  deep_clone: false,
  continue_numbering: false,
};

export interface ICopyProductForm {
  onSubmit: (values: ICopyProductFormFields) => void;
}

const maxCopies = 50;
const customContext = {
  properties: {
    number_of_copies: {
      validations: [
        {
          numericality: {
            allow_blank: false,
            allow_nil: false,
            greater_than_or_equal_to: 1,
            less_than_or_equal_to: maxCopies,
            only_integer: true,
          },
        },
      ],
    },
  },
};

const hint = `You can create up to ${maxCopies} copies per Product at a time.`;

const CopyProductForm: React.FC<ICopyProductForm> = ({ onSubmit }) => {
  const {
    formData,
    handlers: { onSetFields: _, ...handlers },
    valid,
    onSubmit: handlersSubmit,
  } = useForm<ICopyProductFormFields>(initialValues, Model.PRODUCTS, onSubmit, customContext);

  return (
    <>
      <form onSubmit={handlersSubmit}>
        <FormNumericInput
          formId="copy-product"
          label="Number of copies per Product"
          name="number_of_copies"
          {...formData.number_of_copies}
          {...handlers}
          helperText={hint}
        />
        <FormCheckbox name="deep_clone" label="Deep clone product hierarchy" {...formData.deep_clone} {...handlers} />
        <FormCheckbox
          name="continue_numbering"
          label="Append sequence number to title"
          {...formData.continue_numbering}
          {...handlers}
        />
      </form>
      <FormSubmitSection labels={{ confirm: 'Submit' }} submitDisabled={!valid} onSubmit={handlersSubmit} />
    </>
  );
};

export const useCloneAction = (items: IProduct[], options: IUseActionsOptionParam): IProductActionConfig => {
  const { dialogStore, toastStore } = useStore();

  const handleSubmit = useCallback(
    async (values: ICopyProductFormFields) => {
      const productIds = map(items, 'id');
      const { entity, entityWithCount } = pluralEntityInflect('Product', productIds.length);

      try {
        await cloneProducts(productIds, values);
        toastStore.success(`${entityWithCount} cloned`);
        await options?.onSuccess?.();
        return dialogStore.close();
      } catch (error) {
        toastStore.error(`${entity} cloning failed: ${error.text}`);
        await options?.onFailure?.();
      }
    },
    [toastStore, dialogStore, items, options],
  );

  const body = useCallback(() => <CopyProductForm onSubmit={handleSubmit} />, [handleSubmit]);

  const openCopyProductDialog = useCallback((): void => {
    dialogStore.openModal({
      title: 'Copy Product(s)',
      body,
      size: IModalSize.XS,
    });
  }, [body, dialogStore]);

  return {
    name: IProductActionName.CLONE,
    shouldDisplay,
    icon: 'duplicate',
    title: 'Copy',
    handler: openCopyProductDialog,
  };
};
