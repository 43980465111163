import { useAddToGroupDialog } from 'components/add-to-group-dialog';
import { GroupTypes, IMarketingEvent } from 'types';
import { IUseAction } from 'utils/actions';
import { shouldDisplayAddEvents as shouldDisplay } from './actions-acl';

import { IMarketingEventActionName } from './types';

export const useAddEventsAction: IUseAction<IMarketingEvent, IMarketingEventActionName> = (items, options) => {
  const [openSelectionDialog] = useAddToGroupDialog({
    group: items[0],
    entityType: 'events',
    options,
    groupType: GroupTypes.EVENT,
    omni: true,
  });

  return {
    name: IMarketingEventActionName.ADD_EVENTS,
    shouldDisplay,
    icon: 'add',
    title: 'Add sub-events',
    handler: openSelectionDialog,
  };
};
