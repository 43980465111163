import React, { useCallback } from 'react';
import { ActionIcon, RingProgress } from '@mantine/core';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { IObservableArray } from 'mobx';
import { startCase } from 'lodash';

import { MantineIcon } from 'utils/ui';
import { Classes } from 'utils/ui';
import { Routes } from 'utils/routes';
import { IAssetFile, IFile } from 'types';

import './index.scss';

export interface IAssetUploadDialog {
  files: IAssetFile[];
  uploadedFiles: IObservableArray<IFile>;
  progress: number;
  isUploadPending: boolean;
  storeId: string;
}

const UploadMessge: React.FC<{ storeId?: string }> = ({ storeId }) => {
  if (!storeId) return null;
  let message = '';
  const idRegex = /\d/g;
  const entityId = storeId.match(idRegex)?.join('');

  if (storeId?.startsWith('product-details')) {
    return entityId ? (
      <>
        Asset upload to <Link to={`${Routes.PRODUCTS}/${entityId}`}>Product</Link>{' '}
      </>
    ) : (
      <>Asset upload to Product</>
    );
  }
  if (storeId?.startsWith('asset-details')) {
    const subAssetType = startCase(storeId.split('_').pop()) || 'Sub-asset';
    message = `${subAssetType} upload to asset`;
    return entityId ? (
      <>
        {`${subAssetType} upload to `}
        <Link to={`${Routes.ASSETS}/${entityId}`}>asset</Link>
      </>
    ) : (
      <> {message} </>
    );
  }
  if (storeId?.startsWith('create-asset-page')) message = 'Asset creation';

  return message ? <>{message}</> : null;
};

export const AssetUploadShortcutDetailed: React.FC<IAssetUploadDialog> = ({
  files,
  progress,
  isUploadPending,
  uploadedFiles,
  storeId,
}) => {
  const navigate = useNavigate();
  const openAssetUploadPage = useCallback(
    (id: string) => {
      navigate(`${Routes.CREATE_ASSET}`, { state: { storeId: id } });
    },
    [navigate],
  );

  if (!isUploadPending) {
    return null;
  }

  return (
    <div className="assets-upload-modal">
      <div className="asset-upload-modal__progress-indicator">
        <RingProgress
          variant="primary"
          sections={[{ value: progress, color: 'var(--mfx-primary)' }]}
          size={65}
          thickness={4}
          roundCaps
          label={<div className="asset-upload-modal__progress-text">{progress}%</div>}
        />
      </div>
      <div className={`assets-upload-modal__text ${Classes.TEXT_MUTED}`}>
        <UploadMessge storeId={storeId} />
        <div>{`${uploadedFiles.length} out of ${files.length}`}</div>
        <div>files uploaded</div>
      </div>
      <ActionIcon
        variant="subtle"
        color="gray.5"
        className="open-assets-upload-page-btn"
        onClick={() => openAssetUploadPage(storeId)}
      >
        <MantineIcon icon="maximize" />
      </ActionIcon>
    </div>
  );
};

export default AssetUploadShortcutDetailed;
