import { useCallback } from 'react';

import { IParametronData, Parametron } from '@mediafellows/parametron';

import { ParametronStore } from 'store/parametron-store';

import { getAssets, queryParentsMultipleAssets, querySubAssetsMultipleAssets } from 'utils/apis/asset';
import { isAssetIngesting, refreshIngestingAssetsDelay } from 'utils/asset';
import { byId } from 'utils/general';
import { useInterval } from 'utils/hooks/use-interval';
import { EqFilter, IFilter, RangeFilter } from 'helpers/filters/types';
import { createdAtRange } from 'utils/date';
import { searchProducts } from 'utils/apis/product';
import { getIsMm3Assets } from 'utils/asset';

import { IMm3Asset, ISearchFilter } from 'types';

export const refreshIngestingAssets = async (searchStore: ParametronStore | null): Promise<void> => {
  if (!searchStore?.objects?.length) {
    return;
  }
  const { objects, update } = searchStore;
  const assetsInIngest = objects.filter(isAssetIngesting);
  if (!assetsInIngest.length) {
    return;
  }
  const refreshedAssets = await getAssets(assetsInIngest.map((e) => e.id));
  const assetsById = byId(refreshedAssets);

  update({ objects: objects.map((e) => assetsById[e.id] || e) });
};

export const useRefreshIngestingAssets = (assetSearch: ParametronStore | null): void => {
  const refresh = useCallback(() => {
    refreshIngestingAssets(assetSearch);
  }, [assetSearch]);

  useInterval(refresh, refreshIngestingAssetsDelay);
};

export const parseQueryParamsToFilters = ({
  file_status,
  main_classification,
  created_at,
  type,
}: Record<string, string>): Record<string, IFilter> => {
  const isMm3Assets = getIsMm3Assets();

  return {
    ...(created_at && createdAtRange[created_at]
      ? { created_at: new RangeFilter('created_at', ...createdAtRange[created_at]) }
      : {}),
    ...(file_status ? { file_status: new EqFilter('file_status', file_status) } : {}),
    ...(type ? { type: new EqFilter('type', type), root: new EqFilter('root', false) } : {}),
    ...(main_classification
      ? {
          main_classification: new EqFilter('main_classification', main_classification),
          ...(isMm3Assets ? { root: new EqFilter('root', false) } : {}),
        }
      : {}),
  };
};

export const initQueryParams = (api: Parametron, queryParams: Record<string, string>): void => {
  if (queryParams.main_classification) {
    api.setFilter('main_classification', 'eq', queryParams.main_classification);
  }
  if (queryParams.file_status) {
    api.setFilter('file_status', 'eq', queryParams.file_status);
  }
  if (queryParams.type) {
    api.setFilter('type', 'eq', queryParams.type);
  }
  if (queryParams.created_at && createdAtRange[queryParams.created_at]) {
    api.setFilter('created_at', 'range', ...createdAtRange[queryParams.created_at]);
  }
};

export const handleUpdate = async (
  data: IParametronData,
  assetSearch: ParametronStore<IMm3Asset> | null,
): Promise<void> => {
  const isMm3Assets = getIsMm3Assets();

  if (!assetSearch) {
    return;
  }
  assetSearch.updateParamsAndFiltersCount(['include_deleted']);
  if (!data.objects?.length) {
    assetSearch.update(data);
    return;
  }
  const assetIds = data.objects.map((e) => e.id);
  const filters: ISearchFilter[] = [['asset_ids', 'in', assetIds]];
  const productsPromise = searchProducts({ search: { filters } }, '', 'unfurl');
  let childAssetsPromise;
  let parentAssetsPromise;
  if (isMm3Assets) {
    const parentIds = data.objects.reduce((acc, e) => {
      if (!e.root) {
        acc.push(e.id);
      }
      return acc;
    }, []);
    if (parentIds?.length) {
      parentAssetsPromise = queryParentsMultipleAssets({ asset_id: parentIds });
    }

    childAssetsPromise = querySubAssetsMultipleAssets({ asset_id: assetIds });
  }
  const [products, childrenByParentId, parentsById = {}] = await Promise.all([
    productsPromise,
    childAssetsPromise,
    parentAssetsPromise,
  ]);
  const productsById = byId(products);

  data.objects.map((asset) => {
    asset.products = (asset.products || []).map((p) => productsById[p.product_id]).filter(Boolean);
    if (!isMm3Assets) {
      return;
    }
    asset.children = childrenByParentId[asset.id];
    asset.parent = parentsById[asset.id]?.[0];
  });
  assetSearch.update(data);
};
