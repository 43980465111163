import { IActionConfig, IShouldDisplayActionFunction } from 'utils/actions/types';
import { ISelectionStandardized } from 'types';

export enum ISelectionActionName {
  UPLOAD_ASSET_TO_SELECTION = 'upload-asset-to-selection',
  ADD_TO_THIS_SELECTION = 'add-to-this-selection',
  COPY = 'copy',
  DELETE = 'delete',
  RESTORE = 'restore',
  REMOVE = 'remove',
  CHANGE_ACCESS = 'change-access',
  RECOMMEND = 'recommend',
  DELIVER = 'deliver',
  CREATE_GUILD = 'create-guild',
}

export type IShouldDisplaySelectionActionFunction = IShouldDisplayActionFunction<
  ISelectionStandardized,
  ISelectionActionName
>;

export type ISelectionActionConfig = IActionConfig<ISelectionStandardized, ISelectionActionName>;
