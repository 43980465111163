import React, { useCallback } from 'react';

import { FormImportToMB } from 'components/form-import-to-mb/form-import-to-mb';
import { useStore } from 'store';
import { IAsset, IMm3Asset } from 'types';
import { IAssetActionConfig, IAssetActionName } from './types';
import { isDisabledExportToMB, shouldDisplayImportToMB as shouldDisplay } from 'utils/actions/asset/mediabench-actions';
import { getValidIds } from 'utils/general';
import { IModalSize } from 'components/dialogs/types';

export const useImportToMBAction = (items: (IAsset | IMm3Asset)[]): IAssetActionConfig => {
  const { dialogStore } = useStore();
  const isDisabled = isDisabledExportToMB(items);

  const body = useCallback((): JSX.Element => {
    const itemIds = getValidIds(items).map((e) => Number(e));
    return <FormImportToMB itemIds={itemIds} />;
  }, [items]);

  const handler = useCallback(() => {
    dialogStore.openModal({
      title: 'Export to MediaBench',
      body,
      size: IModalSize.XS,
    });
  }, [body, dialogStore]);

  return {
    name: IAssetActionName.EXPORT_TO_MB,
    shouldDisplay,
    icon: 'drawer-left',
    title: 'Export to MediaBench',
    handler,
    isDisabled,
  };
};
