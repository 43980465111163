import React, { useCallback, useState } from 'react';
import cx from 'classnames';
import { ArrowLeft } from 'blueprint5-icons';
import { UmGenericListShowcase } from '@mediafellows/mm3-types';
import { Button } from '@mantine/core';

import { useDataSectionStore } from 'store/hooks';
import { useStore } from 'store';

import { IGenericListCollectionForm } from 'components/showcase-form/utils';
import { Steps } from 'components/showcase-form/constants';

import { ToastError } from 'components/toast';

import { Classes, MantineIcon } from 'utils/ui';
import { createList, uploadPreviewImage } from 'utils/apis/showcase';
import { createListItems } from 'utils/apis/showcase-item';
import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';
import { IFormMultiSelectOption } from 'helpers/form/fields/select-helpers';

interface ICreateShowcaseButtonsProps {
  currentStep: Steps;
  goBack: () => void;
  form: IUseMm3FormReturn<UmGenericListShowcase> | IUseMm3FormReturn<IGenericListCollectionForm>;
  handleNext: () => void;
  selectedItems: IFormMultiSelectOption[];
  entityType: string;
}
export const CreateShowcaseButtons: React.FC<ICreateShowcaseButtonsProps> = ({
  currentStep,
  goBack,
  form,
  handleNext,
  selectedItems,
  entityType = 'Showcase',
}) => {
  const { values, valid } = form;

  const { dialogStore, toastStore } = useStore();
  const { searchStore, updateStore } = useDataSectionStore();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const refresh = useCallback(
    (showcase: UmGenericListShowcase): void => {
      if (!searchStore) {
        return;
      }
      searchStore.update({
        objects: [showcase, ...(searchStore.objects || [])],
      });
      updateStore({ active: showcase.id });
    },
    [searchStore, updateStore],
  );

  const onSubmit = useCallback(async (): Promise<void> => {
    try {
      setIsSubmitting(true);
      const showcase = await createList(values);
      if (values.type === 'List::Collection') {
        const url = (values as IGenericListCollectionForm)?.previewImageUrl;
        if (url) {
          await uploadPreviewImage({ url }, showcase);
        }
      }
      await createListItems(selectedItems, showcase?.id).catch((error) => {
        toastStore.error(
          <ToastError
            error={error}
            placeholder={`Sorry, something went wrong when adding items to this ${entityType}...`}
          />,
        );
      });
      refresh(showcase);
      toastStore.success(`${entityType} was created successfully!`);
      dialogStore.close();
    } catch (error) {
      if (error?.text === 'Purpose has already been taken') {
        toastStore.error(<ToastError placeholder={'Identifier has already been taken'} />);
      } else {
        if (error.response.body.description?.match(/purpose/i)) {
          error.response.body.description = error.response.body.description.replace(/purpose/i, 'Identifier');
        }
        toastStore.error(
          <ToastError error={error} placeholder={`Sorry, something went wrong when creating the ${entityType}...`} />,
        );
      }
    } finally {
      setIsSubmitting(false);
    }
  }, [values, selectedItems, refresh, toastStore, entityType, dialogStore]);

  return (
    <div
      className={cx(Classes.DIALOG_FOOTER_ACTIONS, 'd-flex', {
        'justify-content-end': currentStep === Steps.Step1,
        'justify-content-between': currentStep === Steps.Step2,
      })}
    >
      {currentStep === Steps.Step1 && (
        <Button disabled={!valid} variant="primary" className="px-2" onClick={handleNext}>
          Next
        </Button>
      )}
      {currentStep === Steps.Step2 && (
        <>
          <Button size="xs" variant="subtle" onClick={goBack} leftSection={<MantineIcon icon={<ArrowLeft />} />}>
            Back
          </Button>
          <Button size="xs" variant="primary" onClick={onSubmit} loading={isSubmitting} disabled={isSubmitting}>
            Create
          </Button>
        </>
      )}
    </div>
  );
};
