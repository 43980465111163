import React, { useCallback, useEffect, useMemo } from 'react';
import { ActionIcon } from '@mantine/core';
import { Cross } from 'blueprint5-icons';

import AssetPresetFields, {
  IAssetPresetFieldsProps,
} from 'components/asset/asset-upload-components/preset-form/preset-form';
import AssetPresetControls from 'components/asset/asset-upload-components/single-asset-form/preset-control';
import { Thumbnail } from 'components/thumbnail';

import { FormInput, FormCheckbox, IUseFormReturn } from 'helpers/form';
import { MantineIcon } from 'utils/ui/icon';

import { IAssetFields, IAssetFile } from 'types';
import { IAssetFormProps } from './types';
import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';

export const SharedAssetForm: React.FC<
  IAssetFormProps & {
    form: IUseFormReturn<IAssetFields> | IUseMm3FormReturn<IAssetFields>;
  }
> = ({
  initialValues,
  removeFile,
  form,
  shouldShowErrors,
  withParentField,
  applyPreset,
  savePreset,
  onDataChange,
  index,
  setIsValidForm,
  assetsUploaderStore,
}) => {
  const { formData, handlers: handleChanges, values, valid } = form;
  const formId = values.id;

  const handlers = useMemo(() => {
    return {
      ...handleChanges,
      onChange(values: Partial<IAssetFile>): void {
        handleChanges.onChange(values);
        onDataChange(values, index);
      },
    };
  }, [handleChanges, onDataChange, index]);

  const removeAsset = useCallback(() => {
    removeFile(initialValues);
  }, [removeFile, initialValues]);

  useEffect(() => {
    setIsValidForm(initialValues.id, valid);
  }, [valid, initialValues, setIsValidForm]);

  return (
    <form className="asset-upload-components__single-asset-form-container" id={formId}>
      <div className="asset-upload-components__single-asset-form-main-info">
        <FormCheckbox formId={formId} name="selected" {...formData.selected} {...handlers} />
        <Thumbnail
          image={initialValues.preview || initialValues.uploadURL}
          assetType={initialValues.type}
          showType
          noResize={Boolean(initialValues.preview)}
        />
        <div className="asset-upload-components__single-asset-form-fields">
          <ActionIcon className="clear-all__button" color="gray.5" variant="subtle" onClick={removeAsset}>
            <MantineIcon icon={<Cross />} />
          </ActionIcon>
          <FormInput formId={formId} name="file_name" label="File Name" {...formData.file_name} disabled />
          <FormInput formId={formId} name="name" label="Asset Name" {...formData.name} {...handlers} />
        </div>
      </div>
      <AssetPresetFields
        {...({
          formId,
          formData,
          handlers,
          shouldShowErrors,
          values,
          withParentField,
          assetsUploaderStore,
        } as unknown as IAssetPresetFieldsProps)}
      />
      <AssetPresetControls {...{ values, applyPreset, savePreset }} />
    </form>
  );
};
