import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import {
  Checkbox,
  Combobox,
  Group,
  Pill,
  PillsInput,
  Tree,
  useCombobox,
  Flex,
  CloseButton,
  ActionIcon,
  noop,
} from '@mantine/core';
import { ChevronDown, ChevronRight } from 'blueprint5-icons';
import cx from 'classnames';

import { FormGroup } from 'helpers/form/fields/form-group';

import { Intent, MantineIcon } from 'utils/ui';
import { tagRenderer as defaultTagRenderer, IFormTreeSelectProps, buildTree, TreeNode } from './tree-selector-utils';
import { formatFormLabel, getFieldPlaceholder } from 'helpers/form/fields/helpers';

import './style.scss';

export const FormTreeSelect: React.FC<IFormTreeSelectProps> = (props) => {
  const {
    name,
    label,
    className,
    onChange,
    validation,
    touched,
    value,
    tagRenderer = defaultTagRenderer,
    required,
    options,
    inline = false,
    placeholder,
    disabled = false,
    hideClearAllButton = false,
    size = 'md',
    tagsSize = 'md',
  } = props;
  const showError = touched && !validation?.valid;
  const intent = showError ? Intent.DANGER : undefined;
  const [checked, setChecked] = useState<TreeNode[]>([]);
  const [indeterminate, setIndeterminate] = useState<TreeNode[]>([]);
  const [selectedItems, setSelectedItems] = useState<TreeNode[]>([]);
  const optionsInitialized = useRef(false);

  const formattedPlaceholder = getFieldPlaceholder({ placeholder, disabled, defaultPlaceholder: `Select ${label}` });

  const [treeData, setTree] = useState<TreeNode[]>([]);

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
  });

  const setCheckedStates = useCallback((treeData: TreeNode[]): { selected: TreeNode[] } => {
    const checked = treeData?.flatMap((node) => node.getChecked());
    const indeterminate = treeData?.flatMap((node) => node.getIndeterminate());
    const selected = treeData?.flatMap((node) => node.getSelected());
    setChecked(checked);
    setIndeterminate(indeterminate);
    setSelectedItems(selected);
    return { selected };
  }, []);

  useEffect(() => {
    if (!options?.length || optionsInitialized.current) return;
    const initialValues = value?.map((v) => v.toString());
    const treeData = buildTree({ data: options, initialValues }).tree;
    setTree(treeData);
    setCheckedStates(treeData);
    optionsInitialized.current = true;
  }, [options, setCheckedStates, value]);

  const setCheckedStatesAndForm = useCallback(() => {
    const { selected } = setCheckedStates(treeData);
    const newValues = Number.isNaN(Number(selected?.[0]?.value))
      ? selected.map((node) => node.value)
      : selected.map((node) => Number(node.value));

    onChange?.({ [name]: newValues });
  }, [setCheckedStates, treeData, onChange, name]);

  const selectedValues = useMemo(
    () =>
      selectedItems?.map((node) => (
        <Pill
          key={node?.value}
          withRemoveButton
          onRemove={() => {
            node.uncheck();
            setCheckedStatesAndForm();
          }}
          radius="xs"
          removeButtonProps={{ color: 'gray.5' }}
          className="tree-select__pill"
          size={tagsSize}
        >
          {tagRenderer(node)}
        </Pill>
      )),
    [selectedItems, setCheckedStatesAndForm, tagRenderer, tagsSize],
  );

  const handleReset = useCallback((): void => {
    treeData?.forEach((node) => node.uncheck());
    setCheckedStatesAndForm();
  }, [setCheckedStatesAndForm, treeData]);
  const groupClass = cx(className, { 'form-tree-inline__container': inline });
  return (
    <FormGroup
      label={formatFormLabel(label, required)}
      labelFor={name}
      intent={intent}
      helperText={showError ? validation?.errorMessage : ''}
      inline={inline}
      className={groupClass}
    >
      <Combobox
        store={combobox}
        withinPortal
        position="bottom-start"
        middlewares={{ flip: false, shift: false }}
        offset={3}
      >
        <Combobox.DropdownTarget>
          <PillsInput
            pointer
            onClick={() => !disabled && combobox.toggleDropdown()}
            size={size}
            classNames={{ input: 'tree-select__pill-input' }}
          >
            <Pill.Group className="justify-content-between flex-nowrap">
              {selectedValues.length > 0 && (
                <>
                  <Flex
                    gap="0.4rem"
                    wrap="wrap"
                    className={cx('tree-select__selected-values', { 'w-100': required || hideClearAllButton })}
                  >
                    {selectedValues}
                  </Flex>
                  <CloseButton
                    onClick={handleReset}
                    size={size}
                    className="tree-select__clear-btn"
                    hidden={required || hideClearAllButton}
                  />
                </>
              )}
              <Combobox.EventsTarget>
                <PillsInput.Field
                  type={selectedValues.length ? 'hidden' : 'visible'}
                  readOnly
                  placeholder={formattedPlaceholder}
                  onKeyDown={(event) => {
                    if (event.key === 'Backspace') {
                      event.preventDefault();
                      const node = selectedItems[selectedItems.length - 1];
                      node.uncheck();
                      setCheckedStatesAndForm();
                    }
                  }}
                />
              </Combobox.EventsTarget>
            </Pill.Group>
          </PillsInput>
        </Combobox.DropdownTarget>

        <Combobox.Dropdown className="form-tree-select__dropdown">
          <Combobox.Options mah={200} style={{ overflowY: 'auto' }}>
            <Tree
              data={treeData}
              className={cx(className)}
              renderNode={({ node: treeNode, expanded, hasChildren, elementProps }) => {
                const node = treeNode as TreeNode;
                return (
                  <Combobox.Option value={node.value} key={node.value} active={node.isChecked()}>
                    <Group gap={5} {...elementProps} onClick={noop} wrap="nowrap">
                      <ActionIcon
                        onClick={hasChildren ? elementProps.onClick : noop}
                        variant="transparent"
                        style={{
                          cursor: hasChildren ? 'pointer' : 'default',
                          fill: !hasChildren ? 'transparent' : 'currentColor',
                        }}
                      >
                        {!expanded && (
                          <MantineIcon icon={<ChevronRight color={!hasChildren ? 'transparent' : 'currentColor'} />} />
                        )}
                        {expanded && (
                          <MantineIcon icon={<ChevronDown color={!hasChildren ? 'transparent' : 'currentColor'} />} />
                        )}
                      </ActionIcon>
                      <Checkbox
                        size="sm"
                        indeterminate={indeterminate.includes(node)}
                        checked={checked.includes(node)}
                        onClick={() => {
                          node.toggleCheck();
                          setCheckedStatesAndForm();
                        }}
                        onChange={noop}
                        label={node.label}
                      />
                    </Group>
                  </Combobox.Option>
                );
              }}
            />
          </Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
    </FormGroup>
  );
};
export default FormTreeSelect;
