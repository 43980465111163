import { GroupTypes, IContact } from 'types';

import { useAddToGroupDialog } from 'components/add-to-group-dialog';
import { shouldDisplayAddToSelection as shouldDisplay } from './actions-acl';
import { IContactActionConfig, IContactActionName } from 'utils/actions/contact/types';

export const useAddToSelectionAction = (items: IContact[]): IContactActionConfig => {
  const [openSelectionDialog] = useAddToGroupDialog({
    entityType: 'users',
    items: items.map(({ id, first_name, last_name }) => ({ label: `${first_name} ${last_name}`, value: id })),
    groupType: GroupTypes.SELECTION,
    omni: true,
  });

  return {
    name: IContactActionName.ADD_TO_SELECTION,
    shouldDisplay,
    icon: 'add-to-artifact',
    title: `Add to Selection`,
    handler: openSelectionDialog,
  };
};
