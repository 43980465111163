import React from 'react';
import { observer } from 'mobx-react-lite';
import { Thumbnail } from 'components/thumbnail';
import cx from 'classnames';

import { getEntityType, formatAssetType } from 'utils/general';
import { formatAssetClassification } from 'utils/asset';
import { IAsset, IMm3Asset } from 'types';
import { Classes } from 'utils/ui';
import { flags } from 'utils/flags';
import { getIsMm3Assets } from 'utils/asset';

import './style.scss';

const AssetSelectedTabItem: React.FC<{
  asset: IAsset | IMm3Asset;
  customParser?: (entity?: IAsset | IMm3Asset, entityType?: string) => string;
}> = observer(({ asset, customParser }) => {
  const isMm3Assets = getIsMm3Assets();
  const { name, classification, preview_image } = asset;
  const assetType = isMm3Assets ? (asset as IMm3Asset)?.type : getEntityType(classification);
  const classificationLabel = flags.hasCustomClassifications
    ? formatAssetClassification(classification)
    : formatAssetType(classification);

  return (
    <div className={'asset-selected-tab-item'}>
      <div className="asset-selected-tab-item__thumb px-2">
        <Thumbnail image={preview_image?.url} showType assetType={assetType} customTag={customParser?.(asset)} />
      </div>
      <div className="asset-selected-tab-item__content text-truncate">
        <div className="asset-selected-tab-item__title text-truncate mb-1">{name}</div>
        <div title={classificationLabel} className={cx(Classes.TEXT_MUTED, Classes, 'text-truncate')}>
          {classificationLabel}
        </div>
      </div>
    </div>
  );
});

export default AssetSelectedTabItem;
