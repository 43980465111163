import React, { useState, useMemo } from 'react';

import { Document, Page } from 'react-pdf';

import './style.scss';

const TOTAL_MAX_PAGES = 6;
const PDF_PAGE_WIDTH = 200;

export const PDFPreview: React.FC<{ source: string }> = ({ source }) => {
  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }): void => {
    setNumPages(numPages);
  };

  const pages = useMemo((): JSX.Element[] => {
    if (!numPages) {
      return [];
    }
    const elements: JSX.Element[] = [];
    const maxPages = Math.min(numPages, TOTAL_MAX_PAGES);

    for (let pageNumber = 1; pageNumber <= maxPages; pageNumber++) {
      elements.push(
        <Page
          key={`pdf-page-${pageNumber}`}
          pageNumber={pageNumber}
          width={PDF_PAGE_WIDTH}
          className="pdf-preview__page"
        />,
      );
    }

    return elements;
  }, [numPages]);

  return (
    <Document className="pdf-preview__container" file={source} onLoadSuccess={onDocumentLoadSuccess}>
      {pages}
    </Document>
  );
};
