import React from 'react';
import { UmGenericListShowcase } from '@mediafellows/mm3-types';

import { FormInput, FormAccessLevel } from 'helpers/form';
import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';
import { ShowcaseCommonForm } from 'components/showcase-form/showcase-common-parts';

const AdvertisementFormFirstStep: React.FC<IUseMm3FormReturn<UmGenericListShowcase>> = ({
  handlers,
  formData,
  onSubmit,
  valid,
  values,
}) => {
  return (
    <ShowcaseCommonForm handlers={handlers} formData={formData} onSubmit={onSubmit} valid={valid} values={values}>
      <FormAccessLevel large name="access_level" label="Access level" {...handlers} {...formData?.access_level} />
      <FormInput textarea name="meta.description" label="Description" {...handlers} {...formData?.meta?.description} />
    </ShowcaseCommonForm>
  );
};

export default AdvertisementFormFirstStep;
