import React from 'react';

import { InfoBoxV2 } from 'components/info-box-v2';
import { Row } from 'components/label-value-pair';
import { TagsList } from 'components/entity-list';
import { IAsset, IMm3Asset, IMm3AssetType } from 'types';
import { withAssetVersion } from 'utils/asset';
import { AmAmazonusAsset3DigitalImf } from '@mediafellows/mm3-types';
import { parseLanguages } from 'utils/hooks/languages';

export const AssetOldMetadataIdentifiersSection: React.FC<{ asset?: IAsset }> = ({ asset }) => {
  const { archive_number, asset_identifier, external_reference, tags } = asset || {};

  return (
    <InfoBoxV2 gridTemplateColumns="180px 1fr" title="Identifiers">
      <TagsList tags={tags} className={'asset-metadata-tab__tag me-1'} />
      <Row label="External Reference" value={external_reference} size="m" />
      <Row label="Asset Identifier" value={asset_identifier} size="m" />
      <Row label="Archive Number" value={archive_number} size="m" />
    </InfoBoxV2>
  );
};

export const AssetMm3MetadataIdentifiersSection: React.FC<{ asset?: IMm3Asset }> = ({ asset }) => {
  const { external_reference, tags, meta, type } = asset || {};
  const { archive_number, asset_identifier } = meta || {};
  const isImf = type === IMm3AssetType.IMF;

  return (
    <>
      <InfoBoxV2 gridTemplateColumns="180px 1fr" title="Identifiers">
        <TagsList tags={tags} className={'asset-metadata-tab__tag me-1'} />
        <Row label="External Reference" value={external_reference} size="m" />
        <Row label="Asset Identifier" value={asset_identifier} size="m" />
        <Row label="Archive Number" value={archive_number} size="m" />
      </InfoBoxV2>
      {isImf ? (
        <div className="amazonus-imf-preview-section-wrapper">
          <InfoBoxV2
            className="product-metadata-tab__second-section"
            gridTemplateColumns="1fr"
            title="Video Tracks"
            withBorder
          >
            {(asset as AmAmazonusAsset3DigitalImf)?.meta?.imf_virtual_video_tracks?.map((e, i) => {
              return (
                <InfoBoxV2
                  title={`Video Track #${i + 1}`}
                  padded={false}
                  gridTemplateColumns="200px 1fr 200px 1fr 200px 1fr"
                  className="imf-item"
                  key={i}
                  withBorder
                >
                  <Row label="Configuration Experience" value={e.configuration || '[n/a]'} />
                  <Row label="Video Bit Depth" value={e.bit_rate || '[n/a]'} />
                  <Row label="Color Encode Primaries" value={e.color_space || '[n/a]'} />
                  <Row label="Mastering Display Primary" rawLabel value={e.mastering_display_primary || '[n/a]'} />
                  <Row label="Transfer Characteristics" value={e.transfer_characteristics || '[n/a]'} />
                  <Row label="Container Format" value={e.container_format || '[n/a]'} />
                  <Row label="Width" value={e.width || '[n/a]'} />
                  <Row label="Height" rawLabel value={e.height || '[n/a]'} />
                </InfoBoxV2>
              );
            })}
          </InfoBoxV2>
          <InfoBoxV2
            className="product-metadata-tab__second-section"
            gridTemplateColumns="1fr"
            title="Audio Tracks"
            withBorder
          >
            {(asset as AmAmazonusAsset3DigitalImf)?.meta?.imf_virual_audio_tracks?.map((e, i) => {
              return (
                <InfoBoxV2
                  title={`Audio Track #${i + 1}`}
                  padded={false}
                  gridTemplateColumns="200px 1fr 200px 1fr 200px 1fr"
                  className="imf-item"
                  key={i}
                  withBorder
                >
                  <Row label="Dolby Vision Version" value={e.dolby_vision_version || '[n/a]'} />
                  <Row label="Language" value={parseLanguages(e.language_id) || '[n/a]'} />
                  <Row label="SoundField" rawLabel value={e.soundfield || '[n/a]'} />
                  <Row label="Channel Config" value={e.channel_configuration || '[n/a]'} />
                  <Row label="Sample Rate" value={e.sample_rate || '[n/a]'} />
                  <Row label="Bit Rate" rawLabel value={e.bit_rate || '[n/a]'} />
                </InfoBoxV2>
              );
            })}
          </InfoBoxV2>
          <InfoBoxV2
            className="product-metadata-tab__second-section"
            gridTemplateColumns="1fr"
            title="Timed Text Tracks"
            withBorder
          >
            {(asset as AmAmazonusAsset3DigitalImf)?.meta?.imf_virtual_text_tracks?.map((e, i) => {
              return (
                <InfoBoxV2
                  title={`Timed Text Track #${i + 1}`}
                  padded={false}
                  gridTemplateColumns="200px 1fr 200px 1fr 200px 1fr"
                  className="imf-item"
                  key={i}
                  withBorder
                >
                  <Row label="Language" value={parseLanguages(e.language_id) || '[n/a]'} />
                  <Row label="IMSC version" value={e.imsc_version || '[n/a]'} />
                  <Row label="configuration Experience" value={e.configuration || '[n/a]'} />
                </InfoBoxV2>
              );
            })}
          </InfoBoxV2>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export const AssetMetadataIdentifiersSection = withAssetVersion(
  AssetOldMetadataIdentifiersSection,
  AssetMm3MetadataIdentifiersSection,
) as React.FC<{ asset: IMm3Asset | IAsset }>;
