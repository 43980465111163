import React from 'react';
import { FormNumericInput, FormSelect } from 'helpers/form/fields';
import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';

import { AmAmazonusAsset3DigitalImf } from '@mediafellows/mm3-types';
import { useBasicStore } from 'store/hooks';
import { InfoBoxV2 } from 'components/info-box-v2';

export const FormVideoTrack1: React.FC<
  { name: string; index: number } & IUseMm3FormReturn<AmAmazonusAsset3DigitalImf>
> = ({ handlers, name, formData, index }) => {
  return (
    <InfoBoxV2 title={`Virtual Track #${index + 1}`} padded={false} gridTemplateColumns="1fr 1fr 1fr">
      <FormSelect
        name={`${name}.configuration`}
        key="authority"
        label="Configuration Experience"
        {...handlers}
        {...formData?.meta?.imf_virtual_video_tracks?.[index]?.configuration}
      />
      <FormSelect
        name={`${name}.bit_rate`}
        key="bit_rate"
        label="Video Bit Depth"
        {...handlers}
        {...formData.meta?.imf_virtual_video_tracks?.[index]?.bit_rate}
        large
      />
      <FormSelect
        name={`${name}.color_space`}
        key="color_space"
        label="Color Encode Primaries"
        {...handlers}
        {...formData.meta?.imf_virtual_video_tracks?.[index]?.color_space}
      />
      <FormSelect
        name={`${name}.mastering_display_primary`}
        key="mastering_display_primary"
        label="Mastering Display Primary"
        {...handlers}
        {...formData.meta?.imf_virtual_video_tracks?.[index]?.mastering_display_primary}
      />
      <FormSelect
        name={`${name}.transfer_characteristics`}
        key="transfer_characteristics"
        label="Transfer Characteristics"
        {...handlers}
        {...formData.meta?.imf_virtual_video_tracks?.[index]?.transfer_characteristics}
      />
      <FormSelect
        name={`${name}.container_format`}
        key="container_format"
        label="Container Format"
        {...handlers}
        {...formData.meta?.imf_virtual_video_tracks?.[index]?.container_format}
      />
      <FormNumericInput
        name={`${name}.width`}
        key="width"
        label="Width"
        {...handlers}
        {...formData.meta?.imf_virtual_video_tracks?.[index]?.width}
      />
      <FormNumericInput
        name={`${name}.height`}
        key="height"
        label="Height"
        {...handlers}
        {...formData.meta?.imf_virtual_video_tracks?.[index]?.height}
      />
    </InfoBoxV2>
  );
};

export const FormVideoTrack2: React.FC<
  { name: string; index: number } & IUseMm3FormReturn<AmAmazonusAsset3DigitalImf>
> = ({ handlers, name, formData, index }) => {
  const { languagesDataOptions } = useBasicStore();

  return (
    <InfoBoxV2 title={`Audio Track #${index + 1}`} padded={false} gridTemplateColumns="1fr 1fr 1fr">
      <FormSelect
        name={`${name}.dolby_vision_version`}
        key="authority"
        label="Dolby Vision Version"
        {...handlers}
        {...formData?.meta?.imf_virual_audio_tracks?.[index]?.dolby_vision_version}
      />
      <FormSelect
        name={`${name}.language_id`}
        key="language_id"
        label="Language"
        {...handlers}
        {...formData?.meta?.imf_virual_audio_tracks?.[index]?.language_id}
        options={languagesDataOptions}
      />
      <FormSelect
        name={`${name}.soundfield`}
        key="color_space"
        label="SoundField"
        {...handlers}
        {...formData?.meta?.imf_virual_audio_tracks?.[index]?.soundfield}
      />
      <FormSelect
        name={`${name}.channel_configuration`}
        key="channel_configuration"
        label="Channel Config"
        {...handlers}
        {...formData?.meta?.imf_virual_audio_tracks?.[index]?.channel_configuration}
      />
      <FormSelect
        name={`${name}.sample_rate`}
        key="sample_rate"
        label="Sample Rate"
        {...handlers}
        {...formData?.meta?.imf_virual_audio_tracks?.[index]?.sample_rate}
      />
      <FormSelect
        name={`${name}.bit_rate`}
        key="bit_rate"
        label="Bit Rate"
        {...handlers}
        {...formData.meta?.imf_virual_audio_tracks?.[index]?.bit_rate}
        large
      />
    </InfoBoxV2>
  );
};

export const TimedTextTrack: React.FC<
  { name: string; index: number } & IUseMm3FormReturn<AmAmazonusAsset3DigitalImf>
> = ({ handlers, name, formData, index }) => {
  const { languagesDataOptions } = useBasicStore();

  return (
    <InfoBoxV2 title={`Timed Text Track #${index + 1}`} padded={false} gridTemplateColumns="1fr 1fr 1fr">
      <FormSelect
        name={`${name}.language_id`}
        key="language_id"
        label="Language"
        {...handlers}
        {...formData.meta?.imf_virtual_text_tracks?.[index]?.language_id}
        options={languagesDataOptions}
      />
      <FormSelect
        name={`${name}.imsc_version`}
        key="imsc_version"
        label="IMSC version"
        {...handlers}
        {...formData?.meta?.imf_virtual_text_tracks?.[index]?.imsc_version}
      />
      <FormSelect
        name={`${name}.configuration`}
        key="configuration"
        label="configuration Experience"
        {...handlers}
        {...formData.meta?.imf_virtual_text_tracks?.[index]?.configuration}
      />
    </InfoBoxV2>
  );
};
