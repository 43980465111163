import React, { useCallback } from 'react';

import { useStore } from 'store';
import EditSubtitleDialogBody, { IEditSubtitleProps } from './edit-subtitle-body';
import { IModalSize } from 'components/dialogs/types';

export const useEditSubtitleDialog = (props: IEditSubtitleProps): [() => void] => {
  const { onSubmit, subtitle } = props;
  const { dialogStore } = useStore();

  const body = useCallback(() => {
    return <EditSubtitleDialogBody onSubmit={onSubmit} subtitle={subtitle} />;
  }, [onSubmit, subtitle]);

  const openDialog = (): void => {
    dialogStore.openModal({
      title: 'Edit subtitle',
      body,
      size: IModalSize.XS,
    });
  };

  return [openDialog];
};
