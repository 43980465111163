import React from 'react';
import { observer } from 'mobx-react-lite';

import { FilterCheckbox, FilterSelect, FilterText } from 'helpers/filters/fields';
import { IFiltersHandlers } from 'helpers/filters';
import FilterButtonGroup from 'helpers/filters/fields/filter-button-group/filter-button-group';
import {
  ContextDataProvider,
  queryDataExecutorCache,
  QueryDataProvider,
} from 'helpers/data-provider/option-data-provider';

import { IAsset, ILanguage } from 'types';
import { DataSectionStore } from 'store/data-section-store';

import {
  IAssetFilters,
  classificationsOptionsProvider,
  fileStatusOptionsProvider,
} from 'components/asset/asset-filters/utils';
import { formatAssetClassification, getAssetModel } from 'utils/asset';
import { formatType } from 'utils/format-type';
import { getIsMm3Assets } from 'utils/asset';

import { AdditionalFiltersCmp } from 'components/asset/asset-filters/additional-filters';
import { AssetParentFilter } from 'components/asset/asset-filters/parent-filters';
import { queryLanguages } from 'utils/apis/language';
import { IResult } from '@mediafellows/chipmunk';

interface IAccessFiltersProps {
  dataSectionStore: DataSectionStore<IAsset>;
  filterHandlers: IFiltersHandlers<IAssetFilters>;
  filterValues: IAssetFilters;
  withMainAssetsFilter?: boolean | null;
  isSmartGroupFilters?: boolean;
}

const languageOptionsProvider = new QueryDataProvider(
  queryDataExecutorCache('um.language.search-cache', queryLanguages),
  (data: IResult<ILanguage>) => data.objects.map((l) => ({ value: l.id, label: l.name })),
);
const isMm3Assets = getIsMm3Assets();
const mainClassificationProvider = new ContextDataProvider(
  getAssetModel(),
  isMm3Assets ? 'main_classification' : 'main_classifications',
  false,
  formatAssetClassification,
);

export const typesOptionsProvider = new ContextDataProvider(getAssetModel(), 'type', false, formatType);

export const AssetEssentialFilters = observer(
  ({
    filterValues,
    filterHandlers,
    dataSectionStore,
    withMainAssetsFilter,
    isSmartGroupFilters,
  }: IAccessFiltersProps) => {
    const { searchStore } = dataSectionStore;
    const isMm3Assets = getIsMm3Assets();

    return (
      <>
        {!isSmartGroupFilters && (
          <FilterText
            label="Search List"
            placeholder="Search"
            name="_"
            filter={filterValues._}
            onChange={filterHandlers.onChange}
          />
        )}

        <AdditionalFiltersCmp filterHandlers={filterHandlers} filterValues={filterValues} />

        <FilterButtonGroup
          label="Type"
          name="main_classification"
          disabled={searchStore?.running}
          filter={filterValues.main_classification}
          optionsProvider={mainClassificationProvider}
          onChange={filterHandlers.onChange}
        />

        {isMm3Assets ? (
          <FilterCheckbox
            label="File Type"
            name="type"
            optionsProvider={typesOptionsProvider}
            filter={filterValues.type}
            onChange={filterHandlers.onChange}
            aggregations={searchStore.aggregationValues('type')}
          />
        ) : (
          <></>
        )}

        <AssetParentFilter
          filterHandlers={filterHandlers}
          filterValues={filterValues}
          withMainAssetsFilter={withMainAssetsFilter}
        />
        <FilterSelect
          label="Asset Type"
          name="classification"
          placeholder="Select Asset Type"
          optionsProvider={classificationsOptionsProvider}
          filter={filterValues.classification}
          onChange={filterHandlers.onChange}
          aggregations={searchStore.aggregationValues('classification')}
        />
        <FilterSelect
          label="File Status"
          name="file_status"
          placeholder="Select File Status"
          optionsProvider={fileStatusOptionsProvider}
          filter={filterValues.file_status}
          onChange={filterHandlers.onChange}
          aggregations={searchStore.aggregationValues('file_status')}
        />
        <FilterSelect
          label="Language"
          name="language_id"
          placeholder="Select Language"
          filter={filterValues.language_id}
          optionsProvider={languageOptionsProvider}
          onChange={filterHandlers.onChange}
          aggregations={searchStore.aggregationValues(isMm3Assets ? 'language_id' : 'language_ids')}
        />
      </>
    );
  },
);
