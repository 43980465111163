import { getProductsAncestryInfo } from 'utils/apis/product';
import { createdAtRange } from 'utils/date';
import { NotExistFilter } from 'helpers/filters/types';
import { IFilters } from 'components/product-filters';
import { InFilter, RangeFilter } from 'helpers/filters/types';
import groupBy from 'lodash/groupBy';
import { IParametronData } from '@mediafellows/parametron';

import { by, getValidIds } from 'utils/general';
import { queryProductAssets } from 'utils/apis/product-asset';
import { IProductActionName } from 'utils/actions/product/types';

export const mapQueryParamsToFilters = (queryParams: Record<keyof IFilters, string>): Partial<IFilters> => {
  return Object.entries(queryParams).reduce((acc, [key, value]) => {
    if (value && new Set(['type', 'access_level', 'default_layer.meta.category_ids', 'created_at']).has(key)) {
      if (key === 'type') {
        return {
          ...acc,
          [key]: new InFilter(key, [value]),
          no_parent: new NotExistFilter('parent_id', false),
        };
      }
      if (key === 'created_at' && createdAtRange[value]) {
        return { ...acc, [key]: new RangeFilter(key, ...createdAtRange[value]) };
      }

      return { ...acc, [key]: new InFilter(key, [value]) };
    }

    return acc;
  }, {});
};

export const actionsWithDelayedRefresh = [
  IProductActionName.REMOVE_FROM_GROUP,
  IProductActionName.DELETE,
  IProductActionName.RESTORE,
];

export const handleUpdate = async (data: IParametronData, productSearch): Promise<void> => {
  productSearch?.update(data);
  productSearch?.updateParamsAndFiltersCount(['include_deleted']);

  const product_ids = getValidIds(data.objects);

  if (product_ids.length > 0) {
    const assetsPromise = queryProductAssets(
      { product_ids },
      true,
      'id, product_id, asset { id, type, root }, classification',
    );

    const ancestryInfos = await getProductsAncestryInfo(product_ids);

    const objects = await assetsPromise;
    // only assets that the user have access to will have a truthy asset.id
    const assets = objects.filter((e) => e?.asset?.id);

    const assetsByProductId = groupBy(assets, 'product_id');
    const ancestryByProductId = by(ancestryInfos, 'product_id');

    data?.objects?.map((product) => {
      product.assets = assetsByProductId[product.id] || [];
      product.ancestry_info = ancestryByProductId[product.id];
    });

    productSearch?.update(data);
  }
};
