import React, { useCallback, useEffect } from 'react';
import { Button, Text } from '@mantine/core';

import { FormMultiSelect, FormSelect, useForm } from 'helpers/form';
import { FormSubmitSection } from 'components/form-submit-section';
import { ToastError } from 'components/toast';
import { useStore } from 'store';
import { queryAssets } from 'utils/apis/asset';
import { mediaBench } from 'utils/chipmunk';
import { Model } from 'helpers/filters/types';
import { exportAssets } from 'utils/apis/mediabench';
import { IExportToMB, IMBError } from 'types/mediabench';

import { FormCreateNewFolder } from 'components/form-import-to-mb/form-create-new-folder';
import { MantineIcon } from 'utils/ui/icon';
import { IModalSize } from 'components/dialogs/types';

export interface IFormSelectionItems {
  itemIds: number[];
}

type IExport = {
  isExported: boolean;
  link: string;
};

const customContext = { properties: { project_id: { required: true } } };

export const FormImportToMB: React.FC<IFormSelectionItems> = ({ itemIds }): JSX.Element => {
  const { toastStore, dialogStore } = useStore();
  const [exportData, setExportData] = React.useState<IExport>({ isExported: false, link: '' });

  const onConfirm = useCallback(
    async (data: IExportToMB) => {
      const response = await exportAssets({
        asset_ids: data.item_ids,
        project_id: data.project_id,
      });

      if (response.status === 'error') {
        const { error } = response as IMBError;
        toastStore.error(<ToastError error={error} />);
        return;
      }

      setExportData({ isExported: true, link: `${mediaBench}/projects/${data.project_id}` });
    },
    [toastStore],
  );
  const { formData, handlers, valid, values, onSubmit } = useForm<IExportToMB>(
    {
      projectsList: [],
      project_id: null,
      item_ids: itemIds || [],
    },
    Model.ASSETS,
    onConfirm,
    customContext,
  );

  const refreshProjectsList = useCallback(async () => {
    const response = (await exportAssets({
      asset_ids: itemIds,
      project_id: null,
    })) as IMBError;
    if (!response.error.session?.value.token) {
      toastStore.error(<ToastError error={response.error} />);
      return;
    }
    const projectsList = response.error.projects.map((elm) => {
      return {
        value: elm.id,
        label: elm.name,
      };
    });
    handlers.onChange({ projectsList });
  }, [handlers, itemIds, toastStore]);

  useEffect(() => {
    refreshProjectsList();
  }, [refreshProjectsList]);

  const body = useCallback((): JSX.Element => {
    return (
      <FormCreateNewFolder
        projectsList={values.projectsList}
        refreshProjectsList={refreshProjectsList}
        setSelectedProject={(project_id: number) => handlers.onChange({ project_id })}
      />
    );
  }, [refreshProjectsList, values.projectsList, handlers]);

  const handleCreateNewFolder = useCallback(() => {
    dialogStore.openModal({
      title: 'Create New Folder',
      body,
      size: IModalSize.XS,
    });
  }, [body, dialogStore]);

  const handleDestinationChange = (values): void => {
    const { project_id } = values;
    if (!project_id) {
      handlers.onChange({ project_id: null });
    } else {
      handlers.onChange({ project_id: Number(project_id) });
    }
  };

  if (exportData.isExported) {
    return (
      <>
        <Text className="mb-1">Files are being transferred to MediaBench</Text>
        <a
          className="d-flex align-items-center text-align-center "
          href={exportData.link}
          target="_blank"
          rel="noreferrer"
        >
          <Text className="m-0">Track progress</Text>
          <MantineIcon icon="arrow-right" className="mx-2" size={12} />
        </a>
      </>
    );
  }
  return (
    <>
      <form onSubmit={onSubmit}>
        <FormSelect
          name="project_id"
          label="Asset Destination"
          placeholder="Select MediaBench Project"
          large
          required
          {...formData.project_id}
          {...handlers}
          options={values.projectsList}
          onChange={handleDestinationChange}
        />
        <Button
          leftSection={<MantineIcon icon="plus" />}
          onClick={handleCreateNewFolder}
          variant="outline"
          className="mb-2"
        >
          New Folder
        </Button>

        <FormMultiSelect
          fetchValues={queryAssets}
          name="item_ids"
          label="Assets"
          disabled
          {...formData['item_ids']}
          {...handlers}
          omni
        />
      </form>
      <FormSubmitSection submitDisabled={!valid} labels={{ confirm: 'Export' }} onSubmit={onSubmit} />
    </>
  );
};
