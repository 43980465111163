import React, { useEffect, useCallback } from 'react';
import { pick, isUndefined } from 'lodash';

import { getNotificationPreferences } from 'utils/apis/notification-preferences';
import { useRemote } from 'utils/hooks';
import { Checkbox, Group, Tooltip } from '@mantine/core';

const channels = [
  { label: 'Email', value: 'email_channel' },
  { label: 'Mobile app notification', value: 'mobile_channel' },
  { label: 'SMS text message', value: 'sms_channel' },
];

const getTruthyPreferences = (preferences): string[] => {
  return Object.entries(preferences)
    .filter(([, value]) => value === true)
    .map(([key]) => key);
};
export const MeetingNotifications = ({ formData, handlers }): JSX.Element => {
  const fetchPreferences = useCallback(() => {
    return getNotificationPreferences();
  }, []);

  const [data = {}] = useRemote(fetchPreferences);

  useEffect(() => {
    if (data && isUndefined(formData?.notification_preferences?.value.id)) {
      const preferences = pick(data, ['id', 'mobile_channel', 'email_channel', 'sms_channel']);
      handlers.onChange({ notification_preferences: preferences });
    }
  }, [data, handlers, formData?.notification_preferences?.value.id]);

  const smsTooltipLabel = `MediaStore meeting check-in notifications:
    MediaStore will send one message per check-in.
    Contact support@mediafellows.com for support.
    Untick your message preference or text STOP to opt-out of future messages.
    Message and data rates may apply.
    For more information, visit Privacy Policy and Terms & Conditions.`;

  const handlePreferenceChange = useCallback(
    async (newValue: string[]) => {
      const channelsToUpdate = channels.reduce((acc, channel) => {
        if (!newValue) return acc;
        return {
          ...acc,
          [channel.value]: newValue.some((e) => e === channel.value),
        };
      }, {});

      handlers.onChange({
        notification_preferences: {
          id: formData?.notification_preferences?.value.id,
          ...channelsToUpdate,
        },
      });
    },
    [handlers, formData?.notification_preferences?.value.id],
  );

  return (
    <Checkbox.Group
      value={getTruthyPreferences(formData?.notification_preferences?.value ?? {})}
      onChange={handlePreferenceChange}
      label="Meeting Notifications Preferences"
    >
      <Group>
        {channels.map((channel) =>
          channel.value === 'sms_channel' ? (
            <Tooltip key={channel.value} label={smsTooltipLabel} position="top" multiline refProp="rootRef" w={300}>
              <Checkbox size="sm" value={channel.value} label={channel.label} key={channel.value} />
            </Tooltip>
          ) : (
            <Checkbox size="sm" value={channel.value} label={channel.label} key={channel.value} />
          ),
        )}
      </Group>
    </Checkbox.Group>
  );
};

export default MeetingNotifications;
