import React, { useCallback } from 'react';
import { AmGenericAsset3ProtectionOptions } from '@mediafellows/mm3-types';
import { observer } from 'mobx-react-lite';
import get from 'lodash/get';
import startCase from 'lodash/startCase';

import { InfoBoxV2 } from 'components/info-box-v2';
import { Row } from 'components/label-value-pair';
import ScrollWrapper from 'components/scroll-wrapper';
import { WatermarkEditor } from 'components/watermark-editor';

import { FormCountries, FormSelect } from 'helpers/form';
import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';

import { useCountryNames } from 'utils/hooks';
import { DetailsPageTabs, IMm3Asset } from 'types';
import { AssetVideoPlayer } from 'components/common';
import { useBasicStore } from 'store/hooks';
import { getEditablePowerTabs } from 'utils/general';
import { editableTabsWithPowers } from 'pages/asset-details/utils';
import { EnabledField } from './enabled-field';

import './styles.scss';

export const AssetProtectionsTab: React.FC<{
  protections?: AmGenericAsset3ProtectionOptions;
  editModeEnabled: boolean;
  form: IUseMm3FormReturn<AmGenericAsset3ProtectionOptions | undefined>;
  asset: IMm3Asset;
}> = observer(({ asset, editModeEnabled, form }) => {
  const { handlers, formData, values } = form;
  const { affiliationProtections } = useBasicStore();
  const editableTabs = getEditablePowerTabs(editableTabsWithPowers);
  const enableEditMode = editModeEnabled && editableTabs.includes(DetailsPageTabs.PROTECTIONS);
  const countries = useCountryNames(values?.meta?.geo_ip?.country_ids || []);
  const defaultCountries = useCountryNames(affiliationProtections?.meta?.geo_ip?.country_ids || []);

  const handleDrmChange = useCallback(
    (value) => {
      handlers.onChange({
        ...value,
        ...(value['meta.drm.enabled'] ? {} : { ['meta.drm.HDCP_enabled']: false }),
      });
    },
    [handlers],
  );

  const initialWatermarks = get(values, 'meta.visible_watermark.enabled');

  const handleVisibleWatermarkChange = useCallback(
    (value) => {
      handlers.onChange({
        ...value,
        ['meta.visible_watermark.watermarks']: initialWatermarks
          ? []
          : get(affiliationProtections, 'meta.visible_watermark.watermarks'),
      });
    },
    [affiliationProtections, initialWatermarks, handlers],
  );

  const handleGeoIpChange = useCallback(
    (values) => {
      handlers.onChange({
        ...values,
        ...(values['meta.geo_ip.enabled']
          ? {}
          : {
              ['meta.geo_ip.list_type']: null,
              ['meta.geo_ip.country_ids']: [],
            }),
      });
    },
    [handlers],
  );

  return (
    <ScrollWrapper className="asset-details-protections">
      <InfoBoxV2 gridTemplateColumns="minmax(135px, 1fr) 2fr" className="gap-2" title="Protections">
        <Row
          label=""
          value={
            <div className="d-flex gap-2">
              <div className="asset-details-protections__default-label">Default</div>
              <div>Asset</div>
            </div>
          }
        />
        <Row
          label="DRM"
          value={
            <EnabledField
              name="meta.drm.enabled"
              {...form}
              handlers={{ ...handlers, onChange: handleDrmChange }}
              editModeEnabled={enableEditMode}
            />
          }
        />
        <Row
          label="HDCP"
          labelClassName="ms-3"
          hidden={
            !(
              values?.meta?.drm?.enabled ||
              (values?.meta?.drm?.enabled === null && affiliationProtections?.meta?.drm?.enabled)
            )
          }
          value={
            <EnabledField
              name="meta.drm.HDCP_enabled"
              {...form}
              editModeEnabled={Boolean(enableEditMode && values?.meta?.drm?.enabled)}
              disabled={!values?.meta?.drm?.enabled}
              noDefault={values?.meta?.drm?.enabled !== null}
            />
          }
        />

        <Row
          label="Casting prevention"
          value={
            <EnabledField name="meta.casting_prevention.enabled" {...form} editModeEnabled={Boolean(enableEditMode)} />
          }
        />

        <Row
          label="Forensic Watermark"
          value={
            <EnabledField name="meta.forensic_watermark.enabled" {...form} editModeEnabled={Boolean(enableEditMode)} />
          }
        />
        <Row
          label="Visible Watermark"
          value={
            <EnabledField
              name="meta.visible_watermark.enabled"
              {...form}
              handlers={{ ...handlers, onChange: handleVisibleWatermarkChange }}
              editModeEnabled={enableEditMode}
            />
          }
        />
        <Row
          label="GeoIP"
          value={
            <EnabledField
              name="meta.geo_ip.enabled"
              {...form}
              handlers={{ ...handlers, onChange: handleGeoIpChange }}
              editModeEnabled={enableEditMode}
            />
          }
          rawLabel
        />
        {!(
          values?.meta?.geo_ip?.enabled ||
          (affiliationProtections?.meta?.geo_ip?.enabled && values?.meta?.geo_ip?.enabled === null)
        ) ? (
          <></>
        ) : enableEditMode && values?.meta?.geo_ip?.enabled ? (
          <>
            <FormSelect
              name="meta.geo_ip.list_type"
              key="geo_ip.list_type"
              label="GeoIP List Type"
              {...formData?.meta?.geo_ip?.list_type}
              {...handlers}
              className="asset-details-tab__full-grid-width muted-text ms-3"
              formatLabel={startCase}
            />
            <FormCountries
              name="meta.geo_ip.country_ids"
              key="geo_ip.country_ids"
              label="GeoIP Countries"
              {...formData?.meta?.geo_ip?.country_ids}
              {...handlers}
              className="asset-details-tab__country-ids muted-text ms-3"
            />
          </>
        ) : (
          <>
            <Row
              labelClassName="ms-3"
              label="GeoIP List Type"
              value={startCase(
                (values?.meta?.geo_ip?.enabled === null
                  ? affiliationProtections?.meta?.geo_ip?.list_type
                  : values?.meta?.geo_ip?.list_type) || '',
              )}
            />
            <Row
              labelClassName="ms-3"
              label="GeoIP Countries"
              value={values?.meta?.geo_ip?.enabled === null ? defaultCountries : countries}
            />
          </>
        )}
      </InfoBoxV2>

      {enableEditMode && values?.meta?.visible_watermark?.enabled ? (
        <WatermarkEditor
          name="meta.visible_watermark.watermarks"
          value={values?.meta?.visible_watermark?.watermarks}
          {...handlers}
          video={asset}
        />
      ) : (
        <AssetVideoPlayer video={asset} />
      )}
    </ScrollWrapper>
  );
});
