import { Model } from 'helpers/filters/types';
import { getIsMm3Assets } from 'utils/asset';
import { chipmunk } from 'utils/chipmunk';

export interface ITranslateProps {
  id: number;
  source_language_id: string;
  target_language_id: string;
  transcribe_service_name: string;
  translate_service_name: string;
}

export const generateSubtitleMm3 = async (data: ITranslateProps): Promise<ITranslateProps> => {
  const { object } = await chipmunk.run(({ action }) => {
    return action(Model.MM3_AUDIO, 'generate_subtitles', {
      params: { id: data.id },
    });
  });

  return object;
};

export const generateSubtitle = async (params: ITranslateProps): Promise<ITranslateProps> => {
  const isMm3Assets = getIsMm3Assets();
  if (isMm3Assets) {
    return generateSubtitleMm3(params);
  }

  return chipmunk.run(async ({ action }) => {
    const { object } = await action(Model.ASSETS, 'generate_subtitles', { params });

    return object;
  });
};
