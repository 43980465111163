import { ILocalizationOrder } from 'types/localization-order';
import { IUseAction } from 'utils/actions';

import { ILocalizationOrderActionName } from './types';
import { shouldDisplayEdit as shouldDisplay } from './actions-acl';
import { useLocalizationOrderDialog } from 'components/localization-orders/use-order-dialog';

export const useEditAction: IUseAction<ILocalizationOrder, ILocalizationOrderActionName> = (items, options) => {
  const [order] = items;
  const [handler] = useLocalizationOrderDialog({
    order: order,
    tab: options?.tab as unknown as number,
    options,
  });

  return {
    name: ILocalizationOrderActionName.EDIT,
    icon: 'edit',
    shouldDisplay,
    title: 'Edit',
    handler,
  };
};
