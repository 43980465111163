import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { AssetsUploaderStore } from 'store/assets-upload-store';
import { useStore } from 'store';

import { AssetUploadFormHeader } from 'components/asset/asset-upload-header/asset-upload-header';
import { AssetsFormFooter } from 'components/upload-asset-dialog/components/upload-assets-form-footer';
import { AssetsUploadDashboard } from 'components/asset/asset-upload-components/upload-dashboard/upload-dashboard';
import { AssetForm } from 'components/asset/asset-upload-components/single-asset-form/single-asset-form';
import SaveAssetPresetForm from 'components/asset/asset-upload-components/save-preset-modal/save-preset-modal';
import { SelectAssetPreset } from 'components/asset/asset-upload-components/select-preset-modal/select-preset-modal';
import { IModalSize } from 'components/dialogs/types';

import { getStorageItem, STORAGE_KEYS } from 'utils/storage';
import { useInitiateProduct } from './hooks';

import { IAssetFile, IAssetPresetFields, IUploadedFile } from 'types';

import '@uppy/core/dist/style.css';
import '@uppy/drag-drop/dist/style.css';

interface IUploadAssetsFormCallback {
  isValid: boolean;
  files: IAssetFile[];
  filesRemote: IUploadedFile[];
  showErrors: () => void;
  isSending: boolean;
  setIsSending: (isSending: boolean) => void;
}

export interface IUploadAssetsForm {
  onSubmit: (props: IUploadAssetsFormCallback) => Promise<void>;
  productId?: string;
  withParentField?: boolean;
  assetsUploadStore: AssetsUploaderStore;
  extensions?: string[] | null;
}

const createInitialValues = (file: IAssetFile, productId?: string): IAssetFile => {
  if (productId && !file.products?.includes(productId)) {
    return { ...file, products: [...file.products, productId] };
  }
  return file;
};
export const UploaderAssetsForm: React.FC<IUploadAssetsForm> = ({
  onSubmit,
  productId,
  withParentField,
  assetsUploadStore,
  extensions,
}) => {
  const {
    files,
    initializeUppy,
    isValid,
    filesRemote,
    showErrors,
    uppy,
    removeFile,
    savePreset,
    progress,
    applyPreset,
    setIsValidForm,
    onDataChange,
    shouldShowErrors,
    cleanUp,
    isSending,
    setIsSending,
    setFiles,
    dropFileAreaSelector,
  } = assetsUploadStore;
  const { dialogStore } = useStore();
  const [presets, setPresets] = useState<Record<string, IAssetPresetFields>>(
    getStorageItem(STORAGE_KEYS.ASSET_UPLOAD_PRESETS) as Record<string, IAssetPresetFields>,
  );

  useInitiateProduct({ files, onDataChange, productId });

  const openSavePresetModal = useCallback(
    (preset: IAssetPresetFields) => {
      savePreset(preset);
      dialogStore.openModal({
        title: `Save as preset`,
        body: () => <SaveAssetPresetForm preset={preset} setPresets={setPresets} />,
        size: IModalSize.XS,
      });
    },
    [dialogStore, savePreset],
  );

  const openPresetSelector = useCallback(() => {
    dialogStore.openModal({
      title: 'Select Preset',
      body: () => (
        <SelectAssetPreset {...{ presets, shouldShowErrors, applyPreset }} assetsUploaderStore={assetsUploadStore} />
      ),
      className: 'asset-upload-components__modal-wrapper',
      size: IModalSize.M,
    });
  }, [applyPreset, assetsUploadStore, dialogStore, presets, shouldShowErrors]);

  const handleFormSubmit = useCallback(async () => {
    await onSubmit({ files, isValid, filesRemote, showErrors, isSending, setIsSending });
    cleanUp();
  }, [cleanUp, files, filesRemote, isSending, isValid, onSubmit, setIsSending, showErrors]);

  const handleCancel = useCallback(() => {
    cleanUp();
    dialogStore.close();
  }, [cleanUp, dialogStore]);

  useEffect(() => {
    return initializeUppy(extensions);
  }, [extensions, initializeUppy]);

  return (
    <div className="form-upload-asset__wrapper position-relative">
      <div className="upload-asset-dialog__dashboard d-flex flex-column">
        <AssetsUploadDashboard {...{ progress, removeFile, files, uppy }} dropAreaId={dropFileAreaSelector} />
      </div>
      <div className="form-upload-asset__form-wrapper d-flex flex-column">
        <AssetUploadFormHeader openPresetModal={openPresetSelector} files={files} setFiles={setFiles} />
        <div className="form-upload-asset__form">
          {files.map((file, index) => {
            return (
              <AssetForm
                key={file.id}
                initialValues={createInitialValues(file, productId)}
                assetsUploaderStore={assetsUploadStore}
                {...{
                  index,
                  withParentField,
                  savePreset: openSavePresetModal,
                  applyPreset,
                  setIsValidForm,
                  removeFile,
                  onDataChange,
                  shouldShowErrors,
                }}
              />
            );
          })}
        </div>
        <AssetsFormFooter onSubmit={handleFormSubmit} onCancel={handleCancel} assetsUploaderStore={assetsUploadStore} />
      </div>
    </div>
  );
};

export const UploadAssetsForm = observer(UploaderAssetsForm);
