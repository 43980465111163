import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { IObservableArray } from 'mobx';
import { IObject } from '@mediafellows/chipmunk/dist/src/action';

import { FilterSelect, FilterText, FilterTree } from 'helpers/filters/fields';
import { IFiltersHandlers, organizationOptionsProvider } from 'helpers/filters';
import {
  QueryDataProvider,
  StaticDataProvider,
  dynamicDataExecutorCache,
  queryDataExecutorCache,
} from 'helpers/data-provider/option-data-provider';
import { Model } from 'helpers/filters/types';
import { DynamicTreeDataProvider, unflattenTree } from 'helpers/data-provider/tree-data-provider';
import { useFetchFieldOptions } from 'helpers/form';

import { IContact, IDivision } from 'types';
import { DataSectionStore } from 'store/data-section-store';
import { useStore } from 'store/index';

import { AdditionalFiltersCmp } from 'components/contact-filters/additional-filters';
import { IContactFilters } from 'components/contact-filters/contact-filters';

import { IResult, chipmunk } from 'utils/chipmunk';
import { fetchRoles } from 'utils/apis/role';
import { fetchResponsibleContacts } from 'utils/apis/contacts';
import { getContactName } from 'utils/ui';

interface IAccessFiltersProps {
  filterValues: IContactFilters;
  filterHandlers: IFiltersHandlers<IContactFilters>;
  divisions: IObservableArray<IDivision>;
  dataSectionStore: DataSectionStore<IObject>;
  isSmartGroupFilters?: boolean;
}

const divisionTreeProvider = new DynamicTreeDataProvider(
  dynamicDataExecutorCache('divisions', () => chipmunk.run(({ action }) => action(Model.DIVISIONS, 'query'))),
  (data: IResult<IDivision>) =>
    unflattenTree(data.objects.map((d) => ({ label: d.name, value: d.id, ancestry: d.ancestry || '' }))),
);
const responsibleContactOptionsProvider = new QueryDataProvider(
  queryDataExecutorCache('um.user.search.resp.contacts', async (query: string) => {
    const objects = await fetchResponsibleContacts(query);
    return { objects, object: objects[0] };
  }),
  (result: IResult) => result.objects.map((l: IContact) => ({ value: l.id, label: getContactName(l) })),
);

export const ContactEssentialFilters = observer(
  ({ filterValues, filterHandlers, divisions, dataSectionStore, isSmartGroupFilters }: IAccessFiltersProps) => {
    const { searchStore } = dataSectionStore;
    const {
      basicStore: { countriesDataOptions },
    } = useStore();
    const countryOptionsProvider = useMemo(() => new StaticDataProvider(countriesDataOptions), [countriesDataOptions]);
    const roleOptions = useFetchFieldOptions(fetchRoles);
    const rolesOptionsProvider = useMemo(() => new StaticDataProvider(roleOptions), [roleOptions]);

    return (
      <>
        {!isSmartGroupFilters && (
          <FilterText
            label="Search List"
            placeholder="Search"
            name="_"
            filter={filterValues._}
            onChange={filterHandlers.onChange}
          />
        )}

        <AdditionalFiltersCmp filterValues={filterValues} filterHandlers={filterHandlers} />
        <FilterSelect
          label="Platform Role"
          name="role_id"
          placeholder="Select Platform Role"
          optionsProvider={rolesOptionsProvider}
          filter={filterValues.role_id}
          onChange={filterHandlers.onChange}
        />

        <FilterSelect
          label="Responsible Contact"
          name="responsible_user_id"
          placeholder="Select Responsible Contact"
          optionsProvider={responsibleContactOptionsProvider}
          filter={filterValues.responsible_user_id}
          onChange={filterHandlers.onChange}
        />
        <FilterSelect
          label="Country"
          name="country_id"
          placeholder="Select Country"
          optionsProvider={countryOptionsProvider}
          filter={filterValues.country_id}
          onChange={filterHandlers.onChange}
        />
        <FilterSelect
          label="Organization"
          name="organization_id"
          placeholder="Select Organization"
          optionsProvider={organizationOptionsProvider}
          filter={filterValues.organization_id}
          onChange={filterHandlers.onChange}
        />
        {!!divisions.length && (
          <FilterTree
            label="Divisions"
            name="division_ids"
            optionsProvider={divisionTreeProvider}
            filter={filterValues.division_ids}
            onChange={filterHandlers.onChange}
            aggregations={searchStore.aggregationValues('division_ids')}
          />
        )}
      </>
    );
  },
);
