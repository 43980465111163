import React from 'react';
import { observer } from 'mobx-react-lite';
import { IObservableArray } from 'mobx';

import { FilterCheckbox, FilterSelect, FilterTree } from 'helpers/filters/fields';
import { IFiltersHandlers, organizationOptionsProvider, ownersOptionsProvider } from 'helpers/filters';

import { IAsset, IDivision } from 'types';
import { DataSectionStore } from 'store/data-section-store';

import { IAssetFilters, accessOptionsProvider, divisionTreeProvider } from 'components/asset/asset-filters/utils';

interface IAccessFiltersProps {
  dataSectionStore: DataSectionStore<IAsset>;
  filterHandlers: IFiltersHandlers<IAssetFilters>;
  filterValues: IAssetFilters;
  divisions: IObservableArray<IDivision>;
}

export const AssetAccessAndOwnershipFilters = observer(
  ({ filterValues, filterHandlers, dataSectionStore, divisions }: IAccessFiltersProps) => {
    const { searchStore } = dataSectionStore;

    return (
      <>
        <FilterCheckbox
          label="Access Level"
          name="access_level"
          optionsProvider={accessOptionsProvider}
          filter={filterValues.access_level}
          onChange={filterHandlers.onChange}
          disabled={searchStore.running}
          aggregations={searchStore.aggregationValues('access_level')}
        />
        {!!divisions.length && (
          <FilterTree
            label="Divisions"
            name="division_ids"
            optionsProvider={divisionTreeProvider}
            filter={filterValues.division_ids}
            onChange={filterHandlers.onChange}
            aggregations={searchStore.aggregationValues('division_ids')}
          />
        )}
        <FilterSelect
          label="Owner"
          name="owner_id"
          placeholder="Select Owner"
          optionsProvider={ownersOptionsProvider}
          filter={filterValues.owner_id}
          onChange={filterHandlers.onChange}
        />
        <FilterSelect
          label="Organization"
          name="owner_organization_id"
          placeholder="Select Organization"
          optionsProvider={organizationOptionsProvider}
          filter={filterValues.owner_organization_id}
          onChange={filterHandlers.onChange}
        />
      </>
    );
  },
);
