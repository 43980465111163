import React, { useCallback } from 'react';
import { useStore } from 'store';
import { observer } from 'mobx-react-lite';
import { IActionOpts, IResult } from '@mediafellows/chipmunk';
import { DeliveriesGenericDeliveryPackage } from '@mediafellows/mm3-types';

import { ISearchFilter } from 'types';
import { EqFilter, Model, QFilter, RangeFilter } from 'helpers/filters/types';
import { DataSection } from 'components/data-section';
import { IDataSectionDefaultParams } from 'components/data-section/data-section';
import { OrderFilters, IOrderFilters } from 'components/order-filters';
import { Pages } from 'utils/actions/types';
import { IUseActionsOptionParam } from 'utils/actions';
import useControlDataSection from 'utils/hooks/control-data-section';
import { localizationOrderSchema } from 'utils/schemas';
import { OrderListItem } from 'components/order-list-item';
import { OrderPreviewTab } from 'components/order-preview-tab';
import { customSortMenu } from './custom-sort-menu';
import { ActionsMenu } from 'components/action/utils';
import { LocalizationOrderActionsList } from 'components/action';

import { ILocalizationOrderActionName } from 'utils/actions/localization-order';
import { useRefreshDataSection } from 'utils/hooks';

import './style.scss';

const defaultFilters: IOrderFilters = {
  _: new QFilter(''),
  status: new EqFilter('status', ''),
  sent_at: new RangeFilter('sent_at', '', ''),
  created_at: new RangeFilter('created_at', '', ''),
  owner_organization_id: new EqFilter('owner_organization_id', ''),
};

const sidebarTabs = {
  preview: <OrderPreviewTab />,
  filters: <OrderFilters />,
};

interface IOrderDataSectionProps {
  persistentFilters?: ISearchFilter[];
  showParentsOnlyFilter?: boolean;
  parentFilterDefaultValue?: boolean;
  page: Pages;
  actionOptions?: Partial<IUseActionsOptionParam>;
  actionName?: string;
  params?: Record<string, unknown>;
  executor?: (options: IActionOpts) => Promise<IResult<DeliveriesGenericDeliveryPackage>>;
  defaultParams?: IDataSectionDefaultParams;
  headerRightSection?: React.ReactElement;
  persistFilters?: boolean;
}

export const OrdersDataSection: React.FC<IOrderDataSectionProps> = observer((props) => {
  const {
    persistentFilters,
    page = Pages.ORDERS,
    actionName = 'search',
    params,
    executor,
    defaultParams,
    actionOptions,
    headerRightSection,
    persistFilters,
  } = props;
  const refreshDataSection = useRefreshDataSection();
  const {
    dataSectionStore: { checked },
  } = useStore();

  useControlDataSection({
    executor,
    schema: localizationOrderSchema,
    stats: 'status,owner_id,owner_organization_id',
    defaultFilters,
    actionName,
    defaultParams,
    params,
    model: Model.LOCALIZATION_ORDERS_SERIES,
    persistFilters,
    filters: persistentFilters,
  });

  const onSuccess = useCallback(
    async (action?: ILocalizationOrderActionName): Promise<void> => {
      if (action) {
        setTimeout(refreshDataSection, 1000);
      } else {
        refreshDataSection();
      }
    },
    [refreshDataSection],
  );

  const handleItemRenderer = (order): React.ReactElement => {
    return (
      <OrderListItem
        key={order.id}
        order={order}
        contextMenu={
          <ActionsMenu
            component={LocalizationOrderActionsList}
            items={[order]}
            options={{ ...(actionOptions || {}), context: 'single', page, onSuccess }}
          />
        }
      />
    );
  };

  return (
    <DataSection
      className="contacts__data-section"
      tabs={sidebarTabs}
      itemRenderer={handleItemRenderer}
      headerRightSection={headerRightSection}
      contextMenu={
        <ActionsMenu
          component={LocalizationOrderActionsList}
          items={checked}
          options={{ ...(actionOptions || {}), context: 'multi', page, onSuccess }}
        />
      }
      customSortMenu={customSortMenu}
    />
  );
});

export default OrdersDataSection;
