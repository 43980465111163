import React, { useCallback } from 'react';
import { Button } from '@mantine/core';
import { useDialogStore } from 'store/hooks';

import { FormMultiSelect, FormSelect, useFetchFieldOptions, useForm, FormOrganization } from 'helpers/form';
import { FormResponsibleContacts } from 'helpers/form/custom-fields';
import { Model } from 'helpers/filters/types';
import { IContact } from 'types';
import { useStore } from 'store';
import { updateContacts } from 'utils/apis/contacts';
import { fetchRoles } from 'utils/apis/role';
import { Classes } from 'utils/ui';

export interface IFormApproveItems {
  items: IContact[];
  onConfirm: (values: IApproveContactForm) => Promise<boolean>;
}

export type IApproveContactForm = Partial<
  Pick<IContact, 'id' | 'organization_id' | 'role_id' | 'responsible_user_id' | 'tags'>
>;

const customContext = {
  properties: {
    organization_id: { required: true },
    role_id: { required: true },
  },
};

const getInitialValues = (initialValues: IContact): IApproveContactForm => ({
  id: initialValues.id || undefined,
  organization_id: initialValues.organization_id || undefined,
  role_id: initialValues.role_id || undefined,
  responsible_user_id: initialValues.responsible_user_id || undefined,
  tags: initialValues.tags || undefined,
});

export const ApproveContact: React.FC<IFormApproveItems> = ({ onConfirm, items }) => {
  const { close } = useDialogStore();
  const { toastStore } = useStore();
  const roleOptions = useFetchFieldOptions(fetchRoles);
  const item = items[0];
  const initialValues = getInitialValues(item);

  const handleSubmit = useCallback(
    async (values: IApproveContactForm): Promise<void> => {
      try {
        await updateContacts([item.id], values);
        const doClose = await onConfirm(values);
        if (doClose) close();
      } catch (error) {
        toastStore.error(`Contact Update Failed: ${error.text}`);
      }
    },
    [close, item.id, onConfirm, toastStore],
  );

  const { formData, handlers, valid, onSubmit } = useForm<IApproveContactForm>(
    initialValues,
    Model.CONTACTS,
    handleSubmit,
    customContext,
  );
  return (
    <>
      <form onSubmit={onSubmit}>
        <FormOrganization
          name="organization_id"
          key="organization_id"
          label="Organization"
          {...formData.organization_id}
          {...handlers}
        />
        <FormSelect
          name="role_id"
          label="Platform Role"
          large
          {...formData.role_id}
          {...handlers}
          options={roleOptions}
        />
        <FormResponsibleContacts {...handlers} {...formData.responsible_user_id} />
        <FormMultiSelect
          name="tags"
          label="Tags"
          allowNewItems
          {...formData.tags}
          {...handlers}
          options={formData.tags?.value}
          noResults={null}
        />
      </form>
      <div className={Classes.DIALOG_FOOTER_ACTIONS}>
        <Button size="xs" mr="sm" variant="default" onClick={close}>
          Cancel
        </Button>
        <Button size="xs" variant="primary" type="submit" disabled={!valid} onClick={onSubmit}>
          Approve
        </Button>
      </div>
    </>
  );
};
