import React from 'react';

import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';
import { ScheduledJobList } from 'components/scheduled-job-list';
import { FormSubmitSection } from 'components/form-submit-section';
import { IScheduledJob, ItemId } from 'types';

interface IScheduledSharedForm {
  readOnly: boolean;
  renderDetails: (job: IScheduledJob) => JSX.Element | string;
  scheduledJobs: IScheduledJob[];
  removeJob: (ItemId: ItemId) => void;
  onSubmit: (event?: React.FormEvent<HTMLElement | HTMLFormElement> | undefined) => void;
  submitDisabled: boolean;
  title: string;
}
export const SharedScheduledForm: React.FC<IScheduledSharedForm> = ({
  readOnly,
  renderDetails,
  onSubmit,
  removeJob,
  scheduledJobs,
  submitDisabled,
  children,
  title,
}) => {
  return (
    <>
      <form onSubmit={onSubmit} className="access-change__tabs__form">
        {Boolean(scheduledJobs.length) && (
          <FormSectionWrapperV2 title={title} gridTemplateColumns="1fr" padded>
            <ScheduledJobList
              jobs={scheduledJobs}
              onRemove={removeJob}
              renderDetails={renderDetails}
              readOnly={readOnly}
            />
          </FormSectionWrapperV2>
        )}

        {!readOnly && children}
      </form>
      <FormSubmitSection
        submitDisabled={submitDisabled}
        labels={{ confirm: 'Schedule', cancel: 'Close' }}
        onSubmit={onSubmit}
        hidden={readOnly}
      />
    </>
  );
};
