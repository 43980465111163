import React from 'react';
import { observer } from 'mobx-react-lite';

import { IFiltersHandlers } from 'helpers/filters';

import { accessAndOwnershipFilters, getActiveFiltersCount, timestampsFilters } from 'components/product-filters/utils';
import { FilterAccordionItem } from 'components/filter-accordion-item/filter-accordion-item';
import { IFilters } from 'components/product-filters/types';
import { ProductAccessAndOwnershipFilters } from 'components/product-filters/product-access-ownership-filters';
import { ProductTimestampsFilters } from 'components/product-filters/product-timestamps-filters';

import { DataSectionStore } from 'store/data-section-store';
import { useBasicStore } from 'store/hooks';
import { IProduct } from 'types';

import './style.scss';
import { FILTER_SECTIONS } from 'utils/constants';
import { clearActiveFiltersSection } from 'utils/filters';

interface ISharedProductFilters<T = IFilters> {
  filterValues: T;
  filterHandlers: IFiltersHandlers<T>;
  dataSectionStore: DataSectionStore<IProduct>;
  activeAccordion: string | null;
}
export const SharedProductFilters: React.FC<ISharedProductFilters> = observer(
  ({ dataSectionStore, filterValues, filterHandlers, activeAccordion }) => {
    const { divisions } = useBasicStore();
    const activeAccesAndOwnershipFiltersCount = getActiveFiltersCount(filterValues, accessAndOwnershipFilters);
    const activeTimestampsFilters = getActiveFiltersCount(filterValues, timestampsFilters);

    return (
      <>
        <FilterAccordionItem
          value={FILTER_SECTIONS.ACCESS_OWNERSHIP}
          title={FILTER_SECTIONS.ACCESS_OWNERSHIP}
          activeAccordion={activeAccordion}
          activeFiltersCount={activeAccesAndOwnershipFiltersCount}
          onClearFilters={(e) => {
            e.stopPropagation();
            clearActiveFiltersSection(accessAndOwnershipFilters, filterHandlers.onChange);
          }}
        >
          <ProductAccessAndOwnershipFilters
            filterValues={filterValues}
            filterHandlers={filterHandlers}
            dataSectionStore={dataSectionStore}
            divisions={divisions}
          />
        </FilterAccordionItem>

        <FilterAccordionItem
          value={FILTER_SECTIONS.TIMESTAMPS}
          title={FILTER_SECTIONS.TIMESTAMPS}
          activeAccordion={activeAccordion}
          activeFiltersCount={activeTimestampsFilters}
          onClearFilters={(e) => {
            e.stopPropagation();
            clearActiveFiltersSection(timestampsFilters, filterHandlers.onChange);
          }}
          mb={20}
        >
          <ProductTimestampsFilters filterValues={filterValues} filterHandlers={filterHandlers} />
        </FilterAccordionItem>
      </>
    );
  },
);
