import React from 'react';
import { useDialogStore } from 'store/hooks';
import { CreateShellAssetForm } from './create-shell-asset';
import { IModalSize } from 'components/dialogs/types';
import { IAssetFormFields } from './create-shell-asset';

export const useCreateShellAsset = (
  initialValues: Partial<IAssetFormFields>,
  onSuccess: VoidFunction,
): [VoidFunction] => {
  const { openModal } = useDialogStore();

  const handlers: VoidFunction = () =>
    openModal({
      title: 'Create Asset without a file',
      body: () => <CreateShellAssetForm initialValues={initialValues} onSuccess={onSuccess} />,
      size: IModalSize.XL,
      fitInView: true,
    });

  return [handlers];
};
