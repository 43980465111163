import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip, useMantineColorScheme } from '@mantine/core';
import { getLoginToMBLink } from 'utils/apis/mediabench';
import { RoleAcl } from 'components/role-acl';
import { flags, mbImportAllowedRoles } from 'utils/flags';
import mbIcon from 'icons/mb-icon.svg';
import mbIconLight from 'icons/mb-icon-light-theme.svg';

export const MediaBenchLoginButton = (): JSX.Element => {
  const { colorScheme } = useMantineColorScheme();

  if (!flags.showMediaBenchFeature) {
    return <></>;
  }

  const mbLoginLink = getLoginToMBLink();
  const currentIcon = colorScheme === 'dark' ? mbIcon : mbIconLight;

  return (
    <RoleAcl allowedRoles={mbImportAllowedRoles}>
      <Tooltip label="Proceed to MediaBench" position={'bottom'}>
        <Link
          to={mbLoginLink}
          className="bp3-button bp3-minimal top-header__hotkeys me-1"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img width={16} height={16} src={currentIcon} alt="MediaBench Icon" className="top-header__icon-mb" />
        </Link>
      </Tooltip>
    </RoleAcl>
  );
};
