import React from 'react';
import { IContactFilters } from 'components/contact-filters/contact-filters';
import { IFiltersHandlers } from 'helpers/filters';

export const additionalFilters = {};

interface IAdditionalFiltersProps {
  filterValues: IContactFilters;
  filterHandlers: IFiltersHandlers<IContactFilters>;
  activeAccordion?: string | null;
}

export const AdditionalFiltersCmp: React.FC<IAdditionalFiltersProps> = () => <></>;

export const contactAccreditaionFilters = [];
export const AccreditaionFiltersCmp: React.FC<IAdditionalFiltersProps> = () => <></>;
