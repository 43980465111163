import { IconName } from 'blueprint5-icons';
import { IconAlias } from 'icons/icon-aliases';
import { AssetFilters } from 'utils/asset';
import { AssetType } from 'utils/format-asset-type';
import { formatAssetType } from 'utils/general';
import { formatMm3TypeTag } from 'utils/product';

const assetTypeToIcon = {
  [AssetFilters.IMAGE]: IconAlias.ASSET,
  [AssetFilters.VIDEO]: IconAlias.VIDEO,
  [AssetFilters.AUDIO]: IconAlias.AUDIO,
  [AssetFilters.DOCUMENT]: IconAlias.FILES,
  [AssetFilters.IMF]: IconAlias.BOX,
  [AssetType.IMAGE]: IconAlias.ASSET,
  [AssetType.VIDEO]: IconAlias.VIDEO,
  [AssetType.AUDIO]: IconAlias.AUDIO,
  [AssetType.DOCUMENT]: IconAlias.FILES,
  [AssetType.SUBTITLE]: IconAlias.FILES,
  [AssetType.OTHER]: IconAlias.ORDER,
  [AssetType.IMF]: IconAlias.BOX,
};

export const getFallbackIcon = (assetType: string, fallbackIconName?: IconName): IconName | undefined => {
  if (fallbackIconName) return fallbackIconName;
  if (assetTypeToIcon[assetType]) return assetTypeToIcon[assetType];
  const lowerCaseType = assetType.toLowerCase();

  if (lowerCaseType.includes('product')) return IconAlias.PRODUCT;
  if (lowerCaseType.includes('collection')) return IconAlias.COLLECTION;
  if (lowerCaseType.includes('user')) return IconAlias.CONTACT;

  const type = formatMm3TypeTag(assetType || '') || formatAssetType(assetType);
  return IconAlias[type.toUpperCase()];
};
