import { Model } from 'helpers/filters/types';
import { chipmunk, IResult } from 'utils/chipmunk';
import { ICreateSelectionParams } from 'utils/actions/types';
import { createSelection } from 'components/form-selection-items/api';
import { IContact, IFile, IPreviewImage } from 'types';
import { deletePreviewImage, ingestPreviewImage } from 'utils/apis/preview-image';
import { updateContacts } from 'utils/apis/contacts';

export const generalActionAPI = (action: string, userIds: string[]): Promise<string[]> => {
  return chipmunk.run(async (chipmunk) => {
    return await chipmunk.action(Model.CONTACTS, action, {
      schema: 'id',
      params: { user_ids: userIds },
    });
  });
};

export const inviteContacts = async (contactIds: string[], roleId?: number): Promise<IResult> => {
  return chipmunk.run(async (chipmunk) => {
    if (Boolean(roleId)) {
      await chipmunk.action(Model.CONTACTS, 'member.update', {
        params: {
          id: contactIds,
        },
        body: {
          role_id: roleId,
        },
      });
    }
    const params = roleId ? { id: contactIds, role_id: roleId } : { id: contactIds };
    return await chipmunk.action(Model.CONTACTS, 'invite', {
      params,
    });
  });
};

export const approveContacts = async (contactIds: string[]): Promise<IResult> => {
  return chipmunk.run(async (chipmunk) => {
    return chipmunk.action(Model.CONTACTS, 'approve', {
      params: { user_ids: contactIds },
    });
  });
};

export const rejectContacts = async (contactIds: string[]): Promise<IResult> => {
  return chipmunk.run(async (chipmunk) => {
    return chipmunk.action(Model.CONTACTS, 'reject', {
      ROR: true,
      params: { user_ids: contactIds },
    });
  });
};

export const createContactSelectionWithItems = async (
  params: ICreateSelectionParams,
  user_ids: (string | number)[],
): Promise<IResult> => {
  const { object: group } = await createSelection(params);

  return chipmunk.run(async ({ action }) => {
    return action(Model.CONTACTS_GROUPS, 'add_users', {
      params: { group_id: group.id, user_ids },
    });
  });
};

export const removeUserPreviewImage = async (user: IContact): Promise<IPreviewImage | undefined> => {
  await updateContacts([user.id], { preview_image_id: null });

  return deletePreviewImage(user.preview_image_id);
};

export const uploadContactAvatar = async (
  contactId?: string,
  file?: IFile,
  previewImageId?: number | null,
): Promise<void> => {
  if (!contactId || !file?.url) {
    return;
  }
  const object = await ingestPreviewImage(file.url, previewImageId);
  return updateContacts([contactId], { id: contactId, preview_image_id: object.id });
};

export const impersonate = async (email: string): Promise<{ id: string; login_domain: string }> => {
  return chipmunk.run(async ({ action }) => {
    const { object } = await action(Model.SESSION, 'impersonate', {
      body: { email },
    });
    return object;
  });
};

export const forcePasswordReset = async (ids: string[]): Promise<{ id: string[] }> => {
  return chipmunk.run(async ({ action }) => {
    return await action(Model.CONTACTS, 'force_password_reset', {
      params: { id: ids },
    });
  });
};
