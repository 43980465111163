import React, { useMemo, useCallback, useState, Dispatch, SetStateAction } from 'react';
import { observer } from 'mobx-react-lite';
import { McGenericRecommendationAsset, McGenericRecommendationProduct } from '@mediafellows/mm3-types';
import { isBefore } from 'date-fns';
import { useNavigate } from 'react-router';
import cx from 'classnames';
import { Tabs } from '@mantine/core';

import { Routes } from 'utils/routes';
import { DetailsPageTabs, IAsset, IGroupAssetItem } from 'types';
import { ISectionHeaderAction, SectionHeader } from 'components/section-header';
import { BodyWrapper } from 'components/layout';
import { DataSectionControls } from 'components/data-section-controls';
import { GroupTimelineTab } from 'components/marketing-entity-detail';
import GroupAssetsTreeTab from 'components/marketing-entity-detail/group-asset-tree/group-asset-tree';
import { RecommendationActions } from 'components/action';
import { ManageAssetsButtons } from 'components/manage-assets-button';
import { SortButton } from 'components/sort-buttons';
import { AssetTypeAggregations } from 'components/asset';
import { TabButtonsWrapper } from 'components/tabs';
import { ActionsMenu } from 'components/action/utils';

import { IUseActionsOptionParam, Pages } from 'utils/actions';
import { useEditRecommendationAction, IRecommendationActionName } from 'utils/actions/recommendation';
import { useRemote, useTab } from 'utils/hooks';
import { IActionName } from 'utils/actions/types';
import { getAssetAggregations, queryAssets } from 'utils/apis/asset';
import { getRecommendationAssetsFilters, isAssetRecommendation } from 'utils/recommendation';
import { Classes } from 'utils/ui';
import { getIsMm3Assets } from 'utils/asset';
import { isRecommendationDraftAndSingleContext as shouldDisplayEditRecommendation } from 'utils/actions/recommendation/actions-acl';

import { RecommendationDetailTab, RecommendationTabPanel, updateRecommendationAssets } from './utils';
import { RecommendationDetailsOverview } from './recommendation-details-overview';
import { RecommendationCCListTab, RecommendationRecipientsTab } from './recommendation-recipients-tab';
import { RecommendationAnalyticsTab } from './recommendation-analytics-tab';
import { RecommendationProductsTab } from './recommendation-products-tab';
import { RecommendationAssetsTab } from './recommendation-assets-tab';

import './style.scss';

const tabOptions = [
  DetailsPageTabs.ASSETS,
  DetailsPageTabs.PRODUCTS,
  DetailsPageTabs.RECIPIENTS,
  DetailsPageTabs.TIMELINE,
  DetailsPageTabs.ANALYTICS,
  DetailsPageTabs.CC_LIST,
];

const getWizardTab = (tab: DetailsPageTabs): DetailsPageTabs => {
  if (tab === DetailsPageTabs.TIMELINE) {
    return DetailsPageTabs.PRODUCTS;
  }
  if (tab === DetailsPageTabs.CC_LIST) {
    return DetailsPageTabs.RECIPIENTS;
  }
  return tab;
};

export const RecommendationDetails: React.FC<{
  recommendation: McGenericRecommendationProduct;
  setRecommendation: React.Dispatch<React.SetStateAction<McGenericRecommendationProduct>>;
}> = observer(({ recommendation, setRecommendation }) => {
  const [isAssetEditEnabled, setIsAssetEditEnabled] = useState<boolean>(false);
  const [isSaveClicked, setIsSaveClicked] = useState<boolean>(false);
  const [isCancelClicked, setIsCancelClicked] = useState<boolean>(false);

  const [isAssetUpdateLoading, setIsAssetUpdateLoading] = useState<boolean>(false);
  const isMm3Assets = getIsMm3Assets();

  const {
    id,
    subject,
    product_ids,
    asset_ids,
    recipient_list,
    recommendation_group_id,
    status,
    bcc_list,
    cc_list,
    expires_at,
    type,
  } = recommendation || {};
  const isAssetReco = isAssetRecommendation({ type });

  const [currentTab, setCurrentTab] = useTab(
    isAssetReco ? DetailsPageTabs.ASSETS : DetailsPageTabs.PRODUCTS,
    tabOptions,
  );
  const isRecommendationDraft = status === 'draft';
  const [isSortModeEnabled, setIsSortModeEnabled] = useState(false);

  const fetchGroupAssets = useCallback(async (): Promise<IGroupAssetItem[]> => {
    if (!asset_ids || isAssetReco) return [];
    const assets = await queryAssets<IAsset>({ ids: asset_ids }, getRecommendationAssetsFilters());
    return assets.map((asset) => ({ asset_id: asset.id, asset: asset } as IGroupAssetItem));
  }, [asset_ids, isAssetReco]);

  const [recommendationAssets] = useRemote(fetchGroupAssets);

  const navigate = useNavigate();

  const onSuccess = useCallback(
    async (action?: IActionName, newValue?: McGenericRecommendationProduct): Promise<void> => {
      switch (action) {
        case IRecommendationActionName.DELETE:
          navigate(`${Routes.RECOMMENDATIONS}`);
          break;
        default:
          setRecommendation((oldValue) => ({
            ...oldValue,
            ...newValue,
          }));
      }
    },
    [navigate, setRecommendation],
  );

  const options = useMemo<IUseActionsOptionParam<McGenericRecommendationProduct>>(
    () => ({ context: 'single', page: Pages.DETAILS, onSuccess, tab: getWizardTab(currentTab) }),
    [currentTab, onSuccess],
  );

  const editAction = useEditRecommendationAction([recommendation], options);

  const resetStatesOnSave = (): void => {
    setIsAssetEditEnabled(false);
    setIsSaveClicked(false);
    setIsAssetUpdateLoading(false);
  };

  const onAssetUpdate = useCallback(
    async (assetsToUpdate?: { assetsToAdd?: string[]; assetsToDelete?: string[] }) => {
      if (!assetsToUpdate) return;
      try {
        const { assetsToAdd, assetsToDelete } = assetsToUpdate;
        if (assetsToAdd?.length || assetsToDelete?.length) {
          setIsAssetUpdateLoading(true);
          const updatedRecommendation = await updateRecommendationAssets({
            id,
            assetIds: asset_ids,
            assetsToUpdate,
          });
          updatedRecommendation &&
            setRecommendation((reco) => ({ ...reco, asset_ids: updatedRecommendation?.asset_ids }));
        }
      } finally {
        resetStatesOnSave();
      }
    },
    [asset_ids, id, setRecommendation],
  );

  const actions = shouldDisplayEditRecommendation(editAction, 0, null, [recommendation], options)
    ? [{ ...editAction, type: 'edit' } as ISectionHeaderAction]
    : undefined;

  const handleTabChange = useCallback(
    (tab): void => {
      setCurrentTab(tab);
    },
    [setCurrentTab],
  );

  const isRecommendationExpired = expires_at ? isBefore(new Date(expires_at), new Date()) : false;
  const isAssetTabEditDisabled = !isAssetEditEnabled || !isRecommendationDraft;

  const getAssetsCount = useCallback(
    () =>
      getAssetAggregations(asset_ids, isMm3Assets ? 'type' : 'main_classification', getRecommendationAssetsFilters()),
    [asset_ids, isMm3Assets],
  );
  const [assetAggregation, {}] = useRemote(getAssetsCount);
  const canManageAssets =
    isRecommendationDraft &&
    currentTab === DetailsPageTabs.ASSETS &&
    !isRecommendationExpired &&
    type === 'Recommendation::Product';
  const hideSort = !(
    (currentTab === DetailsPageTabs.PRODUCTS && isRecommendationDraft) ||
    (currentTab === DetailsPageTabs.ASSETS && isAssetReco && isRecommendationDraft)
  );
  return (
    <BodyWrapper>
      <SectionHeader
        title={subject}
        subtitle={
          <span className={cx(Classes.TEXT_MUTED, 'd-flex gap-1')}>
            <span>{isAssetReco ? '' : `${product_ids?.length || 0} Products | `}</span>
            <AssetTypeAggregations aggregations={assetAggregation} disabled /> |
            <span>{recipient_list?.length || 0} Recipients</span>
          </span>
        }
        actions={actions}
        useBackground
        dropdownMenu={<ActionsMenu component={RecommendationActions} items={[recommendation]} options={options} />}
      />
      <div className="recommendation-details__content-wrapper">
        <RecommendationDetailsOverview assetAggregation={assetAggregation} recommendation={recommendation} />
        <div className="recommendation-details__tabs-wrapper">
          <Tabs value={currentTab} onChange={handleTabChange} className="recommendation-details__tabs h-100">
            <Tabs.List className="d-flex flex-nowrap">
              <RecommendationDetailTab value={DetailsPageTabs.PRODUCTS} activeTab={currentTab} hidden={isAssetReco} />
              <RecommendationDetailTab value={DetailsPageTabs.ASSETS} activeTab={currentTab} />
              <RecommendationDetailTab value={DetailsPageTabs.RECIPIENTS} activeTab={currentTab} />
              <RecommendationDetailTab
                value={DetailsPageTabs.CC_LIST}
                activeTab={currentTab}
                valueParser={() => 'CC/BCC LISTS'}
              />
              <RecommendationDetailTab
                value={DetailsPageTabs.ANALYTICS}
                activeTab={currentTab}
                hidden={!recommendation.sent_at}
              />
              <RecommendationDetailTab value={DetailsPageTabs.TIMELINE} activeTab={currentTab} />
              <TabButtonsWrapper>
                <SortButton
                  isSortModeEnabled={isSortModeEnabled}
                  setIsSortModeEnabled={setIsSortModeEnabled}
                  hidden={hideSort}
                />
                <DataSectionControls disabled={isSortModeEnabled} hidden={currentTab !== DetailsPageTabs.PRODUCTS} />
                <ManageAssetsButtons
                  showButton={canManageAssets}
                  isAssetEditEnabled={isAssetEditEnabled}
                  setIsAssetEditEnabled={setIsAssetEditEnabled}
                  setIsSaveClicked={setIsSaveClicked}
                  isLoading={isAssetUpdateLoading}
                  setIsCancelClicked={setIsCancelClicked}
                />
              </TabButtonsWrapper>
            </Tabs.List>
            <RecommendationTabPanel
              value={DetailsPageTabs.PRODUCTS}
              content={
                <RecommendationProductsTab
                  recommendation={recommendation}
                  setRecommendation={setRecommendation}
                  isSortModeEnabled={isSortModeEnabled}
                />
              }
            />
            <RecommendationTabPanel
              value={DetailsPageTabs.ASSETS}
              content={
                isAssetReco ? (
                  <RecommendationAssetsTab
                    recommendation={recommendation as McGenericRecommendationAsset}
                    isSortModeEnabled={isSortModeEnabled}
                    setRecommendation={setRecommendation as Dispatch<SetStateAction<McGenericRecommendationAsset>>}
                  />
                ) : (
                  <GroupAssetsTreeTab
                    productIds={product_ids}
                    assetIds={asset_ids}
                    entityIdName="recommendationId"
                    disabled={isAssetTabEditDisabled}
                    onAssetUpdate={onAssetUpdate}
                    isSaveClicked={isSaveClicked}
                    groupAssets={recommendationAssets}
                    isCancelClicked={isCancelClicked}
                    setIsCancelClicked={setIsCancelClicked}
                    assetFilters={getRecommendationAssetsFilters()}
                  />
                )
              }
            />
            <RecommendationTabPanel
              value={DetailsPageTabs.RECIPIENTS}
              content={<RecommendationRecipientsTab recommendation={recommendation} />}
            />
            <RecommendationTabPanel
              value={DetailsPageTabs.CC_LIST}
              content={<RecommendationCCListTab cc_list={cc_list} bcc_list={bcc_list} />}
            />
            <RecommendationTabPanel
              value={DetailsPageTabs.ANALYTICS}
              content={<RecommendationAnalyticsTab recommendation={recommendation} />}
            />
            <RecommendationTabPanel
              value={DetailsPageTabs.TIMELINE}
              content={<GroupTimelineTab groupId={recommendation_group_id} />}
            />
          </Tabs>
        </div>
      </div>
    </BodyWrapper>
  );
});

export default RecommendationDetails;
