import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { merge, get, omit } from 'lodash';

import { useStore } from 'store';

import { ToastError } from 'components/toast';
import { IAssetFormFields, useAssetEditForm } from 'components/asset/asset-edit-form';
import { IScrollableTab, ScrollableTabs } from 'components/scrollable-tabs/scrollable-tabs';

import { useTab } from 'utils/hooks';
import { parseKeyPathToObject } from 'utils/payload';
import { saveAsset } from 'utils/apis/asset';
import { useEditMode } from 'utils/hooks';
import { customEditAssetProtections } from 'utils/apis/protections';
import { Model } from 'helpers/filters/types';
import { useMm3Form } from 'helpers/form';
import { Loading } from 'components/loading';

import { DetailsPageTabs, IAsset, IMm3Asset } from 'types';
import { AmGenericAsset3DigitalSubtitle, AmGenericAsset3ProtectionOptions } from '@mediafellows/mm3-types';
import { AssetDetailsButtons } from './asset-details-buttons';
import { AssetDetailsHeader } from './asset-details-header';
import { isAssetVideo } from 'utils/format-asset-type';

import { getTabsList, tabOptions, useRefreshIngestingAsset } from './utils';
import { AssetChildrenTab } from './asset-children-tabs';
import { getIsMm3Assets } from 'utils/asset';

import './style.scss';

export const AssetDetailsLoaded: React.FC<{
  asset: IAsset | IMm3Asset;
  setAsset: React.Dispatch<React.SetStateAction<IAsset>>;
  refreshAsset: () => Promise<void>;
  fetchAsset: () => Promise<IAsset | IMm3Asset>;
  protections?: AmGenericAsset3ProtectionOptions;
}> = observer(({ asset, setAsset, refreshAsset, fetchAsset, protections }) => {
  const { toastStore } = useStore();
  const [editModeEnabled, setEditModeEnabled] = useEditMode();
  const [activeTab, setActiveTab] = useTab(DetailsPageTabs.OVERVIEW, tabOptions);
  const isMm3Assets = getIsMm3Assets();

  const handleTabChange = useCallback((tab: string): void => setActiveTab(tab as DetailsPageTabs), [setActiveTab]);

  useRefreshIngestingAsset(asset, fetchAsset, setAsset);

  const handleSubmit = useCallback(
    async (values: IAssetFormFields | IMm3Asset): Promise<void> => {
      try {
        toastStore.info('Saving');
        const updatedAsset = isMm3Assets
          ? values
          : merge(parseKeyPathToObject<IAssetFormFields, IAsset>(values as IAssetFormFields), {
              default_layer: { id: get(asset, 'default_layer.id') },
            });
        const division_ids = updatedAsset.division_ids?.map((id) => Number(id));
        await saveAsset(
          updatedAsset.id as string,
          { ...updatedAsset, division_ids } as IAsset & (IMm3Asset & AmGenericAsset3DigitalSubtitle),
        );
        toastStore.clearAll();
        toastStore.success('Saved', 1000);
        setEditModeEnabled(false);
        refreshAsset();
      } catch (e) {
        toastStore.clearAll();
        toastStore.error(
          <ToastError error={e} placeholder={`Error while saving: ${get(e, 'object.description', 'Unknown error')}`} />,
        );
      }
    },
    [asset, isMm3Assets, refreshAsset, setEditModeEnabled, toastStore],
  );

  const editForm = useAssetEditForm({ asset: omit(asset, ['meta.watermark']) as IMm3Asset | IAsset, handleSubmit });
  const isVideo = isAssetVideo(asset);

  const getChildTab = useCallback(
    (tab: DetailsPageTabs): JSX.Element => {
      return <AssetChildrenTab asset={asset} tab={tab} setAsset={setAsset} />;
    },
    [asset, setAsset],
  );

  const protectionsForm = useMm3Form<AmGenericAsset3ProtectionOptions | undefined>(
    protections,
    Model.ASSET_PROTECTION_OPTIONS,
    customEditAssetProtections,
  );

  const tabsList: IScrollableTab[] = useMemo(() => {
    return getTabsList({
      asset,
      editForm,
      editModeEnabled,
      getChildTab,
      handleTabChange,
      isVideo,
      protections,
      protectionsForm,
      setAsset,
    });
  }, [asset, editForm, editModeEnabled, getChildTab, handleTabChange, isVideo, protections, protectionsForm, setAsset]);

  if (editForm.isSending) {
    return <Loading text="Saving..." />;
  }

  return (
    <>
      <AssetDetailsHeader
        asset={asset}
        setAsset={setAsset}
        refreshAsset={refreshAsset}
        protectionsForm={protectionsForm}
        editForm={editForm}
        setEditModeEnabled={setEditModeEnabled}
        editModeEnabled={editModeEnabled}
      />

      <div className="asset-details flex-full">
        <div className="asset-detail__content-wrapper h-100 d-flex flex-column">
          <ScrollableTabs
            tabs={tabsList}
            buttons={
              <AssetDetailsButtons
                asset={asset}
                refreshAsset={refreshAsset}
                setAsset={setAsset}
                editModeEnabled={editModeEnabled}
                activeTab={activeTab}
              />
            }
            activeTab={activeTab}
            setActiveTab={handleTabChange}
          />
        </div>
      </div>
    </>
  );
});
