import React, { useCallback, useMemo } from 'react';
import cx from 'classnames';
import { Tabs, Tab } from 'react-tabs-scrollable';

import { DetailsPageTabs } from 'types';
import { useDebounce } from 'utils/hooks';

import { MantineIcon } from 'utils/ui/icon';
import { IconSize } from 'utils/ui/icon-size';

import 'react-tabs-scrollable/dist/rts.css';
import './style.scss';
import { hasPower } from 'utils/powers';

export interface IScrollableTab {
  id: DetailsPageTabs;
  panel: JSX.Element;
  isVisible?: boolean | null;
  disabled: boolean;
  requiredPower?: string;
  formatTitle?: (tab: DetailsPageTabs | string) => string;
}

interface IScrollableTabsProps {
  tabs: IScrollableTab[];
  buttons?: React.ReactNode;
  setActiveTab: (tab: string) => void;
  activeTab: DetailsPageTabs;
}

export const ScrollableTabs: React.FC<IScrollableTabsProps> = ({
  tabs: tabsRegardlessOfPowers,
  activeTab,
  setActiveTab,
  buttons,
}) => {
  const tabs = useMemo(
    () => tabsRegardlessOfPowers.filter((tab) => !tab?.requiredPower || hasPower(tab.requiredPower)),
    [tabsRegardlessOfPowers],
  );

  const onTabsClick = useCallback(
    (e, index): void => {
      e?.preventDefault();
      e?.stopPropagation();
      setActiveTab(tabs[index].id);
    },
    [setActiveTab, tabs],
  );

  const debouncedActiveTabIndex = useDebounce(
    tabs.findIndex((tab) => tab.id === activeTab),
    300,
  );

  return (
    <>
      <div key="tabs-container" className="scrollable-tabs__container gap-2">
        <Tabs
          selectedAnimationDuration={200}
          animationDuration={200}
          key="tabs"
          activeTab={debouncedActiveTabIndex}
          onTabClick={onTabsClick}
          mode="scrollSelectedToCenter"
          navBtnClassName="bp3-button bp3-minimal"
          navBtnContainerClassName="bp3-button-group"
          hideNavBtnsOnMobile={false}
          leftBtnIcon={<MantineIcon icon="chevron-left" size={IconSize.LARGE} />}
          rightBtnIcon={<MantineIcon icon="chevron-right" size={IconSize.LARGE} />}
        >
          {tabs.map(({ id, disabled, formatTitle = (a) => a, isVisible }) => (
            <Tab
              key={id}
              selected={id === activeTab}
              className={cx({ 'scrollable-tabs__tab--disabled': disabled, 'd-none': !isVisible })}
            >
              {formatTitle(id)}
            </Tab>
          ))}
        </Tabs>
        <div className="d-flex py-2 pe-2 gap-2 align-items-center">{buttons}</div>
      </div>
      {tabs[debouncedActiveTabIndex].panel}
    </>
  );
};
