import React, { useCallback } from 'react';

import { useMm3Form } from 'helpers/form/use-mm3-form';
import { Model } from 'helpers/filters/types';
import { useStore } from 'store';
import { ToastError } from 'components/toast';
import { initialValues } from './initial-values';

import LocalizationOrderWizard from './order-wizard';
import { createOrder, updateOrder } from 'utils/apis/localization-order';
import { ICreateWorkFlowOrderPayload } from 'utils/actions/types';
import { ILocalizationOrder } from 'types/localization-order';

interface ILocalizationOrderWizardContainer {
  onFinish: (order: ILocalizationOrder) => void;
  localizationOrder?: ILocalizationOrder;
}

export const LocalizationOrderWizardContainer: React.FC<ILocalizationOrderWizardContainer> = ({
  onFinish,
  localizationOrder,
}) => {
  const { toastStore } = useStore();

  const handleConfirm = useCallback(
    async (values: ILocalizationOrder): Promise<void> => {
      try {
        if (!values) {
          return;
        }

        const order = values.id ? await updateOrder(values) : await createOrder(values);
        toastStore.success(`Localization Order was successfully ${values?.id ? 'updated' : 'created'}!`);
        onFinish(order);
      } catch (error) {
        toastStore.error(<ToastError error={error} />);
      }
    },
    [toastStore, onFinish],
  );

  const form = useMm3Form<ICreateWorkFlowOrderPayload<ILocalizationOrder>>(
    {
      ...initialValues,
      ...{ product_ids: localizationOrder?.product_id ? [localizationOrder?.product_id] : [] },
      ...localizationOrder,
    } as ICreateWorkFlowOrderPayload<ILocalizationOrder>,
    Model.LOCALIZATION_ORDERS_SERIES,
    handleConfirm,
  );

  return <LocalizationOrderWizard orderForm={form} onFinish={onFinish} />;
};
