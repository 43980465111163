import React from 'react';

import { InfoBoxV2 } from 'components/info-box-v2';
import { LabelValuePair as Row } from 'components/label-value-pair';
import { FieldListV2, FormInput, FormMultiSelect } from 'helpers/form';
import { getMm3Tags, getTags } from 'components/asset/edit-sections/utils';
import type {
  IAssetMm3MetadataEditTabProps,
  IAssetOldMetadataEditTabProps,
} from 'components/asset/edit-sections/types';
import { withAssetVersion } from 'utils/asset';
import { CustomIdentifierEditRows } from 'pages/asset-details/asset-metadata-tab/custom-row';
import { IMm3Asset, IMm3AssetType } from 'types';
import { FormVideoTrack1, FormVideoTrack2, TimedTextTrack } from 'components/asset/edit-sections/imf';

import './style.scss';
import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';

export const AssetOldMetadataEditIdentifiersSection: React.FC<IAssetOldMetadataEditTabProps> = ({ form }) => {
  const { formData, handlers } = form;

  return (
    <InfoBoxV2 gridTemplateColumns="180px 1fr" title="Identifiers">
      <Row
        label="Tags"
        value={
          <FormMultiSelect
            name="tags"
            placeholder="Add Tags"
            allowNewItems
            {...formData.tags}
            {...handlers}
            fetchValues={getTags}
            noResults={null}
            hideClearIfEmptySelect
          />
        }
        size="m"
      />
      <Row
        label="External Reference"
        value={
          <FormInput
            name="external_reference"
            placeholder="Type External Reference"
            {...formData.external_reference}
            {...handlers}
          />
        }
        size="m"
      />
      <Row
        label="Asset Identifier"
        value={
          <FormInput
            name="asset_identifier"
            placeholder="Type Asset Identifier"
            {...formData.asset_identifier}
            {...handlers}
          />
        }
        size="m"
      />
      <Row
        label="Archive Number"
        value={
          <FormInput
            name="archive_number"
            placeholder="Type Archive Number"
            {...formData.archive_number}
            {...handlers}
          />
        }
        size="m"
      />
    </InfoBoxV2>
  );
};

export const AssetMm3IMFSection: React.FC<
  IAssetMm3MetadataEditTabProps & {
    form: IUseMm3FormReturn<Partial<IMm3Asset>>;
  }
> = ({ form }) => {
  const { formData, handlers, values } = form;

  if (values.type !== IMm3AssetType.IMF) {
    return <></>;
  }

  return (
    <div className="asset-metadata-tab__imf-section">
      <FieldListV2
        prefix="meta.imf_virtual_video_tracks"
        label="Video Tracks"
        formValues={values}
        formHandlers={handlers}
        formData={formData}
        fieldProps={() => form}
        component={FormVideoTrack1}
        limit={8}
        withSorting
        className="asset-edit__imf"
      />
      <FieldListV2
        prefix="meta.imf_virual_audio_tracks"
        label="Audio Tracks"
        formValues={values}
        formHandlers={handlers}
        formData={formData}
        fieldProps={() => form}
        component={FormVideoTrack2}
        limit={8}
        withSorting
        className="asset-edit__imf"
      />
      <FieldListV2
        prefix="meta.imf_virtual_text_tracks"
        label="Timed Text Tracks"
        formValues={values}
        formHandlers={handlers}
        formData={formData}
        fieldProps={() => form}
        component={TimedTextTrack}
        limit={8}
        withSorting
        className="asset-edit__imf"
      />
    </div>
  );
};

export const AssetMm3MetadataEditIdentifiersSection: React.FC<IAssetMm3MetadataEditTabProps> = ({ form }) => {
  const { formData, handlers } = form;

  return (
    <>
      <InfoBoxV2 gridTemplateColumns="180px 1fr" title="Identifiers">
        <Row
          label="Tags"
          value={
            <FormMultiSelect
              name="tags"
              placeholder="Add Tags"
              allowNewItems
              {...formData.tags}
              {...handlers}
              fetchValues={getMm3Tags}
              noResults={null}
              hideClearIfEmptySelect
            />
          }
          size="m"
        />
        <Row
          label="External Reference"
          value={
            <FormInput
              name="external_reference"
              placeholder="Type External Reference"
              {...formData.external_reference}
              {...handlers}
            />
          }
          size="m"
        />
        <Row
          label="Asset Identifier"
          value={
            <FormInput
              name="meta.asset_identifier"
              placeholder="Type Asset Identifier"
              {...formData.meta?.asset_identifier}
              {...handlers}
            />
          }
          size="m"
        />
        <Row
          label="Archive Number"
          value={
            <FormInput
              name="meta.archive_number"
              placeholder="Type Archive Number"
              {...formData.meta?.archive_number}
              {...handlers}
            />
          }
          size="m"
        />

        <CustomIdentifierEditRows formData={formData} handlers={handlers} />
      </InfoBoxV2>
      <AssetMm3IMFSection form={form} />
    </>
  );
};

export const AssetMetadataEditIdentifiersSection = withAssetVersion(
  AssetOldMetadataEditIdentifiersSection,
  AssetMm3MetadataEditIdentifiersSection,
);
