import { IAsset, IMm3Asset } from 'types';

import { IUseActionsOptionParam, IUseActionsResponse, dividerAction, useActions } from 'utils/actions';

import { useApproveAction } from './approve-action';
import { useRejectAction } from './reject-action';
import { useUpdateAction } from './update-action';
import { useDeleteAction } from './delete-action';
import { useUploadPreviewAction } from './upload-preview-action';
import { useUploadAssetAction } from './upload-asset-action';
import { useAssignToProductAction } from './assign-asset-to-product-action';
import { useCreateSelectionAction } from './create-selection-action';
import { useReingestAction } from './reingest-action';
import { useUnarchiveAction } from './unarchive-action';
import { useDeletePreviewAction } from './delete-preview-action';
import { useArchiveAction } from './archive-action';
import { useRestoreAction } from './restore-action';
import { useExtractPreviewAction } from './extract-preview-action';
import { useExtractPreviewActionMm3 } from './extract-preview-action-mm3';
import { useDownloadAction } from './download-action';
import { useAddToSelectionAction } from './add-to-selection-action';
import { useChangeAccessAction } from './change-access-action';
import { IAssetActionName } from 'utils/actions/asset/types';
import { useUnassignAction } from './unassign';
import { useRemoveFromGroupAction } from './remove-from-group';
import { useRemoveFromConferenceAction } from './remove-from-conference';
import { useSetUpConferenceAction } from './set-up-conference';
import { useUploadSubtitleAction } from './upload-subtitle';
import { useImportToMBAction } from './import-to-mediabench';
import { useRecommendAction } from './recommend-action';
import { useCreateScreeningRoomAction } from './create-screening-room';
import { useDeliveryAction } from './deliver-action';
import { useAddToDraftAction } from './add-to-draft-action';
import { useTranscodeAction } from './transcode';
import { useGenerateSubtitlesAction } from './generate-subtitles';
import { useAssignToAssetAction } from './assign-sub-asset';
import { getIsMm3Assets } from 'utils/asset';
import { useUnassignToAssetAction } from './unassign-sub-asset';

export { useChangeAccessAction };
export * from './types';
export * from './api';

export const useAssetActions = (
  entities: (IAsset | IMm3Asset)[] = [],
  options: IUseActionsOptionParam,
): IUseActionsResponse<IAssetActionName> => {
  const isMm3Assets = getIsMm3Assets();
  const useImageExtract = isMm3Assets ? useExtractPreviewActionMm3 : useExtractPreviewAction;

  const assignSubAssets = useAssignToAssetAction(entities as IMm3Asset[], options);
  const unassignSubAsset = useUnassignToAssetAction(entities as IMm3Asset[], options);
  const recommendAction = useRecommendAction(entities, options);
  const removeFromConference = useRemoveFromConferenceAction(entities, options);
  const removeFromGroupAction = useRemoveFromGroupAction(entities, options);
  const uploadPreviewAction = useUploadPreviewAction(entities, options);
  const uploadAssetAction = useUploadAssetAction(entities, options);
  const assignToProductAction = useAssignToProductAction(entities, options);
  const unassignAction = useUnassignAction(entities, options);
  const createSelectionAction = useCreateSelectionAction(entities);
  const createScreeningRoom = useCreateScreeningRoomAction(entities);
  const approveAction = useApproveAction(entities, options);
  const rejectAction = useRejectAction(entities, options);
  const updateAction = useUpdateAction(entities);
  const deleteAction = useDeleteAction(entities, options);
  const reingestAction = useReingestAction(entities, options);
  const unarchiveAction = useUnarchiveAction(entities, options);
  const deletePreviewAction = useDeletePreviewAction(entities, options);
  const archiveAction = useArchiveAction(entities, options);
  const restoreAction = useRestoreAction(entities, options);
  const extractPreviewAction = useImageExtract(entities, options);
  const downloadAction = useDownloadAction(entities, options);
  const addToSelectionAction = useAddToSelectionAction(entities);
  const changeAccessAction = useChangeAccessAction(entities, options);
  const setUpConference = useSetUpConferenceAction(entities);
  const uploadSubtitle = useUploadSubtitleAction(entities, options);
  const importToMediaBench = useImportToMBAction(entities);
  const deliveryAction = useDeliveryAction(entities, options);
  const addToDraftAction = useAddToDraftAction(entities);
  const transcodeAction = useTranscodeAction(entities);
  const generateSubtitlesAction = useGenerateSubtitlesAction(entities[0]);

  const actions = [
    removeFromGroupAction,
    removeFromConference,
    unassignAction,
    dividerAction,
    recommendAction,
    dividerAction,
    setUpConference,
    createScreeningRoom,
    createSelectionAction,
    addToSelectionAction,
    downloadAction,
    dividerAction,
    updateAction,
    transcodeAction,
    generateSubtitlesAction,
    changeAccessAction,
    assignSubAssets,
    unassignSubAsset,
    assignToProductAction,
    deliveryAction,
    addToDraftAction,
    dividerAction,
    uploadAssetAction,
    reingestAction,
    extractPreviewAction,
    uploadPreviewAction,
    deletePreviewAction,
    dividerAction,
    uploadSubtitle,
    dividerAction,
    approveAction,
    rejectAction,
    dividerAction,
    importToMediaBench,
    dividerAction,
    archiveAction,
    unarchiveAction,
    deleteAction,
    restoreAction,
  ];

  return useActions<IAsset | IMm3Asset, IAssetActionName>(actions, options, entities);
};
