import React, { useCallback } from 'react';
import { IContact, ItemId } from 'types';
import { IUseActionsOptionParam } from 'utils/actions/types';

import { IContactActionConfig, IContactActionName } from 'utils/actions/contact/types';
import { FormContacts, FormMultiSelect, useForm } from 'helpers/form';
import { Model } from 'helpers/filters/types';
import { queryContacts, updateContacts } from 'utils/apis/contacts';
import { FormSubmitSection } from 'components/form-submit-section';
import { useDialogStore } from 'store/hooks';

import { useStore } from 'store/index';
import { ToastError } from 'components/toast';
import { IModalSize } from 'components/dialogs/types';

import './style.scss';

interface IFormData {
  userIds: ItemId[];
  responsible_user_id?: ItemId;
}

const customContext = {
  properties: {
    userIds: {
      required: true,
    },
  },
};
const ResponsibleContactForm: React.FC<Pick<IFormData, 'userIds'> & { handleSubmit: (values) => Promise<void> }> = ({
  userIds,
  handleSubmit,
}) => {
  const { formData, handlers, onSubmit, valid } = useForm(
    { userIds, responsible_user_id: null },
    Model.CONTACTS,
    handleSubmit,
    customContext,
  );

  return (
    <>
      <form onSubmit={onSubmit}>
        <FormContacts
          name="responsible_user_id"
          label="Responsible Contact"
          {...handlers}
          {...formData.responsible_user_id}
          emptyValue={null}
        />
        <FormMultiSelect
          name="userIds"
          label="Contacts"
          fetchValues={queryContacts}
          omni
          {...formData.userIds}
          {...handlers}
        />
      </form>
      <FormSubmitSection submitDisabled={!valid} labels={{ confirm: 'Submit' }} onSubmit={onSubmit} />
    </>
  );
};

export const useUpdateResponsibleContactAction = (
  contacts: IContact[],
  options: IUseActionsOptionParam,
): IContactActionConfig => {
  const { openModal, close } = useDialogStore();
  const { toastStore } = useStore();
  const handleSubmit = useCallback(
    async ({ userIds, responsible_user_id }: IFormData): Promise<void> => {
      try {
        await updateContacts(userIds, { responsible_user_id });
        options?.onSuccess?.();
        toastStore.success('Responsible Contact has been Updated successfully!');
        close();
      } catch (error) {
        toastStore.error(<ToastError error={error} />);
      }
    },
    [close, options, toastStore],
  );

  const handler = useCallback(() => {
    return openModal({
      title: 'Update Responsible Contact',
      size: IModalSize.S,
      body: () => <ResponsibleContactForm userIds={contacts.map((e) => e.id)} handleSubmit={handleSubmit} />,
    });
  }, [contacts, handleSubmit, openModal]);

  return {
    name: IContactActionName.UPDATE_RESPONSIBLE_CONTACT,
    icon: 'following',
    title: 'Update Responsible Contact',
    handler,
  };
};
