import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { ActionIcon, MantineSize, Menu, MultiSelect, MultiSelectProps } from '@mantine/core';
import cx from 'classnames';
import { Cross } from 'blueprint5-icons';

import { IAggregation, InFilter, NotInFilter } from 'helpers/filters/types';
import { IDataProvider } from 'helpers/data-provider/option-data-provider';
import { useDebounce } from 'utils/hooks';
import { highlightText } from 'helpers/form/fields/helpers';
import { MantineIcon } from 'utils/ui';
import { pluralizeLabel } from 'utils/general';

import './style.scss';

export type IMultiSelectFilterDefinition = InFilter | NotInFilter;

interface IFilterMultiSelectProps {
  name: string;
  label: string;
  placeholder?: string;
  optionsProvider: IDataProvider;
  size?: MantineSize;
  disabled?: boolean;
  className?: string;
  aggregations?: IAggregation[];
  filter: IMultiSelectFilterDefinition;
  onChange?: (newValue: { [key: string]: IMultiSelectFilterDefinition }) => void;
}

const FilterMultiSelect: React.FC<IFilterMultiSelectProps> = observer((props) => {
  const {
    name,
    placeholder: customPlaceHolder,
    label,
    filter,
    aggregations,
    onChange,
    className,
    optionsProvider,
    disabled,
    size = 'sm',
  } = props;
  const { value } = filter || {};
  const [query, setQuery] = useState('');
  const valueNormalized = useMemo(
    () => (Array.isArray(value) ? value.map((v) => v.toString()) : [value.toString()]),
    [value],
  );
  const placeholder = customPlaceHolder || `Search ${pluralizeLabel(label)}`;

  const debouncedQuery = useDebounce(query, 150);

  const itemRenderer: MultiSelectProps['renderOption'] = useCallback(
    ({ option }) => {
      const aggr = aggregations?.find((a) => a.value == option.value);
      return (
        <Menu>
          <Menu.Item
            disabled={Boolean(option?.disabled || (aggregations?.length && !aggr?.count))}
            key={option.value}
            rightSection={aggr && `${aggr.count}`}
          >
            {highlightText(option.label, query)}
          </Menu.Item>
        </Menu>
      );
    },
    [aggregations, query],
  );

  const handleItemSelect = useCallback(
    (valueString: string[]): void => {
      const value = Number.isNaN(Number(valueString[0])) ? valueString : valueString.map(Number);
      onChange?.({ [name]: { ...filter, value } });
    },
    [filter, name, onChange],
  );

  const handleQueryChange = useCallback((query: string) => {
    setQuery(query);
  }, []);

  useEffect(() => {
    optionsProvider.filter(debouncedQuery);
  }, [debouncedQuery, optionsProvider]);

  const handlePopoverOpen = useCallback(() => {
    optionsProvider.init();
  }, [optionsProvider]);

  const clearFilter = useCallback(() => {
    onChange?.({ [name]: { ...filter, value: [] } });
  }, [filter, name, onChange]);

  const options = optionsProvider.data.map((option) => ({
    label: option.label,
    value: option.value.toString(),
  }));

  return (
    <div className={cx('d-flex align-items-center justify-space-between me-0 mw-100', className)}>
      <div className="flex-fill w-100 filter-multi-select__wrap">
        <MultiSelect
          className="filter-multiselect"
          label={label}
          onChange={handleItemSelect}
          data={options}
          searchable
          searchValue={query}
          onSearchChange={handleQueryChange}
          value={valueNormalized}
          onDropdownOpen={handlePopoverOpen}
          nothingFoundMessage="Nothing found..."
          renderOption={itemRenderer}
          placeholder={valueNormalized.length ? undefined : placeholder}
          hidePickedOptions
          comboboxProps={{ dropdownPadding: 0, portalProps: { className: 'filter-multiselect-popover__portal' } }}
          maxDropdownHeight={300}
          size={size}
          disabled={disabled}
        />
      </div>
      <ActionIcon
        size="md"
        variant="subtle"
        className="mt-4"
        onClick={clearFilter}
        disabled={disabled || !valueNormalized.length}
      >
        <MantineIcon icon={<Cross />} />
      </ActionIcon>
    </div>
  );
});

export default FilterMultiSelect;
