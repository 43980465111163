import React from 'react';
import { observer } from 'mobx-react-lite';
import { FilterDateRange } from 'helpers/filters/fields';
import { IFiltersHandlers } from 'helpers/filters';
import { IFilters } from 'components/product-filters/types';

interface ITimestampFiltersProps {
  filterValues: IFilters;
  filterHandlers: IFiltersHandlers<IFilters>;
}

export const ProductTimestampsFilters = observer(({ filterValues, filterHandlers }: ITimestampFiltersProps) => (
  <>
    <FilterDateRange
      label="Created at"
      name="created_at"
      filter={filterValues.created_at}
      onChange={filterHandlers.onChange}
    />
    <FilterDateRange
      label="Updated at"
      name="updated_at"
      filter={filterValues.updated_at}
      onChange={filterHandlers.onChange}
    />
  </>
));
