import { ColDef, themeAlpine } from 'ag-grid-community';
import { colorSchemeDarkBlue } from 'ag-grid-community';
import { DateEditor, StatusBadge, UploadRenderer } from './grid-custom-elements';

export interface IRowData {
  assetUpload: string | JSX.Element;
  series: string;
  season: string;
  episode: string;
  assetType: string;
  language: string | string[];
  targetDate: string;
  dueDate: string;
  deliveryDate?: string;
  status: string;
  workType: string;
  runTime: string;
}

export const columns: ColDef<IRowData>[] = [
  {
    field: 'assetUpload',
    headerName: 'Asset Upload',
    cellRenderer: UploadRenderer,
    width: 160,
  },
  { field: 'series', headerName: 'Series', width: 150 },
  { field: 'season', headerName: 'Season', width: 120 },
  { field: 'episode', headerName: 'Episode', width: 150 },
  { field: 'assetType', headerName: 'Asset Type', width: 120 },
  { field: 'language', headerName: 'Language', width: 100 },
  {
    field: 'targetDate',
    headerName: 'Target Date',
    cellEditor: DateEditor,
    width: 130,
  },
  {
    field: 'dueDate',
    headerName: 'Due Date',
    cellEditor: DateEditor,
    width: 130,
  },
  {
    field: 'deliveryDate',
    headerName: 'Delivery Date',
    cellEditor: DateEditor,
    width: 130,
  },
  {
    field: 'status',
    headerName: 'Status',
    cellRenderer: StatusBadge,
    width: 130,
  },
  { field: 'workType', headerName: 'Work Type', width: 120 },
  { field: 'runTime', headerName: 'Run Time', width: 100 },
];

export const customTheme = themeAlpine
  .withPart(colorSchemeDarkBlue)
  .withParams({ backgroundColor: '#202b33', accentColor: '#24313b' });
