import React, { useCallback } from 'react';
import { isEqual } from 'lodash';

import { ACCESS_TABS, IAsset } from 'types';
import { useRemote } from 'utils/hooks';
import { loadAllScheduledJobs } from 'utils/actions/asset/api';
import { AssetAccessChangeForm } from 'components/asset/asset-access-change-form';
import { getIsMm3Assets } from 'utils/asset';

import { ScheduledChangeIndicatorWrapper } from './scheduled-change-indicator-wrapper';

import { ICommonEntity } from './types';
import './style.scss';

export type IAvailableType = 'status' | 'access' | 'permissions' | 'protection';

const TABS_MAP: Record<IAvailableType, string[]> = {
  status: ['scheduled_status'],
  access: ['scheduled_access'],
  permissions: ['scheduled_access'],
  protection: ['scheduled_protection'],
};

interface IScheduledChangeIndicator {
  entity?: ICommonEntity & {
    scheduled_jobs_count?: number | null;
  };
  type?: IAvailableType;
  className?: string;
}

export const ScheduledChangeIndicatorAsset: React.FC<IScheduledChangeIndicator> = ({
  entity,
  type = '',
  className,
}) => {
  const isMm3Assets = getIsMm3Assets();
  const refresh = useCallback(async () => {
    if (!entity?.scheduled_jobs_count || !type || !entity?.id) {
      return [];
    }
    return loadAllScheduledJobs([entity.id]);
  }, [entity, type]);

  const [jobs = [], isLoading] = useRemote(refresh, []);

  const scheduledTabs = [
    ACCESS_TABS.SCHEDULED_ACCESS,
    ACCESS_TABS.SCHEDULED_STATUS,
    ...(isMm3Assets ? [] : [ACCESS_TABS.SCHEDULED_PROTECTION]),
    ACCESS_TABS.SCHEDULED_DELEGATION,
  ];

  if (isLoading || !entity || !entity.id) {
    return null;
  }

  if ((jobs.length === 0 && type) || entity.scheduled_jobs_count === 0) {
    return null;
  }

  const tabs = TABS_MAP[type] ?? scheduledTabs;

  if (type) {
    const hasJobType = jobs.some((j) => {
      switch (type) {
        case 'status':
          return j.name === 'change_status_on_asset' && !isEqual(j['workflow_status'], entity.status);
        case 'access':
          return j.name === 'change_access_level_on_asset' && !isEqual(j['access_level'], entity.access_level);
        case 'permissions':
          return j.name === 'change_access_level_on_asset' && !isEqual(j['permissions'], entity.permissions);
        case 'protection':
          return (
            !isMm3Assets &&
            j.name === 'change_protection_levels_on_asset' &&
            !isEqual(j['protection_levels'], entity.protection_levels)
          );
      }
    });

    if (!hasJobType) {
      return null;
    }
  }

  return (
    <ScheduledChangeIndicatorWrapper className={className}>
      <AssetAccessChangeForm items={[entity as IAsset]} options={{ context: 'single' }} readOnly tabs={tabs} />
    </ScheduledChangeIndicatorWrapper>
  );
};
