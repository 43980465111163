export const locationDetailSchema = `
address,
allow_overlapping_meetings,
capacity,
created_at,
ends_at,
host_ids,
hosts,
id,
meta { location_type },
name,
starts_at,
time_zone,
updated_at
`;

export const locationListSchema = 'id, type, name, host_ids, starts_at, ends_at, time_zone';
