import { IGuild } from './types';

export const initialValues: IGuild = {
  name: '',
  access_level: 'private',
  product_ids: [],
  productList: [],
  product_selections: [],
  asset_ids: [],
  assetList: [],
  contacts: [],
  contact_ids: [],
  organization_ids: [],
  expires_at: '',

  codes_count: 0,
  requested_codes_count: 0,

  permit_download: false,
  delegates_access: true,
  include_descendants: true,
  permitAssetDownload: false,
  subAssetIds: [],
};
