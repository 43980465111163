import React, { useCallback } from 'react';

import { IProduct, ISelectionStandardized } from 'types';
import { useStore } from 'store';

import { GuildWizardContainer } from './guild-wizard-container';
import { IModalSize } from 'components/dialogs/types';

interface IUseGuildDialog {
  onConfirm?: (params: {}) => void;
  products?: IProduct[];
  productSelections?: ISelectionStandardized[];
}

export const useGuildDialog = ({ products, productSelections }: IUseGuildDialog): [() => void] => {
  const { dialogStore } = useStore();

  const body = useCallback((): JSX.Element => {
    return (
      <GuildWizardContainer
        onSuccess={() => dialogStore.close()}
        products={products}
        productSelections={productSelections}
      />
    );
  }, [products, productSelections, dialogStore]);

  const openDialog = (): void => {
    dialogStore.openModal({
      title: 'Create Guild',
      body,
      fitInView: true,
      size: IModalSize.XL,
    });
  };

  return [openDialog];
};
