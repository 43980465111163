import React, { useCallback } from 'react';
import { AmGenericAsset3ProtectionOptions } from '@mediafellows/mm3-types';
import get from 'lodash/get';
import { observer } from 'mobx-react-lite';

import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';

import { useBasicStore } from 'store/hooks';

import { FormSegment, IFieldHandlers } from 'helpers/form';

import { IFormSegment } from 'helpers/form/fields/form-segment/form-segment';

import './styles.scss';

const labelToValue = {
  default: null,
  on: true,
  off: false,
};
const segmentsNoDefault = [
  { value: 'on', label: 'ON' },
  { value: 'off', label: 'OFF' },
];

const segments = [{ value: 'default', label: 'Default' }, ...segmentsNoDefault];

export const FormEnabled: React.FC<
  Omit<IFormSegment, 'segments' | 'value'> &
    IFieldHandlers<boolean | null> & { value?: boolean | null; noDefault?: boolean; defaultValue?: boolean }
> = ({ label, name, onChange, onBlur, value, defaultValue, noDefault, ...rest }) => {
  const handleChange = useCallback(
    (values: { [name: string]: string }) => {
      return onChange?.({ [name]: labelToValue[values[name]] });
    },
    [onChange, name],
  );
  const v = noDefault ? Boolean(value) : value;

  return (
    <div className="d-flex">
      <div className="asset-details-protections__default-label">{defaultValue ? 'ON' : 'OFF'}</div>
      <FormSegment
        name={name}
        label={label}
        {...rest}
        segments={noDefault ? segmentsNoDefault : segments}
        onChange={handleChange}
        onBlur={onBlur}
        className="mb-0"
        style={noDefault ? { marginLeft: '68px' } : {}}
        value={v === true ? 'on' : v === false ? 'off' : 'default'}
      />
    </div>
  );
};

const formatEnabled = (value?: boolean | null, noDefault?: boolean): string => {
  const val = noDefault ? Boolean(value) : value;
  return val === true ? 'ON' : val === false ? 'OFF' : 'Default';
};

export const EnabledField: React.FC<
  Partial<IUseMm3FormReturn<AmGenericAsset3ProtectionOptions>> & {
    editModeEnabled: boolean;
    name: string;
    disabled?: boolean | null;
    noDefault?: boolean;
  }
> = observer(({ values, handlers, formData, name, editModeEnabled, disabled, noDefault }) => {
  const { affiliationProtections } = useBasicStore();
  const protectionValue = get(affiliationProtections, name);
  if (editModeEnabled)
    return (
      <FormEnabled
        noDefault={noDefault}
        name={name}
        {...get(formData, name)}
        {...handlers}
        disabled={Boolean(disabled)}
        defaultValue={protectionValue}
      />
    );

  return (
    <div className="d-flex gap-2">
      <div className="asset-details-protections__default-label">{protectionValue ? 'ON' : 'OFF'}</div>
      <div>{formatEnabled(get(values, name), noDefault)}</div>
    </div>
  );
});
