import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Button, Modal } from '@mantine/core';

import { chipmunk } from 'utils/chipmunk';
import { Classes, PasswordVisibilityToggle } from 'utils/ui';
import { Routes } from 'utils/routes';
import { useStore } from 'store';
import { FormInput, useForm } from 'helpers/form';
import { Model } from 'helpers/filters/types';
import { ToastError } from 'components/toast';

export interface IChangePasswordFields {
  current_password: string;
  password: string;
  password_confirmation: string;
}

const initialValues: IChangePasswordFields = {
  current_password: '',
  password: '',
  password_confirmation: '',
};

export const ChangePasswordModal: React.FC = () => {
  const { toastStore } = useStore();
  const navigate = useNavigate();
  const location = useLocation();

  const { contactId } = useParams();
  const [inProgress, setInProgress] = useState(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const isChangePasswordRoute = location.pathname.includes('/change_password');

  const togglePasswordVisibility = (): void => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleClose = useCallback(() => {
    navigate(`${Routes.CONTACTS}/${contactId}/overview`);
  }, [contactId, navigate]);

  const handleSubmit = useCallback(
    (data: IChangePasswordFields, valid: boolean) => {
      if (!valid) {
        return;
      }

      setInProgress(true);

      chipmunk.run(async (chipmunk) => {
        try {
          await chipmunk.action(`${Model.CONTACTS}/password`, 'update', {
            params: { user_id: contactId },
            body: data,
          });

          toastStore.success('Password was changed successfully!');
          handleClose();
        } catch (err) {
          toastStore.error(<ToastError error={err} placeholder="Sorry, something went wrong. Please try again." />);
        }
        setInProgress(false);
      });
    },
    [contactId, handleClose, toastStore],
  );

  const { formData, handlers, valid, resetFields, onSubmit } = useForm<IChangePasswordFields>(
    initialValues,
    'um.user/password',
    handleSubmit,
  );

  useEffect(() => {
    if (!isChangePasswordRoute) {
      resetFields();
    }
  }, [isChangePasswordRoute, resetFields]);

  return (
    <Modal opened={isChangePasswordRoute} onClose={handleClose} title="Change Password">
      <form onSubmit={onSubmit}>
        <FormInput
          name="current_password"
          type={showPassword ? 'text' : 'password'}
          rightElement={<PasswordVisibilityToggle showPassword={showPassword} onToggle={togglePasswordVisibility} />}
          label="Current Password"
          showPositiveValidation={true}
          {...formData.current_password}
          {...handlers}
        />
        <FormInput
          name="password"
          type={showPassword ? 'text' : 'password'}
          rightElement={<PasswordVisibilityToggle showPassword={showPassword} onToggle={togglePasswordVisibility} />}
          label="Password"
          showPositiveValidation={true}
          {...formData.password}
          {...handlers}
        />
        <FormInput
          name="password_confirmation"
          type={showPassword ? 'text' : 'password'}
          rightElement={<PasswordVisibilityToggle showPassword={showPassword} onToggle={togglePasswordVisibility} />}
          label="Confirm Password"
          placeholder="Repeat Password"
          showPositiveValidation={true}
          {...formData.password_confirmation}
          {...handlers}
        />
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button size="xs" mr="sm" variant="default" onClick={handleClose}>
            Cancel
          </Button>
          <Button size="xs" variant="primary" type="submit" disabled={!valid} onClick={onSubmit} loading={inProgress}>
            Change
          </Button>
        </div>
      </form>
    </Modal>
  );
};
