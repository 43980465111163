import React from 'react';

import { FormCheckbox, IFormHandlers } from 'helpers/form';
import { IFormData } from 'helpers/form/mm3';
import { IAssetPresetFields } from 'types/asset';

export interface IAssetPresetFieldsProps {
  formId: string;
  formData: IFormData<Partial<IAssetPresetFields>>;
  handlers: Omit<IFormHandlers<Partial<IAssetPresetFields>>, 'onSetFields'>;
  values: IAssetPresetFields;
}

export const AvailableInVideoPlayerField = ({
  formData,
  handlers,
  formId,
  values,
}: IAssetPresetFieldsProps): JSX.Element => {
  return (
    <div className="d-flex justify-content-space-between">
      <FormCheckbox
        formId={formId}
        name="meta.show_in_player"
        label="Make available in video player"
        {...formData['meta.show_in_player']}
        {...handlers}
        className="mb-1"
        disabled={!Boolean(values.classification?.match(/audio|subtitle/i))}
      />
    </div>
  );
};
