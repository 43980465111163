import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Tooltip } from '@mantine/core';

import { AssetsUploaderStore } from 'store/assets-upload-store';
import { useBasicStore } from 'store/hooks';

import {
  FormCheckbox,
  FormChecklist,
  FormSelect as Select,
  FormMultiSelect,
  FormHtmlInput,
  FormProducts,
  FormRemoteSelect,
  FormAccessLevel,
  FormDivisions,
  FormInput,
  FormAssetStatus,
} from 'helpers/form';
import { IFormData, IFormHandlers, IFormMm3Handlers } from 'helpers/form/types';
import { IFormData as IMm3FormData } from 'helpers/form/mm3';

import { getWatermarkOptions } from 'components/asset/asset-upload-components/utils';
import { CustomAssetRow } from 'components/asset/asset-edit-form/sections/custom-row';
import { MarketingUseField } from 'components/asset/asset-upload-components/preset-form/marketing-use-field';
import { AvailableInVideoPlayerField } from './available-in-player-field';

import { AssetFilters, formatAssetClassification, formatAssetPurpose, getIsMm3Assets } from 'utils/asset';
import { getEntityType } from 'utils/general';
import { queryAssets } from 'utils/apis/asset';
import { MantineIcon } from 'utils/ui';
import { Mm3ContextDataProvider } from 'helpers/data-provider/option-data-provider';
import { Model } from 'helpers/filters/types';

import { IAsset, IAssetPresetFields, ItemId } from 'types';
import { filterClassificationsByType } from 'utils/asset-classification';

import './index.scss';

export interface IAssetPresetFieldsProps<T = IAssetPresetFields>
  extends Partial<Pick<AssetsUploaderStore, 'initialValues' | 'productPresetApplied'>> {
  formId: string;
  formData: IFormData<T>;
  handlers: IFormHandlers<T> | IFormMm3Handlers<T>;
  values: T;
  shouldShowErrors: boolean;
  withParentField?: boolean;
}

const fetchParentAssets = (q: string, id: ItemId): Promise<IAsset[]> => queryAssets({ q, ids: id ? [id] : undefined });

const subtileOptions = new Mm3ContextDataProvider(Model.MM3_SUBTITLE, 'meta.purpose');

export const AssetPresetFields: React.FC<IAssetPresetFieldsProps> = ({
  formId,
  formData,
  handlers,
  values,
  shouldShowErrors,
  withParentField,
  initialValues,
  productPresetApplied,
}) => {
  const { languagesDataOptions } = useBasicStore();
  const isMm3Assets = getIsMm3Assets();

  const FormSelect = useCallback(
    ({ touched, ...props }): JSX.Element => <Select {...props} touched={shouldShowErrors || touched} />,
    [shouldShowErrors],
  );

  const classification = values?.classification || '';
  const watermarkOptions = isMm3Assets ? [] : getWatermarkOptions(formData.watermark as { options: string[] });

  const assetItemType = getEntityType(classification);
  const isVideo: boolean = assetItemType === AssetFilters.VIDEO;
  const isAudio: boolean = assetItemType === AssetFilters.AUDIO;
  const isSubtitle: boolean = classification.includes('subtitle');

  const shouldShowVideoPlayer = isMm3Assets && values.parent_id;

  const onAccessLevelChange = useCallback(
    ({ access_level }) => {
      if (access_level !== 'division') handlers.onChange({ division_ids: [], access_level });
      else handlers.onChange({ access_level });
    },
    [handlers],
  );
  const classificationOptions = filterClassificationsByType(
    values?.type || '',
    formData.classification?.options as string[],
  );

  return (
    <div className="asset-upload-components__preset-form-container mt-3">
      <div className="asset-upload-components__preset-form-left">
        <div className="mb-3">
          {shouldShowVideoPlayer ? (
            <AvailableInVideoPlayerField
              values={values}
              formData={formData as IMm3FormData<Partial<IAssetPresetFields>>}
              handlers={handlers}
              formId={formId}
            />
          ) : (
            <MarketingUseField values={values} formData={formData} handlers={handlers} formId={formId} />
          )}
          <div className="d-flex justify-content-space-between">
            <FormCheckbox
              formId={formId}
              name="sharable"
              label="Sharable"
              className="mb-1"
              {...formData.sharable}
              {...handlers}
            />
            <Tooltip label="Allow non-Administrator contacts to share this asset with other contacts">
              <div className="info-sign_container">
                <MantineIcon icon="info-sign" size={11} color="white" />
              </div>
            </Tooltip>
          </div>
          <FormChecklist
            hasLabelOptionsCapitalized
            formId={formId}
            name="permissions"
            className="mb-1"
            {...formData.permissions}
            {...handlers}
          />
        </div>
        {isMm3Assets ? (
          <></>
        ) : (
          <div className="mt-3">
            <FormChecklist
              formId={formId}
              name="protection_levels"
              label="Protection Levels"
              {...formData.protection_levels}
              {...handlers}
              hasLabelOptionsCapitalized
            />
          </div>
        )}
      </div>
      <div className="asset-upload-components__preset-form-right">
        {!values.parent_id && (
          <div className="asset-upload-components__preset-form-products">
            <FormProducts
              name="products"
              label="Products"
              {...formData.products}
              {...handlers}
              forceReInitiating={productPresetApplied}
            />
          </div>
        )}
        <FormSelect
          name="classification"
          label="Type"
          large
          {...formData.classification}
          {...handlers}
          options={classificationOptions}
          formatLabel={formatAssetClassification}
          disabled={Boolean(initialValues?.classification)}
        />
        {isMm3Assets && withParentField && !values.products?.length && (
          <FormRemoteSelect
            fetchOptions={fetchParentAssets}
            name="parent_id"
            label="Main Asset"
            large
            {...formData.parent_id}
            {...handlers}
            emptyValue={null}
            disabled={Boolean(initialValues?.parent_id)}
            className="main-asset_field"
          />
        )}
        <CustomAssetRow formData={formData} handlers={handlers} />
        {!isMm3Assets && Boolean(watermarkOptions.length) && (
          <FormSelect
            name="watermark"
            label="Watermark"
            large
            {...formData.watermark}
            {...handlers}
            options={watermarkOptions}
          />
        )}
        <FormAccessLevel
          name="access_level"
          label="Access"
          large
          {...formData.access_level}
          {...handlers}
          onChange={onAccessLevelChange}
          filterOptionsByUserRole
        />
        {formData?.access_level?.value === 'division' && (
          <FormDivisions
            label="Divisions"
            name="division_ids"
            {...formData.division_ids}
            {...handlers}
            large
            isValueNumber
          />
        )}

        {isMm3Assets ? (
          <FormSelect
            name="meta.language_id"
            label="Language"
            {...formData['meta.language_id']}
            {...handlers}
            options={languagesDataOptions}
          />
        ) : (
          <FormMultiSelect
            name="languages"
            label="Languages"
            {...formData.languages}
            {...handlers}
            options={languagesDataOptions}
          />
        )}

        {(isSubtitle || isAudio) && isMm3Assets && (
          <FormCheckbox
            name="meta.language_id_approved"
            label="Language Approved"
            {...formData['meta.language_id_approved']}
            {...handlers}
            groupClassName="my-auto"
          />
        )}

        <FormAssetStatus name="status" label="Status" large {...formData.status} {...handlers} />

        {isVideo && (
          <>
            <FormSelect
              name="burned_in_subtitles_language_id"
              label="Burned-in Subtitles"
              large
              {...formData.burned_in_subtitles_language_id}
              {...handlers}
              options={languagesDataOptions}
            />

            <FormSelect
              name="dubbed_language_id"
              label="Dubbed language"
              large
              {...formData.dubbed_language_id}
              {...handlers}
              options={languagesDataOptions}
            />
          </>
        )}

        {(isSubtitle || isAudio) && isMm3Assets && (
          <FormSelect
            name="meta.purpose"
            label="Purpose"
            {...formData['meta.purpose']}
            {...handlers}
            formatLabel={formatAssetPurpose}
            optionsProvider={subtileOptions}
          />
        )}

        {(isSubtitle || isAudio) && isMm3Assets && (
          <FormInput name="meta.label" label="Label for video player" {...formData?.['meta.label']} {...handlers} />
        )}

        <div className="asset-upload-components__preset-form-products">
          <FormHtmlInput
            formId={formId}
            name="layer.notes"
            type="textarea"
            label="Notes"
            large
            {...formData['layer.notes']}
            {...handlers}
          />
        </div>
        {isMm3Assets && (
          <>
            <FormCheckbox
              label="AI-generated"
              name="meta.ai_generated"
              {...handlers}
              {...formData?.['meta.ai_generated']}
            />
            <FormInput
              label="AI Notes"
              name="meta.ai_notes"
              {...handlers}
              {...formData?.['meta.ai_notes']}
              disabled={Boolean(!formData?.['meta.ai_generated']?.value)}
              placeholder="Type AI Notes"
            />
          </>
        )}
      </div>
    </div>
  );
};

export default React.memo(observer(AssetPresetFields));
