import React from 'react';
import { Accordion, ActionIcon } from '@mantine/core';
import cx from 'classnames';

import { MantineIcon } from 'utils/ui';

interface IFilterAccordionItemProps {
  title: string;
  value: string;
  activeAccordion: string | null;
  activeFiltersCount: number;
  onClearFilters: (e: React.MouseEvent) => void;
  children: React.ReactNode;
  mb?: number | string;
}

export const FilterAccordionItem: React.FC<IFilterAccordionItemProps> = ({
  title,
  value,
  activeAccordion,
  activeFiltersCount,
  onClearFilters,
  children,
  mb,
}) => (
  <Accordion.Item value={value} mb={mb}>
    <Accordion.Control>
      <div className="entity-filters__title d-flex justify-content-between">
        <h4
          className={cx('d-flex align-items-center mb-0', {
            'active-accordion__element': activeAccordion === value,
          })}
        >
          {title}
          <span
            className={cx('pl-1 accordion-filter__count', {
              'active-accordion__element': activeAccordion === value,
            })}
          >
            {activeFiltersCount}
          </span>
        </h4>
        <div>
          <ActionIcon
            onClick={(e) => {
              e.stopPropagation();
              onClearFilters(e);
            }}
            variant="subtle"
            color="gray.5"
            radius="sm"
          >
            <MantineIcon icon="cross" />
          </ActionIcon>
        </div>
      </div>
    </Accordion.Control>
    <Accordion.Panel style={{ paddingTop: '0.75rem' }}>{children}</Accordion.Panel>
  </Accordion.Item>
);
