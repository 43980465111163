import { IObject } from '@mediafellows/chipmunk/dist/src/action';

import { FormsStore } from './forms-store';
import { AssetsEditStore } from './assets-edit-store';
import { ToastStore } from './toast-store';
import { BasicStore } from './basic-store';
import { DialogStore } from './dialog-store';
import { DataSectionStore } from './data-section-store';
import { AssetsUploaderStore } from './assets-upload-store';
import { AssetsUploadToSelectionStore } from 'store/assets-upload-to-selection-store';
import { SortDataSectionStore } from './sort-data-store';
import { NotificationsStore } from './notifications-store';
import { LocationsStore } from './locations-store';
import { MultiAssetsUploaderStore } from './subs-assets-upload-store';
import { ICustomization } from 'types';
import { AssetsFileUploadStore } from './assets-file-upload-store';

export class RootStore<T = IObject> {
  public toastStore: ToastStore;
  public dialogStore: DialogStore;
  public basicStore: BasicStore;
  public dataSectionStore: DataSectionStore<T>;
  public customization: ICustomization;
  public assetsUploadStore: AssetsUploaderStore;
  public assetsUploadToSelectionStore: AssetsUploadToSelectionStore;
  public assetsFileUploadStore: AssetsFileUploadStore;

  public assetsEditStore: AssetsEditStore;
  public formsStore: FormsStore;
  public sortDataSectionStore: SortDataSectionStore;
  public notificationsStore: NotificationsStore;
  public locationsStore: LocationsStore;
  public multiAssetsUploaderStore: MultiAssetsUploaderStore;

  constructor() {
    this.toastStore = new ToastStore();
    this.dialogStore = new DialogStore();
    this.basicStore = new BasicStore();
    this.dataSectionStore = new DataSectionStore();
    this.assetsUploadStore = new AssetsUploaderStore('UPLOAD_ASSETS_DROP_AREA');
    this.multiAssetsUploaderStore = new MultiAssetsUploaderStore();
    this.assetsUploadToSelectionStore = new AssetsUploadToSelectionStore();
    this.assetsFileUploadStore = new AssetsFileUploadStore();
    this.assetsEditStore = new AssetsEditStore();
    this.formsStore = new FormsStore();
    this.sortDataSectionStore = new SortDataSectionStore();
    this.notificationsStore = new NotificationsStore();
    this.locationsStore = new LocationsStore();
  }
}
