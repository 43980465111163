import React, { useCallback, useEffect } from 'react';
import { Button, FileButton } from '@mantine/core';

import { FormCheckbox, FormDate, FormInput, FormMultiSelect, FormProduct, FormSelect } from 'helpers/form';
import { InfoBoxV2 } from 'components/info-box-v2';
import { maxDate } from 'components/product-edit-sections/utils';
import { today } from 'utils/date';
import { useRemote } from 'utils/hooks';
import { downloadTemplate, uploadTemplate } from 'utils/apis/localization-order';
import { ILocalizationOrderStep } from 'components/localization-orders/types';
import { useStore } from 'store';
import { getOrganizations } from 'utils/apis/organization';
import { IOrganization } from 'types';

import '../style.scss';

export const OrderItemsStep: React.FC<ILocalizationOrderStep> = ({ orderForm: { formData, handlers, values } }) => {
  const { toastStore } = useStore();

  const downloadOrderTemplate = useCallback(async () => {
    await downloadTemplate();
  }, []);

  const uploadOderTemplate = useCallback(
    async (file) => {
      const formData = new FormData();
      formData.append('file', file);

      const { status } = await uploadTemplate(formData);

      if (status === 'success') {
        toastStore.success('The template was uploaded Successfully');
      }
    },
    [toastStore],
  );

  const fetchOrganization = useCallback(async (): Promise<IOrganization[]> => {
    if (!values.owner_organization_id) {
      return [];
    }
    return getOrganizations([values.owner_organization_id], 'id, name');
  }, [values.owner_organization_id]);

  const [organizations = []] = useRemote(fetchOrganization);

  const handleChange = ({ trailer }: { trailer: boolean }): void => {
    handlers.onChange({ trailer: trailer ? 'YES' : 'NO' });
  };

  useEffect(() => {
    if (values.subtitles && !values.metadata) {
      handlers.onChange({
        metadata: values.subtitles,
      });
    }
  }, [values.subtitles, values.metadata, handlers]);

  return (
    <div className="localization-order-project__details h-100">
      <div className="project-action-details">
        <div className="project-selected">
          <FormInput name="project_name" label="Project Name" {...formData.project_name} {...handlers} disabled />
          <FormProduct
            name="product_ids"
            label="Title Selected"
            large
            {...formData.product_id}
            {...handlers}
            disabled
          />
        </div>
        <div className="action-buttons">
          <FileButton onChange={uploadOderTemplate} accept=".xlsx, .xls">
            {(props) => (
              <Button {...props} variant="primary" size="xs">
                Upload Template
              </Button>
            )}
          </FileButton>
          <Button variant="primary" size="xs" onClick={downloadOrderTemplate}>
            Download Template
          </Button>
        </div>
      </div>

      <InfoBoxV2 gridTemplateColumns="repeat(3, 1fr)" title="PO Information">
        <FormDate
          name="due_date"
          label="Client Due Date"
          {...formData.due_date}
          {...handlers}
          emptyValue={null}
          large
          minDate={today}
          maxDate={maxDate}
          withTime
        />
        <FormSelect name="launch_territory" label="Launch Territory" {...formData.launch_territory} {...handlers} />
        <FormDate
          name="delivery_date_from_vendor"
          label="Delivery Date From Vendor"
          {...formData.delivery_date_from_vendor}
          {...handlers}
          emptyValue={null}
          large
          maxDate={maxDate}
          withTime
        />
        <FormSelect name="catalog_count" label="Catalog Count" {...formData.catalog_count} {...handlers} />
        <FormInput name="release_cycle" label="Release Cycle" {...formData.release_cycle} {...handlers} />
        <FormSelect
          name="license_type"
          label="License Type"
          {...formData.license_type}
          {...handlers}
          options={formData.license_type?.options}
        />
        <FormSelect name="licensee" label="Licensee" {...formData.licensee} {...handlers} />
        <FormSelect
          name="content_partner_name"
          label="Content Partner Name"
          {...formData.content_partner_name}
          {...handlers}
        />
        <FormSelect
          name="content_partner_alias"
          label="Content Partner Alias"
          {...formData.content_partner_alias}
          {...handlers}
        />
        <FormSelect name="billing_entity" label="Billing Entity" {...formData.billing_entity} {...handlers} />
      </InfoBoxV2>
      <InfoBoxV2 gridTemplateColumns="1fr 1fr 4fr 3fr" title="Deliverables" className="order-product-data">
        <FormSelect name="resolution" label="Resolution" {...formData.resolution} {...handlers} />
        <FormCheckbox
          name="trailer"
          label="Trailer Availability"
          {...formData.trailer}
          {...handlers}
          onChange={handleChange}
          value={formData.trailer.value === 'YES'}
        />
        <div>
          <FormMultiSelect name="subtitles" label="Subtitles" {...formData.subtitles} {...handlers} />
          {organizations[0]?.name === 'Sony' && (
            <>
              <FormMultiSelect
                name="subtitles"
                label="Subtitles - CLOSED CAPTIONS"
                {...formData.subtitles}
                {...handlers}
              />
              <FormMultiSelect name="subtitles" label="Subtitles - SDH" {...formData.subtitles} {...handlers} />
              <FormMultiSelect
                name="subtitles"
                label="Subtitles - FORCED NARATIVE"
                {...formData.subtitles}
                {...handlers}
              />
            </>
          )}
          <FormMultiSelect name="dubbing" label="Dubbing" {...formData.dubbing} {...handlers} />
          {organizations[0]?.name === 'Sony' && (
            <>
              <FormMultiSelect name="dubbing" label="Dubbing - VOICE OVER" {...formData.dubbing} {...handlers} />
              <FormMultiSelect name="dubbing" label="Dubbing - LIP SYNC" {...formData.dubbing} {...handlers} />
              <FormMultiSelect name="dubbing" label="Dubbing - LECTORING" {...formData.dubbing} {...handlers} />
            </>
          )}
          <FormMultiSelect name="metadata" label="Metadata" {...formData.metadata} {...handlers} rawLabel />
          <FormMultiSelect name="artwork_season" label="Artwork Season" {...formData.artwork_season} {...handlers} />
          <FormMultiSelect name="artwork_episode" label="Artwork Episode" {...formData.artwork_episode} {...handlers} />
          <FormSelect
            name="localized_artwork"
            label="Localized Artwork"
            {...formData.localized_artwork}
            {...handlers}
          />
        </div>
        <div>
          <FormInput
            name="content_partner_deliverables"
            label="Content Partner Deliverables"
            {...formData.content_partner_deliverables}
            {...handlers}
          />
          <FormDate
            name="content_partner_delivery_deadline"
            label="Content Partner Delivery deadline"
            {...formData.content_partner_delivery_deadline}
            {...handlers}
            emptyValue={null}
            large
            maxDate={maxDate}
            withTime
          />
          <FormInput name="notes" label="Notes" {...formData.notes} {...handlers} textarea />
        </div>
      </InfoBoxV2>
    </div>
  );
};
