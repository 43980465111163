import React, { useCallback } from 'react';

import { useStore } from 'store';

import { IUploadedFile } from 'types';

import { IUseActionsOptionParam } from 'utils/actions/types';
import { UploadAssetsForm } from 'components/upload-asset-product-dialog/components/upload-assets-form';
import { ToastError } from 'components/toast';
import { byId, pluralWordInflect } from 'utils/general';
import { createAssets } from 'pages/assets-create/api';
import { IModalSize } from 'components/dialogs/types';
import { AssetsUploaderStore } from 'store/assets-upload-store';
import { AssetUploadModalHeader } from 'components/asset/asset-upload-modal-header/asset-upload-modal-header';

export type IUseUploadAssetDialogCallback = () => void;

export function useUploadAssetDialog<T>(
  options?: IUseActionsOptionParam<T> & { assetsUploadStore?: AssetsUploaderStore; extensions?: string[] | null },
): [IUseUploadAssetDialogCallback] {
  const { dialogStore, toastStore } = useStore();
  const onSubmit = useCallback(
    async ({ isValid, files, filesRemote, showErrors, setIsSending, isSending }) => {
      try {
        if (isSending) {
          return;
        }
        if (!isValid) {
          return showErrors();
        }
        setIsSending(true);
        const filesRemoteById = byId<IUploadedFile>(filesRemote);
        await createAssets(files, filesRemoteById);
        options?.onSuccess?.();
        toastStore.success(`${pluralWordInflect('asset', files.length)} will be added`);
        dialogStore.close();
      } catch (error) {
        toastStore.error(<ToastError error={error} />);
      } finally {
        setIsSending(false);
      }
    },
    [toastStore, dialogStore, options],
  );

  const openDialog = useCallback(() => {
    dialogStore.openModal({
      className: 'upload-asset-dialog__dialog',
      size: IModalSize.XL,
      header: () => <AssetUploadModalHeader assetsUploadStore={options?.assetsUploadStore} />,
      body: () =>
        options?.assetsUploadStore ? (
          <UploadAssetsForm
            assetsUploadStore={options?.assetsUploadStore}
            productId={String(options?.productId || '')}
            onSubmit={onSubmit}
            withParentField={options?.withParentField}
            extensions={options?.extensions}
          />
        ) : (
          <></>
        ),
    });
  }, [dialogStore, options, onSubmit]);

  return [openDialog];
}
