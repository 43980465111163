import React from 'react';

import { parseSubtitles } from 'components/asset/asset-preview-tab/utils';
import { InfoBoxV2 } from 'components/info-box-v2';
import { Row } from 'components/label-value-pair';

import { useBasicStore } from 'store/hooks';
import { IAsset, IMm3Asset } from 'types';
import { formatDuration } from 'utils/date';
import { formatAssetType, formatResolution, joinLanguages } from 'utils/general';
import { withAssetVersion } from 'utils/asset';

export const AssetOldMetadataPopover: React.FC<{ asset: IAsset }> = ({ asset }) => {
  const { languages } = useBasicStore();
  const { width, height, classification, protection_levels, language_ids, duration, subtitles } = asset;
  const subs = parseSubtitles('subtitle', languages, subtitles);
  const captions = parseSubtitles('caption', languages, subtitles);
  return (
    <InfoBoxV2 gridTemplateColumns="auto auto" padded wrapperClassName="py-1">
      <Row label="Asset Type" value={formatAssetType(classification)} hidden={!classification} />
      <Row label="Resolution" value={`${formatResolution({ width, height })}`} hidden={!height && !width} />
      <Row label="Runtime" value={formatDuration(duration, 'seconds')} hidden={!duration} />
      <Row label="protection levels" value={protection_levels?.join(', ')} hidden={!protection_levels?.length} />
      <Row label="Languages" value={joinLanguages(languages, language_ids)} hidden={!language_ids?.length} />
      <Row label="Subtitles" value={subs} hidden={!subtitles?.length} />
      <Row label="Captions" value={captions} hidden={!captions?.length} />
    </InfoBoxV2>
  );
};

export const AssetMm3MetadataPopover: React.FC<{ asset: IMm3Asset }> = ({ asset }) => {
  const { classification } = asset;
  const { width, height, duration } = asset.source_meta || {};

  return (
    <InfoBoxV2 gridTemplateColumns="auto auto" padded wrapperClassName="py-1">
      <Row label="Asset Type" value={formatAssetType(classification)} hidden={!classification} />
      <Row label="Resolution" value={`${formatResolution({ width, height })}`} hidden={!height && !width} />
      <Row label="Runtime" value={formatDuration(duration, 'seconds')} hidden={!duration} />
      {/* <Row label="Languages" value={joinLanguages(languages, language_ids)} hidden={!language_ids?.length} /> */}
      {/* <Row label="Subtitles" value={subs} hidden={!subtitles?.length} /> */}
      {/* <Row label="Captions" value={captions} hidden={!captions?.length} /> */}
    </InfoBoxV2>
  );
};

export const AssetMetadataPopover = withAssetVersion(AssetOldMetadataPopover, AssetMm3MetadataPopover);
