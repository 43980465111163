import React, { useCallback } from 'react';
import { CalGenericMeetingRegular } from '@mediafellows/mm3-types';

import { useDialogStore } from 'store/hooks';
import { useStore } from 'store';
import { IUseAction } from 'utils/actions';
import { updateMeeting } from 'utils/apis/meeting';
import { AttendanceForm, IAttendeesFormData } from 'components/scheduler/attendees-form';
import { ToastError } from 'components/toast';

import { IMeetingActionName } from './types';
import { IModalSize } from 'components/dialogs/types';

const isUserAttending = (attendees: string[], email: string): boolean => {
  return attendees.includes(email);
};

export const useCheckInInviteesAction: IUseAction<CalGenericMeetingRegular, IMeetingActionName> = (items, options) => {
  const { close, openModal } = useDialogStore();
  const { dialogStore, toastStore } = useStore();

  const submit = useCallback(
    async ({ id, attendees }: IAttendeesFormData): Promise<void> => {
      try {
        if (!items?.[0] || !id || !options?.calendar?.current) {
          return;
        }
        const [{ invites = [] }] = items;
        const body = {
          id,
          invites: invites.map((e) => ({
            ...e,
            attended: isUserAttending(attendees, e.email),
            checked_in_at: isUserAttending(attendees, e.email) ? e.checked_in_at : null,
          })),
        };
        const { invites: updatedInvites } = await updateMeeting(body);

        const calendar = options.calendar.current.getApi();
        const meeting = calendar.getEventById(`${id}`);
        meeting?.setExtendedProp('invites', updatedInvites);

        toastStore.success('Invitees attendance Checked in Successfully!');
        dialogStore.close();
        options?.onSuccess?.();
      } catch (error) {
        toastStore.error(<ToastError error={error} />);
        options?.onFailure?.();
      }
    },
    [items, options, toastStore, dialogStore],
  );

  const handler = useCallback(() => {
    if (!items?.[0] || !options) {
      return;
    }

    if (options.closePrevDialog) {
      close();
    }

    return openModal({
      title: 'Check in invitees',
      body: () => {
        return <AttendanceForm {...items[0]} calendar={options.calendar?.current?.getApi()} submit={submit} />;
      },
      size: IModalSize.XS,
    });
  }, [items, options, openModal, close, submit]);

  return {
    name: IMeetingActionName.MANAGE_ATTENDEES,
    icon: 'tick',
    title: 'Check in Invitees',
    handler,
  };
};
