import { IUseActionsOptionParam, IUseActionsResponse, useActions } from 'utils/actions';
import { IScreeningRoomActionName } from './types';
import { useUploadGroupPreviewAction } from 'utils/actions/common/upload-group-preview';

import { IGroup, GroupTypes } from 'types';
import { useDeleteGroupAction } from 'utils/actions/common/delete-action';
import { IGroupActionName, shouldDisplaySingleNotDeleted, useAddEntitiesToGroupAction } from 'utils/actions/common';
import { useDeletePreviewAction } from './delete-preview';
import { useRestoreGroupAction } from 'utils/actions/common/restore-action';
import { useEmailAction } from './email-action';

export const useScreeningRoomActions = (
  entities: IGroup[],
  options: IUseActionsOptionParam,
): IUseActionsResponse<IGroupActionName | IScreeningRoomActionName> => {
  const sendEmail = useEmailAction(entities, options);
  const uploadPreviewAction = useUploadGroupPreviewAction(entities, options);
  const deleteScreeningRoomAction = useDeleteGroupAction(entities, options);
  const addContacts = useAddEntitiesToGroupAction(entities, {
    ...options,
    omni: true,
    groupType: GroupTypes.SCREENING_ROOM,
    dialogTitle: 'Add contacts to this Screening Room',
    entityType: 'contacts_and_selections',
    shouldDisplay: shouldDisplaySingleNotDeleted,
    groupLabel: 'Screening room',
    title: 'Add contacts',
  });
  const addAssets = useAddEntitiesToGroupAction(entities, {
    ...options,
    omni: true,
    groupType: GroupTypes.SCREENING_ROOM,
    dialogTitle: 'Add assets to this Screening Room',
    entityType: 'assets_and_selections',
    shouldDisplay: shouldDisplaySingleNotDeleted,
    groupLabel: 'Screening room',
    title: 'Add assets',
  });

  const deletePreview = useDeletePreviewAction(entities, options);
  const restoreAction = useRestoreGroupAction(entities, options, 'Screening Room');
  const actions = [
    uploadPreviewAction,
    addAssets,
    addContacts,
    sendEmail,
    deletePreview,
    deleteScreeningRoomAction,
    restoreAction,
  ];

  return useActions<IGroup, IGroupActionName | IScreeningRoomActionName>(actions, options, entities);
};
