import { useCallback, useMemo } from 'react';
import { IAsset, ISearchFilter, ItemId } from 'types';
import { getIsMm3Assets } from 'utils/asset';
import { tuco } from 'utils/chipmunk';
import { useRemote } from 'utils/hooks';

export const useMainAssetCountInGroup = (group_id?: ItemId): [number] => {
  const filters = useMemo(() => {
    const isMm3Assets = getIsMm3Assets();
    return [['group_ids', 'eq', group_id], ...(isMm3Assets ? [['root', 'eq', true] as ISearchFilter] : [])];
  }, [group_id]);

  const loadAssets = useCallback(
    () =>
      tuco<IAsset>('getGroupAssets', {
        body: {
          search: {
            filters,
          },
        },
      }),
    [filters],
  );

  const [result] = useRemote(loadAssets);

  return [result?.objects?.length || 0];
};
