import { useCallback } from 'react';

import { IUseActionsOptionParam } from 'utils/actions/types';
import { IAsset, IMm3Asset } from 'types';

import { useStore } from 'store';

import { useEarlyUploadImageDialog } from 'components/upload-preview-image/upload-preview-image-early';

import { shouldDisplayUploadAsset as shouldDisplay } from './actions-acl';
import { IAssetActionConfig, IAssetActionName } from 'utils/actions/asset/types';

// TODO: handle other and subtitle asset type
export const extensionsByClassification = {
  image: ['image/*'],
  video: ['video/*'],
  audio: '.aac .ac3 .aif .aiff .amr .ape .dts .flac .m4a .mka .mks .mp3 .oga .ogg .wav .wma'.split(' '),
  document: '.doc .docx .pdf .txt .rtf .odt .ott .tex .xls .xlsx .ppt .pptx .csv .html .htm .xml .md'.split(' '),
};

export const useUploadAssetAction = (
  items: (IAsset | IMm3Asset)[],
  options: IUseActionsOptionParam,
): IAssetActionConfig => {
  const {
    assetsFileUploadStore: { isUploadPending, submitFile },
  } = useStore();

  const onSubmit = useCallback(
    async (file) => {
      await submitFile(file, (asset: IAsset | IMm3Asset) => options.onSuccess?.(IAssetActionName.UPLOAD_ASSET, asset));
    },
    [options, submitFile],
  );

  const [openUploadImageDialog] = useEarlyUploadImageDialog({
    onSubmit,
    extensions: extensionsByClassification[items?.[0]?.main_classification || 'image'],
    customMessage: 'Select a file to upload',
    asset: items[0],
    onSuccess: (asset: IAsset | IMm3Asset) => options.onSuccess?.(IAssetActionName.UPLOAD_ASSET, asset),
  });

  return {
    name: IAssetActionName.UPLOAD_ASSET,
    shouldDisplay,
    icon: 'upload',
    title: 'Upload File',
    isDisabled: isUploadPending,
    handler: openUploadImageDialog,
  };
};
