import React, { useEffect } from 'react';
import { Tooltip } from '@mantine/core';

import { FormCheckbox, IFormHandlers } from 'helpers/form';
import { IAssetPresetFields } from 'types/asset';
import { MantineIcon } from 'utils/ui';
import { IFormData } from 'helpers/form/mm3';

export interface IAssetPresetFieldsProps {
  formId: string;
  formData: IFormData<Partial<IAssetPresetFields>>;
  handlers: IFormHandlers<Partial<IAssetPresetFields>>;
  values: IAssetPresetFields;
}
export const MarketingUseField: React.FC<IAssetPresetFieldsProps> = ({ formData, handlers, formId, values }) => {
  const hasSelectedProducts = Boolean(values.products?.length);

  useEffect(() => {
    handlers.onChange({
      'product.marketing_use': hasSelectedProducts,
    } as Partial<IAssetPresetFields>);
  }, [hasSelectedProducts, handlers]);

  return (
    <div className="d-flex justify-content-space-between">
      <FormCheckbox
        formId={formId}
        name="product.marketing_use"
        label="Marketing Use"
        {...formData?.['product.marketing_use']}
        {...handlers}
        className="mb-1"
        disabled={!hasSelectedProducts}
      />
      <Tooltip label={'Will be flagged for marketing use ★ on the selected Product(s)'}>
        <div className="info-sign_container">
          <MantineIcon icon="info-sign" size={11} />
        </div>
      </Tooltip>
    </div>
  );
};
