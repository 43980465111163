import React from 'react';

import { InfoBoxV2 } from 'components/info-box-v2';
import { LabelValuePair as Row } from 'components/label-value-pair';

import { formatDuration } from 'utils/date';
import { formatAssetType, formatResolution, formatType, parseDataSize } from 'utils/general';
import { Routes } from 'utils/routes';

import { IMm3Asset, IMm3AssetType } from 'types';
import { DescendantsMm3Assets } from 'components/asset/assets-aggregation/descendants-assets-links';
import { formatAssetClassification } from 'utils/asset';

export const AssetMm3GeneralTabAboutSection: React.FC<{
  asset: IMm3Asset;
}> = ({ asset }) => {
  const { id, source_meta, file_size, type, main_classification, classification } = asset || {};
  const { duration, width, height } = source_meta || {};

  const isVideo: boolean = type === IMm3AssetType.VIDEO;
  const isImage: boolean = type === IMm3AssetType.IMAGE;
  const isIMF: boolean = type === IMm3AssetType.IMF;

  return (
    <InfoBoxV2 gridTemplateColumns="minmax(123px, 1fr) 2fr" useBackground padded={false} wrapperClassName="p-3 m-3">
      <Row label="File Type" value={formatType(type)} />
      <Row label="Type" value={formatAssetClassification(main_classification)} />
      <Row label="Asset Type" value={formatAssetType(classification)} />
      <Row
        label="Sub-Assets"
        rawLabel
        value={
          <DescendantsMm3Assets
            isImf={isIMF}
            assets={asset?.children}
            fallback="—"
            muted
            baseUrl={`${Routes.ASSETS}/${id}`}
          />
        }
        hidden={!(isIMF || isVideo)}
      />
      <Row label="File Size" value={parseDataSize(file_size)} size="m" />
      <Row
        label="Resolution"
        value={`${formatResolution({ width, height })}`}
        size="m"
        hidden={!(isImage || isVideo)}
      />
      <Row label="duration" hidden={!duration} value={formatDuration(duration, 'seconds')} />
    </InfoBoxV2>
  );
};
