import React, { useRef, useCallback, useMemo, useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import cx from 'classnames';
import { ActionIcon, InputProps } from '@mantine/core';
import { DateInput3, DateInput3Props, TimePickerProps } from 'blueprint5-datetime';
import { formatFormLabel, getFieldPlaceholder } from 'helpers/form/fields/helpers';
import { IFieldData, IFieldHandlers, IFieldValidationResults } from 'helpers/form/types';
import { dateFormat, defaultDateFormat, parseDate15Minutes, parseDate, formatDate, formatStringDate } from 'utils/date';
import { isValid, addMinutes } from 'date-fns';
import { changeTimeZone } from 'helpers/form/fields/helpers';
import { MantineIcon } from 'utils/ui';
import { FormGroup } from 'helpers/form/fields/form-group';
import { Intent } from 'utils/ui/intent';

import './style.scss';

type IHTMLInputProps = React.InputHTMLAttributes<HTMLInputElement>;
type IFormDateValue = string | null;

interface IInputGroupProps extends Omit<InputProps, 'defaultValue' | 'value'> {
  defaultValue: string | undefined;
  value: string | undefined;
}

export interface IFormDateProps
  extends Omit<DateInput3Props, 'onChange'>,
    IFieldData<IFormDateValue>,
    IFieldHandlers<IFormDateValue> {
  name: string;
  label: string;
  disabled?: boolean;
  quarters?: boolean;
  placeholder?: string;
  inputProps?: IHTMLInputProps & IInputGroupProps;
  className?: string;
  formId?: string;
  large?: boolean;
  withTime?: boolean;
  showActionsBar?: boolean;
  formatOutput?: string;
  emptyValue?: IFormDateValue;
  canClearSelection?: boolean;
  hideTodayButton?: boolean;
  timeZone?: string | null;
}

const getId = ({ formId = 'id', name }: IFormDateProps): string => `${formId}-${name}`;
const parseMaxMin = (date?: Date, timeZone?: string | null): Date | undefined =>
  date && timeZone ? changeTimeZone(date, timeZone) : date;

export const FormDate: React.FC<IFormDateProps> = (props) => {
  const {
    canClearSelection = true,
    className,
    name,
    label,
    quarters,
    disabled,
    placeholder,
    inputProps,
    onChange,
    onBlur,
    validation = {} as IFieldValidationResults,
    touched,
    value,
    required,
    minDate,
    maxDate,
    large = false,
    withTime = false,
    showActionsBar = true,
    hideTodayButton = false,
    emptyValue = '',
    timeZone = '',
  } = props;
  const formattedPlaceholder = getFieldPlaceholder({
    placeholder,
    disabled,
    defaultPlaceholder: `Select ${label}`,
  });

  const inputEl = useRef<HTMLInputElement>(null);
  const formatOutput = withTime ? undefined : dateFormat;
  const showError = touched && !validation?.valid && (!isEmpty(value) || required);
  const intent = showError ? Intent.DANGER : Intent.NONE;

  const initialDate = formatOutput ? formatDate(new Date(), formatOutput) : formatDate(new Date(), defaultDateFormat);
  const [selectedDate, setSelectedDate] = useState<string | null>(initialDate);

  const min = useMemo(() => parseMaxMin(minDate, timeZone), [minDate, timeZone]);
  const max = useMemo(() => parseMaxMin(maxDate, timeZone), [maxDate, timeZone]);

  const changeHandler = useCallback(
    (selectedDate: string): void => {
      const selectedDateOrNull = quarters ? parseDate15Minutes(selectedDate, min, max) : selectedDate;

      if (!selectedDateOrNull) {
        setSelectedDate(null);
        onChange?.({ [name]: emptyValue });
        return;
      }

      const formatValue = formatOutput
        ? formatDate(formatStringDate(selectedDateOrNull), formatOutput)
        : new Date(selectedDateOrNull).toISOString();

      onChange?.({ [name]: formatValue });
    },
    [name, onChange, formatOutput, emptyValue, min, max, quarters],
  );

  const isInitializedRef = useRef<string>('');
  useEffect(() => {
    if (!value) {
      setSelectedDate(null);
      return;
    }

    let date = new Date(value);

    if (timeZone && isInitializedRef.current !== timeZone) {
      isInitializedRef.current = timeZone;
      date = changeTimeZone(value, timeZone);
      changeHandler(formatDate(date));
    }
    const dateUTC = addMinutes(date, date.getTimezoneOffset());
    const newDate = formatOutput ? dateUTC : date;

    if (isValid(newDate)) {
      const formatedDate = quarters ? parseDate15Minutes(newDate, min, max) : parseDate(newDate, min, max);
      setSelectedDate(formatDate(formatedDate as Date));
    }
  }, [value, timeZone, min, max, formatOutput, quarters, changeHandler]);

  const handleBlur = useCallback(() => {
    onBlur?.(name);
  }, [name, onBlur]);

  const handleDateParse = (str: string): Date | null | false => {
    if (!str) return null;
    const date = new Date(str);
    if (!isValid(date)) return false;
    return quarters ? parseDate15Minutes(new Date(str), min, max) : parseDate(new Date(str), min, max);
  };

  const rightElement = useMemo(() => {
    return (
      <ActionIcon variant="subtle" onClick={() => inputEl.current?.focus()} disabled={disabled}>
        <MantineIcon icon="calendar" />
      </ActionIcon>
    );
  }, [disabled]);

  const timePickerProps: TimePickerProps | undefined = useMemo(() => {
    if (!withTime) {
      return undefined;
    }

    return { timePrecision: 'minute', showArrowButtons: true };
  }, [withTime]);

  const handleFormatDate = (date): string => {
    const format = withTime ? defaultDateFormat : 'dd MMM yyyy';
    return formatDate(date, format);
  };

  return (
    <FormGroup
      label={formatFormLabel(label, required)}
      labelFor={name}
      intent={intent}
      helperText={showError ? validation.errorMessage : ''}
    >
      <DateInput3
        className={cx('date-input-wrapper', className, { 'no-today-button': hideTodayButton })}
        inputProps={{ ...inputProps, id: getId(props), name, onBlur: handleBlur, large, inputRef: inputEl }}
        placeholder={formattedPlaceholder}
        rightElement={rightElement}
        disabled={disabled}
        onChange={changeHandler}
        formatDate={handleFormatDate}
        parseDate={handleDateParse}
        value={selectedDate}
        showActionsBar={showActionsBar}
        minDate={minDate}
        maxDate={maxDate}
        timePrecision={withTime ? 'minute' : undefined}
        timePickerProps={timePickerProps}
        closeOnSelection={!withTime}
        canClearSelection={canClearSelection}
        showTimezoneSelect={false}
      />
    </FormGroup>
  );
};

export default FormDate;
