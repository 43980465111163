import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';

import { SectionHeader } from 'components/section-header';
import { useMultiAssetsUploadStore } from 'store/hooks';
import AssetsFormHeader from './components/assets-form-header';
import FormSubmit from './components/form-submit';
import { AssetsUploadDashboard } from 'components/asset/asset-upload-components/upload-dashboard/upload-dashboard';
import { AssetForm } from 'components/asset/asset-upload-components/single-asset-form/single-asset-form';
import { SelectAssetPreset } from 'components/asset/asset-upload-components/select-preset-modal/select-preset-modal';
import { useStore } from 'store';
import { IAssetPresetFields } from 'types';
import SaveAssetPresetForm from 'components/asset/asset-upload-components/save-preset-modal/save-preset-modal';
import { getStorageItem, STORAGE_KEYS } from 'utils/storage';
import { IModalSize } from 'components/dialogs/types';

import '@uppy/core/dist/style.css';
import '@uppy/drag-drop/dist/style.css';
import './index.scss';

export const AssetsUpload: React.FC = observer(() => {
  const location = useLocation();
  const storeId = location?.state?.storeId;
  const { getOrCreateUploaderStore } = useMultiAssetsUploadStore();

  const store = getOrCreateUploaderStore(storeId || 'create-asset-page');
  const {
    files,
    initializeUppy,
    uppy,
    removeFile,
    savePreset,
    progress,
    applyPreset,
    setIsValidForm,
    onDataChange,
    shouldShowErrors,
    dropFileAreaSelector,
  } = store;
  const { dialogStore } = useStore();
  const [presets, setPresets] = useState<Record<string, IAssetPresetFields>>(
    getStorageItem(STORAGE_KEYS.ASSET_UPLOAD_PRESETS) as Record<string, IAssetPresetFields>,
  );

  useEffect(() => {
    return initializeUppy();
  }, [initializeUppy]);

  const openSavePresetModal = useCallback(
    (preset: IAssetPresetFields) => {
      savePreset(preset);
      dialogStore.openModal({
        title: `Save as preset`,
        body: () => <SaveAssetPresetForm preset={preset} setPresets={setPresets} />,
        size: IModalSize.XS,
      });
    },
    [dialogStore, savePreset],
  );

  const openPresetSelector = useCallback(() => {
    dialogStore.openModal({
      title: 'Select Preset',
      body: () => (
        <SelectAssetPreset
          {...{ presets, shouldShowErrors, applyPreset }}
          withParentField
          assetsUploaderStore={store}
        />
      ),
      className: 'asset-upload-components__modal-wrapper',
      size: IModalSize.M,
    });
  }, [applyPreset, dialogStore, presets, shouldShowErrors, store]);

  return (
    <div className="assets-upload h-100 d-flex flex-column  p-3">
      <h2 className="mt-2 mb-4">Upload New Assets</h2>
      <div className="flex-fill">
        <div className="assets-upload__content h-100">
          <div className="assets-upload__dashboard h-100 d-flex flex-column">
            <SectionHeader useBackground title="Upload Assets" />
            <AssetsUploadDashboard
              {...{ progress, removeFile, files, uppy }}
              dropAreaId={dropFileAreaSelector}
              key={dropFileAreaSelector}
            />
          </div>
          <div className="assets-upload__form d-flex flex-column">
            <AssetsFormHeader openPresetModal={openPresetSelector} assetsUploaderStore={store} />
            <div className="asset-upload__wrapper">
              <div className="asset-upload__content">
                {files.map((file, index) => (
                  <AssetForm
                    key={file.id}
                    initialValues={file}
                    assetsUploaderStore={store}
                    {...{
                      index,
                      savePreset: openSavePresetModal,
                      applyPreset,
                      setIsValidForm,
                      removeFile,
                      onDataChange,
                      shouldShowErrors,
                      withParentField: true,
                    }}
                  />
                ))}
              </div>
            </div>
            {Boolean(files.length) && <FormSubmit assetsUploaderStore={store} />}
          </div>
        </div>
      </div>
    </div>
  );
});
