import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Tabs } from '@mantine/core';
import { useNavigate } from 'react-router';

import { DetailsPageTabs } from 'types';
import { SectionHeader } from 'components/section-header';
import { BodyWrapper } from 'components/layout';

import { useTab } from 'utils/hooks';

import { OrderDetailsOverview } from './order-details-overview';
import { IOrder, OrderDetailTab, OrderTabPanel } from './utils';
import { OrderRecipientsTab } from './order-recipients-tab';
import { OrderProductsTab } from './order-products-tab';
import { OrderItemsGrid } from './order-items-tab';
import { ActionsMenu } from 'components/action/utils';
import { LocalizationOrderActionsList } from 'components/action';
import { IUseActionsOptionParam, Pages } from 'utils/actions';
import { ILocalizationOrder } from 'types/localization-order';
import { IActionName } from 'utils/actions/types';
import { Routes } from 'utils/routes';
import { ILocalizationOrderActionName } from 'utils/actions/localization-order';

import './style.scss';

const tabOptions = [DetailsPageTabs.ORDER_ITEMS, DetailsPageTabs.PRODUCTS, DetailsPageTabs.RECIPIENTS];

export const OrderDetails: React.FC<{
  page?: Pages;
  order: IOrder;
  setOrder: React.Dispatch<React.SetStateAction<IOrder>>;
}> = observer(({ order, setOrder, page }) => {
  const { project_name, product_id, recipient_list } = order || {};
  const [currentTab, setCurrentTab] = useTab(DetailsPageTabs.ORDER_ITEMS, tabOptions);
  const navigate = useNavigate();

  const handleTabChange = useCallback(
    (tab): void => {
      setCurrentTab(tab);
    },
    [setCurrentTab],
  );

  const onSuccess = useCallback(
    async (action?: IActionName, newValue?: ILocalizationOrder): Promise<void> => {
      switch (action) {
        case ILocalizationOrderActionName.DELETE:
          navigate(`${Routes.ORDER_MANAGEMENT}`);
          break;
        default:
          setOrder((oldValue) => ({
            ...oldValue,
            ...newValue,
          }));
      }
    },
    [navigate, setOrder],
  );

  const options = useMemo<IUseActionsOptionParam<ILocalizationOrder>>(
    () => ({ context: 'single', page, onSuccess }),
    [onSuccess, page],
  );

  return (
    <BodyWrapper>
      <SectionHeader
        title={project_name}
        subtitle={
          <span className="muted-text">
            {[[`${[product_id].length || 0} Product`], `${recipient_list?.length || 0} Recipient(s)`].join(' | ')}
          </span>
        }
        actions={[]}
        useBackground
        dropdownMenu={<ActionsMenu component={LocalizationOrderActionsList} items={[order]} options={options} />}
      />
      <div className="delivery-details__content-wrapper">
        <OrderDetailsOverview order={order} />
        <div className="delivery-details__tabs-wrapper">
          <Tabs value={currentTab} onChange={handleTabChange} className={'delivery-details__tabs h-100'}>
            <Tabs.List className="d-flex flex-nowrap">
              <OrderDetailTab value={DetailsPageTabs.ORDER_ITEMS} activeTab={currentTab} />

              <OrderDetailTab value={DetailsPageTabs.PRODUCTS} activeTab={currentTab} />

              <OrderDetailTab value={DetailsPageTabs.RECIPIENTS} activeTab={currentTab} />
            </Tabs.List>
            <OrderTabPanel value={DetailsPageTabs.ORDER_ITEMS} content={<OrderItemsGrid order={order} />} />

            <OrderTabPanel value={DetailsPageTabs.PRODUCTS} content={<OrderProductsTab order={order} />} />

            <OrderTabPanel value={DetailsPageTabs.RECIPIENTS} content={<OrderRecipientsTab order={order} />} />
          </Tabs>
        </div>
      </div>
    </BodyWrapper>
  );
});

export default OrderDetails;
