import { EqFilter, InFilter, NotExistFilter, RangeFilter, QFilter, IFiltersDefinition } from 'helpers/filters/types';
import { additionalFilters } from 'components/product-filters/additional-filters';
import { IFilters } from './types';
import omit from 'lodash/omit';
import { IAssetFilters } from 'components/asset/asset-filters';

export const noParentFilter = (defaultValue = true): { no_parent: NotExistFilter } => ({
  no_parent: new NotExistFilter('parent_id', defaultValue),
});

export const getDefaultFilters = ({ showParentsOnlyFilter = true, parentFilterDefaultValue = true }): IFilters => {
  return {
    _: new QFilter(''),
    type: new InFilter('type', []),
    updated_at: new RangeFilter('updated_at', '', ''),
    created_at: new RangeFilter('created_at', '', ''),
    access_level: new InFilter('access_level', []),
    owner_id: new EqFilter('owner_id', ''),
    owner_organization_id: new EqFilter('owner_organization_id', ''),
    'default_layer.meta.year_of_production': new RangeFilter('default_layer.meta.year_of_production', 1896, 2040),
    'default_layer.meta.language_ids': new EqFilter('default_layer.meta.language_ids', ''),
    'default_layer.meta.category_ids': new InFilter('default_layer.meta.category_ids', []),
    division_ids: new InFilter('division_ids', []),
    ...(showParentsOnlyFilter ? noParentFilter(parentFilterDefaultValue) : undefined),
    ...additionalFilters,
  } as IFilters;
};

export const stats = [
  'access_level',
  'type',
  'default_layer.meta.category_ids',
  'default_layer.meta.language_ids',
  'division_ids',
  ...Object.keys(omit(additionalFilters, ['meta.ccid.fts'])),
].join(',');

export function getActiveFiltersCount<T extends IFiltersDefinition = IAssetFilters>(
  filterValues: T,
  filterAttributes: string[] = [],
): number {
  let count = 0;

  for (const filter of Object.values(filterValues)) {
    if (!('method' in filter)) {
      continue;
    }

    if (!filterAttributes?.includes(filter.attribute)) {
      continue;
    }

    switch (filter.method) {
      case 'in': {
        const inFilter = filter as InFilter;
        if (Array.isArray(inFilter.value) && inFilter.value.length > 0) {
          count++;
        }
        break;
      }
      case 'range': {
        const rangeFilter = filter as RangeFilter;
        const { start, end } = rangeFilter;

        if (
          (typeof start === 'string' && start !== '') ||
          (typeof end === 'string' && end !== '') ||
          (typeof start === 'number' && typeof end === 'number' && !(start === 1896 && end === 2040))
        ) {
          count++;
        }
        break;
      }
      case 'not_exist': {
        if (filter.value === true) {
          count++;
        }
        break;
      }
      case 'eq':
      case 'q': {
        if (filter.value !== '' && filter.value !== false) {
          count++;
        }
        break;
      }
    }
  }

  return count;
}

export const essentialFilters = [
  'type',
  'parent_id',
  'default_layer.meta.category_ids',
  'default_layer.meta.language_ids',
  'default_layer.meta.year_of_production',
  'default_layer.meta.year_of_production',
  'meta.ccid.fts',
];

export const accessAndOwnershipFilters = ['access_level', 'owner_id', 'division_ids', 'owner_organization_id'];
export const timestampsFilters = ['created_at', 'updated_at'];
