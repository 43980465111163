import React, { useCallback } from 'react';
import { Button } from '@mantine/core';

import { IGroup, IIdentifiable, ItemId } from 'types';
import { useForm } from 'helpers/form';
import { Model } from 'helpers/filters/types';
import { useStore } from 'store';

import { sendScreeningsEmails } from 'utils/apis/screening-room';

import { ScreeningRoomFormEmail } from './screening-room-notify-email';
import { IScreeningRoomEmail } from './types';
import { Classes } from 'utils/ui';

interface IScreeningRoomEmailSetupProps {
  item: IGroup & IIdentifiable;
  onSuccess?: () => void;
  onFailure?: () => void;
  user_ids: ItemId[];
}

const customContext = {
  properties: {
    email_subject: { required: true, validations: [{ length: { maximum: 300 } }] },
    email_recipient_ids: { required: true },
    email_message: { required: true, validations: [{ length: { maximum: 2000 } }] },
  },
};

export const ScreeningRoomEmailSetup: React.FC<IScreeningRoomEmailSetupProps> = ({
  onSuccess,
  onFailure,
  item,
  user_ids,
}) => {
  const {
    toastStore,
    basicStore: { affiliation },
  } = useStore();
  const { primary_buyer_domain } = affiliation;

  const sendEmail = useCallback(
    async (values: IScreeningRoomEmail) => {
      try {
        const { email_subject, email_message, email_recipient_ids } = values;
        toastStore.info('Submitting');
        await sendScreeningsEmails({
          user_ids: email_recipient_ids,
          subject: email_subject,
          message: email_message,
        });

        toastStore.clearAll();
        toastStore.success('Email notification(s) sent successfully');

        onSuccess?.();
      } catch {
        onFailure?.();
      }
    },
    [onSuccess, onFailure, toastStore],
  );

  const emailMessage = `
    <p>
      The Screening Room
      <a href="${primary_buyer_domain?.name}/screening-details/${item.id}">${item.name}</a>
      has just been created for you.
    </p>

    <p>
      Please click
      <a href="${primary_buyer_domain?.name}/screening-details/${item.id}">this link</a>
      to see its content.
    </p>
  `;

  const form = useForm<IScreeningRoomEmail>(
    {
      email_subject: 'A new Screening Room is available',
      email_recipient_ids: user_ids,
      email_message: emailMessage,
    },
    Model.WORKFLOW,
    sendEmail,
    customContext,
  );

  return (
    <>
      <ScreeningRoomFormEmail form={form} />
      <Button
        variant="primary"
        size="md"
        onClick={form.onSubmit}
        disabled={!form.valid}
        className={`${Classes.DIALOG_FOOTER_ACTIONS} m-3 justify-content-center`}
      >
        Send Email
      </Button>
    </>
  );
};
