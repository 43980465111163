import React, { useCallback } from 'react';
import cx from 'classnames';

import { FormCheckbox, FormProducts, IFormMultiSelectOption } from 'helpers/form';

import { IRecommendProductsStep } from './types';
import { IGroup, IIdentifiable, IProduct, IQueryParams, ISearchFilter } from 'types';
import { querySelections } from 'utils/apis/groups';
import { fetchProductsWithAncestry } from 'utils/apis/product';
import { parseProducts } from 'components/recommendation/recommendation-shared/utils';
import { ActionIcon, Tooltip } from '@mantine/core';
import { MantineIcon } from 'utils/ui';
import { flags } from 'utils/flags';

export const queryProductSelection = async (
  params: IQueryParams,
  filters: ISearchFilter[] = [],
): Promise<((IProduct | IGroup) & IIdentifiable)[]> => {
  if (params?.ids?.length === 0) {
    return [];
  }
  return querySelections(params, filters, 'products');
};

export const RecommendProductsStepProducts: React.FC<IRecommendProductsStep> = ({
  form: { formData, handlers, values },
}) => {
  const { showFutureEpisodesFeature } = flags;

  const fetchProducts = useCallback(
    async ({ q, ids }: IQueryParams, filters: ISearchFilter[]): Promise<(IProduct | IGroup | string)[]> => {
      const productIds = ids?.filter(
        (id) => !values.product_selections?.map((selection) => selection.id).includes(id as number),
      );
      const productPromise = fetchProductsWithAncestry({ q, ids: productIds }, filters);
      const selectionPromise = queryProductSelection({ q, ids }, filters);

      const [products, selections] = await Promise.all([productPromise, selectionPromise]);
      return [...products, ...selections];
    },
    [values.product_selections],
  );

  const setProducts = useCallback(
    (options: IFormMultiSelectOption[]): void => {
      handlers.onChange(parseProducts(options));
    },
    [handlers],
  );

  return (
    <div className="product-recommendation-form h-100">
      <FormProducts
        fetchValues={fetchProducts}
        name="productList"
        label="Products"
        onSelectedItemsChange={setProducts}
        fitInParent
        omni
        {...formData.productList}
        {...handlers}
        required
      />
      <FormCheckbox
        className={cx('product-recommendation-form__generate-links mb-0', {
          'd-flex align-items-center': showFutureEpisodesFeature,
          'd-none': !showFutureEpisodesFeature,
        })}
        name="includeFutureEpisodes"
        labelElement={
          <div className="d-flex align-items-center pb-1">
            <span>Include Future Episodes</span>
            <Tooltip
              label="All selected products on this recommendation will include Future Episodes"
              position="bottom"
            >
              <ActionIcon variant="subtle">
                <MantineIcon icon="help" />
              </ActionIcon>
            </Tooltip>
          </div>
        }
        {...formData.includeFutureEpisodes}
        {...handlers}
      />
    </div>
  );
};
