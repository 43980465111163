import React from 'react';
import { Tooltip } from '@mantine/core';

import { IColumn } from 'components/list-table';

import { IAudioTrack, IMm3AudioTrack } from 'types';
import { formatType } from 'utils/format-type';
import { Position } from 'utils/ui/position';
import { getIsMm3Assets } from 'utils/asset';

export const columns: IColumn<IAudioTrack | IMm3AudioTrack>[] = [
  {
    title: 'File Name',
    renderer: ({ storage_url }) => {
      const fileName = storage_url?.split('/').pop();
      return (
        <Tooltip key={storage_url} label={fileName} position={Position.TOP}>
          <div className="text-truncate">{fileName}</div>
        </Tooltip>
      );
    },
    columnClassName: 'asset-artifact__filename text-truncate',
  },
  {
    title: 'Status',
    renderer: ({ status }) => status,
    columnClassName: 'asset-artifact__status text-capitalize',
  },
  {
    title: 'Type',
    renderer: (artifact: IAudioTrack | IMm3AudioTrack) => {
      const type = getIsMm3Assets() ? artifact['$type'] : artifact['@type'];

      return formatType(type, '/');
    },
    columnClassName: 'asset-artifact__type text-capitalize',
  },
  {
    title: 'Format',
    renderer: ({ storage_url }) => formatType(storage_url, '.'),
    columnClassName: 'asset-artifact__format text-capitalize',
  },
];
