import { useCallback } from 'react';
import { map } from 'lodash';
import { ILocalizationOrder } from 'types/localization-order';
import { useStore } from 'store';

import { ILocalizationOrderActionName } from './types';
import { isOrderDraftAndSingleContext as shouldDisplay } from './actions-acl';

import { IUseAction } from 'utils/actions';
import { deleteOrder } from 'utils/apis/localization-order';
import { pluralEntityInflect } from 'utils/general';
import { Intent } from 'utils/ui';

export const useDeleteAction: IUseAction<ILocalizationOrder, ILocalizationOrderActionName> = (items, options) => {
  const { toastStore, dialogStore } = useStore();
  const { entityWithDemonstrative, entity, entityWithCount } = pluralEntityInflect('Order', items.length);
  const executeDelete = useCallback(async () => {
    try {
      await deleteOrder(map(items, 'id'));
      toastStore.success(`${entityWithCount} deleted`);
      return await options?.onSuccess?.(ILocalizationOrderActionName.DELETE);
    } catch (error) {
      toastStore.error(`${entity} delete failed: ${error.text}`);
      await options?.onFailure?.();
    }
  }, [entity, entityWithCount, items, options, toastStore]);
  const handler = useCallback(() => {
    dialogStore.openConfirm({
      title: 'Confirm Delete',
      body: `Are you sure you want to delete ${entityWithDemonstrative}?`,
      onConfirm: executeDelete,
    });
  }, [dialogStore, entityWithDemonstrative, executeDelete]);
  return {
    name: ILocalizationOrderActionName.DELETE,
    shouldDisplay,
    icon: 'trash',
    title: 'Delete',
    intent: Intent.DANGER,
    handler,
  };
};
