import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import cx from 'classnames';
import { IObject } from '@mediafellows/chipmunk/dist/src/action';
import { ActionIcon, Button, Popover, Switch } from '@mantine/core';
import { EventInput } from '@fullcalendar/react';

import { FormInvitees } from './form-invitees';
import { FormHosts } from './form-hosts';
import { FormParticipants } from './form-participants';
import { SaveFilterPreset, SelectFilterPreset } from './filter-preset-selector';
import { IMeetingPropsFilters } from './calendar';

import { FormSelect, FormRemoteSelect } from 'helpers/form';
import { ToastError } from 'components/toast';
import { getRootStore } from 'store/index';
import { useSessionStore } from 'store/session-store';
import { getTimezones } from 'utils/apis/meeting';
import { queryLocation } from 'utils/apis/location';
import { MantineIcon } from 'utils/ui';
import { chipmunk } from 'utils/chipmunk';
import { MeetingTypes, countActiveCalendarFilters } from './utils';

import './style.scss';

interface IMeetingFiltersProps {
  timezone: string;
  filters: IMeetingPropsFilters;
  setFilters: React.Dispatch<React.SetStateAction<IMeetingPropsFilters>>;
  setTimezone: (tz: string) => void;
  events?: EventInput[];
}
interface ISavedFilter {
  id: number;
  name: string;
  filters: IMeetingPropsFilters;
}

const filtersToString = (filters: IMeetingPropsFilters): string => {
  const keys = Object.keys(filters)
    .filter((key) => Boolean(filters[key]))
    .sort();

  if (!keys.length) return '';

  const resultSetArr: [string, IMeetingPropsFilters][] = [];
  for (const key of keys) resultSetArr.push([key, filters[key]]);
  return JSON.stringify(resultSetArr);
};

const objectToSavedFilter = (object?: IObject | undefined): ISavedFilter | undefined => {
  if (!(object instanceof Object)) return;
  let { description } = object;
  const { id, search_params } = object;
  if (!Number.isInteger(id) || id <= 0) return;
  if (typeof description !== 'string') return;
  if (!(description = description.trim()).length) return;
  if (!(search_params instanceof Object)) return;
  if (!Object.keys(search_params).length) return;
  return { id, name: description, filters: search_params };
};

const loadSchedulerFilters = async (): Promise<ISavedFilter[]> => {
  let objects: IObject[] | undefined = undefined;
  const { toastStore } = getRootStore();
  return chipmunk.run(async (chipmunk) => {
    try {
      objects = (
        await chipmunk.action('um.saved_search', 'query', {
          params: {
            target: 'scheduler-filters',
          },
        })
      ).objects;
    } catch (error) {
      toastStore.error(<ToastError error={error} placeholder={'Sorry, something went wrong'} />);
    }

    if (!(objects instanceof Array)) objects = [];

    const result: ISavedFilter[] = [];

    for (const object of objects) {
      const savedFilter = objectToSavedFilter(object);
      if (!savedFilter) continue;
      result.push(savedFilter);
    }

    return result;
  });
};

const saveSchedulerFilter = async (name: string, filters: IMeetingPropsFilters): Promise<ISavedFilter | undefined> => {
  let result: ISavedFilter | undefined = undefined;
  const { toastStore } = getRootStore();
  return chipmunk.run(async (chipmunk) => {
    try {
      result = objectToSavedFilter(
        (
          await chipmunk.action('um.saved_search', 'create', {
            body: {
              description: name,
              target: 'scheduler-filters',
              search_params: filters,
            },
          })
        )?.object,
      );
    } catch (error) {
      toastStore.error(<ToastError error={error} placeholder={'Sorry, something went wrong'} />);
    }
    return result;
  });
};

const updateSchedulerFilter = async (
  filterItem: ISavedFilter,
  newFilters: IMeetingPropsFilters,
): Promise<ISavedFilter | undefined> => {
  let result: ISavedFilter | undefined = undefined;
  const { toastStore } = getRootStore();
  return chipmunk.run(async (chipmunk) => {
    try {
      result = objectToSavedFilter(
        (
          await chipmunk.action('um.saved_search', 'update', {
            body: {
              description: filterItem.name,
              target: 'scheduler-filters',
              search_params: newFilters,
            },
            params: { id: filterItem.id },
          })
        )?.object,
      );
    } catch (error) {
      toastStore.error(<ToastError error={error} placeholder="Sorry, something went wrong" />);
    }
    return result;
  });
};

const removeSchedulerFilter = async (id: number): Promise<boolean> => {
  let result = false;
  const { toastStore } = getRootStore();
  return chipmunk.run(async (chipmunk) => {
    try {
      result =
        (
          await chipmunk.action('um.saved_search', 'delete', {
            body: { id },
          })
        )?.object?.id === id;
    } catch (error) {
      toastStore.error(<ToastError error={error} placeholder={'Sorry, something went wrong'} />);
    }
    return result;
  });
};

const MeetingFilters: React.FC<React.PropsWithChildren<IMeetingFiltersProps>> = ({
  timezone,
  setFilters,
  setTimezone,
  filters,
  events,
}: IMeetingFiltersProps) => {
  const [timezones, setTimezones] = useState<string[]>([]);
  const user = useSessionStore((state) => state.user);
  const [openedPopover, setOpenedPopover] = useState(false);
  const [busy, setBusy] = useState(true);
  const [presetName, setPresetName] = useState('');
  const [savedFilters, setSavedFilters] = useState<undefined | ISavedFilter[]>(undefined);

  useEffect(() => {
    loadSchedulerFilters().then((filters) => {
      setSavedFilters(filters);
      setPresetName('');
      setBusy(false);
    });
  }, []);

  useEffect(() => {
    async function fetchData(): Promise<void> {
      if (isEmpty(timezones)) {
        const timezones = await getTimezones();
        setTimezones(['local', ...timezones]);
      }
    }
    fetchData();
  }, [timezones]);

  const onLocationChange = async ({ location_id }: Partial<IMeetingPropsFilters>): Promise<void> => {
    setFilters((filters) => ({
      ...filters,
      location_id,
    }));
  };

  const onOnlyMeSwitch = ({ only_me }: Partial<IMeetingPropsFilters>): void => {
    if (user?.id) {
      setFilters((oldFilters) => ({
        ...oldFilters,
        only_me,
        participant_ids: only_me ? user.id : null,
        host_id: null,
        invites_id: null,
        type: only_me ? undefined : MeetingTypes.REGULAR,
      }));
    }
  };

  const onMeetingTypeChange = (event): void => {
    const value = event.currentTarget.checked;
    setFilters((oldFilters) => ({
      ...oldFilters,
      type: value ? undefined : MeetingTypes.REGULAR,
    }));
  };

  const onParticipantChange = ({ participant_ids }: Partial<IMeetingPropsFilters>): void => {
    setFilters((filters) => ({
      ...filters,
      participant_ids,
      host_id: null,
      invites_id: null,
      only_me: participant_ids === user?.id,
    }));
  };

  const onChange = (val: Partial<IMeetingPropsFilters>): void => {
    setFilters((oldFilters) => ({
      ...oldFilters,
      ...val,
      participant_ids: null,
      only_me: false,
    }));
  };

  const inviteIds = events?.map((e) => e.invites.map((i) => i.user_id)).flat();

  const matchingFilter = ((): ISavedFilter | undefined => {
    if (!savedFilters) return undefined;

    const propsFiltersStr = filtersToString(filters);
    const foundFilter = savedFilters?.find((savedFilter) => filtersToString(savedFilter.filters) === propsFiltersStr);

    return foundFilter;
  })();

  const activeFilters = countActiveCalendarFilters(filters, timezone);

  return (
    <div className="meeting-filters">
      <div className="meeting-filters__content">
        <SelectFilterPreset
          filters={filters}
          onChange={setFilters}
          matchingFilter={matchingFilter}
          savedFilters={savedFilters}
          busy={busy}
          setBusy={setBusy}
          removeSchedulerFilter={removeSchedulerFilter}
          setSavedFilters={setSavedFilters}
        />
        <Popover
          position="bottom-start"
          offset={0}
          onOpen={() => setOpenedPopover(true)}
          onClose={() => setOpenedPopover(false)}
          closeOnClickOutside={false}
        >
          <div className="d-flex align-items-center gap-2">
            <Popover.Target>
              <ActionIcon
                variant="subtle"
                color="gray.5"
                className={cx('', { 'active-popover__icon': Boolean(openedPopover) })}
              >
                <MantineIcon icon="filter-list" />
              </ActionIcon>
            </Popover.Target>
            <span className="filter__count">{activeFilters}</span>
            <Button
              onClick={() => {
                setFilters({
                  type: MeetingTypes.REGULAR,
                });
              }}
              leftSection={<MantineIcon icon="cross" />}
              variant="subtle"
              size="xs"
              disabled={activeFilters === 0}
            >
              Clear All
            </Button>
          </div>
          <Popover.Dropdown>
            <div className="popover-content d-flex flex-column gap-3">
              <div className="entity-filters__title mb-3">
                <h4 className="mb-3">New Filter</h4>
                <div className="d-flex justify-content-between">
                  <FormSelect
                    label="Timezone"
                    placeholder="Select Timezone"
                    name="timezone"
                    value={timezone}
                    options={timezones}
                    onChange={(value) => setTimezone(String(value['timezone']))}
                    size="xs"
                    className="form-timezone"
                  />
                  <FormRemoteSelect
                    label="Location"
                    placeholder="Select Location"
                    name="location_id"
                    fetchOptions={queryLocation}
                    onChange={onLocationChange}
                    value={filters.location_id}
                    className="form-remote-select"
                  />
                </div>
                <div className="d-flex justify-content-between">
                  <FormHosts
                    label="Hosts"
                    placeholder="Select Hosts"
                    name="host_id"
                    onChange={onChange}
                    value={filters.host_id}
                    className="form-hosts"
                  />
                  <FormInvitees
                    label="Invitees"
                    placeholder="Select Invitees"
                    name="invites_id"
                    ids={inviteIds}
                    onChange={onChange}
                    value={filters.invites_id}
                    className="form-invitees"
                  />
                </div>
                <FormParticipants
                  label="Either"
                  placeholder="Select Participants"
                  name="participant_ids"
                  ids={inviteIds}
                  onChange={onParticipantChange}
                  value={filters.participant_ids}
                  className="form-participants"
                />
                <Switch
                  className="meeting-filters__checkbox"
                  onChange={(event) => onOnlyMeSwitch({ only_me: event.currentTarget.checked })}
                  label="Show my meetings only"
                  checked={Boolean(filters.only_me)}
                />
                <div className="d-flex align-items-center justify-content-between">
                  <Switch
                    label="Include personal meetings"
                    data-param="type"
                    onChange={onMeetingTypeChange}
                    checked={Boolean(!filters.type)}
                  />
                </div>
              </div>
              <div className="entity-filters__title">
                <div className="d-flex align-items-center">
                  <h4 className="mb-3">Filter Presets</h4>
                </div>
                <SaveFilterPreset
                  filters={filters}
                  onChange={setFilters}
                  filtersToString={filtersToString}
                  savedFilters={savedFilters}
                  busy={busy}
                  setBusy={setBusy}
                  removeSchedulerFilter={removeSchedulerFilter}
                  setSavedFilters={setSavedFilters}
                  presetName={presetName}
                  setPresetName={setPresetName}
                  updateSchedulerFilter={updateSchedulerFilter}
                  saveSchedulerFilter={saveSchedulerFilter}
                  matchingFilter={matchingFilter}
                />
              </div>
            </div>
          </Popover.Dropdown>
        </Popover>
      </div>
    </div>
  );
};

export default MeetingFilters;
