import React, { useCallback } from 'react';
import { ILocalizationOrder } from 'types/localization-order';

import { useStore } from 'store';

import { IUseActionsOptionParam } from 'utils/actions';
import { IModalSize } from 'components/dialogs/types';
import { LocalizationOrderWizardContainer } from './order-wizard-container';
import { ILocalizationOrderActionName } from 'utils/actions/localization-order';

interface IUseLocalizationOrderDialogProps {
  order?: ILocalizationOrder;
  tab?: number;
  options?: IUseActionsOptionParam<ILocalizationOrder>;
  itemAction?: boolean;
}

export const useLocalizationOrderDialog = ({
  order,
  options,
  itemAction,
}: IUseLocalizationOrderDialogProps): [() => void] => {
  const {
    dialogStore: { openModal, close },
  } = useStore();

  const body = useCallback((): JSX.Element => {
    return (
      <LocalizationOrderWizardContainer
        onFinish={(newValue) => {
          close();
          options?.onSuccess?.(ILocalizationOrderActionName.EDIT, newValue);
        }}
        localizationOrder={order}
      />
    );
  }, [order, close, options]);

  const modalTitle = `${itemAction ? 'Create' : 'Edit'} Localization Order`;
  const openDialog = (): void => {
    openModal({
      title: modalTitle,
      body,
      fitInView: true,
      size: IModalSize.XL,
    });
  };

  return [openDialog];
};
