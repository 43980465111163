import { action, observable, toJS } from 'mobx';

import { IAsset, IMm3Asset, ItemId } from 'types';
import { getIsMm3Assets } from 'utils/asset';

import { getStorageItem, setStorageItem, STORAGE_KEYS } from 'utils/storage';

type ISelected = { selected?: boolean };
export class AssetsEditStore {
  @observable assets: (IMm3Asset & ISelected)[] | (IAsset & ISelected)[] = [];
  @observable assetIds: ItemId[] = [];
  @observable isModalOpen = false;
  @observable isPresetModalOpen = false;
  @observable isSavePresetModalOpen = false;
  @observable preset: IAsset | IMm3Asset;
  @observable presetsRecord: Record<string, IAsset | IMm3Asset> = getStorageItem(STORAGE_KEYS.ASSET_EDIT_PRESETS);

  @observable onSuccess: (() => void) | null;

  @action.bound
  setPresetsRecord(name: string, preset: IMm3Asset | IAsset): void {
    setStorageItem(
      STORAGE_KEYS.ASSET_EDIT_PRESETS,
      toJS({
        ...this.presetsRecord,
        [name]: preset,
      }),
    );

    this.presetsRecord = getStorageItem(STORAGE_KEYS.ASSET_EDIT_PRESETS);
  }

  @action.bound
  setAssetIds(ids: ItemId[]): void {
    this.assetIds = ids;
  }

  @action.bound
  setAssets(assets: IAsset[]): void {
    this.assets = assets;
  }

  @action.bound
  onDataChange(asset: IAsset): void {
    this.assets = this.assets.map((currentAsset) => (currentAsset.id === asset.id ? asset : currentAsset));
  }

  @action.bound
  setIsPresetModalOpen(isOpen: boolean): void {
    this.isPresetModalOpen = isOpen;
  }

  @action.bound
  setIsSavePresetModalOpen(isOpen: boolean): void {
    this.isSavePresetModalOpen = isOpen;
  }

  @action.bound
  applyPreset(preset: IAsset | IMm3Asset): void {
    const isMm3Assets = getIsMm3Assets();
    this.assets = this.assets.map((asset) =>
      asset.selected
        ? {
            ...asset,
            ...preset,
            ...(isMm3Assets ? { meta: { ...asset.meta, ...(preset as IMm3Asset).meta } } : {}),
            id: asset.id,
          }
        : asset,
    );
  }

  @action.bound
  savePreset(preset: IAsset | IMm3Asset): void {
    this.isSavePresetModalOpen = true;
    this.preset = preset;
  }
}
