import React from 'react';
import { observer } from 'mobx-react-lite';

import { IAsset } from 'types';

import { IAssetFilters } from 'components/asset/asset-filters/utils';
import { SmartGroupFilterWrapper } from 'components/smart-group-filter-wrapper/smart-group-filter-wrapper';
import { AssetEssentialFilters } from 'components/asset/asset-filters/asset-essential-filters';
import { AssetAccessAndOwnershipFilters } from 'components/asset/asset-filters/asset-access-ownership-filters';
import { AssetSettingsFilters } from 'components/asset/asset-filters/asset-settings-filters';
import { AssetTimestampsFilters } from 'components/asset/asset-filters/asset-timestamps-filters';

import { DataSectionStore } from 'store/data-section-store';
import { useBasicStore } from 'store/hooks';
import { FILTER_SECTIONS } from 'utils/constants';
import { IFiltersHandlers } from 'helpers/filters';
import { getIsMm3Assets } from 'utils/asset';

interface ISmartGroupFormAssetFilters {
  dataSectionStore: DataSectionStore<IAsset>;
  filterValues: IAssetFilters;
  filterHandlers: IFiltersHandlers<IAssetFilters>;
}

export const SmartGroupFormAssetFilters: React.FC<ISmartGroupFormAssetFilters> = observer(
  ({ dataSectionStore, filterValues, filterHandlers }) => {
    const { divisions } = useBasicStore();
    const isMm3Assets = getIsMm3Assets();

    return (
      <>
        <SmartGroupFilterWrapper title={FILTER_SECTIONS.ESSENTIALS}>
          <AssetEssentialFilters
            filterValues={filterValues}
            filterHandlers={filterHandlers}
            dataSectionStore={dataSectionStore}
            withMainAssetsFilter={isMm3Assets}
            isSmartGroupFilters
          />
        </SmartGroupFilterWrapper>
        <SmartGroupFilterWrapper title={FILTER_SECTIONS.ACCESS_OWNERSHIP} wrapperClassName="access-filter__wrapper">
          <AssetAccessAndOwnershipFilters
            filterValues={filterValues}
            filterHandlers={filterHandlers}
            dataSectionStore={dataSectionStore}
            divisions={divisions}
          />
        </SmartGroupFilterWrapper>
        <SmartGroupFilterWrapper title={FILTER_SECTIONS.SETTINGS}>
          <AssetSettingsFilters
            filterValues={filterValues}
            filterHandlers={filterHandlers}
            dataSectionStore={dataSectionStore}
          />
        </SmartGroupFilterWrapper>
        <SmartGroupFilterWrapper title={FILTER_SECTIONS.TIMESTAMPS}>
          <AssetTimestampsFilters filterValues={filterValues} filterHandlers={filterHandlers} />
        </SmartGroupFilterWrapper>
      </>
    );
  },
);
