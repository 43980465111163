import { useCallback, useState } from 'react';

import { isEqual } from 'lodash';

import { loadAsset } from 'utils/apis/asset';
import { useInterval } from 'utils/hooks/use-interval';
import { refreshPreviewIngestingDelay } from 'utils/constants';
import { ingestStatuses } from 'utils/asset';

import { IAsset, IAssetFile, IMm3Asset } from 'types';

export interface IAssetFileUploadDialog {
  onClick: () => void;
  files: IAssetFile[];
  progress: number;
  isUploadPending: boolean;
  uploadFinished: boolean;
  asset?: IAsset | IMm3Asset;
  removeFile: (file: { id: string }) => void;
  isSending: boolean;
  setAsset: (asset: IAsset | IMm3Asset) => void;
  isSubmitted: boolean;
  cleanUp: () => void;
  isCancelled: boolean;
}

export function isAssetFileIngesting({
  file_status,
  preview_image,
}: Pick<IAsset | IMm3Asset, 'file_status' | 'preview_image' | 'preview_image_id' | 'classification'>): boolean {
  return Boolean(ingestStatuses.has(file_status || '') || ingestStatuses.has(preview_image?.status || ''));
}
export const useRefreshIngestingAssetFile = (
  asset: IAsset | IMm3Asset | undefined,
  setAsset: (asset?: IAsset | IMm3Asset | null) => void,
): boolean => {
  const [isCleared, setIsCleared] = useState(false);

  const refresh = useCallback(
    async (id: number): Promise<void> => {
      if (!asset || !asset.id) return;
      if (!isAssetFileIngesting(asset)) {
        setIsCleared(true);
        return clearInterval(id);
      }
      const newValue = await loadAsset(asset?.id);
      const hasChanged = !isEqual(asset, newValue);

      if (hasChanged) {
        setAsset(newValue);
        setIsCleared(!isAssetFileIngesting(newValue));
      }
    },
    [setAsset, asset],
  );

  useInterval(refresh, refreshPreviewIngestingDelay);
  return isCleared;
};
