import React, { useCallback } from 'react';
import { IProduct } from 'types';
import { IFormMultiSelectOption } from 'helpers/form';
import { FormProducts } from 'helpers/form';

import { parseProductAncestors } from 'components/delivery/utils';
import { ILocalizationOrderStep } from 'components/localization-orders/types';
import { fetchAllProductsDescendants } from 'utils/apis/product';

export const OrdersProductsStep: React.FC<ILocalizationOrderStep> = ({ orderForm: { formData, handlers } }) => {
  const { onChange } = handlers;

  const fetchDescendants = async (productId: string): Promise<IProduct[]> => {
    return await fetchAllProductsDescendants([productId]);
  };

  const setProductAncestry = useCallback(
    async (options: IFormMultiSelectOption[]): Promise<void> => {
      const descendants = await fetchDescendants(options[0]?.id as string);
      const productAncestry = parseProductAncestors(options);
      onChange({
        product_ancestry: productAncestry,
        product_id: options[0]?.id as number,
        products: [options[0] as IProduct, ...descendants],
      });
    },
    [onChange],
  );

  return (
    <div className="product-recommendation-form h-100">
      <FormProducts
        name="product_ids"
        label="Products"
        fitInParent
        omni
        onSelectedItemsChange={setProductAncestry}
        {...formData.product_ids}
        {...handlers}
      />
    </div>
  );
};
