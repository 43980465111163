import { Row } from 'components/label-value-pair';
import React from 'react';
import { IContact, IITVStudiosContact } from 'types/contact';

interface ISalesforceContactPreviewProps {
  contact: IITVStudiosContact;
}
export const CustomContactRow: React.FC<{ contact: IContact }> = () => <></>;

export const CustomContactSettingsRow: React.FC<{ contact: IContact }> = () => <></>;

export const CustomContactBusinessRow: React.FC<ISalesforceContactPreviewProps> = ({ contact }) => {
  return (
    <>
      <Row label="Salesforce ID" value={contact.salesforce_id} size="m" />
    </>
  );
};
